import 'babel-polyfill'; 
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import Main from './Main'
//import PDF from './PDF'
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Main/>, document.getElementById('root'));
serviceWorker.unregister();
