import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col,Table,Select,Tag,Radio,Button,DatePicker, Modal} from "antd";
import './App.css';
import moment from 'moment';
import {ApiHeader,strings,apiurl,DTD_ROLES,NORMAL_ROLES,ONLINE_ROLES} from './data';
const Option = Select.Option;

const locale = 'EN';

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            systemData:null,
            resource:null,            
            loading:false,            
            type:'daily',
            fromdate:'',
            fromdate1:null,
            todate1:null,
            todate:'',           
            tabledata:[],
            count:0,
            triggerReload:false,
            showalertmodel:false,
            alertmessage:"",
        }
        this.columns = [
            {
                title: strings.date,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))
            }, {
                title: strings.type,
                dataIndex: 'type',
                key: 'type',
                width:'20%',
                sorter: (a, b) => a.type.localeCompare(b.type, 'en', { numeric: true }),
            }, {
                title: strings.name,
                dataIndex: 'name',
                key: 'name',
                width:'35%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
                render:(a,b)=>(a.replace("()",""))
            },
            {
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {
                        return <Tag color={'#455773'} key={tag}  style={{marginTop:5}}><a href={"/srs_report/"+record.fileName}>{tag.toUpperCase()}</a></Tag>;
                    })}
                    </span>
                ),
            }
        ];
    }

    

    componentDidMount() {
        sessionStorage.setItem('tabNumber',16);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        var cd = moment();
        this.setState({resource:sd.resource,systemData:sd,fromdate:cd.format('YYYYMMDD'),fromdate1:cd,todate:cd.format('YYYYMMDD'),todate1:cd});        
    }
  
    componentWillUnmount() {
  
    }


    downloadreport=()=>{
        var rdata = {};
        var isvalid = true;
        rdata.type = this.state.type;          
        
        if(this.state.fromdate != ''){
            rdata.fromdate=this.state.fromdate;
        }else{
            isvalid=false;
        }

        if(this.state.todate != ''){
            rdata.todate=this.state.todate;
        }else{
            isvalid=false;
        }
            
        if(isvalid==false){
            this.setState({alertmessage:strings.plsfillalletc,showalertmodel:true})
            return;
        }

        this.setState({loading:true})
        fetch(apiurl+'report/download', {
            headers: ApiHeader,
            method: 'POST',   
            credentials: 'same-origin',
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data.reportdata;
                    const bytes = this.base64ToArrayBuffer(bs);
                    this.saveByteArray('OperationalParking_'+moment().format('YYYYMMDD')+'.xls',bytes);
                
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    saveByteArray=(reportName, byte)=> {
        var blob = new Blob([byte], {type: "application/vnd.ms-excel"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    }

    base64ToArrayBuffer=(base64)=> {
        var binaryString = window.atob(base64);
       
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
           var ascii = binaryString.charCodeAt(i);
           bytes[i] = ascii;
        }
        return bytes;
    }

    searchReports=()=>{
        
        var rdata = {};
        var isvalid = true;
        rdata.type = this.state.type;          
        rdata.role = sessionStorage.getItem("userrole");
        if(this.state.fromdate != ''){
            rdata.fromdate=this.state.fromdate;
        }else{
            isvalid=false;
        }

        if(this.state.todate != ''){
            rdata.todate=this.state.todate;
        }else{
            isvalid=false;
        }
            
        if(isvalid==false){
            this.setState({alertmessage:strings.plsfillalletc,showalertmodel:true})
            return;
        }
        
        this.setState({loading:true})
        fetch(apiurl+'report/list', {
            headers: ApiHeader,
            method: 'POST',   
            credentials: 'same-origin',
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data.report;
                    for(var i=0;i<bs.length;i++){                                             
                        bs[i].key = i+1;
                        bs[i].date = moment.unix((bs[i].createTime/1000)).format("YYYY-MM-DD");
                        bs[i].tags = [strings.download];
                        if(bs[i].type == 'daily'){
                            bs[i].type = strings.Daily;
                        }
                    }
                    this.setState({tabledata:bs,count:bs.length});
                
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    
    

    onfromChange=(val)=>{
        this.setState({fromdate1:val,fromdate:val.format('YYYYMMDD')})
    }

    ontoChange=(val)=>{
        this.setState({todate1:val,todate:val.format('YYYYMMDD')})
    }

    handleTypeChange=(value)=> {       
        
        this.setState({type:value});
    }

    clearScrren=()=>{
        var cd = moment();
        this.setState({
            type:'daily',
            fromdate:cd.format('YYYYMMDD'),
            fromdate1:cd,
            todate:cd.format('YYYYMMDD'),
            todate1:cd
        })        
    }

    
    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
    }
    
    render() {
    
    
        return (
            <div className='Tab2Div'>
                    <div className="FirstDiv">
                        <span className="TitleOfTheBox">{strings.searchreport}</span>
                        <Row xs={24} >
                            <Col md={4}>
                                <div>
                                    <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                                    <span className="placeholder">{strings.type}</span>
                                </div>
                            </Col> 
                            <Col md={7}>                                
                                <Select className="InputSizeOfThird" value={this.state.type} onChange={this.handleTypeChange} className='bookingDatebox1'>
                                    <Option value="daily">{strings.daily}</Option>
                                    {NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) && <Option value="operational">{strings.operationalcarpark}</Option>}
                                </Select>
                            </Col>                            
                        </Row>

                        <Row md={24} style={{marginTop:30,marginBottom:30}}>
                            <Col md={4}>
                                <div>
                                    <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                                    <span className="placeholder" >{strings.fromdate}</span>
                                </div>
                            </Col>
                            <Col md={7}>
                                <DatePicker className="InputSizeOfThird" value={this.state.fromdate1} onChange={this.onfromChange} className='bookingDatebox1'/>
                            </Col>
                            <Col md={3}>
                                <div>
                                    <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                                    <span className="placeholder" >{strings.todate}</span>
                                </div>
                            </Col>
                            <Col md={7}>
                                    <DatePicker className="InputSizeOfThird" value={this.state.todate1} onChange={this.ontoChange} className='bookingDatebox1'/>
                            </Col>
                        </Row>
                        <Button type="primary" size='large' style={{marginRight: 17}} hidden={this.state.type != 'daily'}  loading={this.state.loading} onClick={this.searchReports}>
                            {strings.search}
                        </Button>
                        <Button type="primary" size='large' style={{marginRight: 17}} hidden={this.state.type == 'daily'} loading={this.state.loading} onClick={this.downloadreport}>
                            {strings.download}
                        </Button>
                        <Button size='large' style={{marginRight: 20}}  onClick={this.clearScrren}>
                        {   strings.clear}
                        </Button>
                    </div>

                    <div>
                        <div className="table-operations">
                        </div>
                        <Table style={{marginTop:12,backgroundColor:'#fff'}} columns={this.columns} dataSource={this.state.tabledata} />
                        <div className="CountOfData">
                            <span className="CountText">{strings.totalcount} {this.state.count}</span>
                        </div>        
                    </div>                    
                
                    <Modal
                        title={strings.alert}
                        visible={this.state.showalertmodel}
                        onCancel={()=>this.setState({showalertmodel:false})}
                        footer={[                  
                            <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerReload)window.location.reload();}}>
                            {strings.ok}
                            </Button>,
                        ]}
                        >
                            <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                            <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                    </Modal>                              
            </div>
        );
    }
}    
export default Report;

