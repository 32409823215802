import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Row, Col, Table, Select, Tag, Button, Radio, Modal, Popconfirm, Input, DatePicker, message, Empty, Spin, Upload, Icon } from "antd";
import './App.css';
import moment from 'moment';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES, TYPES, SIZES, ZONES, DIVISIONS } from './data';
import Workbook from 'react-excel-workbook';

const Option = Select.Option;
const locale = 'EN';
const COLORS = ['#8884d8', '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#83a6ed', '#FF00FF'];
class AnalystUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemData: null,
            resource: null,
            loading: false,
            showalertmodel: false,
            alertmessage: "",
            users:[],
            fileList:[],
        }
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber', 5);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({ resource: sd.resource, systemData: sd });     
        this.getUsers();
    }

    componentWillUnmount() {

    }

    getUsers = () => {        
        this.setState({ loading: true })
        fetch(apiurl + 'user/getAnalystUser', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                this.setState({ users: data.data.user});        
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
            }
            this.setState({ loading: false })
        })
        .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    handleUpload = () => {
        const { fileList } = this.state;
        var fname = fileList[0].name;
        var fs = fname.split('.');
        if(fs[fs.length-1] != 'xlsx'){
          message.warning('Please Select valid excel file')
          return;
        }    
        this.setState({loading:true});         
        var formData = new FormData();
        formData.append('file', fileList[0]);
        fetch(apiurl+'user/uploadAnalystUsers', {
            headers: {
                'token':"srswebapp",
                'userId':sessionStorage.getItem("userId")
            },
            method: 'POST',
            processData: false,
            credentials: 'same-origin',
            body: formData,      
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){                    
                    this.setState({fileList:[],loading:false});  
                    this.getUsers();          
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    onfromChange = (val) => {
        this.setState({ fromdate: val })
    }

    clearScrren=()=>{
        this.setState({fromdate: moment()});
    }
    

    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
    }

    render() {

        const columns=[{
            title: strings.login,
            dataIndex: 'login',
            key: 'login',
            width: '20%',
        }, {
            title: strings.name,
            dataIndex: 'name',
            key: 'name',
            width: '30%',
        }]
        const {loading,fileList} = this.state;
        const props = {
            onRemove: (file) => {
              this.setState((state) => {
                const index = state.fileList.indexOf(file);
                const newFileList = state.fileList.slice();
                newFileList.splice(index, 1);
                return {
                  fileList: newFileList,
                };
              });
            },
            beforeUpload: (file) => {
              this.setState({
                fileList: [file],
              });
              return false;
            },
            fileList,
        };
        return (
            <div>
                <div className="FirstDiv" style={{ marginTop: 10 }}>
                    <span className="TitleOfTheBox">{strings.user}</span>
                    <Row span={24} >
                        <Col span={12}>
                            <div style={{float:'left'}}>
                                <Upload {...props}>
                                    <Button >
                                        <Icon type="upload" /> {strings.selectfile}
                                    </Button>
                                </Upload>
                            </div>                            
                            <Button
                                type="primary"
                                onClick={this.handleUpload}
                                disabled={fileList.length === 0}
                                loading={loading}
                                style={{marginLeft:15,display:'inline-block'}}
                                >
                                {strings.clicktoupload}
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Workbook
                                    filename="Carpark_Analyst_users.xlsx"
                                    element={<Button type="primary" style={{marginLeft: 15,float:'right'}}>{strings.download}</Button>}>
                                    <Workbook.Sheet data={this.state.users} name="Users">
                                        <Workbook.Column label="Login" value="login" />
                                        <Workbook.Column label={'Name'} value='name' />
                                    </Workbook.Sheet>
                            </Workbook>
                        </Col>
                    </Row> 
                </div>
                <div style={{ marginTop: 20 }}>
                    <Spin spinning={this.state.loading}> 
                        <Table columns={columns} dataSource={this.state.users} pagination={{pageSize:10}}/>
                    </Spin>
                </div>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={() => this.setState({ showalertmodel: false })}
                    footer={[
                        <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerReload) window.location.reload(); }}>
                            {strings.ok}
                        </Button>,
                    ]}
                >
                    <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                    <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                </Modal>
            </div>
        );
    }
}
export default AnalystUser;

