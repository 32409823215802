import React, { Component } from 'react';
import { Row, Col, Tabs, Form, Menu, Dropdown, Badge, Modal, Button, Radio, Spin, Tag, Icon, Tooltip } from "antd";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import fetch from './FetchWithTimeout';
import './App.css';
import moment from 'moment';
import { ApiHeader, strings, isdevelopment, apiurl, admin_roles, ADMIN_ROLES, NORMAL_ROLES } from './data'
import Booking from './Booking'
import Login from './Login'
import SensorData from './SensorData'
import Schedule from './Schedule'
import MyBooking from './MyBooking'
import PendingBooking from './PendingBooking'
import Carpark from './Carpark'
import Setting from './Setting'
import Malfunction from './Malfunction'
import Maintenance from './Maintenance'
import bg from './assets/EMSD_HQ.jpg'
import User from './User';
import Setup from './Setup';
import AdminSchedule from './AdminSchedule'
import AdminDashboard from './AdminDashboard'
import PendingForAction from './PendingForAction'
import Report from './Report';
import System from './System';
import Reminder from './Reminder';
import ZoomBook from './ZoomBook';
import Dtd from './Dtd';
import Visitor from './Visitor';
import Analyst from './Analyst'

const TabPane = Tabs.TabPane;

const MINUTES_UNITL_AUTO_LOGOUT = 30 // in mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY = 'lastAction';
var locale = 'EN';
class Main extends Component {
  constructor(props) {
    super(props);
    this.check();
    this.initListener();
    this.initInterval();
    this.state = {
      isAuthenticated: false,
      isadminView: false,
      notification: [],
      subscribelist: [],
      showalertmodel: false,
      alertmessage: '',
      ntype: 'a',
      loading: false,
      resource: [],
      zoomAccount: [],
      menuvisible: false,
      loginlang: 'en',
      loginloading: false,
      tabval: '1',
      isbookmarkbooking: false,
      news: [],
      showAndroid: false,
      showHuewai:false,
      showApple: false,
      showtext: false,
      ismobile:false
    }
  }


  getLastAction() {
    return parseInt(sessionStorage.getItem(STORE_KEY));
  }

  setLastAction(lastAction) {
    sessionStorage.setItem(STORE_KEY, lastAction.toString());
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (isTimeout) {
      sessionStorage.setItem("isAuthenticated", false);
      this.setState({ isAuthenticated: false })
      this.signout();
      sessionStorage.clear();

    }
  }

  blinktext = () => {
    if (this.state.showApple) {
      var x = document.getElementById("blinktext");
      x.style.color = x.style.color == "red" ? "black" : "red";
    }

    if (this.state.showAndroid) {
      var x = document.getElementById("blinktext1");
      x.style.color = x.style.color == "red" ? "black" : "red";
    }

    if(this.state.showHuewai){
      var x = document.getElementById("blinktext2");
      x.style.color = x.style.color == "red" ? "black" : "red";
    }
  }

  componentDidMount() {

    let isMobile = RegExp(/Android|webOS|iPhone/i).test(navigator.userAgent);
		if(isMobile){
      this.setState({ismobile:true});
    }

    setInterval(this.blinktext, 300)
    if (sessionStorage.getItem("isAuthenticated") == 'true' && sessionStorage.getItem('systemdata') != null) {         
      ApiHeader.userId = sessionStorage.getItem("userId");
      strings.setLanguage(sessionStorage.getItem("userlang") != null ? sessionStorage.getItem("userlang") : 'en');
      var d = sessionStorage.getItem('systemdata');
      var sd = JSON.parse(d);
      this.setState({ resource: sd.resource });
      this.setState({ zoomAccount: sd.zoomAccounts });

      this.searchSubscribed();
      this.searchnotification();
      this.getusernews();
      if (sessionStorage.getItem('tabNumber') == null || sessionStorage.getItem('tabNumber') == 0) {
        sessionStorage.setItem('tabNumber', 1);
      }
      if (sessionStorage.getItem('isadminView') === 'true') {
        this.setState({ tabval: sessionStorage.getItem("userrole") != ADMIN_ROLES.ONLINE ? '11' : '12' })
      } else {
        this.setState({ tabval: '1' })
      }
      this.hydrateStateWithSessionStorage();
      window.addEventListener(
        "beforeunload",
        this.saveStateTosessionStorage.bind(this)
      );
    } else {
      sessionStorage.setItem("isAuthenticated", false);
      this.setState({
        isAuthenticated: false,
        isadminView: false,
        notification: [],
        subscribelist: [],
        showalertmodel: false,
        alertmessage: '',
        ntype: 'a',
        loading: false,
        resource: [],
        zoomAccount: [],
        menuvisible: false,
        loginlang: 'en',
        loginloading: false,
      });
    }

  }

  componentWillUnmount() {
    this.setState({ isAuthenticated: false, visiblefl: false, isadminView: false });
    window.removeEventListener(
      "beforeunload",
      this.saveStateTosessionStorage.bind(this)
    );

  }


  hydrateStateWithSessionStorage() {
    // for all items in state
    for (let key in this.state) {
      if (key != 'notification' && key != 'subscribelist' && key != 'resource' && key != 'zoomAccount' && key != 'loginloading' && key != 'tabval' && key != 'isbookmarkbooking' && sessionStorage.hasOwnProperty(key)) {

        let value = sessionStorage.getItem(key);

        try {
          value = JSON.parse(value);
          this.setState({ [key]: value });
        } catch (e) {
          // handle empty string
          this.setState({ [key]: value });
        }
      }
    }
  }

  saveStateTosessionStorage() {
    //alert('before unload');
    for (let key in this.state) {
      if (key != 'notification' && key != 'subscribelist' && key != 'resource' && key != 'zoomAccount' && key != 'loginloading' && key != 'tabval' && key != 'isbookmarkbooking')
        sessionStorage.setItem(key, JSON.stringify(this.state[key]));
    }
  }

  authenticate = (form) => {
    if (isdevelopment === 1) {
      var r = {};
      r.username = 'gk';
      r.password = 'srs';
      fetch(apiurl + 'user/login', {
        headers: ApiHeader,
        method: 'POST',
        body: JSON.stringify(r),
      }).then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
        .then(data => {
          if (data.code == "1") {
            sessionStorage.setItem("username", data.data.user.login);
            this.fetchUserData(data.data.user.login);


          } else {
            this.setState({ loginloading: false });
            alert("Error code : " + data.error_code);
          }
        })
        .catch(e => { this.setState({ loginloading: false }); this.signout() })
    } else {
      const data = new FormData();
      data.append('username', form.userName);
      data.append('password', form.password);
      data.append('fakedata', 'test');
      this.setState({ loginloading: true });
      fetch(apiurl + 'perform_login', {
        method: 'POST',
        headers:{'Cookie':""},
        body: data,
      }).then(v => {
        if (v.status == 200) {
          sessionStorage.setItem("username", form.userName);
          this.fetchUserData(form.userName);
        } else {
          this.setState({ loginloading: false });
          alert(strings.userpasswordwrong);

        }
      })
        .catch(e => {
          if (e.message == 'timeout') {
            alert('Request timeout. Please try again');
          } else {
            alert(strings.userpasswordwrong);
          }
          this.setState({ loginloading: false });

        })

    }


  }


  signout = () => {
    
    fetch(apiurl + 'perform_logout', {
      method: 'GET',
      credentials: 'same-origin',
      timeout: 3000,
    }).then(v => {
      sessionStorage.setItem('isAuthenticated', false);
      //sessionStorage.setItem('systemdata',"");
      sessionStorage.setItem("userId", 0);
      sessionStorage.setItem("username", "");
      sessionStorage.setItem("userrole", "");
      sessionStorage.setItem('tabNumber', 1);
      sessionStorage.setItem('isadminView', false);
      sessionStorage.clear();
      document.cookie = "JSESSIONID" +'=; Path=/srs; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';      
      this.setState({ isAuthenticated: false, visiblefl: false, isadminView: false, tabval: '1' });
      //if(v.redirected) window.location = v.url;
    })
      .catch(e => {
        console.warn(e);
        sessionStorage.setItem('isAuthenticated', false);
        //sessionStorage.setItem('systemdata',"");
        sessionStorage.setItem("userId", 0);
        sessionStorage.setItem("username", "");
        sessionStorage.setItem("userrole", "");
        sessionStorage.setItem('tabNumber', 1);
        sessionStorage.setItem('isadminView', false);
        sessionStorage.clear();
        this.setState({ isAuthenticated: false, visiblefl: false, isadminView: false, tabval: '1' });

      })
  }

  fetchSystemData = (userId) => {
    fetch(apiurl + 'system/list', {
      headers: ApiHeader,
      method: 'GET',
      credentials: 'same-origin',
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          
          
          sessionStorage.setItem('systemdata', JSON.stringify(data.data));
          sessionStorage.setItem("isAuthenticated", true);
          this.setState({ isAuthenticated: true });
          //window.location.reload()
          ApiHeader.userId = sessionStorage.getItem("userId");
          strings.setLanguage(sessionStorage.getItem("userlang") != null ? sessionStorage.getItem("userlang") : 'en');
          var d = sessionStorage.getItem('systemdata');
          var sd = JSON.parse(d);

          var anyadmins = sd.analystAdmin;
          for(var aa of anyadmins){
            if(aa.id === userId){
              sessionStorage.setItem('analystAdmin', 'Y');
            }
          }

          this.setState({ resource: sd.resource });
          this.setState({ zoomAccount: sd.zoomAccounts });

          this.searchSubscribed();
          this.searchnotification();

          sessionStorage.setItem('tabNumber', 1);

          this.hydrateStateWithSessionStorage();
          window.addEventListener(
            "beforeunload",
            this.saveStateTosessionStorage.bind(this)
          );

          setTimeout(()=>this.setState({ loginloading: false }),2000)
          
        } else {
          this.setState({ loginloading: false });
          alert("Error code : " + data.error_code);
        }
      })
      .catch(e => { this.setState({ loginloading: false }); console.warn(e); this.signout() })
  }


  checkSession = () => {
    fetch(apiurl + 'user/checkSession', {
      headers: {
        'Content-Type': 'application/json',
        'token': "srswebapp"
      },
      timeout: 3000,
      method: 'GET',
      credentials: 'same-origin',
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          ApiHeader.userId = sessionStorage.getItem("userId");
          strings.setLanguage(sessionStorage.getItem("userlang") != null ? sessionStorage.getItem("userlang") : 'en');
          var d = sessionStorage.getItem('systemdata');
          var sd = JSON.parse(d);
          this.setState({ resource: sd.resource });
          this.setState({ zoomAccount: sd.zoomAccounts });
          this.searchSubscribed();
          this.searchnotification();

          if (sessionStorage.getItem('tabNumber') == null || sessionStorage.getItem('tabNumber') == 0) {

            sessionStorage.setItem('tabNumber', 1);
          }
          this.hydrateStateWithSessionStorage();
          window.addEventListener(
            "beforeunload",
            this.saveStateTosessionStorage.bind(this)
          );

          this.check();
          this.initListener();
          this.initInterval();
        } else {
          sessionStorage.setItem("isAuthenticated", false);
          this.setState({ isAuthenticated: false })
          this.signout();
          sessionStorage.clear();
        }
      })
      .catch(e => { this.signout() })
  }

  fetchUserData = (name) => {
    var rdata = {};
    rdata.name = name;
    fetch(apiurl + 'user/details', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          var user = data.data.user;
          if (user.isDtd != null) {
            sessionStorage.setItem("isDtd", user.isDtd);
          } else {
            sessionStorage.setItem("isDtd", 'N');
          }
          if (user.permission != null && user.permission.includes('carpark_analyst')) {
            sessionStorage.setItem("isAnalyst", 'Y');
          } else {
            sessionStorage.setItem("isAnalyst", 'N');
          }
          if (user.role != null) {
            sessionStorage.setItem("userrole", user.role);
            sessionStorage.setItem('tabNumber', 1);
          }
          sessionStorage.setItem('tabNumber', 1);
          if (user.lang != null) {
            sessionStorage.setItem("userlang", user.lang.toLowerCase());
          }
          strings.setLanguage(user.lang != null ? user.lang.toLowerCase() : 'en');
          if (user.id != null) {
            sessionStorage.setItem("userId", user.id);
            ApiHeader.userId = user.id;
            this.fetchSystemData(user.id);
            this.getusernews();
          }
        } else {
          this.setState({ loginloading: false });
          alert("Error code : " + data.error_code);
        }
      })
      .catch(e => { this.setState({ loginloading: false }); console.warn(e); this.signout() })
  }

  changeView = () => {
    if (this.state.isadminView) {
      //sessionStorage.setItem('tabNumber',1);
      this.setState({ isadminView: !this.state.isadminView, tabval: '1' })

    } else {
      // sessionStorage.setItem('tabNumber',11);
      this.setState({ isadminView: !this.state.isadminView, tabval: sessionStorage.getItem("userrole") != ADMIN_ROLES.ONLINE ? '11' : '12' })
    }
  }


  searchnotification = () => {
    this.setState({ loading: true })
    var rdata = {};
    rdata.userId = ApiHeader.userId;
    fetch(apiurl + 'notification/listUnread', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          var data1 = data.data.notifications;

          for (var i = 0; i < data1.length; i++) {
            data1[i].isopen = false;
          }
          this.setState({ notification: data1 })
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
      })
      .catch(e => console.warn(e))
  }

  searchSubscribed = () => {

    var rdata = {};
    rdata.userId = ApiHeader.userId;
    fetch(apiurl + 'subscribe/list', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {

          var bss = data.data.resourceSubscribes;
          var bs = [];
          for (var i = 0; i < bss.length; i++) {
            bs = bs.concat(bss[i].resourceSubscribe)
          }

          for (var i = 0; i < bs.length; i++) {
            bs[i].key = i;
            bs[i].sd = bs[i].subscribeDate.substring(0, 4) + "-" + bs[i].subscribeDate.substring(4, 6) + "-" + bs[i].subscribeDate.substring(6, 8);
            var resource = this.state.resource;

            for (var j = 0; j < resource.length; j++) {
              if (resource[j].id === bs[i].resourceId) {
                if (locale === 'EN') {
                  bs[i].rname = resource[j].nameEN;

                } else {
                  bs[i].rname = resource[j].nameZH;
                }
                break;
              }
            }
          }
          this.setState({ subscribelist: bs })
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }

      })
      .catch(e => console.warn(e))
  }

  searchnotification1 = () => {
    this.setState({ loading: true })
    var rdata = {};
    rdata.userId = ApiHeader.userId;
    fetch(apiurl + 'notification/listread', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          var data1 = data.data.notifications;

          for (var i = 0; i < data1.length; i++) {
            data1[i].isopen = false;
          }
          this.setState({ notification: data1 })
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
      })
      .catch(e => console.warn(e))
  }

  notificationClick = (n, index) => {

    var rdata = {};
    rdata.notificationId = n.id;
    fetch(apiurl + 'notification/read', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          var data = this.state.notification;
          data.splice(index, 1);
          this.setState({ notification: data, menuvisible: true })
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
      })
      .catch(e => console.warn(e))
  }

  readAll = () => {

    fetch(apiurl + 'notification/readAll', {
      headers: ApiHeader,
      credentials: 'same-origin',
      method: 'GET',
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          this.reloadnotification();
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
      })
      .catch(e => console.warn(e))
  }

  cancelSubscribe = (n, index) => {
    var rdata = {};
    rdata.subscribeId = n.id;
    fetch(apiurl + 'subscribe/cancel', {
      headers: ApiHeader,
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          var data = this.state.subscribelist;
          data.splice(index, 1);
          this.setState({ subscribelist: data })
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
      })
      .catch(e => console.warn(e))
  }

  getusernews = () => {
    fetch(apiurl + 'system/getusernews', {
      headers: ApiHeader,
      method: 'GET',
      credentials: 'same-origin',
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          this.setState({ news: data.data.news });
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true, issearching: false })
        }
        this.setState({ issearching: false })
      })
      .catch(e => { console.warn(e); this.setState({ issearching: false }) })
  }

  newsread = (newsid) => {
    var rdata = {};
    rdata.newsId = newsid;
    rdata.userId = ApiHeader.userId;
    fetch(apiurl + 'system/newsread', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          this.getusernews()
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true, issearching: false })
        }
        this.setState({ issearching: false })
      })
      .catch(e => { console.warn(e); this.setState({ issearching: false }) })
  }


  rowclick = (n, index) => {
    var data = this.state.notification;
    data[index].isopen = !data[index].isopen;
    this.setState({ notification: data });
  }

  rendermenu = () => {
    var data = [];
    this.state.notification.map((n, index) => {
      data.push(
        this.state.ntype == 'a' ? <Menu.Item key={index} style={{ width: 650, color: '#405069', cursor: 'default', height: n.isopen == false ? 40 : 70 }} >
          <Row xs={24}  >
            <Col md={1} onClick={() => this.rowclick(n, index)} style={{ cursor: 'pointer' }}><span style={{ fontSize: 18, fontWeight: 500, cursor: 'pointer' }}>{n.isopen == true ? '-' : '+'}</span></Col>
            <Col md={1} onClick={() => this.rowclick(n, index)} style={{ cursor: 'pointer' }}><span style={{ fontSize: 14, fontWeight: 500 }}>{index + 1}</span></Col>
            <Col md={15} onClick={() => this.rowclick(n, index)} style={{ cursor: 'pointer' }}>
              <Tooltip title={n.subject}><p style={{ fontSize: 14, fontWeight: 500, margin: 0, marginLeft: 10 }} className="notificationp">{n.subject}</p></Tooltip>
            </Col>
            <Col md={5} onClick={() => this.rowclick(n, index)} style={{ cursor: 'pointer' }}><span style={{ fontSize: 12, fontWeight: 500 }}>{moment.unix((n.sendTime / 1000)).format("YYYY-MM-DD hh:mmA").replace('AM', 'am').replace('PM', 'pm')}</span></Col>
            <Col md={2} ><Tag color={'#455773'} style={{ fontSize: 12, fontWeight: 500, marginLeft: 5 }} onClick={() => this.notificationClick(n, index)}>{strings.read}</Tag></Col></Row>
          <div hidden={n.isopen == false}><span style={{ fontSize: 12, fontWeight: 500 }}>{n.content.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm')}</span></div>
        </Menu.Item> : <Menu.Item key={index} style={{ width: 650, color: '#405069', height: n.isopen == false ? 40 : 70, cursor: 'default' }}>
          <Row xs={24} onClick={() => this.rowclick(n, index)} style={{ cursor: 'pointer' }}>
            <Col md={1}  ><span style={{ fontSize: 18, fontWeight: 500, cursor: 'pointer' }}>{n.isopen == true ? '-' : '+'}</span></Col>
            <Col md={1} ><span style={{ fontSize: 14, fontWeight: 500 }}>{index + 1}</span></Col>
            <Col md={15} >
              <Tooltip title={n.subject}><p style={{ fontSize: 14, fontWeight: 500, margin: 0, marginLeft: 10 }} className="notificationp">{n.subject}</p></Tooltip>
            </Col>
            <Col md={5} ><span style={{ fontSize: 12, fontWeight: 500, marginRight: 10, float: 'right' }}>{moment.unix((n.sendTime / 1000)).format("YYYY-MM-DD hh:mmA").replace('AM', 'am').replace('PM', 'pm')}</span></Col></Row>
          <div hidden={n.isopen == false}><span style={{ fontSize: 12, fontWeight: 500 }}>{n.content.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm')}</span></div>
        </Menu.Item>
      );
      data.push(<Menu.Divider />);
    });
    return data;
  }
  
  rendermenu1 = () => {

    var menu = <Menu>
      <Radio.Group value={this.state.ntype} buttonStyle="solid" onChange={(e) => this.notificationTabClick(e)} style={{ marginLeft: 240, marginBottom: 5 }}>
        <Radio.Button value="a" >{strings.unread}</Radio.Button>
        <Radio.Button value="b">{strings.read1}</Radio.Button>
      </Radio.Group>

      <Button style={{ marginTop: 3, marginLeft: 90, fontSize: 14 }} onClick={() => this.readAll()}>{strings.readall}</Button>
      <Button style={{ marginTop: 3, float: 'right', marginRight: 10, fontSize: 14 }} onClick={() => this.reloadnotification()}><Icon type="sync" /></Button>
      <Spin spinning={this.state.loading}>
        <Menu style={{ height: 600, overflowY: 'auto', overflowX: 'hidden' }}>
          <Menu.Divider />
          <Menu.Item key={999} style={{ width: 650, color: '#405069', height: 30, cursor: 'default' }}>
            <div><span style={{ fontSize: 14, fontWeight: 500, marginLeft: 130 }}>{strings.notification}</span><span style={{ fontSize: 12, fontWeight: 500, marginRight: 110, float: 'right' }}>{strings.date}</span></div>
          </Menu.Item>
          <Menu.Divider />
          {this.rendermenu()}
        </Menu>
      </Spin>
    </Menu>
    return menu;
  }

  reloadnotification = () => {
    if (this.state.ntype == 'a') {
      this.searchnotification();
    } else {
      this.searchnotification1();
    }
  }

  rendermenu3 = () => {
    var data = [];
    if (this.state.subscribelist.length > 0) {
      this.state.subscribelist.map((n, index) => {
        data.push(
          <Menu.Item key={index} style={{ width: 500, color: '#405069', height: 30, cursor: 'default' }}>
            <Row xs={24}>
              <Col md={14} ><span style={{ fontSize: 14, fontWeight: 500 }}>{n.rname}</span></Col>
              <Col md={6} ><span style={{ fontSize: 12, fontWeight: 500, }}>{n.sd}</span></Col>
              <Col md={4} ><Tag color={'#455773'} style={{ fontSize: 12, fontWeight: 500, marginRight: 10 }} onClick={() => this.cancelSubscribe(n, index)}>{strings.cancel}</Tag></Col>
            </Row>
          </Menu.Item>
        );
        data.push(<Menu.Divider />);
      });
    } else {
      data.push(<Menu.Item key={1} style={{ width: 300, color: '#405069', height: 30, cursor: 'default', textAlign: 'center' }}>
        <div><span style={{ fontSize: 15, fontWeight: 500 }}>{strings.nodata}</span></div>
      </Menu.Item>);
    }

    return data;
  }


  rendermenu2 = () => {

    var menu = <Menu>
      <Menu.Item key={999} style={{ width: 500, color: '#405069', height: 30, cursor: 'default' }}>
        <Row xs={24}>
          <Col md={14} ><span style={{ fontSize: 14, fontWeight: 500 }}>{strings.room}</span></Col>
          <Col md={6} ><span style={{ fontSize: 14, fontWeight: 500, }}>{strings.date}</span></Col>
          <Col md={4} ></Col>
        </Row>        
      </Menu.Item>
      <Menu.Divider />
      {this.rendermenu3()}
    </Menu>
    return menu;
  }

  notificationTabClick = (i) => {
    if (i.target.value == 'a') {
      this.setState({ ntype: 'a' })
      this.searchnotification();
    }
    else {
      this.setState({ ntype: 'b' })
      this.searchnotification1();
    }

  }

  handleVisibleChange = (flag) => {
    this.setState({ menuvisible: flag, ntype: 'a' });
  }

  gotohome = () => {
    this.setState({ tabval: '1', isadminView: false })
  }

  gotobooking = () => {
    this.setState({ tabval: '1', isbookmarkbooking: true })
  }

  gotoMybooking = () => {
    this.setState({ tabval: '4' })
  }

  gotolivepage=()=>{
    this.setState({ tabval: '6', isadminView: false })
  }

  gotobooking1 = () => {
    this.setState({ tabval: '8', isbookmarkbooking: true })
  }

  changeBookmarkbooking = () => {
    this.setState({ isbookmarkbooking: false })
  }

  chnageTab = (val) => {
    this.setState({ tabval: val });
  }



  setuserlang = (lang) => {
    strings.setLanguage(lang);
    this.setState({});
  }

  findErrorMessage = (msg) => {
    return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
  }

  changeLocaltoen = () => {
    this.setState({ loginlang: 'en' })
  }
  changeLocaltozh = () => {
    this.setState({ loginlang: 'zh' })
  }

  render() {
    const LoginForm = Form.create()(Login);

    return (
      <Router>
        <Spin spinning={this.state.loginloading} >
        {this.state.isAuthenticated || sessionStorage.getItem('isAuthenticated') == true ? <div className="App">
          <header className="App-header">
            <div>
              <Row style={{ height: 50, }}>
                <Col span={3} style={{ padding: 5, paddingTop: 10 }} >
                  <div className="logoDiv" style={{ cursor: 'pointer' }}>
                    <img className="logo" src={require('./assets/logo.jpg')} onClick={this.gotohome} />

                  </div>
                </Col>
                <Col span={9} style={{ paddingTop: 8 }} ><span className="titleText" style={{ float: 'right' }}>{strings.title}</span></Col>
                <Col span={12} style={{ paddingTop: 8 }} >
                  <div className="SignOutDiv">
                    <span>

                      <span style={{ fontSize: 14, marginRight: 25 }} className="SignOutBtn" onClick={this.changeView} hidden={sessionStorage.getItem("userrole") == 'user'}>{this.state.isadminView ? strings.userview : strings.adminview}</span>
                      <a href='https://assets.zoom.us/docs/user-guides/zoom-rooms-full-user-guide.pdf' target="_blank" style={{ color: 'black', fontSize: 14, fontWeight: 500, marginRight: 25 }}><Icon type="download" /> {strings.zoomguide}</a>
                      <a href='/srs_asset/userguide.pdf' target="_blank" style={{ color: 'black', fontSize: 14, fontWeight: 500, marginRight: 25 }}><Icon type="download" /> {strings.userguide}</a>
                      <Dropdown overlay={() => this.rendermenu2()} placement="bottomRight" trigger={['click']}>
                        <Badge offset={[-17, 1]} dot={this.state.subscribelist.length > 0 ? true : false}><img className="eyeIcon" src={require('./assets/eye.png')} onClick={() => this.searchSubscribed()} /></Badge>
                      </Dropdown>
                      <Dropdown overlay={() => this.rendermenu1()} placement="bottomRight" trigger={['click']} onVisibleChange={this.handleVisibleChange} visible={this.state.menuvisible}>
                        <Badge offset={[-2, -1]} dot={this.state.notification.length > 0 && this.state.ntype == 'a' ? true : false}><img className="bellIcon" src={require('./assets/bell.png')} onClick={() => this.searchnotification()} /></Badge>
                      </Dropdown>

                      <span className="UserName">{strings.hello} {sessionStorage.getItem("username")}</span>
                      <span style={{ fontSize: 14 }} className="SignOutBtn" onClick={this.signout}>{strings.signout}</span>
                    </span>

                  </div>
                </Col>
              </Row>
            </div>
          </header >
          {this.state.news.length > 0 &&
            <Row style={{ background: '#ff7f27', color: 'white', textAlign: 'left', }}>
              <Col span={24} style={{ padding: '0 10px' }}>
                <span className="TitleOfTheBox" style={{ color: 'white', fontSize: 14, lineHeight: '30px' }}>{strings.getLanguage() == 'en' ? this.state.news[0].contentEN : this.state.news[0].contentZH}</span>
                <span style={{ marginLeft: 10 }}>|</span>
                <Tag color={'#45577350'} style={{ marginLeft: 10 }} onClick={() => this.newsread(this.state.news[0].id)}>{strings.gotit}</Tag>
              </Col>
            </Row>}
          {this.state.isadminView == true && admin_roles.includes(sessionStorage.getItem("userrole")) ?
            <Tabs onTabClick={(key, e) => this.chnageTab(key)} destroyInactiveTabPane={true}
              tabBarStyle={{ elevation: 0, backgroundColor: '#f5f5f5', borderWidth: 0, color: '#34435b', }} defaultActiveKey={sessionStorage.getItem("userrole") != ADMIN_ROLES.ONLINE ? '11' : '12'} activeKey={this.state.tabval}>
              {sessionStorage.getItem("userrole") != ADMIN_ROLES.ONLINE && <TabPane tab={<span className="TabName">{strings.dashboard}</span>} key="11" >
                <AdminDashboard gotolivepage={this.gotolivepage}/>
              </TabPane>}
              {sessionStorage.getItem("userrole") != ADMIN_ROLES.DTD && sessionStorage.getItem("userrole") != ADMIN_ROLES.ONLINE && sessionStorage.getItem("userrole") != ADMIN_ROLES.DTD_ONLINE
                && <TabPane tab={<span className="TabName">{strings.pendingforaction}</span>} key="18" >
                  <PendingForAction />
                </TabPane>}
              <TabPane tab={<span className="TabName">{strings.schedule}</span>} key="12">
                <AdminSchedule gotomybooking={this.gotoMybooking}/>
              </TabPane>
              {/* <TabPane tab={<span className="TabName">{strings.malfunction}</span>} key="13">
              <Malfunction/>
              </TabPane> */}
              {sessionStorage.getItem("userrole") !== ADMIN_ROLES.ONLINE &&
                <TabPane tab={<span className="TabName">{strings.maintenance}</span>} key="14">
                  <Maintenance />
                </TabPane>}
              <TabPane tab={<span className="TabName">{strings.user}</span>} key="15">
                <User />
              </TabPane>
              <TabPane tab={<span className="TabName">{strings.report}</span>} key="16">
                <Report />
              </TabPane>
              {NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) && <TabPane tab={<span className="TabName">{strings.setup}</span>} key="17">
                <Setup />
              </TabPane>}              
              {sessionStorage.getItem("userrole") != ADMIN_ROLES.DTD && sessionStorage.getItem("userrole") != ADMIN_ROLES.ONLINE && sessionStorage.getItem("userrole") != ADMIN_ROLES.DTD_ONLINE
                && <TabPane tab={<span className="TabName">{strings.quota}</span>} key="22">
                <Visitor />
              </TabPane>}
              {sessionStorage.getItem("userrole") !== ADMIN_ROLES.NORMAL && sessionStorage.getItem("userrole") != ADMIN_ROLES.ONLINE && sessionStorage.getItem("userrole") != ADMIN_ROLES.NORMAL_ONLINE &&
                <TabPane tab={<span className="TabName">{strings.dtd}</span>} key="21">
                  <Dtd />
                </TabPane>}
              {sessionStorage.getItem("userrole") !== ADMIN_ROLES.ONLINE &&
                <TabPane tab={<span className="TabName">{strings.reminder}</span>} key="20">
                  <Reminder />
                </TabPane>}
              {NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) &&
                <TabPane tab={<span className="TabName">{strings.system}</span>} key="19">
                  <System />
                </TabPane>}
            </Tabs>
            :
            <Tabs onTabClick={(key, e) => this.chnageTab(key)} destroyInactiveTabPane={true} tabBarStyle={{ elevation: 0, backgroundColor: '#f5f5f5', borderWidth: 0, color: '#34435b', }} defaultActiveKey={'1'} activeKey={this.state.tabval}>
              <TabPane tab={<span className="TabName"><img className="TabsIcon" src={require('./assets/person.png')} />{strings.bookroom}</span>} key="1"  >
                <Booking gotomybooking={this.gotoMybooking} updateSubscribelist={this.searchSubscribed} showbookmarkbooking={this.state.isbookmarkbooking} changeBookmarkbooking={this.changeBookmarkbooking} />
              </TabPane>
              <TabPane tab={<span className="TabName"><img className="IconCar" src={require('./assets/car.png')} />{strings.bookcarpark}</span>} key="2" >
                <Carpark gotomybooking={this.gotoMybooking}/>
              </TabPane>
              <TabPane tab={<span className="TabName"><img className="IconCar" src={require('./assets/zoom.png')} />{strings.bookzoom}</span>} key="8" >
                <ZoomBook gotomybooking={this.gotoMybooking} updateSubscribelist={this.searchSubscribed} showbookmarkbooking={this.state.isbookmarkbooking} changeBookmarkbooking={this.changeBookmarkbooking} />
              </TabPane>
              <TabPane tab={<span className="TabName"><img className="TabsBooking" src={require('./assets/booking.png')} />{strings.mybooking}</span>} key="4">
                <MyBooking gotomybooking={this.gotoMybooking}/>
              </TabPane>
              <TabPane tab={<span className="TabName"><img className="TabsBooking" src={require('./assets/booking.png')} />{strings.pendingforaction}</span>} key="5">
                <PendingBooking gotomybooking={this.gotoMybooking}/>
              </TabPane>
              <TabPane tab={<span className="TabName"><img className="IconSc" src={require('./assets/sc.png')} />{strings.schedule}</span>} key="3">
                <Schedule gotomybooking={this.gotoMybooking}/>
              </TabPane>
              <TabPane tab={<span className="TabName"><img className="TabsData" src={require('./assets/data.png')} />{strings.livedata}</span>} key="6">
                <SensorData />
              </TabPane>
              <TabPane tab={<span className="TabName"><img className="TabsSetting" src={require('./assets/setting.png')} />{strings.setting}</span>} key="7">
                <Setting gotobooking={this.gotobooking} setuserlang={this.setuserlang} gotobooking1={this.gotobooking1} />
              </TabPane>
              {(sessionStorage.getItem("isAnalyst") === 'Y' || sessionStorage.getItem('analystAdmin') === 'Y') && <TabPane tab={<span className="TabName"><img className="TabsSetting" src={require('./assets/data.png')} />{strings.analyst}</span>} key="23">
                <Analyst />
              </TabPane>}
            </Tabs>}
          <Modal
            title={strings.alert}
            visible={this.state.showalertmodel}
            onCancel={() => this.setState({ showalertmodel: false })}
            footer={[
              <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerReload) window.location.reload(); }}>
                {strings.ok}
              </Button>,
            ]}
          >
            <span className="TitleOfTheBox">{strings.errormessage}</span><br />
            <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
          </Modal>
        </div > :
          
            <div style={{ backgroundImage: `url(${bg})` }} className="loginpageimagediv">
              <div style={{ textAlign: 'center', position: 'absolute', right: 20, top: 20 }} >
                <span style={{ fontSize: 22, fontWeight: 500, cursor: 'pointer' }} className={this.state.loginlang == 'en' ? 'localechangelabelselected' : 'localechangelabel'} onClick={() => this.changeLocaltoen()}>Eng</span>
                <span style={{ fontSize: 22, fontWeight: 500, color: 'white' }} > | </span>
                <span style={{ fontSize: 22, fontWeight: 500, cursor: 'pointer' }} className={this.state.loginlang == 'zh' ? 'localechangelabelselected' : 'localechangelabel'} onClick={() => this.changeLocaltozh()}>中文</span>
              </div>
              <div style={{ height: '14vh', width: '100%' }}></div>
              <div style={{ textAlign: 'center' }}><span className="loginTitle" style={{ fontWeight: 500, color: 'white' }}>總部設施預約系統</span></div>
              <div style={{ textAlign: 'center' }}><span className="loginTitle" style={{ fontWeight: 500, color: 'white' }}>Smart Reservation System in EMSD Headquarters</span></div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3%' }}>
                <Route path="/srs/" render={(props) => <LoginForm {...props} logincallback={this.authenticate} loginlang={this.state.loginlang} isMobile={this.state.ismobile}/>} />
                <Route path="/srs/login" render={(props) => <LoginForm {...props} logincallback={this.authenticate} loginlang={this.state.loginlang} isMobile={this.state.ismobile}/>} />
              </div>
              <Row style={{marginTop:5}}>
                <Col span={8} style={{ textAlign: 'center' }}>
                  
                </Col>
                <Col span={8} style={{ textAlign: 'center',}}>
                  <img src={'https://booking.emsd.gov.hk/srs_asset/apple_download.svg'} style={{width:150,cursor:'pointer',padding:5}} onClick={()=>this.setState({showApple:true})}/>
                  <img src={'https://booking.emsd.gov.hk/srs_asset/android_download.svg'} style={{width:150,cursor:'pointer',padding:5}} onClick={()=>this.setState({showAndroid:true})}/>
                  <img src={'https://booking.emsd.gov.hk/srs_asset/huawei_download.svg'} style={{width:150,cursor:'pointer',padding:5}} onClick={()=>this.setState({showHuewai:true})}/>
                </Col>
                <Col span={8} style={{ textAlign: 'center' }}>
                  
                </Col>
              </Row>

              <Modal
                title={''}
                visible={this.state.showAndroid}
                onCancel={() => this.setState({ showAndroid: false })}
                footer={null}
              >
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <img src={require('./assets/ic_launcher.png')} width='100'></img>
                  </div>
                  <div style={{ marginTop: 10, textAlign: 'center' }}>
                    <img src={'https://booking.emsd.gov.hk/srs_asset/android_qrcode_v1_7.png'} width='250'></img>
                  </div>
                  <div style={{ marginTop: 10, textAlign: 'center', fontWeight: 'bold' }}>
                    <span>{strings.scanqrapp} {' '} <a href="https://booking.emsd.gov.hk/srsapp/srs_v1_7.apk">{strings.clickthislink}</a> {' '} {strings.formobileversion}</span>                     
                  </div>
                  <div style={{ marginTop: 10, textAlign: 'center' }}>
                    <span id="blinktext1" style={{ color: 'red', fontSize: 16}} >{strings.androidinstruction1}{' '} 
                      <span style={{ fontWeight: 'bold' }} >{strings.androidinstruction2} {' '} </span>
                      <span >{strings.androidinstruction3}</span>
                      <span style={{ fontWeight: 'bold' }} >{strings.androidinstruction4}</span>
                      <span >{strings.androidinstruction5}</span>
                    </span>
                  </div>
                </div>
              </Modal>
              <Modal
                title={''}
                visible={this.state.showHuewai}
                onCancel={() => this.setState({ showHuewai: false })}
                footer={null}
              >
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <img src={require('./assets/ic_launcher.png')} width='100'></img>
                  </div>
                  <div style={{ marginTop: 10, textAlign: 'center' }}>
                    <img src={'https://booking.emsd.gov.hk/srs_asset/android_qrcode_v1_7.png'} width='250'></img>
                  </div>
                  <div style={{ marginTop: 10, textAlign: 'center', fontWeight: 'bold' }}>
                    <span>{strings.scanqrapp} {' '} <a href="https://booking.emsd.gov.hk/srsapp/srs_v1_7.apk">{strings.clickthislink}</a> {' '} {strings.formobileversion}</span>                     
                  </div>
                  <div style={{ marginTop: 10, textAlign: 'center' }}>
                    <span id="blinktext2" style={{ color: 'red', fontSize: 16}} >{strings.androidinstruction11}{' '} 
                      <span style={{ fontWeight: 'bold' }} >{strings.androidinstruction2} {' '} </span>
                      <span >{strings.androidinstruction3}</span>
                      <span style={{ fontWeight: 'bold' }} >{strings.androidinstruction4}</span>
                      <span >{strings.androidinstruction5}</span>
                    </span>
                  </div>
                </div>
              </Modal>
              <Modal
                title={''}
                visible={this.state.showApple}
                onCancel={() => this.setState({ showApple: false })}
                footer={null}
              >
                <div style={{ textAlign: 'center' }}>
                  <img src={require('./assets/ic_launcher.png')} width='100'></img>
                </div>
                <div style={{ marginTop: 10, textAlign: 'center' }}>
                  <img src={'https://booking.emsd.gov.hk/srs_asset/apple_qrcode.png'} width='250'></img>
                </div>
                <div style={{ marginTop: 10, textAlign: 'center', fontWeight: 'bold' }}>
                  <span>{strings.scanqrapp} {' '} <a href="itms-services://?action=download-manifest&url=https://booking.emsd.gov.hk/srsapp/manifest.plist">{strings.clickthislink}</a> {' '} {strings.formobileversion}</span>   
                </div>
                <div style={{ marginTop: 10, textAlign: 'center' }}>
                  <a href="https://booking.emsd.gov.hk/srsapp/download.html" id="blinktext" style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }} target="_blank">{strings.followinstructionapp}</a>
                </div>
              </Modal>
            </div>}
          </Spin>
      </Router>
    );
  }
}

export default Main;
