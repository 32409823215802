import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Row, Col,Spin,Button,Icon,Modal,Dropdown,Checkbox,Menu} from "antd";
import './App.css';
import {ApiHeader,strings,apiurl} from './data';
import Backgroundimg from './assets/parking.png';
const locale = 'EN';

class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            systemData:null,
            loading:false,
            dashboardData:[],
            timestr:'',
            datestr:'',
            tvc:0,
            vc:0,
            ttc:0,
            tc:0,
            tac:0,
            ac:0,
            totalCount:0,
            count:0,
            fthandle:null,
            srhandle:null,
            alertmessage:'',
            showalertmodel:false,
            underMaintenance:false,
            floors:[],
            selectedFloor:[],
            selectedFloorCopy:[],
            menuvisible1:false,
        }
        
    }

    handleVisibleChange = (flag) => {
        this.setState({ menuvisible1: flag});
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber',11);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({systemData:sd, floors:sd.buildingFloor.reverse()});       
        this.searchResource();
        this.flashTime()
        var fthandle = setInterval(this.flashTime, 60000);
        this.setState({fthandle:fthandle})
    }

  
    componentWillUnmount() {
        clearInterval(this.state.fthandle)        
    }                                            
    
    flashTime=()=>{
        var now = new Date();
        var y = now.getFullYear();
        var M = ('0'+(now.getMonth()+1)).slice(-2);
        var d = ('0' + now.getDate()).slice(-2);
        var h = ('0' + now.getHours()).slice(-2);
        var m = ('0' + now.getMinutes()).slice(-2);
        var s = ('0' + now.getSeconds()).slice(-2);
        this.setState({datestr:y+"/"+M+"/"+d,timestr:h+":"+m})          
    }

    searchResource=()=>{
        
        this.setState({loading:true})
        fetch(apiurl+'resource/dashbordsensordata', {
            headers: ApiHeader,
            credentials: 'same-origin',
            method: 'GET',            
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var data1 = data.data;  
                    data1 = data1.reverse();
                    this.setState({selectedFloor:data.underMaintenance.split(','), selectedFloorCopy:data.underMaintenance.split(',')})                   
                    this.setState({dashboardData:data1,totalCount:data.totalVisitorCount+data.totalTemporaryCount+data.totalAdminCount,
                        count:data.visitorCount+data.temporaryCount+data.adminCount, tvc:data.totalVisitorCount,vc:data.visitorCount,
                        ttc:data.totalTemporaryCount,tc:data.temporaryCount,ac:data.adminCount,tac:data.totalAdminCount})
                }else{
                  this.setState({alertmessage:strings.errorcode+''+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    renderData=()=>{
        var data = [];
        this.state.dashboardData.map((dd,index) => {    
            data.push(dd.roomData.length>0 || dd.washroomData.length>0?<Row span={24} key={index}>
                <Col span={1}>
                    <div style={{marginTop:60,background:'#89b6ff',width:150,height:50,transform: 'rotate(-90deg)',textAlign:'center',paddingTop: 8,letterSpacing: 2}}>
                        <span style={{color:'white',fontWeight:500,fontSize:22}}>{strings.level} {dd.bfName}</span>
                    </div>
                </Col>
                <Col span={16}>
                    <Row span={24} style={{marginLeft:50,}}>
                        {this.renderRoomData(dd.roomData)}
                    </Row>
                </Col>
                <Col span={7} style={{marginLeft:-20}}>
                    <Row span={24}>
                        {/* {this.renderWashroomData(dd.washroomData)} */}
                    </Row>
                </Col>
            </Row>:'');
            data.push(<div key={index+'i'} style={{background:'#000',width:'100%',height:30}}></div>)
        });
        return data;
    }

    renderRoomData=(rd)=>{
        if(locale == 'EN')
            rd.sort((a, b) => a.nameEN.localeCompare(b.nameEN));
        else
            rd.sort((a, b) => a.nameZH.localeCompare(b.nameZH));
        var data = [];
        rd.map((sd,index) => {   
            data.push(<div key={index}><Col span={3} className="dashboard-box">
            <div style={{marginTop:10,marginLeft:15}}>
                {sd.sensorData.IsOccupied==null?<img src={require('./assets/grey.svg')} style={{marginBottom:5,height:28,width:28}}></img>:(sd.sensorData.IsOccupied?<img src={require('./assets/red.png')} style={{marginBottom:5}}></img>
                :<img src={require('./assets/green.png')} style={{marginBottom:5}}></img>)}
                <span style={{color:'white',fontWeight:500,fontSize:22,marginLeft:10}}>{sd.nameEN}</span>
            </div>
            <div style={{marginTop:10,marginLeft:15}} hidden={sd.sensorData.Temperature==null}>
                <img src={require('./assets/temp.png')} style={{marginBottom:5,marginLeft:5}}></img>
                <span style={{color:'white',fontWeight:500,fontSize:22,marginLeft:15}}>{sd.sensorData.Temperature==null?'--':(parseFloat(sd.sensorData.Temperature).toFixed(2))+'\u2103'}</span>
            </div>
            <div style={{marginTop:10,marginLeft:15}} hidden={sd.sensorData.Humidity==null}>
                <img src={require('./assets/drop.png')} style={{marginBottom:5,marginLeft:5}}></img>
                <span style={{color:'white',fontWeight:500,fontSize:22,marginLeft:15}}>{sd.sensorData.Humidity==null?'--':(parseFloat(sd.sensorData.Humidity).toFixed(2))+'%'}</span>
            </div>
        </Col>
        <Col span={1}></Col></div> );
        });
        return data;
    }

    renderWashroomData=(wds)=>{
                
        var data = [];
        wds.map((wd,index) => {
            data.push(<div><Col span={5} className="dashboard-washroom-box">
                            <span style={{color:'white',fontWeight:500,fontSize:18,position:'absolute', top:5, left:5}}>{index+1}</span>
                            ({wd.isMale?<img src={require('./assets/male.png')} style={{width:70, height:100,marginTop:10}}></img>
                            :<img src={require('./assets/female.png')} style={{width:70, height:100,marginTop:10}}></img>})
                            ({wd.isMale?<span style={{color:'white',fontWeight:500,fontSize:22}} >{strings.gents}</span>
                            :<span style={{color:'white',fontWeight:500,fontSize:22}} >{strings.ladies}</span>})
                            <div style={{width:120, height:30, background:'#ff0000',opacity:0.7,position:'absolute',top:60}}>
                                <span style={{color:'white',fontWeight:500,fontSize:22}}>{wd.status}</span>
                            </div>
                        </Col>
                        <Col span={1}></Col>
                </div>);
        });
        return data;
    }

    domaintenance=()=>{
        this.setState({ loading: true });
        var rdata = {};
        rdata.buildingFloorId = this.state.selectedFloor.join(',');
        fetch(apiurl + 'resourcetemp/startmaintenance', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                this.setState({selectedFloorCopy:this.state.selectedFloor, alertmessage: strings.maintenancesuccess, showalertmodel: true, loading: false, menuvisible1:false})
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true, loading: false })
            }

        })
        .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    gototlivepage=()=>{
        this.props.gotolivepage();
    }

    opentvpage=()=>{
        var win = window.open("/srs_board/tvboard.html", '_blank');        
        win.focus();
    }
        

    gotopd=()=>{
        var win = window.open("/srsadmin/index_desktop.html?public=true", '_blank');        
        win.focus();
    }

    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
    }

    rendermenu2 = () => {
        var menu = <Menu>
          {this.state.floors.map((f,i)=><Menu.Item style={{ color: '#405069'}}>
            <div><Checkbox onChange={(e)=>this.onchangeFloor(f.id,e)} checked={this.state.selectedFloor.includes(f.id+'')}>{f.floorEN}</Checkbox></div>
          </Menu.Item>)}        
          <Menu.Item style={{ color: '#405069'}}>
            <Row>
                <Col span={24}>
                    <Button type={'primary'} size={'small'} onClick={this.domaintenance}>{strings.submit}</Button>
                    <Button type={'primary'} style={{ marginLeft: 10 }} size={'small'} onClick={()=>this.setState({menuvisible1:false,selectedFloor:[...this.state.selectedFloorCopy]})}>{strings.cancel1}</Button>
                </Col>
            </Row>
          </Menu.Item>
        </Menu>
        return menu;
    }

    onchangeFloor=(fid,e)=>{
        if(e.target.checked){
            if(!this.state.selectedFloor.includes(fid)){
                this.setState({menuvisible1:true, selectedFloor:[...this.state.selectedFloor,fid+'']})
            }
        }else{
            this.setState({menuvisible1:true, selectedFloor:this.state.selectedFloor.filter((f)=>f !== fid+'')})
        }
    }

    render() {    
    
        return (            
            <div style={{background:'#000',width:'100%',textAlign: 'left'}} className='Tab4Div'>
                <Spin spinning={this.state.loading}>
                    <div style={{background:'#000',width:'100%',height:40}}>  
                        <Row>
                            <Col span={12} style={{ textAlign: 'left' }}><Button style={{marginTop: 5,marginLeft: 20}} onClick={()=>this.searchResource()}><Icon type="sync" /></Button></Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Dropdown overlay={() => this.rendermenu2()} placement="bottomCenter" trigger={['click']} onVisibleChange={this.handleVisibleChange} visible={this.state.menuvisible1}>
                                    <Button type={'primary'} style={{ marginLeft: 10 }}>{strings.maintenance}</Button>
                                </Dropdown>
                                <Button type={'primary'} onClick={this.gototlivepage} style={{ marginLeft: 10 }}>{strings.carparksharing}</Button>
                                <Button type={'primary'} onClick={this.opentvpage} style={{ marginLeft: 10 }}>{strings.tvboard}</Button>
                                <Button style={{marginTop: 5,marginLeft: 10,marginRight:10}} onClick={this.gotopd}>{strings.publicdashboard}</Button>                                   
                            </Col>
                        </Row>                  
                        
                    </div>
                    <div style={{height:450,overflowX:'auto',}}>
                        {this.renderData()}                    
                    </div>
                    <Row span={24} style={{marginTop:30,marginLeft:50,marginBottom: 50}}>
                        <Col span={19} style={{height:312, backgroundImage: `url(${Backgroundimg})`}}>
                            <span style={{color:'white',fontSize:20,fontWeight:500,marginLeft:60}}>{strings.occupancystatus}</span>
                            <Row span={24} style={{marginTop:40}}>
                                <Col span={1}></Col>
                                <Col span={5} className="dashboard-count-box">
                                    <span style={{color:'black',fontWeight:500,textDecoration: 'underline'}} className='dashboardfont'>{strings.fixed}</span><br/>
                                    <span style={{color:'black',fontWeight:500}} className='dashboardfont1'>{this.state.ac}/{this.state.tac}</span>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={5} className="dashboard-count-box">
                                    <span style={{color:'black',fontWeight:500,textDecoration: 'underline'}} className='dashboardfont'>{strings.operational}</span><br/>
                                    <span style={{color:'black',fontWeight:500}} className='dashboardfont1'>{this.state.tc}/{this.state.ttc}</span>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={5} className="dashboard-count-box">
                                    <span style={{color:'black',fontWeight:500,textDecoration: 'underline'}} className='dashboardfont'>{strings.visitor}</span><br/>
                                    <span style={{color:'black',fontWeight:500}} className='dashboardfont1'>{this.state.vc}/{this.state.tvc}</span>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={5} className="dashboard-count-box" style={{background:'#fff'}}>
                                    <span style={{color:'black',fontWeight:500,textDecoration: 'underline'}} className='dashboardfont'>{strings.total}</span><br/>
                                    <span style={{color:'black',fontWeight:500}} className='dashboardfont1'>{this.state.count}/{this.state.totalCount}</span>
                                </Col>                                
                            </Row>
                        </Col>
                        <Col span={5}>
                            <img src={require('./assets/hq.jpg')} className='dashboardimg'></img>
                            <div style={{height:80,marginLeft:30,marginTop:30,background:'#353535',textAlign:'center'}} className='dashboardtime'>
                                <span style={{color:'white',fontWeight:500,fontSize:18}}>{this.state.datestr}</span><br/>
                                <span style={{color:'purple',fontWeight:500,fontSize:34}}>{this.state.timestr}</span>
                            </div>                            
                        </Col>
                    </Row>
                </Spin>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={()=>this.setState({showalertmodel:false})}
                    footer={[                  
                        <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerReload)window.location.reload();}}>
                        {strings.ok}
                        </Button>,
                    ]}
                    >
                        <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                        <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                </Modal>
            </div>
        );
    }
}    
export default AdminDashboard;

