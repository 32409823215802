import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col,Table,Select,Tag,Radio,Button,DatePicker, Modal} from "antd";
import './App.css';
import AddMalfunction from './AddMalfunction'
import moment from 'moment';
import {ApiHeader,strings,apiurl,RESOURCE_TYPES} from './data';
const Option = Select.Option;

const locale = 'EN';

class Malfunction extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            systemData:null,
            resource:null,
            radiooption:'a',
            loading:false,
            reportby:'',
            type:RESOURCE_TYPES.MEETING_ROOM,
            date1:null,
            date:'',
            floors:[],
            locations:[],
            floor:'',
            location:'',
            status:'reported',
            tabledata:[],
            count:0,
            selectedMalfunction:null,
            iseditop:false,
            isaddop:true,
            triggerReload:false,
            showalertmodel:false,
            alertmessage:"",
        }
        this.columns = [
            {
                title: strings.reportdate,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))
            },{
                title: strings.reportby,
                dataIndex: 'reportby',
                key: 'reportby',
                width:'10%',                
                sorter: (a, b) => a.reportby.localeCompare(b.reportby, 'en', { numeric: true }),
            }, {
                title: strings.type,
                dataIndex: 'type',
                key: 'type',
                width:'10%',
                sorter: (a, b) => a.type.localeCompare(b.type, 'en', { numeric: true }),
            }, {
                title: strings.floor,
                dataIndex: 'floor',
                key: 'floor',
                width:'15%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
                render:(a,b)=>(a.replace("()",""))
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width:'15%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
                
            }, {
                title: strings.description,
                dataIndex: 'description',
                key: 'description',
                width:'10%',
                sorter: (a, b) => a.description.localeCompare(b.description, 'en', { numeric: true }),
            }, {
                title: strings.status,
                dataIndex: 'status',
                key: 'status',
                width:'10%',
                sorter: (a, b) => a.status.localeCompare(b.status, 'en', { numeric: true }),
            },
            {
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {
                        return <Tag color={'#455773'} key={tag}  style={{marginTop:5}} onClick={()=>this.ontagclick({tag},record)}>{tag.toUpperCase()}</Tag>;
                    })}
                    </span>
                ),
            }
        ];
    }

    

    componentDidMount() {
        sessionStorage.setItem('tabNumber',13);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({resource:sd.resource,systemData:sd});
        var resource = sd.resource;
        var floors=[],locations=[];
        floors.push(strings.any);
        locations.push(strings.any);
        for(var i=0; i<resource.length; i++){
            if(RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType){
                if(locale === 'EN'){
                    if(floors.indexOf(resource[i].locationEN.split(',')[1]) == -1){
                        floors.push(resource[i].locationEN.split(',')[1]);
                    }
                }else{
                    if(floors.indexOf(resource[i].locationZH.split(',')[1]) == -1){
                        floors.push(resource[i].locationZH.split(',')[1]);
                    }
                }
            }
        }
        
        this.setState({floors:floors,locations:locations,floor:floors[0],location:locations[0]});
    }
  
    componentWillUnmount() {
  
    }

    searchmalfunction=()=>{
        this.setState({loading:true})
        var rdata = {};
        rdata.resourceType = this.state.type;        
        var resource = this.state.resource;
        if(this.state.floor == strings.any){
            var rids=[];
            for(var i=0; i<resource.length; i++){
                if(this.state.type == resource[i].resourceType){
                    rids.push(resource[i].id);                    
                }
            }
            rdata.resourceId = rids;
        }else{

            if(this.state.location == strings.any){
                var rids=[];
                for(var i=0; i<resource.length; i++){
                    if(this.state.floor === resource[i].locationZH.split(',')[1] || this.state.floor === resource[i].locationEN.split(',')[1]){
                        rids.push(resource[i].id);                    
                    }
                }
                rdata.resourceId = rids;
            }else{
                for(var i=0; i<resource.length; i++){
                    if((this.state.floor === resource[i].locationZH.split(',')[1] || this.state.floor === resource[i].locationEN.split(',')[1]) && (this.state.location === resource[i].locationZH.split(',')[0] || this.state.location === resource[i].locationEN.split(',')[0])){
                        rdata.resourceId = [resource[i].id];
                        break;
                    }
                }
            }
        }
        
        
        rdata.state=this.state.status;
        
        
        if(this.state.date != ''){
            rdata.date=this.state.date;
        }
            
        if(this.state.reportby != ''){
            if(this.state.reportby.length>=3){
                rdata.reportby=this.state.reportby;
            }else{
                this.setState({alertmessage:strings.reportpersonnameetc,showalertmodel:true})                
                this.setState({loading:false});
                return;
            }            
        }
        
        

        fetch(apiurl+'malfunction/list', {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin',  
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data.resourceMalfunction;                        
                    var bs1 = [];
                    var resource = this.state.resource;
                    for(var i=0;i<bs.length;i++){
                        var b1 = {};
                        b1.id = bs[i].id;
                        b1.key = i+1;
                        b1.date = moment.unix((bs[i].createTime/1000)).format("YYYY-MM-DD hh:mmA");
                        b1.reportby = bs[i].reportedBy;                        
                        b1.description = bs[i].description;
                        b1.remark = bs[i].acknowledgement;
                        if(this.state.type === RESOURCE_TYPES.MEETING_ROOM)
                            b1.type = strings.meetingroom;
                        else if(this.state.type === RESOURCE_TYPES.VISITOR_CARPARK)
                            b1.type = strings.visitorcarpark;
                        else if(this.state.type === RESOURCE_TYPES.OPERATIONAL_CARPARK)
                            b1.type = strings.operationalcarpark;

                        if(bs[i].state == 'handled'){
                            b1.tags = [strings.view];
                            b1.status = strings.handled;
                        }                           
                        else{
                            b1.tags = [strings.view,strings.update];
                            b1.status =  strings.reported;
                        }
                            

                        for(var j=0;j<resource.length;j++){
                            if(resource[j].id === bs[i].resourceId){
                                if(locale ==='EN'){
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                }else{
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);
                    }
                    this.setState({tabledata:bs1,count:bs1.length});
                
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    ontagclick=(tag,b)=>{
        if(tag.tag == strings.update){
           this.setState({selectedMalfunction:b,iseditop:true,isaddop:false,radiooption:'b'}); 
        }else if(tag.tag == strings.view){
            this.setState({selectedMalfunction:b,iseditop:false,isaddop:false,radiooption:'b'}); 
        }        
    }
    
    onRadioChange=(e)=>{
        if(e.target.value == 'a'){
            this.setState({radiooption:'a',isaddop:true})
        }else{
            this.setState({radiooption:'b',isaddop:true})
        }
        this.handleTypeChange(RESOURCE_TYPES.MEETING_ROOM);
    }

    onreportdateValueChange=(val)=>{
        this.setState({date1:val,date:val.format('YYYYMMDD')})
    }

    backbtn=()=>{
        this.setState({radiooption:'a',iseditop:false,isaddop:true,});
        this.searchmalfunction();
    }


    handleTypeChange=(value)=> {
        var resource = this.state.systemData.resource;
        var floors=[],locations=[];
        floors.push(strings.any);
        locations.push(strings.any);
        for(var i=0; i<resource.length; i++){
            if(value === resource[i].resourceType){
                if(locale === 'EN'){
                    if(floors.indexOf(resource[i].locationEN.split(',')[1]) == -1){
                        floors.push(resource[i].locationEN.split(',')[1]);
                    }
                }else{
                    if(floors.indexOf(resource[i].locationZH.split(',')[1]) == -1){
                        floors.push(resource[i].locationZH.split(',')[1]);
                    }
                }
            }
        }
        
        this.setState({type:value,floors:floors,locations:locations,floor:floors[0],location:locations[0]});
    }

    handleFloorChange=(value)=>{
        var resource = this.state.systemData.resource;
        var locations = [];
        for(var i=0; i<resource.length; i++){
            if(this.state.type === resource[i].resourceType){
                if(resource[i].locationEN.split(',')[1] ===  value){
                    locations.push(resource[i].locationEN.split(',')[0]);
                }
            }
            
        }
        this.setState({locations:locations,floor:value,location:locations[0]});
    }

    handleStatusChange=(value)=>{
        this.setState({status:value})
    }

    clearScrren=()=>{
        this.setState({
            floor:'',
            floors:[],
            location:'',
            locations:[],
            type:RESOURCE_TYPES.MEETING_ROOM,
            status:'reported',
            reportby:'',
            date:'',
            date1:null,
        })
        var resource = this.state.resource;
        var floors=[],locations=[];
        floors.push(strings.any);
        locations.push(strings.any);
        for(var i=0; i<resource.length; i++){
            if(RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType){
                if(locale === 'EN'){
                    if(floors.indexOf(resource[i].locationEN.split(',')[1]) == -1){
                        floors.push(resource[i].locationEN.split(',')[1]);
                    }
                    
                }else{
                    if(floors.indexOf(resource[i].locationZH.split(',')[1]) == -1){
                        floors.push(resource[i].locationZH.split(',')[1]);
                    }
                    
                }
            }
        }
        this.setState({floors:floors,locations:locations,floor:floors[0],location:locations[0]});
    }

    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
    }
    

    render() {
    
    
        return (
            <div className='Tab2Div'>                               
                <div hidden={!this.state.isaddop}>
                    <div style={{display: 'flex', justifyContent: 'center',marginTop:20}}>
                        <Radio.Group value={this.state.radiooption} buttonStyle="solid" onChange={this.onRadioChange}>
                            <Radio.Button value="a">{strings.search}</Radio.Button>
                            <Radio.Button value="b">{strings.add}</Radio.Button>
                        </Radio.Group>
                    </div>                        
                </div>
                {this.state.radiooption == 'a'?<div style={{marginTop:20}}>                               
                    <div>
                        <div className="FirstDiv">
                            <span className="TitleOfTheBox">{strings.searchmalfunction}</span>
                            <Row xs={24} >
                                <Col md={5}>
                                    <div>
                                        <span className="placeholder">{strings.type}</span>
                                    </div>
                                    <Select className="InputSizeOfThird" value={this.state.type} onChange={this.handleTypeChange}>
                                        <Option value={RESOURCE_TYPES.MEETING_ROOM}>{strings.meetingroom}</Option>
                                        <Option value={RESOURCE_TYPES.VISITOR_CARPARK}>{strings.visitorcarpark}</Option>
                                        <Option value={RESOURCE_TYPES.OPERATIONAL_CARPARK}>{strings.operationalcarpark}</Option>
                                    </Select>
                                </Col>
                                <Col md={5}>
                                    <div>
                                        <span className="placeholder">{strings.reportdate}</span>
                                        <div style={{ marginBottom: 16 }}>
                                        <DatePicker className="InputSizeOfThird" value={this.state.date1} onChange={(val)=>this.onreportdateValueChange(val)} />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div>
                                        <span className="placeholder">{strings.reportby}</span>
                                    </div>
                                    <Input className="InputSizeOfThird" value={this.state.reportby} onChange={e=>this.setState({reportby:e.target.value})}/>
                                </Col>
                                    <Col md={5}>
                                    <div>
                                        <span className="placeholder">{strings.status}</span>
                                    </div>
                                    <Select className="InputSizeOfThird" value={this.state.status} onChange={this.handleStatusChange}>
                                        <Option value="reported">{strings.reported}</Option>
                                        <Option value="handled">{strings.handled}</Option>
                                    </Select>
                                </Col>
                            </Row>

                            <Row md={24}>
                                <Col md={5} style={{marginTop:-10}}>
                                <div>
                                    <span className="placeholder">{strings.floor}</span>
                                </div>
                                <Select className="InputSizeOfThird" value={this.state.floor} onChange={this.handleFloorChange}>
                                    {
                                        this.state.floors.map(ed =><Option value={ed}>{ed}</Option>)
                                    }                            
                                </Select>
                                </Col>
                                <Col md={5}  style={{marginTop:-10}}>
                                <div>
                                    <span className="placeholder">{strings.location}</span>
                                    <div style={{ marginBottom: 16 }}>
                                    <Select className="InputSizeOfThird" value={this.state.location} onChange={val=>this.setState({location:val})}>
                                    {
                                        this.state.locations.map(ed =><Option value={ed}>{ed}</Option>)
                                    }
                                    </Select>
                                    </div>
                                </div>
                                </Col>
                                
                            </Row>
                            <Button type="primary" size='large' style={{marginRight: 17}}  loading={this.state.loading} onClick={this.searchmalfunction}>
                            {strings.search}
                            </Button>
                            <Button size='large' style={{marginRight: 20}}  onClick={this.clearScrren}>
                            {strings.clear}
                            </Button>
                        </div>

                        <div>
                            <div className="table-operations">
                            </div>
                            <Table style={{marginTop:12,backgroundColor:'#fff'}} columns={this.columns} dataSource={this.state.tabledata} />
                            <div className="CountOfData">
                                <span className="CountText">{strings.totalcount} {this.state.count}</span>
                            </div>        
                        </div>
                        
                    </div>
                    <Modal
                        title={strings.alert}
                        visible={this.state.showalertmodel}
                        onCancel={()=>this.setState({showalertmodel:false})}
                        footer={[                  
                            <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerReload)window.location.reload();}}>
                            {strings.ok}
                            </Button>,
                        ]}
                        >
                            <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                            <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                    </Modal>
                    </div>
                    :<AddMalfunction md={this.state.selectedMalfunction} isedit={this.state.iseditop} isadd={this.state.isaddop} backbtn={this.backbtn}/>
                    }            
            </div>
        );
    }
}    
export default Malfunction;

