import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col, Select, Tag, Radio, Button, Modal, Popconfirm, Spin } from "antd";
import './App.css';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES, NORMAL_ROLES, DTD_ROLES, ONLINE_ROLES, ALL_FLOORS1 } from './data';
import AddResource from './AddResource'
import Sharing from './Sharing'
const Option = Select.Option;

var locale = 'EN';

class Setup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemData: null,
            resource: null,
            zoomaccounts: [],
            radiooption: 'a',
            loading: false,
            loading1: false,
            type: RESOURCE_TYPES.MEETING_ROOM,
            floors: [],
            floor: '',
            nameZH: '',
            nameEN: '',
            sensorData: [],
            triggerReload: false,
            showalertmodel: false,
            alertmessage: "",
            isAdd: true,
            isEdit: true,
            selectedResource: null,
            zas: [],
            iszoom: false,
            carSlots: [],
        }
    }



    componentDidMount() {
        locale = strings.getLanguage() === 'en' ? 'EN' : 'ZH'
        sessionStorage.setItem('tabNumber', 17);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({ resource: sd.resource, zoomaccounts: sd.zoomAccounts, systemData: sd });
        var resource = sd.resource;
        var allfloors = ALL_FLOORS1;
        this.setState({ floors: allfloors, floor: allfloors[0] });
    }

    componentWillUnmount() {

    }

    searchResource = () => {
        this.setState({ loading: true })
        var rdata = {};
        var ids = [];
        if (this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR && this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK) {
            var resource = this.state.systemData.resource;
            for (var i = 0; i < resource.length; i++) {
                if (this.state.type === resource[i].resourceType) {
                    if (locale === 'EN') {
                        if (resource[i].locationEN.split(',')[1] == this.state.floor) {
                            ids.push(resource[i].id);
                        }
                    } else {
                        if (resource[i].locationEN.split(',')[1] == this.state.floor) {
                            ids.push(resource[i].id);
                        }
                    }
                }
            }
            rdata.resourceIds = ids;

            if (this.state.nameEN != '') {
                rdata.nameEN = this.state.nameEN;
            }
            if (this.state.nameZH != '') {
                rdata.nameZH = this.state.nameZH;
            }
            rdata.type = this.state.type;
            fetch(apiurl + 'resource/search1', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {
                        var bs = data.data;
                        var eqs = this.state.systemData.equipment;
                        for (var i = 0; i < bs.length; i++) {
                            bs[i].key = i;
                            if (bs[i].sensorData != null && bs[i].sensorData.Temperature != null && bs[i].sensorData.Humidity != null) {
                                bs[i].temperature = parseFloat(bs[i].sensorData.Temperature).toFixed(2);
                                bs[i].humidity = parseFloat(bs[i].sensorData.Humidity).toFixed(2);
                            }
                            else {
                                bs[i].temperature = '--';
                                bs[i].humidity = '--';
                            }

                            if (this.state.type === RESOURCE_TYPES.VISITOR_CARPARK || this.state.type === RESOURCE_TYPES.OPERATIONAL_CARPARK || this.state.type === RESOURCE_TYPES.ADMIN_CARPARK) {
                                bs[i].iscarpark = true;
                            } else {
                                bs[i].iscarpark = false;
                            }



                            var eqnames = [];
                            for (var e = 0; e < bs[i].equipmentsId.length; e++) {
                                for (var ee = 0; ee < eqs.length; ee++) {
                                    if (eqs[ee].id == bs[i].equipmentsId[e]) {
                                        if (locale === 'EN') {
                                            eqnames.push(eqs[ee].nameEN)
                                        } else {
                                            eqnames.push(eqs[ee].nameZH)
                                        }
                                    }

                                }
                            }
                            bs[i].eqs = eqnames;
                        }
                        if (locale === 'EN') {
                            bs.sort((a, b) => a.nameEN.localeCompare(b.nameEN));
                        } else {
                            bs.sort((a, b) => a.nameZH.localeCompare(b.nameZH));
                        }
                        this.setState({ sensorData: bs });


                    } else {
                        this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                    }
                    this.setState({ loading: false })
                })
                .catch(e => console.warn(e))
        } else if (this.state.type === RESOURCE_TYPES.ADMIN_CARPARK) {
            var resource = this.state.systemData.resourceTemp;
            for (var i = 0; i < resource.length; i++) {
                if (this.state.type === resource[i].resourceType) {
                    if (locale === 'EN') {
                        if (resource[i].locationEN.split(',')[1] == this.state.floor) {
                            ids.push(resource[i].id);
                        }
                    } else {
                        if (resource[i].locationEN.split(',')[1] == this.state.floor) {
                            ids.push(resource[i].id);
                        }
                    }
                }
            }
            rdata.resourceIds = ids;

            if (this.state.nameEN != '') {
                rdata.nameEN = this.state.nameEN;
            }
            if (this.state.nameZH != '') {
                rdata.nameZH = this.state.nameZH;
            }

            fetch(apiurl + 'resourcetemp/search1', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {
                        var bs = data.data;
                        for (var i = 0; i < bs.length; i++) {
                            bs[i].key = i;
                            bs[i].iscarpark = true;
                        }
                        if (locale === 'EN') {
                            bs.sort((a, b) => a.nameEN.localeCompare(b.nameEN));
                        } else {
                            bs.sort((a, b) => a.nameZH.localeCompare(b.nameZH));
                        }
                        this.setState({ sensorData: bs });


                    } else {
                        this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                    }
                    this.setState({ loading: false })
                })
                .catch(e => console.warn(e))

        } else {
            fetch(apiurl + 'zoomaccount/list', {
                headers: ApiHeader,
                method: 'GET',
                credentials: 'same-origin',
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {
                        var zas = [];
                        var zoomaccounts = data.data;
                        for (var i = 0; i < zoomaccounts.length; i++) {
                            if (this.state.type === zoomaccounts[i].resourceType) {
                                if (this.state.nameEN != '' || this.state.nameZH != '') {
                                    if (this.state.nameEN != '') {
                                        if (zoomaccounts[i].nameEN.includes(this.state.nameEN)) {
                                            zas.push(zoomaccounts[i]);
                                        }
                                    }
                                    if (this.state.nameZH != '') {
                                        if (zoomaccounts[i].nameZH.includes(this.state.nameZH)) {
                                            zas.push(zoomaccounts[i]);
                                        }
                                    }
                                }
                                else {
                                    zas.push(zoomaccounts[i]);
                                }
                            }
                        }
                        this.setState({ zas: zas, loading: false })
                    } else {
                        this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                    }
                    this.setState({ loading: false })
                })
                .catch(e => console.warn(e))
        }
    }

    onRadioChange = (e) => {
        if (e.target.value == 'a') {
            this.setState({ radiooption: 'a', isEdit: true, isAdd: true })
        } else if (e.target.value == 'b') {
            this.setState({ radiooption: 'b', isEdit: true, isAdd: true })
        } else {
            this.setState({ radiooption: 'c', isEdit: true, isAdd: true })
        }
        this.handleTypeChange(RESOURCE_TYPES.MEETING_ROOM);
    }


    backbtn = () => {
        this.setState({ radiooption: 'a' });
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({ resource: sd.resource, zoomaccounts: sd.zoomAccounts, systemData: sd }, () => this.searchResource());
    }


    handleTypeChange = (value) => {
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        var resource = sd.resource;
        if (value !== RESOURCE_TYPES.ZOOM_MEETING && value !== RESOURCE_TYPES.ZOOM_WEBINAR) {
            var allfloors = ALL_FLOORS1;
            this.setState({ type: value, floors: allfloors, floor: allfloors[0], iszoom: false, resource: sd.resource, zoomaccounts: sd.zoomAccounts, systemData: sd });
        } else {
            this.setState({ type: value, iszoom: true });
        }

    }

    handleFloorChange = (value) => {

        this.setState({ floor: value });
    }

    handleStatusChange = (value) => {
        this.setState({ status: value })
    }

    clearScrren = () => {
        this.setState({
            floor: '',
            floors: [],
            type: RESOURCE_TYPES.MEETING_ROOM,
            nameZH: '',
            nameEN: '',
            selectedResource: null,
            isAdd: true,
            isEdit: true,

        })
        var resource = this.state.resource;
        var allfloors = ALL_FLOORS1;
        this.setState({ floors: allfloors, floor: allfloors[0] });
    }

    viewResource = (res, type) => {
        if (type === 1) {
            this.setState({ radiooption: 'b', isEdit: false, isAdd: false, selectedResource: res, iszoom: false })
        } else {
            this.setState({ radiooption: 'b', isEdit: false, isAdd: false, selectedResource: res, iszoom: true })
        }

    }

    editResource = (res, type) => {
        if (type === 1) {
            this.setState({ radiooption: 'b', isEdit: true, isAdd: false, selectedResource: res, iszoom: false })
        } else {
            this.setState({ radiooption: 'b', isEdit: true, isAdd: false, selectedResource: res, iszoom: true })
        }
    }

    removeResource = (sd, type) => {
        if (type === 1) {
            this.setState({ loading1: true })
            var rdata = {};
            rdata.id = sd.id;
            fetch(apiurl + (this.state.type === RESOURCE_TYPES.ADMIN_CARPARK ? 'resourcetemp/remove' : 'resource/remove'), {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {
                        this.fetchSystemData(sd.key, sd.id, 1);
                    } else {
                        this.setState({ alertmessage: strings.error + (data.error != null ? data.error : data.error_code), showalertmodel: true })
                    }
                    this.setState({ loading: false })
                })
                .catch(e => console.warn(e))
        } else {
            this.setState({ loading1: true })
            var rdata = {};
            rdata.id = sd.id;
            fetch(apiurl + 'zoomaccount/remove', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {
                        this.fetchSystemData(sd.key, sd.id, 2);
                    } else {
                        this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                    }
                    this.setState({ loading: false })
                })
                .catch(e => console.warn(e))
        }

    }

    fetchSystemData = (key, id, type) => {
        fetch(apiurl + 'system/list', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    sessionStorage.setItem('systemdata', JSON.stringify(data.data));
                    if (type === 1) {
                        var sdata = this.state.sensorData;
                        var sdata1 = [];
                        for (var i = 0; i < sdata.length; i++) {
                            if (sdata[i].id != id) {
                                sdata1.push(sdata[i]);
                            }
                        }
                        this.setState({ sensorData: sdata1, alertmessage: strings.resourceremovedsuccess, showalertmodel: true, isEdit: true, isAdd: true, systemData: data.data })
                    } else {

                        var zas = this.state.zas;
                        var zas1 = [];
                        for (var i = 0; i < zas.length; i++) {
                            if (zas[i].id != id) {
                                zas1.push(zas[i]);
                            }
                        }
                        this.setState({ zas: zas1, alertmessage: strings.resourceremovedsuccess, showalertmodel: true, isEdit: true, isAdd: true, systemData: data.data })
                    }

                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false, loading1: false })
            })
            .catch(e => console.warn(e))
    }

    editMaintenance = (sd, val, index) => {
        var rdata = { id: sd.id, value: val, type: sd.resourceType };
        fetch(apiurl + 'resourcetemp/editMaintenance', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    var sds = this.state.sensorData;
                    sds[index].isUnderMaintenance = val;
                    this.setState({ sensorData: sds, alertmessage: strings.updatesuccess, showalertmodel: true })
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))
    }


    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg : msg;
    }

    render() {


        return (
            <div>
                <Spin spinning={this.state.loading1}>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>

                            <Radio.Group value={this.state.radiooption} buttonStyle="solid" onChange={this.onRadioChange}>
                                <Radio.Button value="a">{strings.search}</Radio.Button>
                                <Radio.Button value="b">{strings.add}</Radio.Button>
                                <Radio.Button value="c">{strings.sharing}</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    {this.state.radiooption == 'a' ? <div style={{ marginTop: 10 }} className='Tab2Div'>
                        <div>
                            <div className="FirstDiv">
                                <span className="TitleOfTheBox">{strings.searchresource}</span>
                                <Row xs={24} style={{ marginTop: 20 }}>
                                    <Col md={4}>
                                        <div>
                                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                            <span className="placeholder">{strings.type}</span>
                                        </div>

                                    </Col>
                                    <Col md={5}>
                                        <Select value={this.state.type} onChange={this.handleTypeChange} className='setupinputbox'>
                                            {(NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) || DTD_ROLES.includes(sessionStorage.getItem("userrole"))) && <Option value={RESOURCE_TYPES.MEETING_ROOM}>{strings.meetingroom}</Option>}
                                            {NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) && <Option value={RESOURCE_TYPES.ADMIN_ROOM}>{strings.fixedroom}</Option>}
                                            {NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) && <Option value={RESOURCE_TYPES.ADMIN_CARPARK}>{strings.fixedcarpark}</Option>}
                                            {ONLINE_ROLES.includes(sessionStorage.getItem("userrole")) && <Option value={RESOURCE_TYPES.ZOOM_MEETING}>{strings.zoommeeting}</Option>}
                                            {ONLINE_ROLES.includes(sessionStorage.getItem("userrole")) && <Option value={RESOURCE_TYPES.ZOOM_WEBINAR}>{strings.zoomwebinar}</Option>}
                                            {NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) && <Option value={RESOURCE_TYPES.WASHROOM}>{strings.washroom}</Option>}
                                        </Select>
                                    </Col>
                                    <Col md={4} hidden={this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR}>
                                        <div>
                                            <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                            <span className="placeholder">{strings.floor}</span>
                                        </div>
                                    </Col>
                                    <Col md={5} hidden={this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR}>
                                        <Select value={this.state.floor} onChange={this.handleFloorChange} className='setupinputbox'>
                                            {
                                                this.state.floors.map((ed, index) => <Option key={index} value={ed}>{ed}</Option>)
                                            }
                                        </Select>
                                    </Col>
                                </Row>

                                <Row md={24} style={{ marginTop: 50 }}>
                                    <Col md={4} style={{ marginTop: -10 }}>
                                        <div>
                                            <span className="placeholder" style={{ marginLeft: 16 }}>{strings.chinesename}</span>
                                        </div>
                                    </Col>
                                    <Col md={5} style={{ marginTop: -10 }}>
                                        <Input size="large" style={{ maxHeight: 32 }} className='setupinputbox' value={this.state.nameZH} placeholder={strings.pleasefill + ' ' + strings.chinesename} onChange={e => this.setState({ nameZH: e.target.value })} />
                                    </Col>
                                    <Col md={4} style={{ marginTop: -10 }}>
                                        <div>
                                            <span className="placeholder" style={{ marginLeft: 16 }}>{strings.englishname}</span>
                                        </div>
                                    </Col>
                                    <Col md={5} style={{ marginTop: -10 }}>
                                        <Input size="large" style={{ maxHeight: 32 }} className='setupinputbox' value={this.state.nameEN} placeholder={strings.pleasefill + ' ' + strings.englishname} onChange={e => this.setState({ nameEN: e.target.value })} />
                                    </Col>
                                </Row>
                                <Button type="primary" size='large' style={{ marginRight: 17, marginTop: 30 }} loading={this.state.loading} onClick={this.searchResource}>
                                    {strings.search}
                                </Button>
                                <Button size='large' style={{ marginRight: 20, marginTop: 30 }} onClick={this.clearScrren}>
                                    {strings.clear}
                                </Button>
                            </div>
                            <div>
                                {this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR ? <Row lg={24}>
                                    {
                                        this.state.sensorData.map((sd, index) => (
                                            <Col lg={4} md={6} sm={6} xs={6} key={index} style={{ marginTop: 20 }}>
                                                <div className="Boxes1">
                                                    {sd.sensorData.IsOccupied == null || sd.isUnderMaintenance === 1 ?
                                                        <img src={require('./assets/grey.svg')} className="minusLogo2"></img> :
                                                        (sd.sensorData.IsOccupied == false ? <img className="minusLogo2" src={require('./assets/green.png')} />
                                                            : <img className="minusLogo2" src={require('./assets/red.png')} />)}
                                                    <span className="NumberTextinBox">{locale == 'EN' ? sd.nameEN : sd.nameZH}</span>
                                                    {this.state.type !== RESOURCE_TYPES.ADMIN_CARPARK &&
                                                        <span style={{ fontSize: 14, marginRight: 10, color: '#ff234c', border: '1px solid #ff234c', float: 'right', lineHeight: '20px', marginTop: 8 }}
                                                            className="SignOutBtn" >{sd.capacity} {this.state.type === RESOURCE_TYPES.WASHROOM ? strings.cubicle : strings.seat}</span>}

                                                    <div className='temptextdiv'>
                                                        <div hidden={sd.iscarpark}>
                                                            <img className="logoTemp" src={require('./assets/temp.png')} hidden={sd.temperature == '--'} />
                                                            <span className="TempText" hidden={sd.temperature == '--'}>{sd.temperature}℃</span>

                                                            <span className='humitextdiv' hidden={sd.temperature == '--'}>
                                                                <img className="logoDrop" src={require('./assets/drop.png')} />
                                                                <span className="TempTextPer" >{sd.humidity}%</span>                                                                
                                                            </span>
                                                            {this.state.type === RESOURCE_TYPES.WASHROOM && sd.adminInactive !== null &&<Row style={{fontSize:12}}>
                                                                <Col span={9}>{strings.admininactive} {':'} </Col>
                                                                <Col span={15}>{sd.adminInactive === ''? strings.na : sd.adminInactive.split(',').map((d,di)=><span key={di}>{d}<br/></span>)}</Col>                                                                
                                                            </Row>}
                                                            {this.state.type === RESOURCE_TYPES.WASHROOM && sd.sensorInactive !== null &&<Row style={{fontSize:12}}>
                                                                <Col span={9}>{strings.sensorinactive} {':'} </Col>
                                                                <Col span={15}>{sd.sensorInactive === ''? strings.na : sd.sensorInactive.split(',').map((d,di)=><span key={di}>{d}<br/></span>)}</Col>                                                                
                                                            </Row>}
                                                            {this.state.type === RESOURCE_TYPES.WASHROOM && sd.lowBattery !== null &&<Row style={{fontSize:12}}>
                                                                <Col span={9}>{strings.lowbattery} {':'} </Col>
                                                                <Col span={15}>{sd.lowBattery === ''? strings.na : sd.lowBattery.split(',').map((d,di)=><span key={di}>{d}<br/></span>)}</Col>                                                                
                                                            </Row>}                                                            
                                                            {this.state.type !== RESOURCE_TYPES.WASHROOM && sd.eqs && <div style={{ marginTop: 20, height: 30 }}>
                                                                {sd.eqs.map((ed, index) => <span key={index} className="Projector">{ed}</span>)}
                                                            </div>}
                                                        </div>
                                                        <div style={{ marginTop: 10, marginLeft: 10, textAlign: 'center' }}>
                                                            <Tag color={'#455773'} style={{ marginTop: 10, fontWeight: 500 }} onClick={() => this.viewResource(sd, 1)}>{strings.view1}</Tag>
                                                            <Tag color={'#455773'} style={{ marginTop: 10, fontWeight: 500 }} onClick={() => this.editResource(sd, 1)}>{strings.edit1}</Tag>
                                                            <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.removeResource(sd, 1)}>
                                                                <Tag color={'#455773'} style={{ marginTop: 10, fontWeight: 500 }}>{strings.remove1}</Tag>
                                                            </Popconfirm>
                                                        </div>
                                                        {(sd.resourceType === RESOURCE_TYPES.ADMIN_CARPARK || sd.resourceType === RESOURCE_TYPES.WASHROOM) && <div style={{ marginTop: 10, textAlign: 'center' }}>
                                                            {sd.isUnderMaintenance === 0 ?
                                                                <Tag color={'#455773'} style={{ fontWeight: 500 }} onClick={() => this.editMaintenance(sd, 1, index)}>{strings.maintenance1}</Tag> :
                                                                <Button size='small' onClick={() => this.editMaintenance(sd, 0, index)}>
                                                                    {strings.resume}
                                                                </Button>}
                                                        </div>}
                                                    </div>
                                                </div>
                                            </Col>))
                                    }
                                </Row> : <Row lg={24}>
                                    {
                                        this.state.zas.map((sd, index) => (<Col lg={4} mod={6} sm={6} xs={6} key={index} style={{ marginTop: 20 }}>
                                            <div className="Boxes1">
                                                <img src={require(sd.status == 'waiting' ? './assets/green.png' : './assets/red.png')} className="minusLogo2"></img>
                                                <span className="NumberTextinBox">{strings.getLanguage() == 'en' ? sd.nameEN : sd.nameZH}</span>
                                                <span style={{ fontSize: 14, marginRight: 10, color: '#ff234c', border: '1px solid #ff234c', float: 'right', lineHeight: '20px', marginTop: 8 }} className="SignOutBtn" >{sd.capacity} {strings.seat}</span>

                                                <div className='temptextdiv'>
                                                    <div style={{ marginTop: 10, marginLeft: 10 }}>
                                                        <Tag color={'#455773'} style={{ fontWeight: 500, marginTop: 10 }} onClick={() => this.viewResource(sd, 2)}>{strings.view1}</Tag>
                                                        <Tag color={'#455773'} style={{ fontWeight: 500, marginTop: 10, }} onClick={() => this.editResource(sd, 2)}>{strings.edit1}</Tag>
                                                        <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.removeResource(sd, 2)}>
                                                            <Tag color={'#455773'} style={{ fontWeight: 500, marginTop: 10, }}>{strings.remove1}</Tag>
                                                        </Popconfirm>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>))
                                    }
                                </Row>}
                            </div>
                        </div>

                        <Modal
                            title={strings.alert}
                            visible={this.state.showalertmodel}
                            onCancel={() => this.setState({ showalertmodel: false })}
                            footer={[
                                <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerReload) window.location.reload(); }}>
                                    {strings.ok}
                                </Button>,
                            ]}
                        >
                            <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                            <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                        </Modal>
                    </div>
                        : this.state.radiooption == 'b' ? <AddResource md={this.state.selectedResource} carSlots={this.state.carSlots} iszoom={this.state.iszoom} isadd={this.state.isAdd} isedit={this.state.isEdit} backbtn={this.backbtn} pdfkey={Math.random} pdfkey1={Math.random} />
                            : <Sharing />
                    }</Spin>
            </div>
        );
    }
}
export default Setup;

