import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import {Radio,Input, Row, Col,List,Select,Modal,Button} from "antd";
import './App.css';
import UserInfo from './UserInfo'
import AdminBooking from './AdminBooking'
import AdminCarpark from './AdminCarpark'
import AdminZoom from './AdminZoom'
import AdminPendingForAction from './AdminPendingForAction'
import {ApiHeader,strings,apiurl,DTD_ROLES,NORMAL_ROLES,ONLINE_ROLES} from './data';
const Search = Input.Search;
const locale = 'EN';

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            systemData:null,
            resource:null,
            radiooption:'a',
            userdata:[],
            selectedUser:null,
            alertmessage:'',
            showalertmodel:false,
            issearching:false,
            searchstr:'',
        }        
    }

    

    componentDidMount() {
        sessionStorage.setItem('tabNumber',15); 
        ApiHeader.userId = sessionStorage.getItem("userId");      
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({resource:sd.resource,systemData:sd});
    }
  
    componentWillUnmount() {
  
    }

    onUserSelect=(item)=>{
        var ud = this.state.userdata
        for(var i=0;i<ud.length;i++){
            ud[i].color='#e6f7ff';
            ud[i].color1='#34435b';
        }
        ud[item.key].color='#ff234c';
        ud[item.key].color1='#fff';
        this.setState({userdata:ud,selectedUser:item})        
    }

    handleSearch1=(e)=>{
        if(!this.state.issearching && this.state.searchstr.length>1) {      
            var val = this.state.searchstr; 
            this.setState({issearching:true,searchstr:''});        
            var rdata = {};
            rdata.name=val;
            fetch(apiurl+'user/search2', {
            headers: ApiHeader,
            method: 'POST',  
            credentials: 'same-origin', 
            body: JSON.stringify(rdata),         
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
                })
                .then(data =>{
                if(data.code == "1"){
                    var users = data.data.user;
                    var uds = [];
                    for(var i=0;i<users.length;i++){
                        var u = {};
                        u.id=users[i].id;
                        u.key=i;
                        u.name=users[i].name;
                        u.post=users[i].post;
                        u.role = users[i].role;
                        u.color='#e6f7ff';
                        u.color1='#34435b';
                        uds.push(u);
                    }
                    this.setState({userdata:uds,issearching:false});
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true,issearching:false})
                }
                this.setState({issearching:false})
            })
            .catch(e => {this.setState({issearching:false});console.warn(e)})
        }else{
            this.setState({alertmessage:strings.enter3char,showalertmodel:true})  
        }
    }

    onRadioChange=(e)=>{
        this.setState({radiooption:e.target.value})        
    }
    

    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
      }
      
    render() {
    
    
        return (
            <div className='Tab3Div'>
                <div style={{marginTop:20}}>                               
                    <Row xs={24}> 
                        <Col xs={4}>
                            <span style={{color:'#ff7f27',fontSize:11}}>{strings.plsincludeslashinsearch}</span>
                            <Search
                                placeholder={strings.enter3char}
                                enterButton
                                disabled={this.state.issearching} 
                                loading={this.state.issearching}
                                value={this.state.searchstr}
                                onChange={e=>this.setState({searchstr:e.target.value})}
                                onSearch={value => this.handleSearch1(value)}
                                style={{width:'80%',fontSize: 15,marginTop:5}}
                            />
                            <div style={{marginTop:10}}>
                            <List
                                size="small"
                                header={null}
                                style={{width:'80%'}}
                                footer={
                                    <div>
                                    {strings.totalcount} {this.state.userdata.length}
                                    </div>
                                }
                                bordered={false}
                                dataSource={this.state.userdata}
                                pagination={{
                                    onChange: page => {
                                    },
                                    pageSize: 10,
                                }}
                                renderItem={item => <List.Item onClick={()=>this.onUserSelect(item)} style={{background:item.color,color:item.color1,fontSize:12,fontWeight:500}}><span style={{marginLeft:5}}>{item.name} ({item.post})</span></List.Item>}
                            />                 
                            </div>
                        </Col>
                        <Col xs={20}>
                            <div style={{display: 'flex', justifyContent: 'center',marginTop:25}}>
                                <Radio.Group value={this.state.radiooption} buttonStyle="solid" onChange={this.onRadioChange}>
                                    <Radio.Button value="a">{strings.info}</Radio.Button>
                                    {(DTD_ROLES.includes(sessionStorage.getItem("userrole")) || NORMAL_ROLES.includes(sessionStorage.getItem("userrole"))) && <Radio.Button value="b">{strings.meetingroom}</Radio.Button>}
                                    {NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) && <Radio.Button value="c">{strings.carpark}</Radio.Button>}
                                    {ONLINE_ROLES.includes(sessionStorage.getItem("userrole")) && <Radio.Button value="e">{strings.zoom}</Radio.Button>}
                                    {NORMAL_ROLES.includes(sessionStorage.getItem("userrole")) && <Radio.Button value="d">{strings.pendingforaction}</Radio.Button>}
                                </Radio.Group>
                            </div>
                            <div style={{marginTop:20}}>
                                {this.state.radiooption == 'a' && this.state.selectedUser != null ?<UserInfo sd={this.state.systemData} su={this.state.selectedUser} />:''}
                                {this.state.radiooption == 'b' && this.state.selectedUser != null ?<AdminBooking sd={this.state.systemData} su={this.state.selectedUser} />:''}
                                {this.state.radiooption == 'c' && this.state.selectedUser != null ?<AdminCarpark sd={this.state.systemData} su={this.state.selectedUser} />:''}
                                {this.state.radiooption == 'e' && this.state.selectedUser != null ?<AdminZoom sd={this.state.systemData} su={this.state.selectedUser} />:''}
                                {this.state.radiooption == 'd' && this.state.selectedUser != null ?<AdminPendingForAction sd={this.state.systemData} su={this.state.selectedUser} />:''}
                            </div>                            
                        </Col>
                    </Row>                             
                </div> 
                <Modal
                        title={strings.alert}
                        visible={this.state.showalertmodel}
                        onCancel={()=>this.setState({showalertmodel:false})}
                        footer={[                  
                            <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerReload)window.location.reload();}}>
                            {strings.ok}
                            </Button>,
                        ]}
                        >
                            <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                            <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                    </Modal>         
            </div>
        );
    }
}    
export default User;

