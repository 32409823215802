import LocalizedStrings from 'react-localization';

var ApiHeader = {
   'Content-Type': 'application/json',
   'token': "srswebapp",
   'userId': 0
};

var isdevelopment = 0;
var apiurl = '/srs/';  //'http://123.176.103.187:8020/srs/api/'; //
export const floorPlan_URL = 'https://booking.emsd.gov.hk/srs_floorplans/';
var admin_roles = ['admin', 'dtd', 'normal', 'online', 'normal_dtd', 'normal_online', 'dtd_online'];
var ADMIN_ROLES = {
   ADMIN: 'admin',
   DTD: 'dtd',
   NORMAL: 'normal',
   ONLINE: 'online',
   NORMAL_DTD: 'normal_dtd',
   NORMAL_ONLINE: 'normal_online',
   DTD_ONLINE: 'dtd_online',
}
export const ALL_FLOORS = ['7/F', '6/F', '5/F', '4/F', '3/F', '2/F', '1/F', 'G/F'];
export const ALL_FLOORS1 = [' 10/F', ' 7/F', ' 6/F', ' 5/F', ' 4/F', ' 3/F', ' 2/F', ' 1/F', ' G/F'];
export const DIVISIONS = ['FD','CSD','DTD','SVSD','BTSD','SMU','SRU','GESD','HSD','MunSD','RB','EEO','ELD','ET','GLD','GSDA','GSDB','TS','DAD'];
export const TYPES = ['AM', 'CC', 'DO', 'DMA', 'SDO'];
export const SIZES = ['EV','L'];
export const ZONES = ['A', 'B'];
export const FLOOR_WITH_ZONE = ['3/F','4/F'];
export const DIVISIONCOLORS = {
    BTSD:{color1:'#ff0000',color2:'#ff0000'},
    CSD:{color1:'#ff0000',color2:'#002060'},
    DAD:{color1:'#ff0000',color2:'#00B050'},
    DTD:{color1:'#CCFFFF',color2:'#CCFFFF'},
    ET:{color1:'#FFFF00',color2:'#002060'},
    EEO:{color1:'#002060',color2:'#002060'},
    ELD:{color1:'#E26B0A',color2:'#E26B0A'},
    FD:{color1:'#FFFF00',color2:'#FFFF00'},
    GLD:{color1:'#00B050',color2:'#E26B0A'},
    GESD:{color1:'#00B050',color2:'#00B050'},
    GSDA:{color1:'#002060',color2:'#00B050'},
    GSDB:{color1:'#00B050',color2:'#002060'},
    HSD:{color1:'#6600FF',color2:'#6600FF'},
    MunSD:{color1:'#00B050',color2:'#CCFFFF'},
    RB:{color1:'#00B050',color2:'#FFFF00'},
    SMU:{color1:'#6600FF',color2:'#FFFF00'},
    SRU:{color1:'#6600FF',color2:'#FFFF00'},
    SVSD:{color1:'#000000',color2:'#000000'},
    TS:{color1:'#E26B0A',color2:'#000000'}
};

export const getResourceContactInfo=(resource)=>{
   var resourceContactInfo = '';
   if(resource.divisions !== null && resource.contacts.length>0){
     var divisions = JSON.parse(resource.divisions);
     if(divisions.length>0){
       var division = divisions[0].name;
       var contactinfo = '';
       for(var contact of resource.contacts){
         if(contactinfo === ''){
           contactinfo = contact.contact;
         }else{
           contactinfo = contactinfo+', '+contact.contact;
         }
       }
       if(division === 'SVSD'){
         resourceContactInfo = strings.meetingroomcontactinfo1;
       }else{
         resourceContactInfo = strings.meetingroomcontactinfo;            
       }
       resourceContactInfo = resourceContactInfo.replace('<SBU>', division).replace('<contact>',contactinfo);
     }        
   }
   return resourceContactInfo;
 }

export const TYPES_SUMMARY = [
   {title:'notshared',isBold:1,value:null,shared:'N'},
   {title:'AM',isBold:0,value:'AM',shared:'N'},
   {title:'CC',isBold:0,value:'CC',shared:'N'},
   {title:'DO',isBold:0,value:'DO',shared:'N'},
   {title:'DMA',isBold:0,value:'DMA',shared:'N'},
   {title:'SDO',isBold:0,value:'SDO',shared:'N'},
   {title:'N/A',isBold:0,value:'',shared:'N'},

   {title:'shared',isBold:1,value:null,shared:'Y'},
   {title:'AM',isBold:0,value:'AM',shared:'Y'},
   {title:'CC',isBold:0,value:'CC',shared:'Y'},
   {title:'DO',isBold:0,value:'DO',shared:'Y'},
   {title:'DMA',isBold:0,value:'DMA',shared:'Y'},
   {title:'SDO',isBold:0,value:'SDO',shared:'Y'},
   {title:'N/A',isBold:0,value:'',shared:'Y'}
]

export const legendText1 = [
   {col1:'Trading Services',col2:'FD',col3:'Financial Division',border:true},
   {col1:'Engineering ',col2:'CSD',col3:'Corporate Service Division'},
   {col1:'Service Branch 3',col2:'DTD',col3:'Digitalisation and Technology Division',border:true},
   {col1:'Engineering',col2:'SVSD',col3:'Security and Vehicle Services Division'},
   {col1:'Service Branch 1',col2:'BTSD',col3:'Boundry and Transport Service Division'},
   {col1:'',col2:'SMU',col3:'Staff Management Unit'},
   {col1:'',col2:'SRU',col3:'Staff Relationship Unit',border:true},
   {col1:'Engineering',col2:'GESD',col3:'General Engineering Service Division'},
   {col1:'Service Branch 2',col2:'HSD',col3:'Health Sector Division'},
   {col1:'',col2:'MunSD',col3:'Municipal Sector Division',border:true},
]

export const legendText2 = [
   {col1:'Railway Branch',col2:'RB',col3:'Railway Branch',border:true},
   {col1:'E&EE Branch ',col2:'EEO',col3:'Energy Efficiency Division'},
   {col1:'',col2:'ELD',col3:'Electricity Legislation Division'},
   {col1:'',col2:'ET',col3:'Electricity Team',border:true},
   {col1:'G&GL Branch',col2:'GLD',col3:'General Legislation Division'},
   {col1:'',col2:'GSDA',col3:'Gas Standards Division 1'},
   {col1:'',col2:'GSDB',col3:'Gas Standards Division 2'},
   {col1:'',col2:'TS',col3:'Technical Secretariat',border:true},
   {col1:'Senior Directorate and DAD',col2:'DAD',col3:'Departmental Administration Division',border:true},
]


export const getContactPerson=(strings, saltuation, name)=>{
   const lang = sessionStorage.getItem("userlang");
   if(lang === 'en'){
      return (strings[saltuation] || '')+' '+name;
   }else{
      const names = name.split('(');
      if(names.length>1){
         return names[0]+' '+(strings[saltuation] || '')+' ('+names[1];
      }else{
         return name+' '+(strings[saltuation] || '');
      }
      
   }
}

export const fontsize = 10;
export const charwidth = 6.36;
export const textHeight = 12;
export const largeSlotWidth = 120;
export const smallSlotWidth = 90;
export const smallSlotHeight = 40;
export const floorPlanWidth = 3745;
export const floorPlanHeight = 1253

export const WASHROOM_ZONES = ['A','B','C'];
export const WASHROOM_TYPES = ['male','female'];
export const WASHROOM_MAX_CAPACITY = 10;

var DTD_ROLES = ['admin', 'dtd', 'normal_dtd', 'dtd_online'];
var NORMAL_ROLES = ['admin', 'normal', 'normal_dtd', 'normal_online'];
var ONLINE_ROLES = ['admin', 'online', 'normal_online', 'dtd_online'];

var RESOURCE_TYPES = {
   VISITOR_CARPARK : "virtual_visitor_carpark",
   OPERATIONAL_CARPARK : "virtual_temporary_carpark",
   MEETING_ROOM : "meeting room",
   ADMIN_CARPARK : "admin_carpark",
   ADMIN_ROOM : "admin_room",
   ZOOM_MEETING : 'zoom_meeting',
   ZOOM_WEBINAR : 'zoom_webinar',
   WASHROOM:'washroom'
}


let strings = new LocalizedStrings({
   en: {      
      MR:'Mr.',
      MS:'Ms.',
      MISS:'Miss',

      next:'Next',
      previous:'Previous',
      admininactive:'Maint (admin)',
      sensorinactive:'Maint (sensor)',
      lowbattery:'Low Battery',
      
      activewashroomcode:'Washroom Active Code',
      washroomactivecodemsg:'Active code for Washroom page access by QR code',
      availablecubical:'Available Cubicle',
      toilet:'Toilet',
      male:'Male',
      female:'Female',
      mlbl:'M',
      flbl:'F',
      maintenancelbl:'MAINTENANCE',
      wtype:'Washroom Type',
      cubicle:'Cubicle',
      wcapacity:'Capacity',
      
      stafftype:"EMSD Staff Type",
      stafftype1:"HQs DMA Claimant",
      stafftype2:"Out-stationed EMSD Staff",
      visitorstaffnotice:"Only for uses by Visitor/ non-EMSD Staff",
      operationalstaffnotice:"Only for uses by EMSD Staff",      
      visitorcapacity: '(cap.8)',      
      operationalcapacity: '(cap.12)',

      graphtype1:'By Date, Time',
      graphtype2:'By Time',
      graphtype:'Graph Type',  
      vacancycount:'Vacancy Count',    

      meetingroomcontactinfo:'This meeting room is administrated by <SBU>.  If you have any enquiry, please contact <contact>.',      
      meetingroomcontactinfo1:'This meeting room is situated outside EMSD HQs and administrated by <SBU>.  If you have any enquiry, please contact <contact>.',      
      minimumvalue:"Minimum value",
      vaccancy:'Vacancy',
      lock:'Lock',
      datalock:'Sensor Lock',
      locktimeerror: 'Lock time should not before current time',
      inactive:'Inactive',
      carparkvacancy:'Carpark Vacancy',
      confirmchangetab:'All unsaved changes will be lost, Are you sure to change resource?',

      analyst:'Analyst',
      data:'Data',
      selectioncriteria:'Selection Criteria',
      class:'Class',
      daterangemonthserr:'Date range should not be more than 6 months',
      daterangemonthserr1:'Date range should not be more than 1 months',
      fromtoerr:'To date should be after From date',

      maintenance1:'MAINTENANCE',
      resume:'RESUME',
      adminfunctionformechanized:'This is the administrative function to assign the parking space to the type of carpark booking.',      
      confirmwithswitch:'Confirm and Send Email',
      confirmnotswitch:'Confirm and Not send Email',
      shortenextend:'SHORTEN / EXTEND',
      shortenextendmeeting:'Shorten / Extend booking time',
      selectendtime:'Please select end time',
      rejected:'Rejected',

      light:'Light',
      addSensor:'Add Sensor',
      airquality:'Air Quality',
      temphumilightoccu:'Temperature and Humidity and Occupancy and Light',
      

      isreminder:'Booking Reminder',
      timeslorerr5: 'Booking timeslot should not be on saturday or sunday',
      roomremindermsg:'A new function of sending reminders to user for the reservation of meeting room 2, 14, 30 days prior to the day of meeting',      
      zoomremindermsg:'A new function of sending reminders to user for the reservation of online meeting 2, 14, 30 days prior to the day of online meeting',
      carparkremindermsg:'A new function of sending reminders to user for the reservation of carpark space 2, 14, 30 days prior to the day of parking',
      pool:'Pool',
      
      upper:'Upper',
      lower:'Lower',
      small:'Small',
      large:'Large',
      vertical:'Vertical',
      horizontal:'Horizontal',
      left:'Left',
      right:'Right',

      carparksharing:'Carpark Sharing',
      tvboard:'TV Board',      

      plsentervalidslotno:'Please enter valid Slot No.',
      publishsuccess:'Save and Publish Successfully',

      maintenancesuccess:'Maintenance Setup Successfully',
      plsenteruniqueslotno:'Please enter unique Slot No.',

      sumofdivisionshouldbeone:'Sum of Division value should be 1',
      plsselectdivision:'Please select division and value',
      
      stopmaintenance:'Stop Maintenance',
      startmaintenance:'Start Maintenance',
      
      sharing:'Sharing',
      textposition:'Position',
      direction:'Direction',
      size:'Size',
      slotno:'Slot No.',

      floorsummary:'Floor Summary',
      buildingsummary:'Building Summary',
      hqsummary:'Headquarters Summary',
      
      export:'Export',
      print:'Print',
      publish:'Save and Publish',
      zone:'Zone',
      division:'Division',
      lev:'Class',
      shared:'Shared',
      coowner:'Co-Owner',
      apply:'Apply',
      reset:'Reset',
      save:'Save',
      summary:'Summary',
      sdo:'Senior Direcorate Officer',
      do:'Directorate Offficer',
      dma:'Duty Mileage Allowance Claimant',
      cc:'Contract Car',
      am:'Government Vehicle',
      share:'Share',
      totalallocation:'Total allocation',
      shareduseam:'Share-use AM',
      physicalparkingspace:'Physical Parking Space',
      notallocated:'Not Allocated',
      allocated:'Allocated',
      color:'Color',
      remark:'Remark',
      num:'No.',
      notshared:'Not Shared',

      adminname1:'Administrative Support 1',
      adminname2:'Administrative Support 2',
      techname1:'Technical Support 1',
      techname2:'Technical Support 2',

      maxregistrant:'Maximum Registrant',
      specific:'Specific',
      effective: 'effective',
      androidinstruction1:'When encountered a security prompt on Android,',
      androidinstruction11:'When encountered a security prompt on Huewai,',
      androidinstruction2:'Enable App Installation from Unknown Sources',
      androidinstruction3:'to proceed the installation.',
      androidinstruction4:'',
      androidinstruction5:'',
      clickthislink:'this link',
      scanqrapp: 'Scan QR code or click',
      formobileversion:'for mobile version',      
      
      this:'this',      
      nonofficehourmsg:'*** As booking period exceeds office hour (i.e. weekday before 08:30 and after 18:00, or on weekends), after your booking is approved, you will receive an email and please contact EMU for arrangement.',
      ecvehicalmsg:'*** For electric vehicle that requires charging facilities, after your booking is approved, you will receive an email and please contact EMU for arrangement.',
  
      carparkreason:"Event / Project / Work",    
      carplatenumber:"Car Plate Number",
      visitorcarparkquota:'Visitor Quota',
      operationalcarparkquota:'Operational Quota',
      amendquota:'Amend Quota',
      amendhistory:'Coming Amendment',
      default:'Default',
      specificdate:'Specific Date',
      quota:'Quota',
      quotaremark:'Remark: Numbers in above table represent the occupancy of visitor/operational carpark in particular timeslot.',
      submitteddate:'Submitted Date',

      searchvisitor: 'Search Visitor',
      noofvisitorparking: 'No. of slots',
      editVisitorParking: 'Edit visitor parking',
      addVisitorParking: 'Add visior parking',
      effectivedate: 'Effective Date',
      noactiveslots: 'No active visitor parking slots',
      activevisitorparking: 'Active visitor parking',
      parkingspace: 'Parking Space',
      nonmechanized: 'Non-mechanized',
      mechanized: 'Mechanized',

      division: 'Division',
      divisions: ['BTSD', 'CSD', 'DAD', 'DTD', 'EEO', 'ELD', 'ET', 'FD', 'GESD', 'GLD', 'GSDA', 'GSDB', 'HSD', 'MunSD', 'RB', 'SMU', 'SRU', 'SVSD', 'TS'],
      carnumbernotvalid: 'Car Plate Number is not valid',
      pendingvisitorapproval: 'Pending Visitor Carpark Approval',
      termsandcondition: 'I, on behalf of the carpark user, have read,understood and accepted the ',
      termsandconditionlink: 'Terms & Conditions of the Use and Disclaimer',
      termsandcondition1:'.',
    

      click: 'For operational carpark, click ',
      here: 'here',
      todownloaduserguide: 'for the User Instruction of Smart Car Parking System.',
      

      minsystemreq: 'Minimum system requirements',
      webbrowsertxt: 'Web browser：Google Chrome/ IE 11',
      resolutiontxt: 'Resolution：1024 x 768 or above',


      
      followinstructionapp: 'Remember to click here to follow the instruction after installation finished',

      isdtdroom: 'Is DTD room',
      dtd: 'DTD',
      upload: 'Upload',
      clicktoupload: 'Click to Upload',
      selectfile: 'Select file',
      plsincludeslashinsearch: 'Note : Please include \'/\' in acting post search string, e.g CE/SV',
      logdownload: 'Log Download',

      downloadcurrentSettings: 'Current Settings',
      downloaddefaultSettings: 'Default Settings',
      restoredefaultsettings: 'Restore Settings',

      encryptiontype: 'Encryption Type',
      encryptiontyperemark: 'End-to-End Encryption is more secured and recommended for meeting with sensitive information. However, telephone dial-in, join-by-browser and other features may not be available.',
      enhancedencryption: 'Default Encryption',
      e2ee: 'End-to-End Encryption',


      reserve: 'Reserve',
      zoomguide: 'Zoom Guide',
      onprem: '(On-Prem)',
      publiccloud: '(Cloud)',
      zoominfo1: '1. For "meeting", please use "meeting(on-prem)" account by-default.',
      zoominfo2: '2. For hosting "webinar" with most target audience are EMSD staff, please use "webinar(on-prem)", else "webinar(cloud)";',
      zoominfo3: '3. To understand the difference between "meeting" and "webinar", please refer',

      iselectric: 'Is Electric Vehicle',
      visitorname: 'Name of Visitor',
      visitorcompany: 'Company / Department',
      purpose: 'Purpose',
      training: 'Training',
      briefing: 'Briefing',
      recruitmentboard: 'Recruitment Board',
      promotionboard: 'Promotion Board',
      others: 'Others',
      advancesearch: 'Advance Search',
      basicsearch: 'Basic Search',
      duration: 'Duration',
      availabletime: 'Available Time',
      searchavailabletimeslot: 'Search Available Timeslot',
      nameofvisitor: 'Please fill Name of Visitor',
      nameofcompanydepartment: 'Please fill Company / Department',
            
      
      halfhour: '0.5 Hour',
      onehour: '1 Hour',
      onehalfhour: '1.5 Hours',
      twohour: '2 Hours',
      twohalfhour: '2.5 Hours',
      threehour: '3 Hours',
      threehalfhour: '3.5 Hours',
      fourhour: '4 Hours',
      fourhalfhour: '4.5 Hours',
      fivehour: '5 Hours',

      fstreminder: '1st Reminder',
      sndreminder: '2nd Reminder',
      trdreminder: '3rd  Reminder',
      fthreminder: '4th Reminder',
      finalreminder: 'Final Reminder',
      sensorhistory: 'Sensor History',
      actiontime: 'Action Time',

      registrants: 'Registrants',
      approved: 'Approved',
      denied: 'Denied',
      firstname: 'Firstname',
      lastname: 'Lastname',
      reminder: 'Reminder',
      reminderlog: 'Booking Reminder Log',
      noaction: 'No Action',
      keep: 'Keep',
      setupreminder: 'Setup Booking Reminder',
      continuesmin: 'Continuous Minutes',
      maxnotification: 'Maximum No. of notification',
      lightincluded: 'Included Lighting',
      savedsuccess: 'Saved Successfully',
      sensor1: 'SENSOR',
      gotit: 'Got it',
      loading: 'Loading',
      deny: 'Deny',
      reminderfeaturestr: 'A trial for meeting room reminder is implemented in this room.',
      useraction: 'User action',

      subjectmaxlength: 'Subject can not be more than 100 character long',
      remarkmaxlength: 'Remark can not be more than 500 character long',
      reasonmaxlength: 'Reason can not be more than 100 character long',
      contactpersonmaxlength: 'Contact person can not be more than 100 character long',
      contactphonemaxlength: 'Contact phone can not be more than 45 character long',
      carnumbermaxlength: 'Car number can not be more than 50 character long',

      iscloud: 'Is Cloud',
      zoomcloudterms: 'Please ensure no restricted information shared during the online meeting',
      startmeetingnote1: '1.  Please download and install Zoom (https://zoom.us/download/)',
      startmeetingnote2: '2.  Please ensure to login Zoom using below the host account before click Start button',
      start: 'Start',

      joinbyvoiceonly: 'Join by Dial-In(Voice Only):',
      dialstring: '1. Dial #dial_number# refer https://emsd-gov-hk.zoom.us/u/acnrHoqp8',
      afterdialedinstring: '2. After dialed in, press ',
      startmeeting: 'Start Meeting',

      show: 'Show',
      hide: 'Hide',
      invitetext: 'Invite Text',
      plsdownloadzoom: 'Please download Zoom (https://zoom.us) and login this account to start a meeting.',
      copyinvitetext: 'Copy Invite Text',


      copyjoinurl: 'Copy Join URL',
      joinzoommeeting: 'Join Online Meeting:',
      onetapmobile: 'One tap mobile',
      dialbyyourlocation: 'Dial by your location',
      findyourlocalnumber: 'Find your local number:',
      message: 'Message',
      error: 'Error : ',
      zoombookmark: 'Online Meeting Room Bookmark',
      hostmailorids: 'hosts emails/ids (comma seperated)',
      topic: 'Topic:',

      livedata: 'Live Data',
      available: 'Available',
      occupied: 'Occupied',
      zoomstatus: 'Online Meeting Room Status',
      meetingorwebinarid: 'Meeting ID',
      meetingorwebinarpassword: 'Meeting Password',
      na: 'N/A',
      meeting: 'Meeting',
      webinar: 'Webinar',

      advanceconfig: 'Advance Config',
      bookzoom: 'Book Online Meeting Room',
      zoomacc: 'Online Meeting Room Account',
      account: 'Account',
      zoommeeting: 'Online Meeting Room (Meeting)',
      zoomwebinar: 'Online Meeting Room (Webinar)',
      audiotype: 'Audio Type',
      bothtelephobyandvoip: 'Both Telephony and VoIP',
      teleponyonly: 'Telephony Only',
      voiponly: 'VoIP only',
      approvaltype: 'Approval Type',
      automaticallyapprove: 'Automatically Approve',
      manuallyapprove: 'Manually Approve',
      noregistrationrequired: 'No Registration Required',

      allowmultipledevices: 'Allow Multiple Devices',
      registrantsrestrictnumber: 'Registrants Restrict Number',
      contactname: 'Contact Name',
      contactemail: 'Contact Email',

      hostemailaddress: 'Host Email',
      hostpassword: 'Host Password',
      passwordonlyvalidformeetingdate: 'Note : This host password is only valid on ',
      joinurl: 'Join URL',
      cipiedsuccess: 'Copied successfully',
      only: '',
      webinarid: 'Webinar ID',
      copymeetingjoinurl: 'Copy Meeting Join URL',
      copyhostaccountinfo: 'Copy Host Account Info',

      alternativehosts: 'Alternative Hosts',
      waitingroom: 'Waiting Room',

      participentvideo: 'Participent Video',
      bookazoommeeting: 'Book a Online Meeting Room (Meeting)',
      bookazoomwebinar: 'Book a Online Meeting Room (Webinar)',
      meetingsetting: 'Meeting Setting',
      webinarsetting: 'Webinar Setting',
      zoomemail: 'Zoom Email',
      zoompassword: 'Zoom Password',
      zoom: 'Online Meeting Room',
      zoom1: 'Online Room',
      meetingid: 'Meeting ID',
      meetingpassword: 'Meeting Password',
      getaccountpassword: 'Get Account Password',
      copy: 'Copy',
      copymeetingurl: 'Copy Meeting URL',


      audiotooltip: 'Determine how participants can join the audio portion of the meeting',
      waitingroomtooltip: 'Enable waiting room',
      alternativehoststooltip: "Alternative host's emails or IDs: multiple values separated by a comma",
      allowmultipledevicestooltip: "Allow attendees to join from multiple devices",
      registrantsrestrictnumbertooltip: "Restrict number of registrants for a webinar (Max 500)",
      contactnametooltip: "Contact name for registration",
      contactemailtooltip: "Contact email for registration",

      started: 'Started',
      finished: 'Finished',
      waiting: 'Waiting',



      fixedroom: 'Room',

      //Main
      title: 'Smart Reservation System',
      read: 'READ',
      unread: 'Unread',
      read1: 'Read',
      notification: 'Notification',
      date: 'Date',
      cancel: 'CANCEL',
      nodata: 'No Data',
      room: 'Room',
      userview: 'User View',
      adminview: 'Admin View',
      hello: 'Hello',
      signout: 'Sign Out',
      dashboard: 'Dashboard',
      schedule: 'Schedule',
      malfunction: 'Malfunction',
      maintenance: 'Maintenance',
      user: 'User',
      report: 'Report',
      setup: 'Setup',
      system: 'System',
      bookroom: 'Book Room',
      bookcarpark: 'Book Carpark',
      mybooking: 'My Booking',
      pendingforaction: 'Pending for Action',
      sensordata: 'Sensor Data',
      setting: 'Setting',
      ok: 'Ok',
      readall: 'Read All',
      userpasswordwrong: 'Username or Password is incorrect. Please check your CapsLock and Input setting.',
      userguide: 'User Guide',
      minsysreq: 'Minimum system requirements',
      webbrowsersupp: 'Web browser： Google Chrome/ Internet Explorer 11',
      resolorabove: 'Resolution： 1024 x 768 or above',
      cap: 'cap.',

      //Add Maintenance
      addmaintenance: 'Add Maintenance',
      editmaintenance: 'Edit Maintenance',
      maintenancedetail: 'Maintenance Detail',
      type: 'Type',
      meetingroom: 'Meeting Room',
      visitorcarpark: 'Visitor Carpark',
      operationalcarpark: 'Operational Carpark',
      floor: 'Floor',
      name: 'Name',
      starttime: 'Start Time',
      endtime: 'End Time',
      description: 'Description',
      create: 'Create',
      update: 'Update',
      clear: 'Clear',
      back: 'Back',
      updatesuccess: 'Updated successfully.',
      addedsuccess: 'Added successfully.',
      errorcode: 'Error Code : ',
      errormessage: ' ',
      confirmcancel: 'Confirm Cancel',
      affectedbookingetc: 'Affected booking for selected time slot.',
      doyouwanttocancel: 'Do you want to cancel them?',
      selectdateandtime: 'Select Date and Time',
      owner: 'Owner',


      //Add Malfunction
      addmalfunction: 'Add Malfunction',
      editmalfunction: 'Edit Malfunction',
      malfunctiondetails: 'Malfunction Detail',
      reportdate: 'Report date',
      remark: 'Remark',

      //Add Resource
      tempnhumidity: 'Temperature and Humidity',
      temperaturehumiditylight:'Temperature, Humidity and Light',
      occupancy: 'Occupancy',
      carparksensor: 'Carpark Sensor',
      addresource: 'Add Resource',
      editresource: 'Edit Resource',
      resourcedetail: 'Resource Detail',
      fixedcarpark: 'Fixed Carpark',
      washroom: 'Washroom',
      chinesename: 'Chinese Name',
      englishname: 'English Name',
      capacity: 'Capacity',
      equipment: 'Equipment',
      latitude: 'Latitude',
      longitude: 'Longitude',
      sensor: 'Sensor',
      pleaseselect: '--Please Select--',
      pleasedragpinetc: 'Please drag and drop yellow pin to locate the resource.',

      //Add System
      holiday: 'Holiday',
      add: 'Add',
      edit: 'Edit',
      details: 'Details',

      //Admin Booking
      view: 'View',
      editbooking: 'Edit Booking',
      chairman: 'Chairman',



      //Admin carpark
      reason: 'Reason',
      state: 'State',
      location: 'Location',
      action: 'Action',
      confirm: 'Confirm',
      cancel1: 'Cancel',
      areyousure: 'Are you sure?',
      totalcount: 'Total Count:',
      editcarpark: 'Edit CarPark',
      visitor: 'Visitor',
      operational: 'Operational',
      carnumber: 'Car Number',
      pleasefill: 'Please fill',
      approver: 'Approver',
      contactperson1: '1st Contact Person Last Name',
      contactphone1: '1st Contact Phone',
      contactperson2: '2nd Contact Person Last Name',
      contactphone2: '2nd Contact Phone',
      bookingcanclled: 'Booking cancelled successfully.',
      time: 'Time',

      //Admin Dashboard
      gents: 'GENTS',
      ladies: 'LADIES',
      publicdashboard: 'Public Dashboard',
      occupancystatus: 'Occupancy Status',
      fixed: 'Fixed',
      operational: 'Operational',
      visitor: 'Visitor',
      total: 'Total',
      level: 'LEVEL',


      //Admin PendingAction
      requestedby: 'Requested By',
      subject: 'Subject',
      approve: 'Approve',
      reject: 'Reject',
      accept: 'Accept',
      decline: 'Decline',
      approvedsuccess: 'Approved successfully',
      rejectedsuccess: 'Rejected successfully',
      acceptedsuccess: 'Accepted successfully',
      declinedsuccess: 'Declined successfully',
      pendingtransferacceptance: 'Pending Transfer Acceptance',
      pendingtransfercarparkapproval: 'Pending Operational Carpark Approval',

      //Admin Schedule
      status: 'Status',
      subrsn: 'Subject/Reason',
      contact1st: '1st Contact',
      contact2nd: '2nd Contact',
      user: 'User',
      any: 'Any',
      dateerr: 'From Date and To Date need to provide together',
      namelengtherrr: 'Contact Person Last Name should be at least 3 characters long',
      enter3char: 'Enter at least 2 characters to search',
      searchbooking: 'Search Booking',
      from: 'From',
      to: 'To',
      booked: 'Booked',
      completed: 'Completed',
      contactperson: 'Contact Person Last Name',
      users: 'Users',
      username: 'User Name',
      select: 'Select',
      delete: 'Delete',
      pending: 'Pending',

      //Booking
      bookmarkaddedsuccess: 'Bookmark added successfully',
      subscribesuccess: 'Subscribed successfully',
      timeslorerr1: 'Booking Timeslot should be after current time',
      timeslorerr2: 'Booking Timeslot should not be after 2 Years',
      bookedsuccess: 'Booked successfully',
      room: 'Room',
      bookmark: 'Bookmark',
      bookaroom: 'Book a Room',
      subscribe: 'Subscribe',
      whenyousubscribeetc: 'When you subscribe a meeting room, you will receive notification when booking on this room is cancelled on',
      pleasedragetc: 'Please drag above timeline to select meeting period.',
      pleasedragetc1: 'Please drag above timeline to select carpark booking period.',
      book: 'Book',
      creator: 'Creator',

      //Carpark
      timeslorerr3: 'Booking timeslot should not be after 2 weeks',
      timeslorerr4: 'Booking timeslot should not be after 3 months',
      bookacarpark: 'Book a Carpark',
      approver: 'Approver',
      searchapprover: 'Search Approver',
      tobeconfirmed: 'To be confirmed',
      posttitle: 'Post Title',
      submittedsuccess: 'Submitted successfully',
      //Edit Booking

      //Edit Carpark

      //FloorMap

      //index

      //Login
      login: 'Log in',
      plsinputpassword: 'Please input your Password!',
      plsinputusername: 'Please input your Employee No.!',
      username: 'Username',
      password: 'Password',
      srsemsdhq: 'Smart Reservation System in EMSD Headquarters',
      supportcontact: 'Support contact',
      adminsupport: 'Administrative Support:',
      adminsupport1: 'Mr CHAN Chi Kwong, Simon (CO/EMU2)  28083672',
      techsupport: 'Technical Support:',
      techsupport1: 'Miss SHU Sin Ting Jennifer (TBIMT) 3155 4585',
      techsupport2: 'Mr. Lo Kin Hang Lawrence (TCAPGWIN3) 96163420',
      employeeno: 'Employee No.',




      //Maintenance
      startdate: 'Start Date',
      enddate: 'End Date',
      searchmaintenance: 'Search Maintenance',
      active: 'Active',
      cancelled: 'Cancelled',

      //Malfunction
      reportby: 'Report By',
      reportpersonnameetc: 'Report Person name should be at least 3 characters long',
      handled: 'Handled',
      reported: 'Reported',
      searchmalfunction: 'Search Malfunction',

      //MyBooking
      pendingapproval: 'Pending Approval',
      transfer: 'Transfer',
      pendingaccept: 'Pending Accept',
      transfersuccess: 'Transfered successfully.',
      current: 'Current',
      history: 'History',
      searchuser: 'Search User',
      carpark: 'Carpark',
      search: 'Search',
      pfaba: 'Pending for Approval by Approver',
      pfabemu: 'Pending for Approval by EMU',

      //PendingBooking
      submittedto: 'Submitted To',
      submitted: 'Submitted',

      //PendingForAction


      //Report
      plsfillalletc: 'Please fill all mendetory fields',
      download: 'Download',
      daily: 'Daily',
      fromdate: 'From Date',
      todate: 'To Date',
      searchreport: 'Search Report',

      //SensorData
      reportedsuccess: 'Reported successfully',
      all: 'All',
      floorplan: 'Floor Plan',
      roomreserved: 'Room Reserved',
      reportmalfunction: 'Report malfunction',
      undermaintenance: 'Under Maintenance',
      carparkreserved: 'Carpark reserved',
      inuse: 'In Use',
      malfunctionmessage: 'Malfunction Message',
      submit: 'Submit',

      //Setting
      book1: 'BOOK',
      remove1: 'REMOVE',
      contactinfo: 'Contact Information',
      edit1: 'EDIT',
      contactphone: 'Contact Phone',
      save1: 'SAVE',
      userprofile: 'User Profile',
      bookmark1: 'Bookmark',
      email: 'Email',
      language: 'Language',
      default: 'Default',
      add1: 'ADD',
      postname: 'Post(Name)',
      postname1: 'Post Title',
      search1: 'SEARCH',
      yes: 'Yes',
      no: 'No',
      createdsuccess: 'Created successfully',
      alert: 'Alert',
      pleasesearchapprover: 'Please serach approver',

      //Setup
      resourceremovedsuccess: 'Resource removed successfully',
      seat: 'Seat',
      view1: 'VIEW',
      searchresource: 'Search Resource',

      //System
      remove: 'Remove',
      removesuccess: 'Removed successfully',

      //Timeslot
      today: 'Today',
      contact1: 'Contact Person1 Last Name',
      contact2: 'Contact Person2 Last Name',
      contact3: 'Contact Phone1',
      contact4: 'Contact Phone2',

      //User
      info: 'Info',

      //Userinfo
      testpushmsg: 'TEST PUSH MESSAGE',
      sendsucces: 'Sent successfully',


      errorcodes: {
         U001: 'Access token is not valid or expired',
         U002: 'name is not valid',
         U003: 'User id is not valid',
         U004: 'role is not valid',
         U005: 'Email is not valid',
         U006: 'Lang is not valid',
         U007: 'Login is not valid',
         U008: 'Phone is not valid',
         U009: 'Post is not valid',
         U010: 'Receive notification is not valid',
         U011: 'Username or Password is incorrect, Please try again or contect administrator',
         U012: 'Fcm token is not valid',
         S001: 'Request parameter is not valid',
         S002: 'Request header params are not valid',
         E001: 'System Error',
         B001: 'User Id and original User Id is not valid',
         B002: 'Resource Id is not valid',
         B003: 'Approver id is not valid',
         B004: 'Resource is already booked for this time period',
         B005: 'Start time or End time is not valid',
         B006: 'Contact Person Last Name name is not valid',
         B007: 'Contact Phone number is not valid',
         B008: 'Booking id is not valid',
         B009: 'Type is not valid',
         B010: 'Booking transfer is not found, Can not accept booking',
         B011: 'Resource is booked for maintenance for this time period',
         B012: 'Can not extende time because resource is booked for this time period',
         B013: 'Can not extende time because resource is booked for maintenance for this time period',
         B014: 'end time should not before start time',
         B015: 'start time should not before current time',
         B016: 'Subject is not valid',
         B017: 'Reason is not valid',
         B018: 'Resource type is not valid',
         B019: 'car parking slot is not available for this time period',
         B020: 'username is not valid',
         B021: 'fromDate should not be bigger than toDate',
         B022: 'FromDate and ToDate format is not valid',
         B023: 'Extende time should be after endtime',
         B024: 'Can not transfer booking to same user',
         B025: 'State is not valid',
         B026: 'Page Number or Page size is not valid',
         B027: 'Booking is already extended once',
         B028: 'Extend slot should be 1 hour only',
         B029: 'Booking is already checked in',
         B030: 'Booking is already checked out',
         B034: 'Visitor name is not valid',
         B035: 'Visitor company/department is not valid',

         B036: 'Mechanized is not valid',
         B037: 'Division is not valid',
         B038: 'staff type is not valid',

         N001: 'Notification Id is not valid',
         N002: 'Date parameter is not valid',
         N003: 'FromDate and ToDate format is not valid',
         N004: 'fromDate should not be bigger than toDate',
         C001: 'User car number is not valid',
         C002: 'User id is not valid',
         C003: 'User car isDefault is not valid',
         C004: 'User car id is not valid',
         M001: 'malfunction Resource Id is not valid',
         M002: 'malfunction description string is not valid',
         M003: 'malfunction state is not valid',
         M004: 'Date format is not valid',
         BM001: 'Bookmark id is not valid',
         BM002: 'User id is not valid',
         BM003: 'Resource id is not valid',
         RS001: 'Resource id is not valid',
         RS002: 'Subscriber user id is not valid',
         RS003: 'Subscriber date is not valid',
         RS004: 'Subscriber date should not be before todays date',
         UA001: 'User Approver id is not valid',
         UA002: 'User id is not valid',
         UA003: 'user Approver isDefault is not valid',
         UA004: 'Approver id is not valid',
         RE001: 'fromTime and toTime is not valid',
         RE002: 'isAnyTime is not valid',
         RE003: 'Resource Type is not valid',
         RE004: 'either resourceId and building floor id should provided',

         RE005: 'Quota is not valid',
         RE006: 'Effective Date is not valid',
         RE007: 'Visitor parking slot id is not valid',
         RE008: 'Parking Slots for effective date is already created',
         RE009: 'Not enough free resource available to reduce',
         RE010: 'Input is not valid',
         RE011: 'New lock time should be larger than old lock time',
         RM001: 'Resource id is not valid',
         RM002: 'maintenance id is not valid',
         RM003: 'state is not valid',
         RM004: 'From Date and To Date format is not valid',
         RM005: 'start time or end time is not valid',
         RM006: 'Description is not valid',
         RM007: 'end time should not before start time',
         RM008: 'start time should not before current time',
         RM010: 'Resource is booked already for maintenance for this time period',
         HO001: 'Holiday date is not valid',
         HO002: 'Holiday name is not valid',
         HO003: 'Holiday id is not valid',
         HO004: 'From Date and To Date format is not valid',
         RP001: 'Report type is not valid',
         RP002: 'From Date and To Date format is not valid',
         DTD001: 'Invalid DTD file. Please upload again',
         ANY001: 'Invalid Carpark Analyst User file. Please upload again'
      }
   },
   zh: {
      MR:'先生',
      MS:'女士',
      MISS:'小姐',
      next:'後七日',
      previous:'前七日',
      admininactive:'維修 (管理員)',
      sensorinactive:'維修 (感應器)',
      lowbattery:'低電量',
      activewashroomcode:'廁所生效碼',
      washroomactivecodemsg:'廁所生效碼的二維碼',
      availablecubical:'可用廁格',
      toilet:'廁所',
      male:'男',
      female:'女',
      mlbl:'M',
      flbl:'F',
      maintenancelbl:'維修中',
      wtype:'廁所類型',
      cubicle:'廁格',
      wcapacity:'廁格數目',

      stafftype:"機電署員工類別",
      stafftype1:"總部申領DMA同事",
      stafftype2:"外派機電署同事",
      visitorstaffnotice:"車位只供訪客或非機電署人員使用",
      operationalstaffnotice:"車位只供機電署同事使用",
      visitorcapacity: '(8個)',
      operationalcapacity: '(12個)',
      vacancycount:'可用車位數量',
      graphtype1:'按日期時間',
      graphtype2:'按時間',
      graphtype:'圖表類型',
      meetingroomcontactinfo1:'這會議室位於機電署總部以外，並由<SBU>管理 。如有任何查詢，請聯繫 <contact>.',
      meetingroomcontactinfo:'這會議室由<SBU>管理 。如有任何查詢，請聯繫 <contact>。',
      vaccancy:'可用車位',
      minimumvalue:"最小數值",
      carparkvacancy:'可用車位',
      lock:'鎖定日期',
      datalock:'鎖定日期',
      locktimeerror: '鎖定日期應少於今天',
      inactive:'已取消',
      confirmchangetab:'所有未保存的更改都將失去，確定要更改放棄嗎?',
      analyst:'數據分析',
      data:'數據',
      selectioncriteria:'請選擇條件',
      class:'種類',
      fromtoerr:'結束日期應該在開始日期之後',
      daterangemonthserr1:'日期範圍不應超過 1 個月',

      analyst:'數據分析',
      data:'數據',
      selectioncriteria:'篩選條件',
      class:'種類',
      daterangemonthserr:'日期範圍不應超過 6 個月',
      fromtoerr:'結束日期應該在起始日期之後',

      confirmwithswitch:'確定並發送電郵',
      confirmnotswitch:'確定並不發送電郵',
      adminfunctionformechanized: '這功能是給管理人員將停車位分配給不同類型的停車場預訂。',
      maintenance1:'維護',
      resume:'恢復',

      shortenextend:'縮短/延長',
      shortenextendmeeting:'縮短/延長預約時間',
      isreminder:'預訂提醒',
      timeslorerr5: '預約日期不可以在星期六或星期日',
      selectendtime:'請選擇結束時間',
      rejected:'已拒絕',

      light:'燈',
      addSensor:'添加傳感器',
      
      airquality:'空氣質素',
      temperaturehumiditylight:'溫度和濕度和燈',
      temphumilightoccu:' 溫度和濕度和在場感測和燈',
      
      carparkremindermsg:'新增停車前2天、14天、30天提醒用戶預約車位的功能',
      roomremindermsg:'新增在會議前2天、14天、30天提醒用戶預訂房間的功能',      
      zoomremindermsg:'新增在線會議前2天、14天、30天提醒用戶預約在線會議的功能',
      
      upper:'上標',
      lower:'下標',
      small:'小',
      large:'大',
      vertical:'垂直',
      horizontal:'橫向',
      plsselectdivision:'請選擇部門',
      plsenteruniqueslotno:'請輸入正確的車位號碼',

      carparksharing:'共享車位',
      tvboard:'TV Board',

      plsentervalidslotno:'請輸入正確車位資料',
      sumofdivisionshouldbeone:'部門比例加起來須要等於1',      
      publishsuccess:'保存並發佈成功',     
      maintenancesuccess:'己成功設定維修',
      stopmaintenance:'結束維修',
      startmaintenance:'開始維修',

      sharing:'共享',
      textposition:'車牌位置',
      direction:'方向',
      size:'大小',
      slotno:'車位編號',

      floorsummary:'樓層概要',
      buildingsummary:'大廈概要',
      hqsummary:'總部概要',
      export:'匯出',
      print:'打印',
      publish:'保存並發佈',
      zone:'區',
      division:'部門',
      lev:'種類',
      shared:'共享',
      coowner:'共同擁有',
      apply:'查詢',
      reset:'重置',
      save:'保存',
      summary:'總結',
      sdo:'Senior Direcorate Officer',
      do:'Directorate Offficer',
      dma:'Duty Mileage Allowance Claimant',
      cc:'Contract Car',
      share:'分享',
      totalallocation:'總分配',
      shareduseam:'共享使用AM',
      physicalparkingspace:'實體停車位',
      notallocated:'未分配',
      allocated:'已分配',
      color:'顏色',
      remark:'備註',
      num:'No.',
      notshared:'不共享',


      adminname1:'行政支援1',
      adminname2:'行政支援2',
      techname1:'技術支援1',
      techname2:'技術支援2',


      maxregistrant:'登記人數上限',
      androidinstruction1:'在安卓系統上遇到安全提示，請',
      androidinstruction11:'在華為系統上遇到安全提示，請',
      androidinstruction2:'允許',
      androidinstruction3:'及',
      androidinstruction4:'繼續未知的來源',
      androidinstruction5:'以安裝應用程式',
      specific:'特定日期',
      this:'這裡',
      effective: '生效中',
      nonofficehourmsg:'*** 由於預訂時段超過辦公時間（即平日08:30之前或18:00之後，及周末），預約被批准後你將會收到一封電郵，請聯繫物業設施管理組安排。',
      ecvehicalmsg:'*** 如電能汽車需要使用充電設施，預約被批准後你將會收到一封電郵，請聯繫物業設施管理組安排。',
  
      carparkreason:"項目 / 計劃 / 工作",
      carplatenumber:"車牌號碼",
      visitorcarparkquota:'訪客車位數量',
      operationalcarparkquota:'公務車位數量',
      amendhistory:'將會更新日期',
      default:'默認',
      specificdate:'特定日期',
      quotaremark:'備註：以上數字代表特定時段訪客/公務停車位的使用情況。',
      quota:'車位數量',            
      amendquota:'車位數量更新', 
      submitteddate:'提交日期',


      searchvisitor: '搜尋訪客',
      noofvisitorparking: '停車位數量',
      editVisitorParking: '編輯訪客停車位',
      addVisitorParking: '添加訪客停車位',
      effectivedate: '生效日期',
      noactiveslots: '沒有生效中的訪客停車位',
      activevisitorparking: '生效中的訪客停車位',
      parkingspace: '停車位',
      nonmechanized: '非機械式停車位',
      mechanized: '機械式停車位',
      division: '部門',
      divisions: ['BTSD', 'CSD', 'DAD', 'DTD', 'EEO', 'ELD', 'ET', 'FD', 'GESD', 'GLD', 'GSDA', 'GSDB', 'HSD', 'MunSD', 'RB', 'SMU', 'SRU', 'SVSD', 'TS'],
      carnumbernotvalid: '車牌無效',
      pendingvisitorapproval: '訪客車位待審批',
      termsandcondition:  '我，代表車位使用者，已閱讀、明白並接受',
      termsandconditionlink: '使用條款和免責聲明',
      termsandcondition1: '。', 
      click: '對於公務停車位，點擊',
      here: '此處',
      todownloaduserguide: '查看智能泊車系統使用說明。',

      webbrowsertxt: '瀏覽器：Google Chrome/ IE 11',
      resolutiontxt: '視窗解像度：1024 x 768 或以上',
      minsystemreq: '最低系統要求',

      clickthislink:'這個鏈結',
      scanqrapp: '掃描二維碼或點擊',
      formobileversion:'手機版',
      followinstructionapp: '安裝完成後，請記住單擊此處並按照說明進行操作',

      reserve: '預約',
      isdtdroom: '是否 DTD 會議室',
      dtd: 'DTD',
      upload: '上傳',
      clicktoupload: '點擊上傳',
      selectfile: '選擇檔案',
      plsincludeslashinsearch: '注意 : 搜索代理職位時請包含 \'/\', 例如 CE/SV',

      downloadcurrentSettings: '目前設定',
      downloaddefaultSettings: '默認設定',
      restoredefaultsettings: '還原所有設定',

      encryptiontype: '加密',
      encryptiontyperemark: '端對端加密是一個更安全的加密方式，建議用於討論敏感內容的會議。但使用時將會有多項功能停用，包括電話撥入、使用瀏覽器加入會議等，敬請留意。',
      enhancedencryption: '增強型加密',
      e2ee: '端對端加密',

      zoomguide: 'Zoom 指南',
      onprem: '(本地)',
      publiccloud: '(雲端)',
      zoominfo1: '1. 對於"網上會議室"，請先選用“會議室（內部部署）”帳戶。',
      zoominfo2: '2. 對於"網上硏討會"， 如大多與會者是機電工程署員工，請選用“硏討會（內部部署）”，否則可選用“硏討會（雲端）”。',
      zoominfo3: '3. 想知道會議室與硏討會的分別，請參考',

      iselectric: '是否電能汽車',
      visitorname: '訪客名稱',
      visitorcompany: '訪客公司/部門',
      purpose: '訪客目的',

      training: '培訓',
      briefing: '簡報',
      recruitmentboard: '招聘',
      promotionboard: '推廣',
      others: '其他',
      advancesearch: '進階搜查',
      basicsearch: '基本搜查',
      duration: '時長',
      availabletime: '可用時段',
      searchavailabletimeslot: '搜查可用時段',
      nameofvisitor: '請填寫訪客名稱',
      nameofcompanydepartment: '請填寫訪客公司/部門',

      halfhour: '30分鐘',
      onehour: '1 小時',
      onehalfhour: '1小時30分鐘',
      twohour: '2 小時',
      twohalfhour: '2小時30分鐘',
      threehour: '3 小時',
      threehalfhour: '3小時30分鐘',
      fourhour: '4 小時',
      fourhalfhour: '4小時30分鐘',
      fivehour: '5 小時',

      fstreminder: '第1次提醒',
      sndreminder: '第2次提醒',
      trdreminder: '第3次提醒',
      fthreminder: '第4次提醒',
      finalreminder: '最後提醒',
      sensorhistory: '傳感器歷史紀錄',
      actiontime: '操作時間',

      registrants: '登記者',
      approved: '已批准',
      denied: '已拒絕',
      firstname: '名',
      lastname: '姓',
      reminder: '提醒',
      reminderlog: '預約提醒日誌',
      noaction: '沒有回覆',
      keep: '保留',
      setupreminder: '設定預約提醒',
      continuesmin: '連續分鐘',
      maxnotification: '最多通知次數',
      lightincluded: '包含燈',
      savedsuccess: '成功儲存',
      sensor1: '傳感器',
      gotit: '知道了',
      loading: '加載中',
      deny: '拒絕',
      reminderfeaturestr: '此會議室已實施了會議室提醒功能作測試。',
      useraction: '操作',

      iscloud: '雲端',
      zoomcloudterms: '請確保網上會議期間沒有機密內容',

      subjectmaxlength: '主題不能超過 100 字串',
      remarkmaxlength: '備註不能超過 500 字串',
      reasonmaxlength: '原因不能超過 100 字串',
      contactpersonmaxlength: '聯絡人名稱不能超過 100 字串',
      contactphonemaxlength: '聯絡人電話不能超過 45 字串',
      carnumbermaxlength: '車牌號碼不能超過 50 字串',

      startmeetingnote1: '1.  請下載及安裝 Zoom (https://zoom.us/download/)',
      startmeetingnote2: '2.  在點擊開始按鈕前, 請確保在 Zoom 應用介面用主持人帳號登入',
      start: '開始',



      joinbyvoiceonly: '通過電話撥打（僅限語音）：',
      dialstring: '1. 撥打 #dial_number# 或參考 https://emsd-gov-hk.zoom.us/u/acnrHoqp8',
      afterdialedinstring: '2. 撥打之後, 按 ',
      startmeeting: '會議開始',

      show: '顯示',
      hide: '隱藏',
      invitetext: '邀請詳情',
      plsdownloadzoom: '請下載 Zoom (https://zoom.us) 並登錄此賬號去開始會議',
      copyinvitetext: '複製邀請詳情',

      copyjoinurl: '複製參加連結',
      topic: '主題:',
      joinzoommeeting: '加入 Zoom 會議:',
      onetapmobile: '行動電話一鍵撥號',
      dialbyyourlocation: '依所在位置撥打',
      findyourlocalnumber: '尋找您的當地電話號碼:',


      livedata: '現場數據',
      available: '可用',
      occupied: '已使用',
      zoomstatus: '狀態',
      meetingorwebinarid: '會議ID',
      meetingorwebinarpassword: '會議密碼',
      na: '不適用',
      meeting: '會議',
      webinar: '研討會',

      message: '訊息',
      error: '錯誤 : ',
      zoombookmark: '網上會議室書籤',
      hostmailorids: '主持人電郵/ID (逗號分隔)',
      advanceconfig: '更多選項',
      bookzoom: '預約網上會議室',
      zoomacc: '網上會議室賬戶',
      account: '賬戶',
      zoommeeting: '網上會議室(會議)',
      zoomwebinar: '網上會議室(研討會)',
      audiotype: '音訊類型',
      bothtelephobyandvoip: '話音電話和網絡電話',
      teleponyonly: '只有話音電話',
      voiponly: '只有網絡電話 ',
      approvaltype: '審批類別',
      automaticallyapprove: '自動審批',
      manuallyapprove: '人工審批',
      noregistrationrequired: '不用登記',

      allowmultipledevices: '允許多設備',
      registrantsrestrictnumber: '限制登記人數',
      contactname: '聯絡人姓氏',
      contactemail: '聯絡人電郵',

      hostemailaddress: '主持人電郵',
      hostpassword: '主持人密碼',
      passwordonlyvalidformeetingdate: '主持人密碼只會生效於',
      joinurl: '加入連結',
      cipiedsuccess: '複製成功',
      only: '',
      webinarid: '研討會 ID',
      copymeetingjoinurl: '複製加入連結',
      copyhostaccountinfo: '複製主持人資料',

      alternativehosts: '備用主持人',
      waitingroom: '等候室',

      participentvideo: '參加者視頻',
      bookazoommeeting: '預約一個網上會議室(會議)',
      bookazoomwebinar: '預約一個網上會議室(研討會)',
      meetingsetting: '會議設定',
      webinarsetting: '研討會設定',
      zoomemail: 'Zoom 電郵',
      zoompassword: 'Zoom 密碼',
      zoom: '網上會議室',
      zoom1: '網上會議室',
      meetingid: '會議 ID',
      meetingpassword: '會議密碼',
      getaccountpassword: '取得賬戶密碼',
      copy: '複製',
      copymeetingurl: '複製會議連結',


      audiotooltip: '參加者加入會議時音頻部分選項',
      waitingroomtooltip: '啟用等候室',
      alternativehoststooltip: "備用主持人的電郵或ID：如果多於一個值，以逗號分隔",
      allowmultipledevicestooltip: "允許參加者通過多種設備加入",
      registrantsrestrictnumbertooltip: "限製研討會登記人數（最多500人）",
      contactnametooltip: "登記聯絡人名稱",
      contactemailtooltip: "登記聯絡人電郵",

      started: '已開始',
      finished: '已結束',
      waiting: '未開始',

      fixedroom: '房間',
      //Main
      title: '總部設施預約系統',
      read: '已讀',
      unread: '未讀',
      read1: '已讀',
      notification: '消息',
      date: '日期',
      cancel: '取消',
      nodata: '暫無數據',
      room: 'Room',
      userview: '用戶模式',
      adminview: '管理模式',
      hello: '你好',
      signout: '登出',
      dashboard: '總匯',
      schedule: '日程',
      malfunction: '設施故障',
      maintenance: '設施維護',
      user: '用戶',
      report: '報告',
      setup: '設定',
      system: '系統',
      bookroom: '預約會議室',
      bookcarpark: '預約停車位',
      mybooking: '我的預約',
      pendingforaction: '待操作',
      sensordata: '設施數據',
      setting: '設定',
      ok: 'Ok',
      readall: '已全讀',
      userpasswordwrong: '用戶名稱或密碼不正確。請檢查您的 CapsLock 和輸入設定。',
      userguide: '操作手冊',
      minsysreq: '最低系統要求',
      webbrowsersupp: '瀏覽器：Google Chrome/ Internet Explorer 11',
      resolorabove: '視窗解像度：1024 x 768 或以上',
      cap: '人數 ',


      //Add Maintenance
      addmaintenance: '添加維護',
      editmaintenance: '修改維護',
      maintenancedetail: '維護詳情',
      type: '類別',
      meetingroom: '會議室',
      visitorcarpark: '訪客車位',
      operationalcarpark: '公務車位',
      floor: '樓層',
      name: '名稱',
      starttime: '開始時間',
      endtime: '結束時間',
      description: '詳情',
      create: '新增',
      update: '修改',
      clear: '重置',
      back: '返回',
      updatesuccess: '已成功修改.',
      addedsuccess: '已成功添加.',
      errorcode: '錯誤瑪 : ',
      errormessage: ' ',
      confirmcancel: '確認取消',
      affectedbookingetc: '受影響的預約.',
      doyouwanttocancel: '確認取消?',
      selectdateandtime: '請選擇日期時間',
      owner: '發起人',


      //Add Malfunction
      addmalfunction: '添加故障',
      editmalfunction: '修改故障',
      malfunctiondetails: '故障詳情',
      reportdate: '報告日期',
      remark: '備註',

      //Add Resource
      tempnhumidity: '溫度和濕度',
      occupancy: '在場感測',
      carparksensor: '停車位感測',
      addresource: '添加設施',
      editresource: '修改設施',
      resourcedetail: '設施詳情',
      fixedcarpark: '固定停車位',
      washroom: '洗手間',
      chinesename: '中文名稱',
      englishname: '英文名稱',
      capacity: '人數',
      equipment: '設備',
      latitude: '緯度',
      longitude: '經度',
      sensor: '傳感器',
      pleaseselect: '--請選擇--',
      pleasedragpinetc: '請拖放黃色針直至到達設施位置。',

      //Add System
      holiday: '假期',
      add: '添加',
      edit: '修改',
      details: '詳情',

      //Admin Booking
      view: '查看',
      editbooking: '修改預約',
      chairman: '主席',



      //Admin carpark
      reason: '原因',
      state: '狀態',
      location: '地點',
      action: '待操作',
      confirm: '確認',
      cancel1: '取消',
      areyousure: '是否確認?',
      totalcount: '總數:',
      editcarpark: '修改停車位',
      visitor: '訪客',
      operational: '公務',
      carnumber: '車牌號碼',
      pleasefill: '請填寫',
      approver: '審批員',
      contactperson1: '聯絡人1姓氏',
      contactphone1: '聯絡人1電話',
      contactperson2: '聯絡人2姓氏',
      contactphone2: '聯絡人2電話',
      bookingcanclled: '已成功取消預約.',
      time: '時間',

      //Admin Dashboard
      gents: '男洗手間',
      ladies: '女洗手間',
      publicdashboard: '公共版總匯',
      occupancystatus: '使用情況',
      fixed: '固定',
      operational: '公務',
      visitor: '訪客',
      total: '總數',
      level: '樓層',


      //Admin PendingAction
      requestedby: '申請人',
      subject: '主題',
      approve: '批准',
      reject: '拒絕',
      accept: '接受',
      decline: '拒絕',
      approvedsuccess: '已成功批准',
      rejectedsuccess: '已成功拒絕',
      acceptedsuccess: '已成功接受',
      declinedsuccess: '已成功拒絕',
      pendingtransferacceptance: '會議室轉讓待接受',
      pendingtransfercarparkapproval: '公務車位待審批',

      //Admin Schedule
      status: '狀態',
      subrsn: '主題/原因',
      contact1st: '聯絡1',
      contact2nd: '聯絡2',
      user: '用戶',
      any: '任何',
      dateerr: '日期(從)和日期(至)需要一起提交',
      namelengtherrr: '聯絡人姓氏長度至少為 3 個字元',

      
      enter3char: '必填寫最少2個字',
      searchbooking: '預約查詢',
      from: '由',
      to: '至',
      booked: '已預約',
      completed: '已完成',
      contactperson: '聯絡人姓氏',
      users: '用戶',
      username: '用戶名稱',
      select: '選擇',
      delete: '刪除',
      pending: '待處理',

      //Booking
      bookmarkaddedsuccess: '已成功添加書籤',
      subscribesuccess: '已成功訂閱',
      timeslorerr1: '預約日期應該在當前時間之後',
      timeslorerr2: '預約日期不應該在2年後',
      bookedsuccess: '已成功預約',
      room: '會議室',
      bookmark: '書籤',
      bookmark1: '書籤',
      bookaroom: '預約會議室',
      subscribe: '訂閱',
      whenyousubscribeetc: '當你訂閱會議室，你將在此設備在該日期的任何預約被取消后收到通知',
      pleasedragetc: '請在時間軸上方拖動以選擇預約會議時間。',
      pleasedragetc1: '請在時間軸上方拖動以選擇預約泊車時間。',
      book: '預約',
      creator: '創建',

      //Carpark
      timeslorerr3: '預約日期不應該在2週後',
      timeslorerr4: '預約日期不應該在3個月後',
      bookacarpark: '預約停車位',
      approver: '審批員',
      searchapprover: '審批員查詢',
      tobeconfirmed: '稍後填寫',
      posttitle: '職位名稱',
      submittedsuccess: '已成功提交',

      //Edit Booking

      //Edit Carpark

      //FloorMap

      //index

      //Login
      login: '登錄',
      plsinputpassword: '請填寫密碼!',
      plsinputusername: '請填寫員工編號!',
      username: '用戶',
      password: '密碼',
      srsemsdhq: '總部設施預約系統',
      supportcontact: '電話查詢',
      adminsupport: '營運支援:',
      adminsupport1: 'Mr CHAN Chi Kwong, Simon (CO/EMU2)  28083672',
      techsupport: '技術支援:',
      techsupport1: 'Miss SHU Sin Ting Jennifer (TBIMT) 3155 4585',
      techsupport2: 'Mr. Lo Kin Hang Lawrence (TCAPGWIN3) 6315 3053',
      employeeno: '員工編號',

      //Maintenance
      startdate: '日期(從)',
      enddate: '日期(至)',
      searchmaintenance: '維護查詢',
      active: '生效中',
      cancelled: '已取消',

      //Malfunction
      reportby: '提交人名稱',
      reportpersonnameetc: '提交人名稱必填寫最少3個字',
      handled: '已處理',
      reported: '已報告',
      searchmalfunction: '故障查詢',

      //MyBooking
      pendingapproval: '待批准',
      transfer: '轉讓',
      pendingaccept: '待接受',
      transfersuccess: '已成功轉讓.',
      current: '生效中',
      history: '歷史紀錄',
      searchuser: '用戶查詢',
      carpark: '停車位',
      search: '查詢',
      pfaba: '待批准 (審批員)',
      pfabemu: '待批准 (EMU)',

      //PendingBooking
      submittedto: '提交到',
      submitted: '已提交',

      //PendingForAction


      //Report
      plsfillalletc: '請填寫所有必須填的欄位',
      download: '下載',
      daily: '每日',
      fromdate: '日期(從)',
      todate: '日期(至)',
      searchreport: '報告查詢',

      //SensorData
      reportedsuccess: '提交成功',
      all: '全部',
      floorplan: '平面圖',
      roomreserved: '會議室已預約',
      reportmalfunction: '提交故障',
      undermaintenance: '維護中',
      carparkreserved: '停車位已預約',
      inuse: '已預約',
      malfunctionmessage: '故障信息',
      submit: '提交',

      //Setting
      book1: '預約',
      remove1: '刪除',
      contactinfo: '聯絡資料',
      edit1: '修改',
      contactphone: '聯絡人電話',
      save1: '儲存',
      userprofile: '用戶檔案',
      email: '電郵',
      language: '語言',
      default: '默認',
      add1: '添加',
      postname: '職位(名稱)',
      postname1: '職位',
      search1: '查詢',
      yes: '是',
      no: '否',
      createdsuccess: '添加成功',
      alert: '提示',
      pleasesearchapprover: '請輸入審批員',

      //Setup
      resourceremovedsuccess: '已成功刪除設施',
      seat: '座位',
      view1: '查看',
      searchresource: '設施查詢',

      //System
      remove: '刪除',
      removesuccess: '已成功刪除',

      //Timeslot
      today: '今日',
      contact1: '聯絡人1姓氏',
      contact2: '聯絡人2姓氏',
      contact3: '聯絡人1',
      contact4: '聯絡人2',

      //User
      info: '資料',
      
      //Userinfo
      testpushmsg: '測試推送消息',
      sendsucces: '成功發送',

      errorcodes: {
         U001: '令牌不正確或已過期',
         U002: '名稱不正確',
         U003: '用戶不正確',
         U004: '角色不正確',
         U005: '電郵地址不正確',
         U006: '語言不正確',
         U007: '登錄不正確',
         U008: '電話不正確',
         U009: '職位不正確',
         U010: '消息不正確',
         U011: '用戶名稱或密碼不正確, 請聯絡管理員',
         U012: '推送令牌不正確',
         S001: '提交參數不正確',
         S002: '提交參數頭欄位不正確',
         E001: '系統數據錯誤',
         B001: '用戶識別碼或原用戶識別碼不正確',
         B002: '設施識別碼不正確',
         B003: '審批員識別碼不正確',
         B004: '提交的日期已經有其他預約',
         B005: '開始時間或結束時間不正確',
         B006: '聯絡人姓氏無效',
         B007: '聯絡電話不正確',
         B008: '預約識別碼不正確',
         B009: '類別不正確',
         B010: '未找到相關預約',
         B011: '設施在此時間段內進行維護',
         B012: '無法延長時間，因為此時間段內已有其他預約',
         B013: '無法延長時間，因為設施已在此時間段內進行維護',
         B014: '結束時間不應該在開始時間之前',
         B015: '開始時間不應該在當前時間之前',
         B016: '主題不正確',
         B017: '原因不正確',
         B018: '設施類別不正確',
         B019: '提交的日期已經沒有停車位',
         B020: '用戶名稱不正確',
         B021: '開始日期不應在結束日期之後',
         B022: '開始日期或結束日期格式不正確',
         B023: '延續日期應該在結束日期之後',
         B024: '不可以轉讓預約給自己',
         B025: '狀態不正確',

         B034: '訪客名稱不能為空',
         B035: '訪客公司/部門不能為空',
         B036: '機械式停車設備無效',
         B037: '部門無效',
         
         B038: '員工類別不正確',
         N001: '消息識別碼不正確',
         N002: 'Date parameter is not valid',
         N003: '開始日期或結束日期格式不正確',
         N004: '結束日期不能大於開始日期',
         C001: '用戶車牌號不正確',
         C002: '用戶識別碼不正確',
         C003: '用戶車牌號默認不正確',
         C004: '用戶車牌號識別碼不正確',
         M001: '故障識別碼不正確',
         M002: '故障詳情不正確',
         M003: '故障狀態不正確',
         M004: '日期格式不正確',
         BM001: '書籤識別碼不正確',
         BM002: '用戶識別碼不正確',
         BM003: '設施識別碼不正確',
         RS001: '設施識別碼不正確',
         RS002: '訂閱用戶識別碼不正確',
         RS003: '訂閱日期不正確',
         RS004: '訂閱日期不應該早於當前時間',
         UA001: '用戶審批員識別碼不正確',
         UA002: '用戶識別碼不正確',
         UA003: '用戶審批員默認不正確',
         UA004: '審批員識別碼不正確',
         RE001: '開始時間或結束時間不正確',
         RE002: '任何時間不正確',
         RE003: '設施類別不正確',
         RE004: '設施識別碼或樓層識別碼須要提供',
         RE005: '配額無效',
         RE006: '生效日期無效',
         RE007: '訪客停車位 ID 無效',
         RE008: '訪客停車位在生效日期時已經存在',
         RE009: '沒有足夠訪客停車位可以減少',
         RE011: '新鎖定日期應大於舊鎖定日期',
         RM001: '設施識別碼不正確',
         RM002: '設施維護識別碼不正確',
         RM003: '狀態不正確',
         RM004: '日期(從)或日期(至)不正確',
         RM005: '開始時間或結束時間不正確',
         RM006: '詳情不正確',
         RM007: '結束時間不應早於開始時間',
         RM008: '開始時間不應早於當前時間',
         RM010: '預約時間有設施維護',
         HO001: '假期日期不正確',
         HO002: '假期名稱不正確',
         HO003: '假期不正確',
         HO004: '開始日期或結束日期格式不正確',
         RP001: '報告類別不正確',
         RP002: '開始日期或結束日期不正確',

         DTD001: '無效的DTD 檔案, 請重新上傳',
         ANY001: '停車場分析用戶檔案無效。請重新上傳'
      }
   }
});
export { ApiHeader, strings, isdevelopment, apiurl, admin_roles, ADMIN_ROLES, DTD_ROLES, NORMAL_ROLES, ONLINE_ROLES, RESOURCE_TYPES };