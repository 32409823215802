import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Row, Col, Table, Select, Tag, Button, Radio, Modal, Popconfirm, Input, DatePicker } from "antd";
import './App.css';
import moment from 'moment';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES, TYPES, SIZES, ZONES, DIVISIONS } from './data';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import AnalystData from './AnalystData'
import AnalystReport from './AnalystReport'
import AnalystSetting from './AnalystSetting'
import DataLock from './DataLock'
import AnalystUser from './AnalystUser'

const Option = Select.Option;
const locale = 'EN';

class Analyst extends Component {
    constructor(props) {
        super(props);
        this.state = {
            radiooption: 'a',
        }
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber', 23);
    }

    componentWillUnmount() {

    }

    onRadioChange = (e) => {        
        this.setState({ radiooption: e.target.value });        
    }

    render() {
        return (
            <div className='Tab2Div'>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        <Radio.Group value={this.state.radiooption} buttonStyle="solid" onChange={this.onRadioChange}>
                            <Radio.Button value="a">{strings.report}</Radio.Button>
                            <Radio.Button value="b">{strings.data}</Radio.Button>
                            <Radio.Button value="c">{strings.setting}</Radio.Button>
                            <Radio.Button value="d">{strings.lock}</Radio.Button>
                            {sessionStorage.getItem('analystAdmin') === 'Y' && <Radio.Button value="e">{strings.user}</Radio.Button>}
                        </Radio.Group>
                    </div>
                    {this.state.radiooption === 'a' && <AnalystReport/>}
                    {this.state.radiooption === 'b' && <AnalystData/>}
                    {this.state.radiooption === 'c' && <AnalystSetting/>}
                    {this.state.radiooption === 'd' && <DataLock/>}
                    {this.state.radiooption === 'e' && <AnalystUser/>}
                </div>
            </div>
        );
    }
}
export default Analyst;

