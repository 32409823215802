import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Row, Col,Table,Select,Tag,Button,Radio,Modal,Popconfirm} from "antd";
import './App.css';
import moment from 'moment';
import EditBooking from './EditBooking';
import EditCarpark from './EditCarpark';
import {ApiHeader,strings,apiurl,RESOURCE_TYPES} from './data';
const Option = Select.Option;
const locale = 'EN';

class PendingBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            systemData:null,
            resource:null,
            roomdata:[],
            parkingdata:[],
            parkingdata1:[],
            count1:0,
            selectedBooking:null,
            isediting:false,
            iseditop:false,
            loading:false,
            radiooption:'a',
            isbooking:true,
            triggerReload:false,
            showalertmodel:false,
            alertmessage:"",
        }
        this.columns = [            
            {
                title: strings.time,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))
            },{
                title: strings.stafftype,
                dataIndex: 'staffType',
                key: 'staffType',
                width:'15%',
                sorter: (a, b) => a.staffType.localeCompare(b.staffType, 'en', { numeric: true }),
                render: (a, b) => (a !== null ? a === 1?strings.stafftype1:strings.stafftype2:'')
            },/* {
                title: strings.floor,
                dataIndex: 'floor',
                key: 'floor',
                width:'10%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width:'10%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
            }, */{
                title: strings.carplatenumber,
                dataIndex: 'carNumber',
                key: 'carNumber',
                width:'10%',
                sorter: (a, b) => a.carNumber.localeCompare(b.carNumber, 'en', { numeric: true }),
            },{
                title:strings.requestedby,
                dataIndex: 'requestedBy',
                key: 'requestedBy',
                width:'15%',
                
                sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy, 'en', { numeric: true }),
            },{
                title: strings.reason,
                dataIndex: 'reason',
                key: 'reason',
                width:'20%',
                sorter: (a, b) => a.reason.localeCompare(b.reason, 'en', { numeric: true }),
            },{
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {
                        return <Tag color={'#455773'} key={tag} style={{marginTop:5}} onClick={()=>this.ontagclick({tag},record)}>{tag.toUpperCase()}</Tag>;
                    })}
                    </span>
                ),
            }
        ];
        this.columns4 = [            
            {
                title: strings.time,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))
            },/* {
                title: strings.floor,
                dataIndex: 'floor',
                key: 'floor',
                width:'10%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width:'10%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
            }, */{
                title: strings.carplatenumber,
                dataIndex: 'carNumber',
                key: 'carNumber',
                width:'10%',
                sorter: (a, b) => a.carNumber.localeCompare(b.carNumber, 'en', { numeric: true }),
            },{
                title:strings.requestedby,
                dataIndex: 'requestedBy',
                key: 'requestedBy',
                width:'20%',
                
                sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy, 'en', { numeric: true }),
            },{
                title: strings.reason,
                dataIndex: 'reason',
                key: 'reason',
                width:'20%',
                sorter: (a, b) => a.reason.localeCompare(b.reason, 'en', { numeric: true }),
            },{
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {
                        return <Tag color={'#455773'} key={tag} style={{marginTop:5}} onClick={()=>this.ontagclick({tag},record)}>{tag.toUpperCase()}</Tag>;
                    })}
                    </span>
                ),
            }
        ];
        this.columns1 = [
            {
                title: strings.time,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))
            },{
                title: strings.floor,
                dataIndex: 'floor',
                key: 'floor',
                width:'10%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width:'10%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
            },{
                title: strings.requestedby,
                dataIndex: 'requestedBy',
                key: 'requestedBy',
                width:'20%',
                
                sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy, 'en', { numeric: true }),
            },{
                title: strings.subject,
                dataIndex: 'subject',
                key: 'subject',
                width:'20%',
                sorter: (a, b) => a.subject.localeCompare(b.subject, 'en', { numeric: true }),
            },{
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {
                        return <Tag color={'#455773'} key={tag} style={{marginTop:5}} onClick={()=>this.ontagclick1({tag},record)}>{tag.toUpperCase()}</Tag>;
                    })}
                    </span>
                ),
            }
        ];
    
        this.columns2 = [            
            {
                title: strings.time,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))
            },{
                title: strings.stafftype,
                dataIndex: 'staffType',
                key: 'staffType',
                width:'15%',
                sorter: (a, b) => a.staffType.localeCompare(b.staffType, 'en', { numeric: true }),
                render: (a, b) => (a !== null ? a === 1?strings.stafftype1:strings.stafftype2:'')
            },/* {
                title: strings.floor,
                dataIndex: 'floor',
                key: 'floor',
                width:'10%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width:'10%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
            }, */{
                title: strings.carplatenumber,
                dataIndex: 'carNumber',
                key: 'carNumber',
                width:'10%',
                sorter: (a, b) => a.carNumber.localeCompare(b.carNumber, 'en', { numeric: true }),
            },{
                title:strings.submittedto,
                dataIndex: 'requestedBy',
                key: 'requestedBy',
                width:'15%',
                
                sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy, 'en', { numeric: true }),
            },{
                title: strings.reason,
                dataIndex: 'reason',
                key: 'reason',
                width:'20%',
                sorter: (a, b) => a.reason.localeCompare(b.reason, 'en', { numeric: true }),
            },{
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {
                        return (tag != strings.cancel1?<Tag color={'#455773'} key={tag} style={{marginTop:5}} onClick={()=>this.ontagclick({tag},record)}>{tag.toUpperCase()}</Tag>:
                            <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={()=>this.ontagclick({tag},record)}>
                                <Tag color={'#455773'} key={tag} style={{marginTop:5}} >{tag.toUpperCase()}</Tag>
                            </Popconfirm>
                        );
                    })}
                    </span>
                ),
            }
        ];
        this.columns5 = [            
            {
                title: strings.time,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))
            },/* {
                title: strings.floor,
                dataIndex: 'floor',
                key: 'floor',
                width:'10%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width:'10%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
            }, */{
                title: strings.carplatenumber,
                dataIndex: 'carNumber',
                key: 'carNumber',
                width:'10%',
                sorter: (a, b) => a.carNumber.localeCompare(b.carNumber, 'en', { numeric: true }),
            },{
                title:strings.submittedto,
                dataIndex: 'requestedBy',
                key: 'requestedBy',
                width:'20%',
                
                sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy, 'en', { numeric: true }),
            },{
                title: strings.reason,
                dataIndex: 'reason',
                key: 'reason',
                width:'20%',
                sorter: (a, b) => a.reason.localeCompare(b.reason, 'en', { numeric: true }),
            },{
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {
                        return (tag != strings.cancel1?<Tag color={'#455773'} key={tag} style={{marginTop:5}} onClick={()=>this.ontagclick({tag},record)}>{tag.toUpperCase()}</Tag>:
                            <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={()=>this.ontagclick({tag},record)}>
                                <Tag color={'#455773'} key={tag} style={{marginTop:5}} >{tag.toUpperCase()}</Tag>
                            </Popconfirm>
                        );
                    })}
                    </span>
                ),
            }
        ];
        this.columns3 = [
            {
                title: strings.time,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))
            },{
                title: strings.floor,
                dataIndex: 'floor',
                key: 'floor',
                width:'10%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width:'10%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
            },{
                title: strings.submittedto,
                dataIndex: 'requestedBy',
                key: 'requestedBy',
                width:'20%',
                
                sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy, 'en', { numeric: true }),
            },{
                title: strings.subject,
                dataIndex: 'subject',
                key: 'subject',
                width:'20%',
                sorter: (a, b) => a.subject.localeCompare(b.subject, 'en', { numeric: true }),
            },{
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {
                        return (tag != strings.cancel1?<Tag color={'#455773'} key={tag} style={{marginTop:5}} onClick={()=>this.ontagclick1({tag},record)}>{tag.toUpperCase()}</Tag>:
                            <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={()=>this.ontagclick1({tag},record)}>
                                <Tag color={'#455773'} key={tag} style={{marginTop:5}} >{tag.toUpperCase()}</Tag>
                            </Popconfirm>
                        );                        
                    })}
                    </span>
                ),
            }
        ];
    
    }
    componentDidMount() {
        sessionStorage.setItem('tabNumber',5);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({resource:sd.resource,systemData:sd});        

        this.searchtranferBooking();
        this.searchPendingCarpark();
    }
  
    componentWillUnmount() {
  
    }

    searchPendingCarpark1=()=>{
        this.setState({loading:true, parkingdata:[], parkingdata1:[]})
        var rdata = {};        
        rdata.userId = ApiHeader.userId;
        rdata.state = ['pending_approval','pending_admin'];
        
        fetch(apiurl+'booking/list', {
            headers: ApiHeader,
            method: 'POST',  
            credentials: 'same-origin', 
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bss = data.data.bookings;
                    var bs = [];
                    for(var i=0; i<bss.length;i++){
                        bs=bs.concat(bss[i].booking)
                    }
                    var bs1 = [], bs2 = []; 
                    var resource = this.state.resource;
                    for(var i=0;i<bs.length;i++){
                                               
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        var ds = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
                        
                        b1.date = ds;
                        b1.tags = [strings.view,strings.cancel1];
                        b1.requestedBy = bs[i].approverName;
                        b1.firstcontact = bs[i].contactPerson1 +'('+bs[i].contactPhone1+')';
                        b1.secondcontact = bs[i].contactPerson2 +'('+bs[i].contactPhone2+')';
                        b1.salutation1 = bs[i].salutation1;  
                        b1.salutation2 = bs[i].salutation2;
                        b1.type = strings.operational;
                        b1.reason = bs[i].reason;
                        b1.remark = bs[i].remark;
                        b1.staffType = bs[i].staffType;
                        b1.state = bs[i].state;
                        b1.carNumber = bs[i].carNumber;
                        b1.approverName = bs[i].approverName;
                        b1.approverId = bs[i].approverId;
                        b1.groupName = bs[i].groupName;

                        b1.isReminder = bs[i].isReminder;
                        b1.isElectric=bs[i].isElectric===null?'':bs[i].isElectric;
                        b1.visitorCompany=bs[i].visitorCompany===null?'':bs[i].visitorCompany;
                        b1.visitorName=bs[i].visitorName===null?'':bs[i].visitorName;
                        b1.purpose=bs[i].purpose===null?'':bs[i].purpose;
                        b1.division = bs[i].division;
                        b1.isMechanized = bs[i].isMechanized;

                        for(var j=0;j<resource.length;j++){
                            if(resource[j].id === bs[i].resourceId){
                                if(locale ==='EN'){
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                }else{
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                }
                                b1.resource = resource[j];
                                b1.resourceType = resource[j].resourceType;
                                break;
                            }
                        }
                        if(b1.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK){
                            bs1.push(b1);
                        }else{
                            bs2.push(b1);
                        }
                        

                    }
                    this.setState({parkingdata:bs1,parkingdata1:bs2,loading:false});
                    
                
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))


        
    }

    
    searchtranferBooking1=()=>{
        this.setState({loading1:true,roomdata:[],count1:0})
        var rdata = {};      
        rdata.userId = ApiHeader.userId;
        rdata.state = ['pending_accept'];
        fetch(apiurl+'booking/list', {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin',  
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bss = data.data.bookings;
                    var bs = [];
                    for(var i=0; i<bss.length;i++){
                        bs=bs.concat(bss[i].booking)
                    }
                    var bs1 = []; 
                    var resource = this.state.resource;
                    for(var i=0;i<bs.length;i++){ 
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        var ds = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
                        
                        b1.date = ds;
                        b1.tags = [strings.view];
                        b1.requestedBy = bs[i].transferName;
                        b1.firstcontact = bs[i].contactPerson1 +'('+bs[i].contactPhone1+')';
                        b1.secondcontact = bs[i].contactPerson2 +'('+bs[i].contactPhone2+')';
                        b1.salutation1 = bs[i].salutation1;  
                        b1.salutation2 = bs[i].salutation2;
                        b1.subject = bs[i].subject;
                        b1.remark = bs[i].remark;
                        b1.staffType = bs[i].staffType;
                        b1.state = bs[i].state;
                        b1.chairman = bs[i].chairman;
                        b1.groupName = bs[i].groupName;
                        b1.isReminder = bs[i].isReminder;
                        for(var j=0;j<resource.length;j++){
                            if(resource[j].id === bs[i].resourceId){
                                if(locale ==='EN'){
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                }else{
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    this.setState({roomdata:bs1,count1:bs1.length,loading1:false});
                    
                
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading1:false})
        })
        .catch(e => console.warn(e))
    }

    searchPendingCarpark=()=>{
        this.setState({loading:true,parkingdata:[],parkingdata1:[]})
        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.OPERATIONAL_CARPARK;        
        rdata.userId = ApiHeader.userId;
        rdata.state = 'pending_approval';
        
        fetch(apiurl+'booking/action', {
            headers: ApiHeader,
            method: 'POST',   
            credentials: 'same-origin',
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data.bookings;
                    
                    var bs1 = [];
                    var resource = this.state.resource;
                    for(var i=0;i<bs.length;i++){
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        b1.unixts = bs[i].startTime;
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        var ds = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
                        b1.carNumber = bs[i].carNumber;
                        b1.date = ds;
                        b1.tags = [strings.approve, strings.reject, strings.view];
                        b1.requestedBy = bs[i].userName;
                        b1.firstcontact = bs[i].contactPerson1 +'('+bs[i].contactPhone1+')';
                        b1.secondcontact = bs[i].contactPerson2 +'('+bs[i].contactPhone2+')';
                        b1.salutation1 = bs[i].salutation1;  
                        b1.salutation2 = bs[i].salutation2;
                        b1.reason = bs[i].reason;
                        b1.remark = bs[i].remark;
                        b1.staffType = bs[i].staffType;
                        b1.state = bs[i].state;
                        b1.type = strings.operational;
                        b1.resourceType = RESOURCE_TYPES.OPERATIONAL_CARPARK;
                        b1.approverName = bs[i].approverName;
                        b1.approverId = bs[i].approverId;
                        b1.groupName = bs[i].groupName;
                        b1.division = bs[i].division;
                        b1.isMechanized = bs[i].isMechanized;
                        b1.isReminder = bs[i].isReminder;
                        for(var j=0;j<resource.length;j++){
                            if(resource[j].id === bs[i].resourceId){
                                if(locale ==='EN'){
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                }else{
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    bs1 = bs1.sort((a, b) => a.unixts - b.unixts);
                    this.setState({parkingdata:bs1,loading:false});
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))

        var rdata1 = {};
        rdata1.resourceType = RESOURCE_TYPES.VISITOR_CARPARK;        
        rdata1.userId = ApiHeader.userId;
        rdata1.state = 'pending_approval';
        
        fetch(apiurl+'booking/action', {
            headers: ApiHeader,
            method: 'POST',   
            credentials: 'same-origin',
            body: JSON.stringify(rdata1),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data.bookings;
                    
                    var bs1 = [];
                    var resource = this.state.resource;
                    for(var i=0;i<bs.length;i++){
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        b1.unixts = bs[i].startTime;
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        var ds = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
                        b1.carNumber = bs[i].carNumber;
                        b1.date = ds;
                        b1.tags = [strings.approve, strings.reject, strings.view];
                        b1.requestedBy = bs[i].userName;
                        b1.firstcontact = bs[i].contactPerson1 +'('+bs[i].contactPhone1+')';
                        b1.secondcontact = bs[i].contactPerson2 +'('+bs[i].contactPhone2+')';
                        b1.salutation1 = bs[i].salutation1;  
                        b1.salutation2 = bs[i].salutation2;
                        b1.reason = bs[i].reason;
                        b1.remark = bs[i].remark;
                        b1.state = bs[i].state;
                        b1.type = strings.operational;
                        b1.resourceType = RESOURCE_TYPES.VISITOR_CARPARK;
                        b1.approverName = bs[i].approverName;
                        b1.approverId = bs[i].approverId;
                        b1.groupName = bs[i].groupName;
                        b1.isReminder = bs[i].isReminder;
                        b1.isElectric=bs[i].isElectric===null?'':bs[i].isElectric;
                        b1.visitorCompany=bs[i].visitorCompany===null?'':bs[i].visitorCompany;
                        b1.visitorName=bs[i].visitorName===null?'':bs[i].visitorName;
                        b1.purpose=bs[i].purpose===null?'':bs[i].purpose;
                        b1.division = bs[i].division;
                        b1.isMechanized = bs[i].isMechanized;
                        for(var j=0;j<resource.length;j++){
                            if(resource[j].id === bs[i].resourceId){
                                if(locale ==='EN'){
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                }else{
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    bs1 = bs1.sort((a, b) => a.unixts - b.unixts);
                    this.setState({parkingdata1:bs1,loading:false});                
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }
    
    searchtranferBooking=()=>{
        this.setState({loading1:true,roomdata:[],count1:0})
        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.MEETING_ROOM;        
        rdata.userId = ApiHeader.userId;
        rdata.state = 'pending_accept';
        fetch(apiurl+'booking/action', {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin',  
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data.bookings;                    
                    var bs1 = [];
                    var resource = this.state.resource;
                    for(var i=0;i<bs.length;i++){
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        b1.unixts = bs[i].startTime;
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        var ds = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
                        b1.firstcontact = bs[i].contactPerson1 +'('+bs[i].contactPhone1+')';
                        b1.secondcontact = bs[i].contactPerson2 +'('+bs[i].contactPhone2+')';
                        b1.salutation1 = bs[i].salutation1;  
                        b1.salutation2 = bs[i].salutation2;
                        b1.date = ds;
                        b1.tags = [strings.accept, strings.decline, strings.view];
                        b1.requestedBy = bs[i].userName;
                        b1.subject = bs[i].subject;
                        b1.state = bs[i].state;
                        b1.remark = bs[i].remark;
                        b1.staffType = bs[i].staffType;
                        b1.chairman = bs[i].chairman;
                        b1.groupName = bs[i].groupName;
                        b1.isReminder = bs[i].isReminder;
                        for(var j=0;j<resource.length;j++){
                            if(resource[j].id === bs[i].resourceId){
                                if(locale ==='EN'){
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                }else{
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    bs1 = bs1.sort((a, b) => a.unixts - b.unixts);
                    this.setState({roomdata:bs1,count1:bs1.length,loading1:false});
                    
                
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading1:false})
        })
        .catch(e => console.warn(e))
    }
    
    removeBooking=(id,key)=>{
        var rdata = {};
        rdata.bookingId=id;
        fetch(apiurl+'booking/cancel', {
            headers: ApiHeader,
            method: 'POST',  
            credentials: 'same-origin', 
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var data = this.state.parkingdata;
                    data.splice(key,1);                   
                    this.setState({parkingdata:data});
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    ontagclick=(tag,b)=>{
        if(tag.tag == strings.approve){            
            this.approveBooking(b.id);
        }else if(tag.tag == strings.reject){
           this.rejectBooking(b.id);
        }else if(tag.tag == strings.cancel1){
            this.removeBooking(b.id,b.key1);
        } 
        else if(tag.tag == strings.view){
            this.setState({isediting:true,isbooking:false,selectedBooking:b,iseditop:false});
        }         
    }

    ontagclick1=(tag,b)=>{
        if(tag.tag == strings.accept){            
            this.acceptBooking(b.id);
        }else if(tag.tag == strings.decline){
            this.declineBooking(b.id);
        }else if(tag.tag == strings.view){
            this.setState({isediting:true,isbooking:true,selectedBooking:b,iseditop:false});
        }         
    }

    approveBooking=(id)=>{
        
        var rdata = {};
        rdata.bookingId=id;
        fetch(apiurl+'booking/approve', {
        headers: ApiHeader,
        method: 'POST',  
        credentials: 'same-origin', 
        body: JSON.stringify(rdata), 
        }).then(response => {
            if (response.ok) {
            return response.json();
            } else {
            throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            
            if(data.code == "1"){
                this.setState({alertmessage:strings.approvedsuccess,showalertmodel:true})
                this.searchtranferBooking();
                this.searchPendingCarpark();
            }else{
                this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
            }
        })
        .catch(e => console.warn(e))
        
    }
    
    rejectBooking=(id)=>{
        
        var rdata = {};
        rdata.bookingId=id;
        fetch(apiurl+'booking/reject', {
        headers: ApiHeader,
        method: 'POST',  
        credentials: 'same-origin', 
        body: JSON.stringify(rdata), 
        }).then(response => {
            if (response.ok) {
            return response.json();
            } else {
            throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            if(data.code == "1"){
                this.setState({alertmessage:strings.rejectedsuccess,showalertmodel:true,})
                this.searchtranferBooking();
                this.searchPendingCarpark();
            }else{
                this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
            }
        })
        .catch(e => console.warn(e))
        
    }

    acceptBooking=(id)=>{
        
        var rdata = {};
        rdata.bookingId=id;
        fetch(apiurl+'booking/accept', {
        headers: ApiHeader,
        method: 'POST',  
        credentials: 'same-origin', 
        body: JSON.stringify(rdata), 
        }).then(response => {
            if (response.ok) {
            return response.json();
            } else {
            throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            
            if(data.code == "1"){
                this.setState({alertmessage:strings.acceptedsuccess,showalertmodel:true})
                this.searchtranferBooking();
                this.searchPendingCarpark();
            }else{
                this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
            }
        })
        .catch(e => console.warn(e))
        
    }
    
    declineBooking=(id)=>{
        
        var rdata = {};
        rdata.bookingId=id;
        fetch(apiurl+'booking/decline', {
        headers: ApiHeader,
        method: 'POST',   
        credentials: 'same-origin',
        body: JSON.stringify(rdata), 
        }).then(response => {
            if (response.ok) {
            return response.json();
            } else {
            throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            
            if(data.code == "1"){
                this.setState({alertmessage:strings.declinedsuccess,showalertmodel:true})
                this.searchtranferBooking();
                this.searchPendingCarpark();
            }else{
                this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
            }
        })
        .catch(e => console.warn(e))
        
    }

    editback=()=>{
        if(this.state.radiooption == 'b'){
            this.searchtranferBooking1();
            this.searchPendingCarpark1();
        }
        else{
            this.searchtranferBooking();
            this.searchPendingCarpark();
        }            
        this.setState({isediting:false,iseditop:false,isbooking:true})
    }

    onRadioChange=(e)=>{
        if(e.target.value == 'b'){
            this.setState({radiooption:'b'})
            this.searchtranferBooking1();
            this.searchPendingCarpark1();
        }else{
            this.setState({radiooption:'a'})
            this.searchtranferBooking();
            this.searchPendingCarpark();
        }
    }

    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
      }

    render() {
        return (
            <div className='Tab2Div'>
            {
                this.state.isediting?
                    (this.state.isbooking?
                    <EditBooking sb={this.state.selectedBooking} isEdit={this.state.iseditop} backbtn={this.editback}/>   
                    :       
                    <EditCarpark sb={this.state.selectedBooking} isEdit={this.state.iseditop} backbtn={this.editback}/>)          
                    :  
                <div>
                    <div style={{display: 'flex', justifyContent: 'center',marginTop:20}}>
                        <Radio.Group value={this.state.radiooption} buttonStyle="solid" onChange={this.onRadioChange}>                            
                            <Radio.Button value="a">{strings.action}</Radio.Button>
                            <Radio.Button value="b">{strings.submitted}</Radio.Button>
                        </Radio.Group>
                    </div>                    
                    <div style={{marginTop: 20,marginLeft:10}}><span style={{fontSize:15,fontWeight:500,color:'#455773'}}>{strings.pendingtransferacceptance}</span></div>
                    <div style={{marginTop:10}}>
                        <div className="table-operations">
                        </div>
                        <Table style={{marginTop:12,backgroundColor:'#fff'}}columns={this.state.radiooption=='b'?this.columns3:this.columns1} pagination={{ pageSize: 5 }}  dataSource={this.state.roomdata} />
                        <div className="CountOfData">
                            <span className="CountText">{strings.totalcount} {this.state.roomdata.length}</span>
                        </div>        
                    </div>
                    <div style={{marginTop:40,marginLeft:10}}><span style={{fontSize:15,fontWeight:500,color:'#455773'}}>{strings.pendingtransfercarparkapproval}</span></div>
                    <div style={{marginTop:10}}>
                        <div className="table-operations">
                        </div>
                        <Table style={{marginTop:12,backgroundColor:'#fff'}} columns={this.state.radiooption=='b'?this.columns2:this.columns} loading={this.state.loading} pagination={{ pageSize: 5 }}  dataSource={this.state.parkingdata} />
                        <div className="CountOfData">
                            <span className="CountText">{strings.totalcount} {this.state.parkingdata.length}</span>
                        </div>        
                    </div>
                    <div style={{marginTop:40,marginLeft:10}}><span style={{fontSize:15,fontWeight:500,color:'#455773'}}>{strings.pendingvisitorapproval}</span></div>
                    <div style={{marginTop:10}}>
                        <div className="table-operations">
                        </div>
                        <Table style={{marginTop:12,backgroundColor:'#fff'}} columns={this.state.radiooption=='b'?this.columns5:this.columns4} loading={this.state.loading} pagination={{ pageSize: 5 }}  dataSource={this.state.parkingdata1} />
                        <div className="CountOfData">
                            <span className="CountText">{strings.totalcount} {this.state.parkingdata1.length}</span>
                        </div>        
                    </div>
                    <Modal
                        title={strings.alert}
                        visible={this.state.showalertmodel}
                        onCancel={()=>this.setState({showalertmodel:false})}
                        footer={[                  
                            <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerReload)window.location.reload();}}>
                            {strings.ok}
                            </Button>,
                        ]}
                        >
                            <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                            <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                    </Modal> 
                </div>
            }                               
            </div>
        );
    }
}    
export default PendingBooking;

