import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Row, Col, Table, Select, Tag, Button, Radio, Popconfirm, Input, Modal, Tooltip, message, DatePicker, Form } from "antd";
import './App.css';
import moment from 'moment';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES } from './data';
const Option = Select.Option;

const Search = Input.Search;
const locale = 'EN';

class Reminder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertmessage: '',
            showalertmodel: false,
            systemData: null,
            resource: null,
            radiooption: 'a',
            floor: '',
            floors: [],
            location: '',
            locations: [],
            type: RESOURCE_TYPES.MEETING_ROOM,
            status: 'any',
            contactperson: '',
            fromdate: '',
            todate: '',
            fromdate1: null,
            todate1: null,
            uid: 0,
            username: '',
            users: [],
            reminderrooms: [],
            roomsResource: [],
            selectedRoom: '',
            islight: '0',
            intervalmin: '15',
            notificationcount: 2,
            searchstr: '',
        }
        this.columns = [
            {
                title: strings.date,
                dataIndex: 'date',
                key: 'date',
                width: '15%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm')),
                sorter: (a, b) => a.startTime - b.startTime,
            }, {
                title: strings.floor,
                dataIndex: 'floor',
                key: 'floor',
                width: '8%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width: '8%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
            }, {
                title: strings.username,
                dataIndex: 'user',
                key: 'user',
                width: '10%',
                sorter: (a, b) => a.user.localeCompare(b.user, 'en', { numeric: true }),
            }, {
                title: strings.status,
                dataIndex: 'state1',
                key: 'state1',
                width: '8%',
                sorter: (a, b) => a.state1.localeCompare(b.state1, 'en', { numeric: true }),
            }, {
                title: strings.subject,
                dataIndex: 'subject',
                key: 'subject',
                width: '15%',
                render: (val, record) => <p className='tablelongtext' style={{ width: 200 }}>{val}</p>,
                sorter: (a, b) => a.subject.localeCompare(b.subject, 'en', { numeric: true }),
            }, {
                title: strings.reminder,
                dataIndex: 'reminder',
                key: 'reminder',
                width: '10%',
                render: (val, record) => this.renderReminderText(val),
                sorter: (a, b) => a.reminder.localeCompare(b.reminder, 'en', { numeric: true }),
            }, {
                title: strings.useraction,
                dataIndex: 'action',
                key: 'action',
                width: '10%',
                render: (val, record) => val,
                sorter: (a, b) => a.action.localeCompare(b.action, 'en', { numeric: true }),
            }, {
                title: strings.actiontime,
                dataIndex: 'actiontime',
                key: 'actiontime',
                width: '9%',
                render: (val, record) => val,
                sorter: (a, b) => a.actiontime.localeCompare(b.actiontime, 'en', { numeric: true }),
            }, {
                title: strings.sensorhistory,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                        {tags.map(tag => {
                            return (<Tag color={'#455773'} key={tag} style={{ marginTop: 5 }} onClick={() => this.ontagclick({ tag }, record)}>{tag.toUpperCase()}</Tag>);
                        })}
                    </span>
                ),
            }
        ];

    }

    renderReminderText = (val) => {
        if (val === '1st Reminder') {
            return strings.fstreminder;
        } else if (val === '2nd Reminder') {
            return strings.sndreminder;
        } else if (val === '3rd Reminder') {
            return strings.trdreminder;
        } else if (val === '4th Reminder') {
            return strings.fthreminder;
        } else if (val === 'final reminder') {
            return strings.finalreminder;
        }
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber', 20);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({ resource: sd.resource, systemData: sd, notificationcount: sd.max_notification, intervalmin: sd.continuous_minutes });
        var resource = sd.resource;

        var floors = [], locations = [];
        floors.push(strings.any);
        locations.push(strings.any);
        var reminderrooms = [], rooms = [];
        for (var i = 0; i < resource.length; i++) {
            if (RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType) {
                if (locale === 'EN') {
                    if (floors.indexOf(resource[i].locationEN.split(',')[1]) == -1) {
                        floors.push(resource[i].locationEN.split(',')[1]);
                    }
                    if (resource[i].isReminderEnabled === 1) {
                        reminderrooms.push({ id: resource[i].id, name: resource[i].nameEN, islight: resource[i].isLightIncluded === 1 ? strings.yes : strings.no });
                    } else {
                        rooms.push({ id: resource[i].id, name: resource[i].nameEN, islight: strings.no })
                    }

                } else {
                    if (floors.indexOf(resource[i].locationZH.split(',')[1]) == -1) {
                        floors.push(resource[i].locationZH.split(',')[1]);
                    }
                    if (resource[i].isReminderEnabled === 1) {
                        reminderrooms.push({ id: resource[i].id, name: resource[i].nameZH, islight: resource[i].isLightIncluded === 1 ? strings.yes : strings.no });
                    } else {
                        rooms.push({ id: resource[i].id, name: resource[i].nameZH, islight: strings.no })
                    }
                }
            }
        }
        reminderrooms.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
        this.setState({ floors: floors, locations: locations, floor: floors[0], location: locations[0], reminderrooms: reminderrooms, roomsResource: rooms, selectedRoom: rooms.length > 0 ? rooms[0].id : '' });
    }

    componentWillUnmount() {

    }

    ontagclick = (tag, b) => {
        if (tag.tag == strings.view1) {
            var link = document.createElement('a');
            link.href = '/srsadmin/reminderlog.html?bId=' + b.id;
            link.target = "_blank";
            link.click();
            document.removeChild(link);
        }

    }

    onRadioChange = (e) => {
        if (e.target.value == 'a') {
            this.setState({ radiooption: 'a' })
        } else {
            this.setState({ radiooption: 'b' })
        }
    }

    onfromChange = (val) => {
        this.setState({ fromdate1: val, fromdate: val.format('YYYYMMDD') })
    }

    ontoChange = (val) => {
        this.setState({ todate1: val, todate: val.format('YYYYMMDD') })
    }

    handleTypeChange = (value) => {
        var resource = this.state.systemData.resource;
        var floors = [], locations = [];
        floors.push(strings.any);
        locations.push(strings.any);
        for (var i = 0; i < resource.length; i++) {
            if (value === resource[i].resourceType) {
                if (locale === 'EN') {
                    if (floors.indexOf(resource[i].locationEN.split(',')[1]) == -1) {
                        floors.push(resource[i].locationEN.split(',')[1]);
                    }
                } else {
                    if (floors.indexOf(resource[i].locationZH.split(',')[1]) == -1) {
                        floors.push(resource[i].locationZH.split(',')[1]);
                    }
                }
            }
        }
        this.setState({ type: value, floors: floors, locations: locations, floor: floors[0], location: locations[0] });
    }

    handleFloorChange = (value) => {
        var resource = this.state.systemData.resource;
        var locations = [];
        locations.push(strings.any);
        for (var i = 0; i < resource.length; i++) {
            if (this.state.type === resource[i].resourceType) {
                if (resource[i].locationEN.split(',')[1] === value) {
                    locations.push(resource[i].locationEN.split(',')[0]);
                }
            }

        }
        this.setState({ locations: locations, floor: value, location: locations[0] });
    }

    handleStatusChange = (value) => {
        this.setState({ status: value })
    }

    clearScrren = () => {
        this.setState({
            floor: this.state.floors[0],
            location: this.state.locations[0],
            type: RESOURCE_TYPES.MEETING_ROOM,
            status: 'any',
            contactperson: '',
            fromdate: '',
            todate: '',
            fromdate1: null,
            todate1: null,
            uid: 0,
            username: '',
        })
    }

    searchUser = () => {
        this.setState({ showmodel: true })
    }

    handleOk = () => {
        this.setState({ showmodel: false, users: [] })
    }

    searchReminderLog = () => {
        this.setState({ loading: true })
        var rdata = {};
        rdata.resourceType = this.state.type;

        var resource = this.state.resource;
        if (this.state.location == strings.any) {
            for (var i = 0; i < resource.length; i++) {
                if (this.state.floor === resource[i].locationZH.split(',')[1] || this.state.floor === resource[i].locationEN.split(',')[1]) {
                    rdata.buildingFloorId = resource[i].buildingFloorId;
                    break;
                }
            }
        } else {
            for (var i = 0; i < resource.length; i++) {
                if ((this.state.floor === resource[i].locationZH.split(',')[1] || this.state.floor === resource[i].locationEN.split(',')[1]) && (this.state.location === resource[i].locationZH.split(',')[0] || this.state.location === resource[i].locationEN.split(',')[0])) {
                    rdata.resourceId = resource[i].id;
                    break;
                }
            }
        }

        if (this.state.uid != 0) {
            rdata.userId = this.state.uid;
        }
        if (this.state.status != 'any')
            rdata.state = this.state.status;


        if ((this.state.fromdate != '' && this.state.todate == '') || (this.state.fromdate == '' && this.state.todate != '')) {
            this.setState({ loading: false });
            this.setState({ alertmessage: strings.dateerr, showalertmodel: true })

            return;
        } else if (this.state.fromdate != '' && this.state.todate != '') {
            rdata.fromDate = this.state.fromdate;
            rdata.toDate = this.state.todate;
        }

        if (this.state.contactperson != '') {
            if (this.state.contactperson.length >= 3) {
                rdata.contactPerson = this.state.contactperson;
            } else {
                this.setState({ alertmessage: strings.namelengtherrr, showalertmodel: true })
                this.setState({ loading: false });
                return;
            }

        }

        fetch(apiurl + 'booking/reminderlist', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    var bs = data.data.bookings;
                    bs = bs.sort((a, b) => b.startTime - a.startTime);
                    var bs1 = [];
                    var resource = this.state.resource;
                    for (var i = 0; i < bs.length; i++) {
                        var b1 = {};
                        b1.key = i;
                        b1.id = bs[i].id;
                        var ds = moment.unix((bs[i].startTime / 1000)).format("YYYY-MM-DD hh:mmA") + "-" + moment.unix((bs[i].endTime / 1000)).format("hh:mmA");
                        b1.startTime = bs[i].startTime;
                        b1.date = ds;
                        if (bs[i].actionTime == null) {
                            b1.actiontime = '';
                        } else {
                            b1.actiontime = moment.unix((bs[i].actionTime / 1000)).format("hh:mm:ssA").replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm');
                        }

                        b1.tags = [strings.view1];
                        if (bs[i].subject == null || bs[i].subject == "")
                            b1.subject = bs[i].reason == null ? '' : bs[i].reason;
                        else
                            b1.subject = bs[i].subject;
                        b1.remark = bs[i].remark;
                        b1.reason = bs[i].reason;
                        b1.resourceType = this.state.type;

                        b1.reminder = bs[i].reminder;
                        if (bs[i].action == 'pending') {
                            b1.action = strings.noaction;
                        } else if (bs[i].action == 'keep') {
                            b1.action = strings.keep;
                        } else if (bs[i].action == 'cancel') {
                            b1.action = strings.cancel1;
                        }


                        b1.state = bs[i].state;
                        if (bs[i].state == 'system_checkout' || bs[i].state == 'completed') {
                            b1.state1 = strings.completed;
                        }

                        if (bs[i].state == 'manually_cancelled' || bs[i].state == 'admin_cancelled') {
                            b1.state1 = strings.cancelled;
                        }

                        if (bs[i].state == 'rejected') {
                            b1.state1 = strings.rejected;
                        }

                        if (bs[i].state == 'booked' || bs[i].state == 'confirmed') {
                            b1.state1 = strings.booked;
                        }

                        if (bs[i].state == 'pending_approval' || bs[i].state == 'pending_admin' || bs[i].state == 'pending_accept') {
                            b1.state1 = strings.pending;
                        }
                        b1.chairman = bs[i].chairman;
                        b1.groupName = bs[i].groupName;
                        b1.user = bs[i].userName;

                        for (var j = 0; j < resource.length; j++) {
                            if (resource[j].id === bs[i].resourceId) {
                                if (locale === 'EN') {

                                    if (this.state.type === RESOURCE_TYPES.MEETING_ROOM) {
                                        b1.floor = resource[j].locationEN.split(',')[1];
                                        b1.location = resource[j].locationEN.split(',')[0];
                                        b1.location1 = resource[j].locationEN.split(',')[0] + " " + resource[j].locationEN.split(',')[1];
                                    } else {
                                        b1.floor = '';
                                        b1.location = '';
                                        b1.location1 = '';
                                    }
                                } else {

                                    if (this.state.type === RESOURCE_TYPES.MEETING_ROOM) {
                                        b1.location1 = resource[j].locationZH.split(',')[0] + " " + resource[j].locationZH.split(',')[1];
                                        b1.location = resource[j].locationZH.split(',')[0];
                                        b1.floor = resource[j].locationEN.split(',')[1];
                                    } else {
                                        b1.floor = '';
                                        b1.location = '';
                                        b1.location1 = '';
                                    }
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }

                        bs1.push(b1);

                    }
                    this.setState({ tabledata: bs1, count: bs1.length });


                } else {
                    this.setState({ alertmessage: strings.errorcode + "" + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))
    }


    handleselectChange = (val) => {
        if (val != '0')
            this.setState({ uid: parseInt(val.split('-')[0]), username: val.split('-')[1] })
    }

    handleSearch = (e) => {
        if (!this.state.issearching && this.state.searchstr.length > 1) {
            var val = this.state.searchstr;
            this.setState({ issearching: true });
            var rdata = {};
            rdata.name = val;
            fetch(apiurl + 'user/search1', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {
                        var users = data.data.user;
                        this.setState({ users: users, issearching: false });
                    } else {
                        this.setState({ alertmessage: strings.errorcode + "" + data.error_code, showalertmodel: true, issearching: false })
                    }
                    this.setState({ issearching: false })
                })
                .catch(e => { this.setState({ issearching: false }); console.warn(e) })
        } else {
            this.setState({ alertmessage: strings.enter3char, showalertmodel: true })
        }
    }

    onRoomCancle1 = (c, index) => {
        var reminderrooms = this.state.reminderrooms;
        reminderrooms.splice(index, 1);
        this.setState({ reminderrooms: reminderrooms, selectedRoom: '', islight: '0' });
    }

    onAddRoom = () => {
        var reminderrooms = this.state.reminderrooms;
        var c = {};
        c.name = <Select size="large" style={{ width: 80, height: 30 }} defaultValue={this.state.selectedRoom} onChange={val => this.setState({ selectedRoom: val })}>
            {this.state.roomsResource.map((r) => <Option value={r.id} key={r.id}>{r.name}</Option>)}
        </Select>;
        c.islight = <Select size="large" style={{ width: 80, height: 30 }} defaultValue={this.state.islight} onChange={val => this.setState({ islight: val })}>
            <Option value="1">{strings.yes}</Option>
            <Option value="0">{strings.no}</Option>
        </Select>;
        c.isaddroom = true;
        var rooms = [c, ...reminderrooms];
        rooms.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
        this.setState({ reminderrooms: rooms });
    }

    onRoomSave1 = (c, index) => {
        var rdata = {};
        rdata.resourceId = this.state.selectedRoom;
        rdata.islight = this.state.islight;

        fetch(apiurl + 'resource/addReminder', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    this.setState({
                        selectedRoom: '',
                        islight: '0'
                    })
                    this.fetchSystemData(strings.createdsuccess)
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
    }

    removeroom = (c) => {

        var rdata = {};
        rdata.resourceId = c.id;

        fetch(apiurl + 'resource/removeReminder', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    this.setState({
                        selectedRoom: '',
                        islight: '0'
                    })
                    this.fetchSystemData(strings.removesuccess)
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
    }

    saveSystemsetting = () => {
        var rdata = {};
        rdata.maxNotification = this.state.notificationcount;
        rdata.continuesmin = this.state.intervalmin;

        fetch(apiurl + 'system/saveremindersetting', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    this.fetchSystemData(strings.savedsuccess)
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
    }

    fetchSystemData = (msg) => {
        fetch(apiurl + 'system/list', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    sessionStorage.setItem('systemdata', JSON.stringify(data.data));
                    var sd = data.data;
                    this.setState({ resource: sd.resource, systemData: sd, notificationcount: sd.max_notification, intervalmin: sd.continuous_minutes });
                    var resource = sd.resource;
                    var reminderrooms = [], rooms = [];
                    for (var i = 0; i < resource.length; i++) {
                        if (RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType) {
                            if (locale === 'EN') {
                                if (resource[i].isReminderEnabled === 1) {
                                    reminderrooms.push({ id: resource[i].id, name: resource[i].nameEN, islight: resource[i].isLightIncluded === 1 ? strings.yes : strings.no });
                                } else {
                                    rooms.push({ id: resource[i].id, name: resource[i].nameEN, islight: strings.no })
                                }

                            } else {
                                if (resource[i].isReminderEnabled === 1) {
                                    reminderrooms.push({ id: resource[i].id, name: resource[i].nameZH, islight: resource[i].isLightIncluded === 1 ? strings.yes : strings.no });
                                } else {
                                    rooms.push({ id: resource[i].id, name: resource[i].nameZH, islight: strings.no })
                                }
                            }
                        }
                    }
                    reminderrooms.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
                    this.setState({ reminderrooms: reminderrooms, roomsResource: rooms, selectedRoom: rooms.length > 0 ? rooms[0].id : '' });
                    if (msg != null)
                        this.setState({ alertmessage: msg, showalertmodel: true })
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
    }


    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
    }

    render() {

        return (
            <div className='Tab2Div' id="maintabdiv">
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <Radio.Group value={this.state.radiooption} buttonStyle="solid" onChange={this.onRadioChange}>
                            <Radio.Button value="a">{strings.history}</Radio.Button>
                            <Radio.Button value="b">{strings.setup}</Radio.Button>
                        </Radio.Group>
                    </div>
                    {this.state.radiooption == 'a' ?
                        <div style={{ marginTop: 20 }}>
                            <div>
                                <div className="FirstDiv">
                                    <span className="TitleOfTheBox">{strings.reminderlog}</span>
                                    <Row xs={24} >
                                        <Col md={5}>
                                            <div>
                                                <span className="placeholder">{strings.type}</span>
                                            </div>
                                            <Select className="InputSizeOfThird" value={this.state.type} style={{ width: 260 }} onChange={this.handleTypeChange}>
                                                <Option value={RESOURCE_TYPES.MEETING_ROOM}>{strings.meetingroom}</Option>
                                            </Select>
                                        </Col>
                                        <Col md={5}>
                                            <div>
                                                <span className="placeholder">{strings.from}</span>
                                                <div style={{ marginBottom: 16 }}>
                                                    <DatePicker className="InputSizeOfThird" value={this.state.fromdate1} onChange={this.onfromChange} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={5}>
                                            <div>
                                                <span className="placeholder">{strings.to}</span>
                                            </div>
                                            <DatePicker className="InputSizeOfThird" value={this.state.todate1} onChange={this.ontoChange} />
                                        </Col>
                                        <Col md={5}>
                                            <div>
                                                <span className="placeholder">{strings.status}</span>
                                            </div>
                                            <Select className="InputSizeOfThird" value={this.state.status} onChange={this.handleStatusChange}>
                                                <Option value="any">{strings.any}</Option>
                                                <Option value="pending">{strings.noaction}</Option>
                                                <Option value="keep">{strings.keep}</Option>
                                                <Option value="cancel">{strings.cancel1}</Option>
                                            </Select>
                                        </Col>
                                    </Row>

                                    <Row md={24} style={{ height: 73 }}>
                                        {this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR && <Col md={5} style={{ marginTop: -10 }}>
                                            <div>
                                                <span className="placeholder">{strings.floor}</span>
                                            </div>
                                            <Select className="InputSizeOfThird" value={this.state.floor} style={{ width: 260 }} onChange={this.handleFloorChange}>
                                                {
                                                    this.state.floors.map((ed, index) => <Option key={index} value={ed}>{ed}</Option>)
                                                }
                                            </Select>
                                        </Col>}
                                        {this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR && <Col md={5} style={{ marginTop: -10 }}>
                                            <div>
                                                <span className="placeholder">{strings.location}</span>
                                                <div style={{ marginBottom: 16 }}>
                                                    <Select className="InputSizeOfThird" value={this.state.location} onChange={val => this.setState({ location: val })}>
                                                        {
                                                            this.state.locations.map((ed, index) => <Option key={index} value={ed}>{ed}</Option>)
                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                        </Col>}
                                        {(this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR) && <Col md={5} style={{ marginTop: -10 }}>
                                            <div>
                                                <span className="placeholder">{strings.zoomacc}</span>
                                            </div>
                                            <Select className="InputSizeOfThird" value={this.state.zoomAccountId} style={{ width: 260 }} onChange={val => this.setState({ zoomAccountId: val })}>
                                                {
                                                    this.state.zoomaccounts.map((ed, index) => <Option key={index} value={ed.id}>{locale === 'EN' ? ed.nameEN : ed.nameZH}</Option>)
                                                }
                                            </Select>
                                        </Col>}
                                        <Col md={5} style={{ marginTop: -10 }}>
                                            <div>
                                                <span className="placeholder">{strings.contactperson}</span>
                                            </div>
                                            <Input className="InputSizeOfThird" value={this.state.contactperson} onChange={e => this.setState({ contactperson: e.target.value })} />
                                        </Col>
                                        <Col md={7} style={{ marginTop: -10 }}>
                                            <div>
                                                <span className="placeholder">{strings.user}</span>
                                            </div>
                                            <Input className="InputSizeOfThird" value={this.state.username} disabled />
                                            <Button key="submit" className='subscribe1' type="danger" style={{ marginLeft: 20 }} onClick={this.searchUser}>
                                                {strings.search}
                                            </Button>

                                        </Col>
                                    </Row>

                                    <Button type="primary" size='large' style={{ marginRight: 17 }} loading={this.state.loading} onClick={this.searchReminderLog}>
                                        {strings.search}
                                    </Button>
                                    <Button size='large' style={{ marginRight: 20 }} onClick={this.clearScrren}>
                                        {strings.clear}
                                    </Button>
                                </div>

                                <div>
                                    <div className="table-operations">
                                    </div>
                                    <Table style={{ marginTop: 12, backgroundColor: '#fff' }} columns={this.columns} dataSource={this.state.tabledata} />
                                    <div className="CountOfData">
                                        <span className="CountText">{strings.totalcount} {this.state.count}</span>
                                    </div>
                                </div>

                            </div>
                            <Modal
                                title={strings.searchuser}
                                visible={this.state.showmodel}
                                onOk={this.handleOk}
                                onCancel={() => this.setState({ showmodel: false })}
                                footer={[

                                    <Button key="submit" className='subscribe1' type="danger" onClick={this.handleOk}>
                                        {strings.select}
                                    </Button>,
                                ]}
                            >
                                <div style={{ paddingTop: 5 }}>
                                    <Row>
                                        <span style={{ color: '#ff7f27', fontSize: 14 }}>{strings.plsincludeslashinsearch}</span>
                                    </Row>
                                    <Row type="flex" style={{ marginTop: 15 }}>
                                        <Col span={6}>{strings.postname1}</Col>
                                        <Col span={18}>
                                            <Form>
                                                <Row type="flex">
                                                    <Col span={17}>
                                                        <Input value={this.state.searchstr} placeholder={strings.enter3char} onChange={e => this.setState({ searchstr: e.target.value })} />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Button type="danger" htmlType="submit" className='subscribe1' style={{ marginLeft: 15, marginTop: 0 }} disabled={this.state.issearching} loading={this.state.issearching} onClick={(e) => this.handleSearch(e)}>
                                                            {strings.search}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                            
                                        </Col>
                                    </Row>
                                    <Row type="flex" style={{ marginTop: 15 }}>
                                        <Col span={6}>{strings.user}</Col>
                                        <Col span={18}>
                                            <Select defaultValue="0" style={{ width: '100%' }} onChange={this.handleselectChange}>
                                                <Option value="0">{strings.pleaseselect}</Option>
                                                {this.state.users.map(d => <Option key={d.id + '-' + d.name}>{d.name} ({d.post})</Option>)}

                                            </Select>
                                        </Col>
                                    </Row>
                                </div>
                            </Modal>

                        </div>
                        : <div style={{ marginTop: 20 }}>
                            <div className="FirstDiv">
                                <Row xs={24} ><Col md={6} ><span className="TitleOfTheBox">{strings.setupreminder}</span></Col></Row>
                                <Row xs={24} style={{ marginTop: 10, }} >
                                    <Col md={2} ></Col>
                                    <Col md={4} >
                                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                        <span style={{ marginLeft: 6 }} className={this.state.nameZHerr ? "DateTextErr" : "DateText"}>{strings.continuesmin}</span>
                                    </Col>
                                    <Col md={5}>
                                        <div>
                                            <Select size="large" style={{ height: 32, width: '90%' }} defaultValue={this.state.intervalmin} onChange={val => this.setState({ intervalmin: val })}>
                                                <Option value={10}>{10}</Option>
                                                <Option value={15}>{15}</Option>
                                                <Option value={20}>{20}</Option>
                                            </Select>
                                        </div>
                                    </Col>
                                    {/*<Col md={4} >
                                <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                                <span style={{marginLeft:6}} className={this.state.nameENerr?"DateTextErr":"DateText"}>{strings.maxnotification}</span>
                                </Col>
                               <Col md={5}>
                                 <div>
                                    <Select size="large" style={{height:32,width:'90%'}} defaultValue={this.state.notificationcount} onChange={val=>this.setState({notificationcount:val})}>                    
                                        <Option value={1}>{1}</Option>
                                        <Option value={2}>{2}</Option>
                                    </Select>
                                </div> 
                                </Col>*/}
                                    <Col md={1} ></Col>
                                    <Col md={2} >
                                        <Button type="primary" loading={this.state.loading} style={{ fontSize: 14 }} onClick={this.saveSystemsetting}>
                                            {strings.save1}
                                        </Button>
                                    </Col>
                                </Row>
                                <div style={{ marginTop: 20 }}>
                                    <Row md={24}>
                                        <Col md={2} ></Col>
                                        <Col md={4}>
                                            <span style={{ marginLeft: 20 }} className={"placeholder"}>{strings.meetingroom}</span>
                                        </Col>
                                        <Col md={4}>
                                            <span style={{ marginLeft: 20 }} className={"placeholder"}>{strings.lightincluded}</span>
                                        </Col>
                                    </Row>
                                    <div>
                                        {this.state.reminderrooms.map((c, index) =>
                                            <Row md={24} style={{ marginTop: 2, marginBottom: 12 }} key={index}>
                                                <Col md={2} ></Col>
                                                <Col md={4}>
                                                    <span style={{ marginLeft: 20 }} className="yellowTextRoom">{c.name}</span>
                                                </Col>
                                                <Col md={4}>
                                                    <span style={{ marginLeft: 20 }} className="yellowTextRoom" >{c.islight}</span>
                                                </Col>
                                                {c.isaddroom ? <Col md={4}>
                                                    <Tag color={'#455773'} onClick={() => this.onRoomSave1(c, index)} style={{ fontWeight: 500 }}>{strings.save1}</Tag>
                                                    <Tag color={'#455773'} onClick={() => this.onRoomCancle1(c, index)} style={{ fontWeight: 500 }}>{strings.cancel}</Tag>
                                                </Col> : <Col md={4}>
                                                    <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.removeroom(c, index)}>
                                                        <Tag color={'#455773'} style={{ fontWeight: 500 }}>{strings.remove1}</Tag>
                                                    </Popconfirm>
                                                </Col>}
                                            </Row>
                                        )}
                                    </div>
                                    <Row md={24} style={{ marginTop: 10 }}>
                                        <Col md={2} ></Col>
                                        <Col md={4}>
                                            <Tag color={'#455773'} onClick={() => this.onAddRoom()} style={{ fontWeight: 500, marginLeft: 20 }}>{strings.add1}</Tag>
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    }
                    <Modal
                        title={strings.alert}
                        visible={this.state.showalertmodel}
                        onCancel={() => this.setState({ showalertmodel: false })}
                        footer={[
                            <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerReload) window.location.reload(); }}>
                                {strings.ok}
                            </Button>,
                        ]}
                    >
                        <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                        <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                    </Modal>
                </div>
            </div>
        )
    }

}

export default Reminder;