import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Row, Col, Button,Modal } from "antd";
import './App.css';
import redpin from './img/red_pin.png'
import greenpin from './img/green_pin.png'
import redp from './img/red_p.png'
import greenp from './img/green_p.png'
import temp from './img/temp.png'
import humi from './img/humi.png'
import {ApiHeader, strings, apiurl, RESOURCE_TYPES,floorPlan_URL} from './data';

const staticpath = "/srsadmin/static/";
class FloorMap extends Component {
  state = {
    date: new Date(),
    currentFloor:'floor7.png',
    currentHQ:'',
    currentFloorName:'7/F',
    sensorData : [],
    resources : [],
    buildingFloor:[],
    alertmessage:'',
    showalertmodel:false,
    currentDate:new Date(),
  }

  componentDidMount() {
    ApiHeader.userId = sessionStorage.getItem("userId");
    var d = sessionStorage.getItem('systemdata');
    var sd = JSON.parse(d);
    this.setState({systemData:sd,resources:sd.resource,buildingFloor:sd.buildingFloor})
    this.floorclick(this.props.floor)
    this.fetchcurrentFloordata(sd.resource)
  }

  componentWillUnmount() {

  }

  findErrorMessage=(msg)=>{
    return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
  }

  fetchcurrentFloordata=(resources)=>{
    var rids = [];
    var resource = [];   
    for(var i=0; i<resources.length; i++){
        if(resources[i].buildingFloorId == this.props.floorid){
            rids.push(resources[i].id);
            resource.push(resources[i])
        }
    }
    if(rids.length ==0){
      return;
    }
    var rdata = {};
    rdata.resourceId = rids;
    fetch(apiurl+'resource/sensordata', {
        headers:ApiHeader,
        method: 'POST',  
        credentials: 'same-origin', 
        body: JSON.stringify(rdata),         
      }).then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data =>{
          if(data.code == "1"){
            var sensordata = [];
            var data1 = data.data;
            for(var i=0; i<resource.length; i++){
                for(var ii=0; ii<data1.length; ii++){
                    if(data1[ii].resourceId == resource[i].id && resource[i].resourceType !== RESOURCE_TYPES.WASHROOM){
                        var sd= {};
                        sd.roomname = resource[i].nameEN;
                        if(resource[i].resourceType === RESOURCE_TYPES.ADMIN_CARPARK || resource[i].resourceType === RESOURCE_TYPES.VISITOR_CARPARK || resource[i].resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK){
                          sd.type = 'carpark';
                          sd.left = this.calculateLeft1(parseInt(resource[i].latitude));
                          sd.top = this.calculateTop1(parseInt(resource[i].longitude)); 
                        }else{
                          if(data1[ii].Temperature == null){
                            sd.temperature = "--";
                          }else{
                            sd.temperature = data1[ii].Temperature.toFixed(1)+'\xB0C';
                          }
                          if(data1[ii].Temperature == null){
                            sd.humidity = "--";
                          }else{
                            sd.humidity = data1[ii].Humidity.toFixed(1)+"%";
                          }
                          sd.type = 'room'
                          sd.left = this.calculateLeft(parseInt(resource[i].latitude));
                          sd.top = this.calculateTop(parseInt(resource[i].longitude)); 
                        }
                        
                        
                        if(data1[ii].IsOccupied == null || data1[ii].IsOccupied == true){
                          sd.IsOccupied = true;
                          sd.color = '#59C146';
                        }else{
                          sd.IsOccupied = data1[ii].IsOccupied;
                          sd.color = 'red';
                        }                        
                        
                        sd.id = resource[i].id;
                        sensordata.push(sd);
                        break;
                    }
                }                
            }
            this.setState({sensorData:sensordata})
          }else{
            this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
          }
          
          
        })
        .catch(e => console.warn(e))
  }


  floorclick=(val)=>{
    if(val=='7/F'){
        this.setState({currentFloor:'floor7.png',currentHQ:'',currentFloorName:'7/F',sensorData:[]})
       
    }        
    else if(val=='6/F'){
        this.setState({currentFloor:'floor6.png',currentHQ:'',currentFloorName:'6/F',sensorData:[]})
        
    }        
    else if(val=='5/F'){
        //this.refs.f5.className = 'spanclass';
        this.setState({currentFloor:'floor5.png',currentHQ:'',currentFloorName:'5/F',sensorData:[]})
        
    }        
    else if(val=='4/F'){
        ////this.refs.f4.className = 'spanclass';
        this.setState({currentFloor:'floor4.png',currentHQ:'',currentFloorName:'4/F',sensorData:[]})
        
    }        
    else if(val=='3/F'){
        //this.refs.f3.className = 'spanclass';
        this.setState({currentFloor:'floor3.png',currentHQ:'',currentFloorName:'3/F',sensorData:[]})
        
    }        
    else if(val=='2/F'){
        //this.refs.f2.className = 'spanclass';
        this.setState({currentFloor:'floor2.png',currentHQ:'',currentFloorName:'2/F',sensorData:[]})
        
    }        
    else if(val=='1/F'){
        //this.refs.f1.className = 'spanclass';
        this.setState({currentFloor:'floor1.png',currentHQ:'',currentFloorName:'1/F',sensorData:[]})
        
    }        
    else if(val=='G/F'){
        //this.refs.f0.className = 'spanclass';
        this.setState({currentFloor:'floorG.png',currentHQ:'',currentFloorName:'G/F',sensorData:[]})
        
    }else if(val=='M/F'){
      //this.refs.f0.className = 'spanclass';
      this.setState({currentFloor:'floorM.png',currentHQ:'',currentFloorName:'M/F',sensorData:[]})
      
  }  

  }

  calculateLeft=(left)=>{
    return Math.floor(((left/2.8371)-20));
  }

  calculateTop=(top)=>{
    return Math.floor(((top/2.8477)-30));
  }

  calculateLeft1=(left)=>{
    return Math.floor(((left/2.8371)-4));
  }

  calculateTop1=(top)=>{
    return Math.floor(((top/2.8477)-6));
  }

  rendorPins=()=>{
    var rtn = [];
    this.state.sensorData.map(sd => rtn.push(sd.type== 'carpark'?<div key={1}>
    {sd.IsOccupied == true?<img src={greenp} style={{position:"absolute",top:sd.top,left:sd.left,width:12,height:12}}></img>
    :<img src={redp} style={{position:"absolute",top:sd.top,left:sd.left,width:12,height:12}}></img>}</div>
    :<div key={1}>
    {sd.IsOccupied == true?<img src={greenpin} style={{position:"absolute",top:sd.top,left:sd.left,width:40,height:40}}></img>
    :<img src={redpin} style={{position:"absolute",top:sd.top,left:sd.left,width:40,height:40}}></img>}
    <div className={sd.IsOccupied == true?'arrow-green':'arrow-red'} style={{position:"absolute",top:sd.top+9,left:sd.left+35,zIndex:5,}}></div>
    <div style={{position:"absolute",top:sd.top+4,left:sd.left+42,background:sd.color,color:'white',textAlign:'center', borderRadius:3,zIndex:5, width:85,height:30}}>
        <span style={{height:30,fontSize:15,fontWeight:'bold'}}>{sd.roomname}</span>
        <div style={{background:'#ededed',height:100,width:'100%',marginTop:4,}}>
            <img src={temp} width={35} height={25} style={{position:'absolute',top:40,left:0}}></img>
            <span style={{fontSize:15,fontWeight:'bold',color:'gray',position:'absolute',top:40,left:30}}>{sd.temperature}</span>
            <img src={humi} width={25} height={25} style={{position:'absolute',top:80,left:5}}></img>
            <span style={{fontSize:15,fontWeight:'bold',color:'gray',position:'absolute',top:80,left:30}}>{sd.humidity}</span>
        </div>
  </div></div>))
    
  return rtn;
}

  render() {
    return (
        <div>
            <Button onClick={this.props.backbtn} style={{position:'absolute',left:20,top:60}}>{strings.back}</Button>
            <div style={{width:1320, position:'relative', display:'inline-block',background:'#ececec'}}>
                <Row>                    
                    <Col span={23}>
                    <img src={floorPlan_URL+''+this.state.currentFloor+'?v='+this.state.currentDate} style={{height:440,width:1320,background:'white'}} alt='Floor'></img>                        
                        {this.rendorPins()}
                    </Col>
                </Row>                    
            </div>
            <Modal
                title={strings.alert}
                visible={this.state.showalertmodel}
                onCancel={()=>this.setState({showalertmodel:false})}
                footer={[                  
                    <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerReload)window.location.reload();}}>
                    {strings.ok}
                    </Button>,
                ]}
                >                    
                    <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
            </Modal>
        </div>
    );
  }
}

export default FloorMap;
