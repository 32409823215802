import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col, Table, Select, Calendar, Tag, Radio, Button, DatePicker, Spin, Popconfirm, InputNumber, Modal, message, } from "antd";
import './App.css';
import moment from 'moment';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES } from './data';
const Option = Select.Option;

const locale = 'EN';
const timelines = ['8AM', '8:15AM', '8:30AM', '8:45AM',
    '9AM', '9:15AM', '9:30AM', '9:45AM', '10AM', '10:15AM', '10:30AM', '10:45AM', '11AM', '11:15AM', '11:30AM', '11:45AM',
    '12PM', '12:15PM', '12:30PM', '12:45PM', '1PM', '1:15PM', '1:30PM', '1:45PM', '2PM', '2:15PM', '2:30PM', '2:45PM',
    '3PM', '3:15PM', '3:30PM', '3:45PM', '4PM', '4:15PM', '4:30PM', '4:45PM', '5PM', '5:15PM', '5:30PM', '5:45PM',
    '6PM', '6:15PM', '6:30PM', '6:45PM', '7PM', '7:15PM', '7:30PM', '7:45PM', '8PM', '8:15PM', '8:30PM', '8:45PM',
    '9PM'];
const thds = ['8am-9am', '9am-10am', '10am-11am', '11am-12pm', '12pm-1pm', '1pm-2pm', '2pm-3pm', '3pm-4pm', '4pm-5pm', '5pm-6pm', '6pm-7pm', '7pm-8pm', '8pm-9pm'];
class Visitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemData: null,
            resource: null,
            loading: false,
            showalertmodel: false,
            alertmessage: "",
            type: 0,
            vquota: 0,
            oquota: 0,
            tabledata: [],
            trs: [],
            dateValue: moment().format('YYYY-MM-DD'),
            rooms: [],
            vrids: [],
            trids: [],
            sdDate:null,
            vquota1:0,
            oquota1:0,
            sddateerr:false,
            typeerr:false,
            vquotaerr:false,
            oquotaerr:false,
            specificactive:null,
            defaultactive:null,
            specificcurrent:null,
            defaultcurrent:null
        }

        this.columns = [{
            title: strings.effectivedate,
            dataIndex: 'effectiveDate',
            key: 'effectiveDate',
            width: '20%',
        },{
            title: strings.type,
            dataIndex: 'type',
            key: 'type',
            width: '20%',
            render: (a, b) => a === 1 ? strings.default : strings.specificdate,
        },{
            title: strings.visitorcarparkquota,
            dataIndex: 'visitorQuota',
            key: 'visitorQuota',
            width: '20%',
        },{
            title: strings.operationalcarparkquota,
            dataIndex: 'operationalQuota',
            key: 'operationalQuota',
            width: '20%',
        },{
            title: strings.submitteddate,
            dataIndex: 'updateTime',
            key: 'updateTime',
            width: '20%',
            render: (a, b) => moment(a?a:b.createTime).format('YYYY-MM-DD HH:mm:ss A'),
        }];
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber', 14);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        var resource = sd.resource;
        var rooms = [], vrids = [], trids = [];
        for (var i = 0; i < resource.length; i++) {
            if (RESOURCE_TYPES.VISITOR_CARPARK === resource[i].resourceType)
                vrids.push(resource[i].id);
            else if (RESOURCE_TYPES.OPERATIONAL_CARPARK === resource[i].resourceType)
                trids.push(resource[i].id);
        }
        rooms.push({ name: strings.visitor, id: 1 });
        rooms.push({ name: strings.operational, id: 2 });
        this.setState({ rooms: rooms, vrids: vrids, trids: trids, resource: resource, systemData: sd });
        this.getupperpartdata(this.state.dateValue);
        this.getlowerpartdata(null);
    }

    componentWillUnmount() {

    }   

    addVisitorParking = () =>{        
        this.setState({ loading: true })
        var rdata = {};
        var iserr = false;
        if( this.state.type === null || this.state.type === 0) {
            this.setState({typeerr:true});
            iserr = true;
        }
        if( !this.state.sdDate) {
            this.setState({sddateerr:true});
            iserr = true;
        }

        if(this.state.vquota<0) {
            this.setState({vquotaerr:true});
            iserr = true;
        }

        if( this.state.oquota<0) {
            this.setState({oquotaerr:true});
            iserr = true;
        }

        if(iserr){
            return;
        }

        rdata.type = this.state.type;
        rdata.visitorQuota = this.state.vquota;
        rdata.operationalQuota = this.state.oquota
        rdata.effectiveDate = this.state.sdDate.unix()*1000;
        fetch(apiurl + 'resource/updatevisitorparking', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                this.setState({ alertmessage: strings.submittedsuccess, showalertmodel: true, })
                this.clearScrren();
                this.getupperpartdata(this.state.dateValue);
                this.getlowerpartdata(null);
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
            }
            this.setState({ loading: false })
        })
        .catch(e => {
            console.warn(e);
            this.setState({ loading: false });
        })
    }

    getupperpartdata = (date) => {
        this.setState({ loading: true })
        var rdata = {};
        rdata.date = moment(date).unix()*1000;
        fetch(apiurl + 'resource/currentvisitorparking', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                var vbs = data.data.vbs;
                var tbs = data.data.tbs;
                var trs = [];
                var tds = [], tds1 = [];
                var datestr = this.state.dateValue + ' 00:00:00';
                var ts = moment(datestr, "YYYY-MM-DD HH:mm:SS").valueOf();
                ts = (ts / 1000) + 28800;
                for (var i = 0; i < timelines.length; i++) {
                    if (i + 1 === timelines.length) {
                        break;
                    }

                    var vcount = 0, ocount = 0;
                    var st = ts + (i * 900);
                    var et = ts + ((i + 1) * 900);

                    for (var b = 0; b < vbs.length; b++) {
                        var sd = vbs[b].startTime / 1000;
                        var ed = vbs[b].groupEndTime ? (vbs[b].groupEndTime/1000) : (vbs[b].endTime/1000);
                        if (sd < et && ed > st) {
                            vcount = vcount + 1;
                        }
                    }
                    tds.push({ id: i, count: vcount===0?'':vcount });

                    for (var b = 0; b < tbs.length; b++) {
                        var sd = tbs[b].startTime / 1000;
                        var ed = tbs[b].groupEndTime ? (tbs[b].groupEndTime/1000) : (tbs[b].endTime/1000);
                        if (sd < et && ed > st) {
                            ocount = ocount + 1;
                        }
                    }
                    tds1.push({ id: i, count: ocount===0?'':ocount });
                }
                trs.push({ id: 1, tds: tds });
                trs.push({ id: 2, tds: tds1 });
                var specificactive = data.data.active1;
                var defaultactive = data.data.active2;
                var specificcurrent = data.data.current1;
                var defaultcurrent = data.data.current2;
                this.setState({ specificactive: specificactive, specificcurrent:specificcurrent, defaultcurrent:defaultcurrent, defaultactive:defaultactive, trs: trs})
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
            }
            this.setState({ loading: false })
        })
        .catch(e => {
            console.warn(e);
            this.setState({ loading: false });
        })
    }

    getlowerpartdata = (date) => {
        this.setState({ loading: true })
        var rdata = {};
        if(this.state.type !== 0)
            rdata.type = this.state.type;
        if(date !== null)
            rdata.date = date.unix()*1000;
        fetch(apiurl + 'resource/currentvisitorparking1', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                var quotas = data.data.quotas; 
                if(data.data.active){
                    this.setState({vquota:data.data.active.visitorQuota, oquota: data.data.active.operationalQuota})
                }        
                this.setState({ tabledata: quotas})
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
            }
            this.setState({ loading: false })
        })
        .catch(e => {
            console.warn(e);
            this.setState({ loading: false });
        })
    }


    clearScrren = () => {        
        this.setState({vquota: 0, oquota: 0, type: 0, sdDate:null, typeerr:false, sddateerr:false, vquotaerr:false, oquotaerr:false })
    }

    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
    }

    fetchSystemData = () => {
        fetch(apiurl + 'system/list', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                this.setState({ loading: false })
                if (data.code == "1") {
                    sessionStorage.setItem('systemdata', JSON.stringify(data.data));
                    this.setState({ alertmessage: strings.updatesuccess, showalertmodel: true })
                } else {
                    this.setState({ alertmessage: strings.errorcode + '' + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => {
                console.warn(e);
                this.setState({ loading: false });
            })
    }

    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
    }

    onSelect = (value) => {
        this.setState({ dateValue: value.format('YYYY-MM-DD') });
        this.getupperpartdata(value.format('YYYY-MM-DD'))
    }

    renderTr = () => {
        var rds = [];
        this.state.trs.map((d) => {
            rds.push(<tr key={d.id} className='tablerow'>{this.renderTd(d.tds)}</tr>);
        });
        return rds;
    }

    renderTd = (tds) => {
        var rds = [];
        tds.map((d) => {
            rds.push(<td key={d.id} style={{ textAlign: 'center' }}>{d.count}</td>);
        });
        return rds;
    }

    renderHeaderTr = () => {
        var rds = [];
        rds.push(<tr key='999' >{this.renderheaderTd()}</tr>);
        return rds;
    }

    renderheaderTd = () => {
        var rhds = [];
        thds.map((d) => {
            rhds.push(<td key={d.id} className='tablerow2td'>{d}</td>);
        });
        return rhds;
    }

    renderRespurce = () => {
        var res = [];
        res.push(<tr key='999' className='tablerow1'><td className='roomnamecol' style={{ textAlign: 'center' }}>{strings.type}</td></tr>);
        this.state.rooms.map((d) => {
            res.push(<tr key={d.id} className='tablerow1' ><td>{d.name}</td></tr>);
        })
        return res;
    }

    onDateChange=(value)=>{
        this.setState({sdDate:value,vquota:0,oquota:0})
        if(value)
            this.getlowerpartdata(value)
    }

    onTypeChange=(value)=>{
        this.setState({type:value,sdDate:null,vquota:0,oquota:0})
        this.getlowerpartdata(null)
    }


    render() {
        const { vquota = 0, oquota = 0, specificactive = null, specificcurrent= null, defaultcurrent= null, defaultactive= null, } = this.state;
        return (
            <div className='Tab2Div'>
                <div>
                    <Row>
                        <Col span={4} className='bookingcalanderdiv'>
                            <div style={{ backgroundColor: '#fff', borderRadius: 4 }}>
                                <Calendar fullscreen={false} onSelect={this.onSelect} onChange={this.onSelect} value={moment(this.state.dateValue)} />
                            </div>
                        </Col>
                        <Col span={20} className='bookinginfodiv' style={{ padding: '0px 20px' }}>
                            <div style={{ marginLeft: 61 }}>
                                <span className="TitleOfTheBox">{this.state.dateValue}</span>
                            </div>
                            <div>
                                <Row xs={24}>
                                    <Col xs={1} className='roomtablecol'>
                                        <div style={{ marginLeft: 12, marginTop: 0, backgroundColor: '#fff', }}>
                                            <table>
                                                <tbody>
                                                    {this.renderRespurce()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                    <Col xs={22} className='timeslot-maindiv'>

                                        <table>
                                            <tbody>
                                                {this.renderHeaderTr()}
                                            </tbody>
                                        </table>
                                        <table style={{ marginTop: -1 }}>
                                            <tbody>
                                                {this.renderTr()}
                                            </tbody>
                                        </table>

                                    </Col>
                                </Row>                                
                            </div>
                            <div style={{ marginLeft: 61, marginTop:5 }}><span className="LongText2" style={{marginLeft:0}}>{strings.quotaremark}</span></div>
                            <Row style={{ marginLeft: 61, marginTop:20 }}>
                                <Col span={8}>
                                    <div className="FirstDiv" style={{ width: 450 }}>
                                        <Row>
                                            <Col span={12}></Col>
                                            <Col span={6} className="DateText" style={{textAlign:'center'}}>{strings.default}</Col>
                                            <Col span={6} className="DateText" style={{textAlign:'center'}}>{strings.specific}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}><span className="DateText">{strings.visitorcarparkquota} ({this.state.dateValue})</span></Col>
                                            <Col span={6} style={{textAlign:'center'}}><span className="DateText" style={{color:defaultactive && !specificactive?'red':''}}>{defaultactive?defaultactive.visitorQuota + (defaultactive && !specificactive?' ('+strings.effective+')':''):'N/A'}</span></Col>
                                            <Col span={6} style={{textAlign:'center'}}><span className="DateText" style={{color:specificactive?'red':''}}>{specificactive?specificactive.visitorQuota + (specificactive?' ('+strings.effective+')':''):'N/A'}</span></Col>
                                        </Row>
                                        <Row style={{ marginTop: 5 }}>
                                            <Col span={12}><span className="DateText">{strings.operationalcarparkquota} ({this.state.dateValue})</span></Col>
                                            <Col span={6} style={{textAlign:'center'}}><span className="DateText" style={{color:defaultactive && !specificactive?'red':''}}>{defaultactive?defaultactive.operationalQuota + (defaultactive && !specificactive?' ('+strings.effective+')':''):'N/A'}</span></Col>
                                            <Col span={6} style={{textAlign:'center'}}><span className="DateText" style={{color:specificactive?'red':''}}>{specificactive?specificactive.operationalQuota + (specificactive?' ('+strings.effective+')':''):'N/A'}</span></Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="FirstDiv" style={{ width: 450, background:'#FFF9C5' }}>
                                        <Row>
                                            <Col span={12}></Col>
                                            <Col span={6} className="DateText" style={{textAlign:'center'}}>{strings.default}</Col>
                                            <Col span={6} className="DateText" style={{textAlign:'center'}}>{strings.specific}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}><span className="DateText">{strings.visitorcarparkquota} ({strings.today})</span></Col>
                                            <Col span={6} style={{textAlign:'center'}}><span className="DateText" style={{color:defaultcurrent && !specificcurrent?'red':''}}>{defaultcurrent?defaultcurrent.visitorQuota + (defaultcurrent && !specificcurrent?' ('+strings.effective+')':''):'N/A'}</span></Col>
                                            <Col span={6} style={{textAlign:'center'}}><span className="DateText" style={{color:specificcurrent?'red':''}}>{specificcurrent?specificcurrent.visitorQuota + (specificcurrent?' ('+strings.effective+')':''):'N/A'}</span></Col>
                                        </Row>
                                        <Row style={{ marginTop: 5 }}>
                                            <Col span={12}><span className="DateText">{strings.operationalcarparkquota} ({strings.today})</span></Col>
                                            <Col span={6} style={{textAlign:'center'}}><span className="DateText" style={{color:defaultcurrent && !specificcurrent?'red':''}}>{defaultcurrent?defaultcurrent.operationalQuota + (defaultcurrent && !specificcurrent?' ('+strings.effective+')':''):'N/A'}</span></Col>
                                            <Col span={6} style={{textAlign:'center'}}><span className="DateText" style={{color:specificcurrent?'red':''}}>{specificcurrent?specificcurrent.operationalQuota + (specificcurrent?' ('+strings.effective+')':''):'N/A'}</span></Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            
                            
                        </Col>
                    </Row>
                </div>
                <div style={{ marginTop: 20 }} className="FirstDiv">
                    <div>
                        <div>
                            <div className="table-operations">
                                <div>
                                    <span className="TitleOfTheBox">{strings.amendquota}</span>
                                </div>
                                <Row>                                    
                                    <Col span={5}>
                                        <Row>
                                            <Col span={8}>
                                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                                <span style={{ marginLeft: 12 }} className={this.state.typeerr ? "DateTextErr" : "DateText"}>{strings.type}</span>
                                            </Col>
                                            <Col span={16}>
                                                <Select size="large" style={{ width: 180 }} className='bookingDatebox' value={this.state.type} onChange={e => this.onTypeChange(e)}>
                                                    <Option value={0}>{strings.pleaseselect}</Option>
                                                    <Option value={1}>{strings.default}</Option>
                                                    <Option value={2}>{strings.specificdate}</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={5}>
                                        <Row>
                                            <Col span={8}>
                                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                                <span style={{ marginLeft: 12 }} className={this.state.sddateerr ? "DateTextErr" : "DateText"}>{strings.effectivedate}</span>
                                            </Col>
                                            <Col span={16}>
                                                <DatePicker disabledDate={d => this.state.type===1?d.isBefore(moment().add(90,'day').format('YYYY-MM-DD')):d.isSameOrBefore(moment().format('YYYY-MM-DD')) } placeholder={strings.selectdate} style={{width: 180}} value={this.state.sdDate} onChange={(e)=>this.onDateChange(e)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={5}>
                                        <Row>
                                            <Col span={8}>
                                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                                <span style={{ marginLeft: 12 }} className={this.state.vquotaerr ? "DateTextErr" : "DateText"}>{strings.visitorcarparkquota}</span>
                                            </Col>
                                            <Col span={16}>
                                                <InputNumber style={{ width: 180 }} min={0} max={50} value={vquota} placeholder={strings.pleasefill+' '+strings.visitorcarparkquota} onChange={e => this.setState({ vquota: e })} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row>
                                            <Col span={8}>
                                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                                <span style={{ marginLeft: 12 }} className={this.state.oquotaerr ? "DateTextErr" : "DateText"}>{strings.operationalcarparkquota}</span>
                                            </Col>
                                            <Col span={16}>
                                                <InputNumber style={{ width: 180 }} min={0} max={50} value={oquota} placeholder={strings.pleasefill+' '+strings.operationalcarparkquota} onChange={e => this.setState({ oquota: e })} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={2}>
                                        <Button type="primary" size='large' style={{ marginRight: 10 }} onClick={this.addVisitorParking} >
                                            {strings.submit}
                                        </Button>
                                        <Button size='large' onClick={this.clearScrren} >
                                            {strings.clear}
                                        </Button>
                                    </Col>
                                </Row>
                                <div style={{ marginTop: 12 }}>
                                    <span className="TitleOfTheBox">{strings.amendhistory}</span>
                                </div>
                                <Table style={{ marginTop: 12, backgroundColor: '#fff' }} columns={this.columns} pagination={{ pageSize: 4 }} dataSource={this.state.tabledata} />
                                <div className="CountOfData">
                                    <span className="CountText">{strings.totalcount} {this.state.tabledata.length}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={() => this.setState({ showalertmodel: false })}
                    footer={[
                        <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); }}>
                            {strings.ok}
                        </Button>,
                    ]}
                >
                    <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                    <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                </Modal>
            </div>
        );
    }
}
export default Visitor;

