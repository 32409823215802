import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Row, Col, Table, Select, Tag, Button, Radio, Modal, Popconfirm, Input, DatePicker, message, Empty, Spin } from "antd";
import './App.css';
import moment from 'moment';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES, TYPES, SIZES, ZONES, DIVISIONS } from './data';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import { Tooltip } from 'recharts';
import { Legend } from 'recharts';
import Workbook from 'react-excel-workbook';

const Option = Select.Option;
const locale = 'EN';
const COLORS = ['#8884d8', '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#83a6ed', '#FF00FF'];
class DataLock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemData: null,
            resource: null,
            loading: false,
            showalertmodel: false,
            alertmessage: "",
            fromdate: moment(),
            locks:[]
        }
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber', 5);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({ resource: sd.resource, systemData: sd });     
        this.getLock();
    }

    componentWillUnmount() {

    }

    getLock = () => {        
        this.setState({ loading: true })
        fetch(apiurl + 'resource/getLock', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                this.setState({ locks: data.data.locks});        
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
            }
            this.setState({ loading: false })
        })
        .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    saveLock = (type,id) => {
        var rdata = {};
        if(type === 1){
            if (this.state.fromdate !== null) {                              
                rdata.date = this.state.fromdate.valueOf();                           
            } else {
                message.warning(strings.plsfillalletc);
                return;
            }
            rdata.isRemove = 0;
        }else{
            rdata.isRemove = 1;
            rdata.lockId = id;
        }
        
        this.setState({ loading: true })
        fetch(apiurl + 'resource/saveLock', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    this.getLock();
                    this.setState({ alertmessage: strings.submittedsuccess, showalertmodel: true })
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    onfromChange = (val) => {
        this.setState({ fromdate: val })
    }

    clearScrren=()=>{
        this.setState({fromdate: moment()});
    }
    

    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
    }

    render() {

        const columns=[{
            title: strings.user,
            dataIndex: 'username',
            key: 'username',
            width: '20%',
        }, {
            title: strings.lock,
            dataIndex: 'lockTime',
            key: 'lockTime',
            width: '30%',
            render: (a, b) => (moment(a).format('YYYY-MM-DD HH'))
        }, {
            title: strings.state,
            dataIndex: 'state',
            key: 'state',
            width: '20%',
            render: (a, b) => (a === 'A'?strings.active:strings.inactive),
            sorter: (a, b) => a.state.localeCompare(b.state, 'en', { numeric: true }),
        },{
            title: strings.action,
            key: 'id',
            dataIndex: 'id',
            render: (id, record) => (               
                    record.state === 'A'?<Popconfirm title="Are you sure?" okText="Confirm" cancelText="Cancel" onConfirm={() => this.saveLock(2,id)}>
                        <Tag color={'#455773'} style={{ marginTop: 5 }}>{strings.remove}</Tag>
                    </Popconfirm>:''
            ),
        }]

        return (
            <div>
                <div className="FirstDiv" style={{ marginTop: 10 }}>
                    <span className="TitleOfTheBox">{strings.lock}</span>
                    <Row xs={24} >
                        <Col md={8}>
                            <div>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="placeholder">{strings.date}</span>
                                <div style={{ marginBottom: 16 }}>
                                    <DatePicker className="InputSizeOfThird" showTime={{ format: 'HH' }} format={'YYYY-MM-DD HH'} style={{ width: 360 }} value={this.state.fromdate} onChange={this.onfromChange} />
                                </div>
                            </div>
                        </Col>
                    </Row>                    
                    <Button type="primary" size='large' style={{ marginRight: 17, marginTop: 10 }} loading={this.state.loading} onClick={()=>this.saveLock(1)}>
                        {strings.submit}
                    </Button>
                    <Button size='large' style={{ marginRight: 20, marginTop: 10 }} onClick={this.clearScrren}>
                        {strings.reset}
                    </Button>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Spin spinning={this.state.loading}> 
                        <Table columns={columns} dataSource={this.state.locks} pagination={{pageSize:10}}/>
                    </Spin>
                </div>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={() => this.setState({ showalertmodel: false })}
                    footer={[
                        <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerReload) window.location.reload(); }}>
                            {strings.ok}
                        </Button>,
                    ]}
                >
                    <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                    <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                </Modal>
            </div>
        );
    }
}
export default DataLock;

