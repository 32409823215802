import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Popconfirm, Row, Col,Table,Select,Tag,Icon,Button,Upload, Modal, message} from "antd";
import './App.css';
import moment from 'moment';
import {ApiHeader,strings,apiurl,RESOURCE_TYPES} from './data';

const locale = 'EN';

class Dtd extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            systemData:null,
            resource:null,
            loading:false,
            fileList:[],
            users:[],
            triggerReload:false,
            showalertmodel:false,
            alertmessage:"",
        }
        this.columns = [
            {
                title: strings.name,
                dataIndex: 'name',
                key: 'name',
                width:'10%',                
                sorter: (a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }),
            },{
                title: strings.posttitle,
                dataIndex: 'post',
                key: 'post',
                width:'20%',
                sorter: (a, b) => a.post.localeCompare(b.post, 'en', { numeric: true }),
            },{
                title: strings.email,
                dataIndex: 'email',
                key: 'email',
                width:'20%',
                sorter: (a, b) => a.email.localeCompare(b.email, 'en', { numeric: true }),
            },{
                title: strings.contactphone,
                dataIndex: 'phone',
                key: 'phone',
                width:'20%',
                sorter: (a, b) => a.phone.localeCompare(b.phone, 'en', { numeric: true }),
            }
        ];
    }

    

    componentDidMount() {
        sessionStorage.setItem('tabNumber',21);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.fetchdtdusers();
    }
  
    componentWillUnmount() {
  
    }

    fetchdtdusers=()=>{
        this.setState({loading:true})        
        fetch(apiurl+'user/listdtduser', {
            headers: ApiHeader,
            method: 'GET', 
            credentials: 'same-origin',       
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                                        
                    this.setState({users:data.data.user})            
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    handleUpload = () => {
        const { fileList } = this.state;
        var fname = fileList[0].name;
        var fs = fname.split('.');
        if(fs[fs.length-1] != 'xlsx'){
          message.warning('Please Select valid excel file')
          return;
        }    
        this.setState({loading:true});         
        var formData = new FormData();
        formData.append('file', fileList[0]);
        fetch(apiurl+'user/uploadDtdUsers', {
            headers: {
                'token':"srswebapp",
                'userId':sessionStorage.getItem("userId")
            },
            method: 'POST',
            processData: false,
            credentials: 'same-origin',
            body: formData,      
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    
                    this.setState({fileList:[],loading:false});  
                    this.fetchdtdusers();          
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }
    
    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
    }

    downloadfile=()=>{           
        var link = document.createElement('a');
        link.href = "/srs_report/Dtd_users.xlsx";
        link.target = "_blank";
        link.download = "Dtd_users_"+(moment().format("YYYYMMDDhh:mm"))+".xlsx";
        link.click();
        document.removeChild(link);
    }

    downloadLogFile=()=>{
        var link = document.createElement('a');
        link.href = "/srs_report/Dtd_users_import_log.txt";
        link.target = "_blank";
        link.click();
        document.removeChild(link);
    }

    render() {

        const {loading,fileList} = this.state;
        const props = {
            onRemove: (file) => {
              this.setState((state) => {
                const index = state.fileList.indexOf(file);
                const newFileList = state.fileList.slice();
                newFileList.splice(index, 1);
                return {
                  fileList: newFileList,
                };
              });
            },
            beforeUpload: (file) => {
              this.setState({
                fileList: [file],
              });
              return false;
            },
            fileList,
        };
        
        return (
            <div className='Tab2Div'>
                <div style={{marginTop:20}}>                               
                    <div>
                        <div className="FirstDiv">
                            <Row span={24} >
                                <Col span={12}>
                                    <div style={{float:'left'}}>
                                        <Upload {...props}>
                                            <Button >
                                                <Icon type="upload" /> {strings.selectfile}
                                            </Button>
                                        </Upload>
                                    </div>
                                    
                                    <Button
                                        type="primary"
                                        onClick={this.handleUpload}
                                        disabled={fileList.length === 0}
                                        loading={loading}
                                        style={{marginLeft:15,display:'inline-block'}}
                                        >
                                        {strings.clicktoupload}
                                    </Button>
                                </Col>
                                <Col span={12}>                                    
                                    <Button
                                        type="primary"
                                        onClick={this.downloadLogFile}
                                        style={{marginLeft: 15,float:'right'}}
                                        >
                                        {strings.logdownload}
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={this.downloadfile}
                                        style={{marginLeft: 15,float:'right'}}
                                        >
                                        {strings.download}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <div className="table-operations">
                            </div>
                            <Table style={{marginTop:12,backgroundColor:'#fff'}} columns={this.columns} dataSource={this.state.users} />
                            <div className="CountOfData">
                                <span className="CountText">{strings.totalcount} {this.state.users.length}</span>
                            </div>        
                        </div>                        
                    </div>
                    <Modal
                        title={strings.alert}
                        visible={this.state.showalertmodel}
                        onCancel={()=>this.setState({showalertmodel:false})}
                        footer={[                  
                            <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerReload)window.location.reload();}}>
                            {strings.ok}
                            </Button>,
                        ]}
                        >
                            <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                            <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                    </Modal>
                </div>                               
            </div>
        );
    }
}    
export default Dtd;

