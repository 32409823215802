import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { DatePicker, Input, Row, Col, Calendar, Button, Modal, Select, message, Radio, Tooltip, Icon, Switch, InputNumber, Checkbox, Tag, Table, Form } from 'antd';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES } from './data';
import moment from 'moment';
import TableDragSelect from "react-table-drag-select";
import "react-table-drag-select/style.css";
import './App.css';
import './drag.css';
const timelines = ['8:30AM', '8:45AM',
  '9AM', '9:15AM', '9:30AM', '9:45AM', '10AM', '10:15AM', '10:30AM', '10:45AM', '11AM', '11:15AM', '11:30AM', '11:45AM',
  '12PM', '12:15PM', '12:30PM', '12:45PM', '1PM', '1:15PM', '1:30PM', '1:45PM', '2PM', '2:15PM', '2:30PM', '2:45PM',
  '3PM', '3:15PM', '3:30PM', '3:45PM', '4PM', '4:15PM', '4:30PM', '4:45PM', '5PM', '5:15PM', '5:30PM', '5:45PM',
  '6PM', '6:15PM', '6:30PM', '6:45PM', '7PM', '7:15PM', '7:30PM', '7:45PM', '8PM', '8:15PM', '8:30PM', '8:45PM',
  '9PM'];
const timelines1 = ['08:30', '08:45',
  '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
  '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45',
  '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45',
  '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45',
  '21:00'];
const locale = 'EN';
const Option = Select.Option;
const bookingStates = ['completed', 'rejected', 'manually_cancelled', 'admin_cancelled', 'system_checkout'];
class EditZoomBooking extends Component {
  constructor(props) {
    super(props);
    ApiHeader.userId = sessionStorage.getItem("userId");
    this.state = {
      userId: ApiHeader.userId,
      startTime: 0,
      endTime: 0,
      resourceId:0,
      dateValue: this.getCurrentDate(),
      cdatevalue: '',
      timeValue: null,
      contactPhone1: "",
      contactPerson1: "",
      salutation1:"",
      salutation2:"",
      contactPhone2: "",
      contactPerson2: "",
      remark: "",
      chairman: "",
      isReminder:false,
      resourceType: RESOURCE_TYPES.ZOOM_MEETING,
      subject: "",
      zoomAccounts: [],
      loading: false,
      selectedTime: '',
      systemData: null,
      loading3: false,
      subjecterr: false,
      cp1err: false,
      cph1err: false,
      chairmanerr: false,
      contectemailerr: false,
      timeerr:false,
      bid: 0,
      triggerBack: false,
      showalertmodel: false,
      alertmessage: "",
      showmodel: false,
      chairmanId: 0,
      issearching: false,
      searchstr: '',
      approvers: [],
      meetingId: '',
      meetingPassword: '',
      zoomMeetingStatus: '',
      JoinUrl: '',
      zoomAccountPassword: '',
      cells: [
        [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
          false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
          false, false, false, false, false, false, false, false, false, false, false, false, false],
      ],
      cellstemp: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false],
      isAdvanceConfig: false,
      settings: {},
      wsettings: {},
      resources: [],
      showhostpassword: false,
      showmeetingpassword: false,
      meetingtime: '',
      bookinguserid: 0,
      isCloud: false,
      isAdminSchedule: false,
      loading4: false,
      isRegistrantShowing: false,
      radiooption: 'a',
      registrantsUsers: [],
      registrantsUsersData: null,
      count: 0,
      bookingstate: '',
      dial_up_numbers: '',
      trs:[],
    }

    this.columns = [
      {
        title: strings.firstname,
        dataIndex: 'first_name',
        key: 'first_name',
        width: '20%',
        sorter: (a, b) => a.first_name.localeCompare(b.first_name, 'en', { numeric: true }),
      }, {
        title: strings.lastname,
        dataIndex: 'last_name',
        key: 'last_name',
        width: '20%',
        sorter: (a, b) => a.last_name.localeCompare(b.last_name, 'en', { numeric: true }),
      }, {
        title: strings.email,
        dataIndex: 'email',
        key: 'email',
        width: '25%',
        sorter: (a, b) => a.email.localeCompare(b.email, 'en', { numeric: true }),
      }, {
        title: strings.action,
        key: 'tags',
        dataIndex: 'tags',
        render: (tags, record) => (
          <span>
            {!bookingStates.includes(this.state.bookingstate) && this.state.radiooption == 'a' && <Tag color={'#455773'} style={{ marginTop: 5 }} onClick={() => this.approveUser(record, 1)}>{strings.approve}</Tag>}
            {!bookingStates.includes(this.state.bookingstate) && this.state.radiooption == 'a' && <Tag color={'#455773'} style={{ marginTop: 5 }} onClick={() => this.approveUser(record, 2)}>{strings.deny}</Tag>}
            {!bookingStates.includes(this.state.bookingstate) && this.state.radiooption == 'b' && <Tag color={'#455773'} style={{ marginTop: 5 }} onClick={() => this.approveUser(record, 3)}>{strings.cancel}</Tag>}
          </span>
        ),
      }
    ]
  }


  showAdvanceConfig = () => {
    this.setState({ isAdvanceConfig: !this.state.isAdvanceConfig })
  }

  componentDidMount() {
    var d = sessionStorage.getItem('systemdata');
    var sd = JSON.parse(d);

    this.setState({ zoomAccounts: sd.zoomAccounts, systemData: sd, isRegistrantShowing: this.props.isregistrants });
    var zoomAccounts = sd.zoomAccounts;
    var rooms = [];
    for(var i=0; i<zoomAccounts.length; i++){    
      if(zoomAccounts[i].resourceType === RESOURCE_TYPES.ZOOM_MEETING)        
        rooms.push({name:strings.getLanguage()=='en'?zoomAccounts[i].nameEN:zoomAccounts[i].nameZH,id:zoomAccounts[i].id,capacity:zoomAccounts[i].capacity,iswebinar:false,isprem:zoomAccounts[i].isCloud=='N'?true:false});
    }
    rooms.sort((a, b) => a.name.localeCompare(b.name)); 
    var rooms1 = [];
    for(var i=0; i<zoomAccounts.length; i++){    
      if(zoomAccounts[i].resourceType === RESOURCE_TYPES.ZOOM_WEBINAR)        
        rooms1.push({name:strings.getLanguage()=='en'?zoomAccounts[i].nameEN:zoomAccounts[i].nameZH,id:zoomAccounts[i].id,capacity:zoomAccounts[i].capacity,iswebinar:true,isprem:zoomAccounts[i].isCloud=='N'?true:false});
    }
    
    rooms1.sort((a, b) => a.name.localeCompare(b.name)); 
    rooms = rooms.concat(rooms1); 

    var b = this.props.sb;
    var isAdminSchedule = false;
    if (this.props.isAdminSchedule == true) {
      isAdminSchedule = true;
    }
    var dstr = b.date.split(" ")[0].split("-")[0] + "-" + b.date.split(" ")[0].split("-")[1] + "-" + b.date.split(" ")[0].split("-")[2];
    this.setState({
      rooms:rooms,
      isAdminSchedule: isAdminSchedule,
      contactPhone1: b.firstcontact.split('(')[1] != null ? b.firstcontact.split('(')[1].slice(0, -1) : '',
      contactPerson1: b.firstcontact.split('(')[0] != null ? b.firstcontact.split('(')[0] : '',
      salutation1: b.salutation1 != null ? b.salutation1:'',
      salutation2: b.salutation2 != null ? b.salutation2:'',
      contactPhone2: b.secondcontact.split('(')[1] != null ? b.secondcontact.split('(')[1].slice(0, -1) : '',
      contactPerson2: b.secondcontact.split('(')[0] != null ? b.secondcontact.split('(')[0] : '',
      remark: b.remark != null ? b.remark : '',
      chairman: b.chairman != null ? b.chairman : '',
      subject: b.subject != null ? b.subject : '',
      bid: b.id,
      bookingstate: b.state,
      resourceType: b.resource.resourceType,
      resourceId:b.resource.id,
      isCloud: b.resource.isCloud == 'Y' ? true : false,
      resources: [b.resource],
      meetingId: b.zoomMeetingId,
      meetingPassword: b.zoomMeetingPassword,
      JoinUrl: b.zoomJoinUrl,
      isReminder:b.isReminder==='Y'?true:false,
      zoomAccountPassword: b.zoomAccountPassword,
      zoomMeetingStatus: b.zoomMeetingStatus,
      settings: JSON.parse(b.setting),
      wsettings: JSON.parse(b.setting),
      meetingtime: b.date,
      dial_up_numbers: b.zoomDialinNo,
      bookinguserid: b.userId,
      selectedTime: b.date.split(" ")[1],
      startTime:b.startTime,
      endTime:b.endTime,
      cdatevalue: moment(dstr),
      dateValue: dstr
    })

    this.getresourcestatus(b.resource, b.id)
    var isstart = false;
    var times = b.date.split(" ")[1].split('-');
    var t1 = times[0];
    if (t1.startsWith('0')) {
      t1 = t1.slice(1);
    }
    t1 = t1.replace(':00', '');
    var t2 = times[1];
    if (t2.startsWith('0')) {
      t2 = t2.slice(1);
    }
    t2 = t2.replace(':00', '');
    for (var i = 0; i < timelines.length; i++) {

      if (timelines[i] === t1 || isstart == true) {
        if (timelines[i] === t2) {
          break;
        }
        var cells = this.state.cells;
        cells[0][i] = true;
        this.setState({ cells: cells, cellstemp: cells[0] });
        isstart = true;
      }
    }
    this.getBookingApprovals(b.id);
    if (this.state.resourceType === RESOURCE_TYPES.ZOOM_MEETING){
      this.getBookingForDay(moment(dstr).format('YYYYMMDD'),'all',rooms,b.resource.id);
    }else{
      this.getBookingForDay1(moment(dstr).format('YYYYMMDD'),'all',rooms,b.resource.id);
    }
  }

  componentWillUnmount() {

  }

  getresourcestatus = (r, id) => {
    var rdata = {};
    rdata.id = r.id;
    rdata.bid = id;
    fetch(apiurl + 'zoomaccount/detail', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          var resource = data.data.resource;
          var booking = data.data.booking;
          this.setState({ resources: [resource], zoomMeetingStatus: booking.zoomMeetingStatus });
        } else {
          this.setState({ alertmessage: strings.error + (data.error != null ? data.error : data.error_code), showalertmodel: true })
        }
        this.setState({ loading: false })
      })
      .catch(e => console.warn(e))
  }

  getBookingForDay=(dval,type,rooms,rid)=>{
    var rdata = {};   

    if(type == null){
      type= 'all';
    }

    if(rooms == null){
      rooms = this.state.rooms;
    }
    
    rdata.fromDate = dval;
    rdata.toDate = dval;
    rdata.state = ['booked','pending_accept','confirmed']
    rdata.resourceType = RESOURCE_TYPES.ZOOM_MEETING;
      
    fetch(apiurl+'booking/list', {
        headers: ApiHeader,
        method: 'POST', 
        credentials: 'same-origin',  
        body: JSON.stringify(rdata),         
      }).then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error('Something went wrong ...');
            }
          })
          .then(data =>{
            if(data.code == "1"){
              var bookings = [];
              if(data.data.bookings.length>0){
                bookings = data.data.bookings[0].booking;
              }
              this.rendertimelinebooking(bookings,rooms,rid)              
            }else{
              this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
            }
            this.setState({loading:false}) 
      })
      .catch(e => {
        this.setState({loading:true});
        console.warn(e)
      })
  }

  getBookingForDay1=(dval,bs,rooms,rid)=>{
    var rdata = {};
      
    rdata.fromDate = dval;
    rdata.toDate = dval;
    rdata.state = ['booked','pending_accept','confirmed']
    rdata.resourceType = RESOURCE_TYPES.ZOOM_WEBINAR;
      
    fetch(apiurl+'booking/list', {
        headers: ApiHeader,
        method: 'POST', 
        credentials: 'same-origin',  
        body: JSON.stringify(rdata),         
      }).then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error('Something went wrong ...');
            }
          })
          .then(data =>{
            if(data.code == "1"){
              var bookings = [];
              if(data.data.bookings.length>0){
                bookings = data.data.bookings[0].booking;
              }
              bookings = bookings.concat(bs);
              this.rendertimelinebooking(bookings,rooms,rid)
            }else{
              this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
            }
            this.setState({loading:false}) 
      })
      .catch(e => {
        this.setState({loading:true});
        console.warn(e)
      })
  }

  rendertimelinebooking=(bookings,rooms,rid)=>{
    if(rooms == null){
      rooms = this.state.rooms;
    }
    var trs = [];
    for(var i=0;i<rooms.length; i++){
      if(rooms[i].id === rid){
          var tr = {};
          tr.id = rooms[i].id;
          tr.rvalue = rooms[i].name.replace('Online ','') +"\n "+(rooms[i].isprem?strings.onprem:strings.publiccloud)+"\n("+strings.cap+rooms[i].capacity+")";
          var tds = [];
          var times = [];
          var bids = [];
          for(var b=0; b<bookings.length;b++){
              if(bookings[b].zoomAccountId == rooms[i].id){
                  bookings[b].room = rooms[i].name;
                  bookings[b].type = rooms[i].iswebinar?strings.zoomwebinar:strings.zoommeeting;
                  var ds = moment.unix((bookings[b].startTime/1000)).format("hh:mmA");
                  if(ds.startsWith('0')){
                      ds = ds.slice( 1 );
                  }
                  ds = ds.replace(':00','');
                  times.push(ds);
                  bids.push(bookings[b].id);
                  var st = bookings[b].startTime/1000;
                  var et = bookings[b].endTime/1000;
                  
                  while(st<(et-(15*60))){
                      st = st + (15*60);
                      var ds1 = moment.unix((st)).format("hh:mmA");
                      if(ds1.startsWith('0')){
                          ds1 = ds1.slice( 1 );
                      }
                      ds1 = ds1.replace(':00','');
                      times.push(ds1);
                      bids.push(bookings[b].id);
                  }
              }
          }
          var isstart = false;
          for(var j=0;j<timelines.length-1;j++){
            var datestr = this.state.dateValue+' '+timelines1[j]
            var cdate = moment(datestr,'YYYY-MM-DD HH:mm');
            if(cdate.valueOf()<=moment().valueOf()){
              var td = {}
              td.disabled=true;
              td.isbooking=true;
              td.id=rooms[i].id+"-"+j+"-"+bids[index];
              td.timevalue=timelines[j];
              tds.push(td);
            }else{
              var index = times.indexOf(timelines[j]);
              if(index==-1){
                  var td = {};
                  td.disabled=false;
                  td.isbooking=true;
                  td.id=rooms[i].id+"-"+j+"-0";
                  td.timevalue=timelines[j];
                  tds.push(td);
              }else{
                  var td = {}
                  td.disabled=true;
                  td.isbooking=true;
                  td.id=rooms[i].id+"-"+j+"-"+bids[index];
                  td.timevalue=timelines[j];
                  tds.push(td);
              }   
            }                         
          }
          tr.tds = tds;
          trs.push(tr);      
        }                  
    }
    this.setState({trs:trs,booking:bookings})
  }


  getCurrentDate = () => {
    var d = new Date();
    return [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');
  }


  addBookmark = () => {

    this.setState({ loading3: true })
    var rdata = {};
    rdata.userId = this.state.userId;
    rdata.zoomAccountId = this.state.zoomAccounts[0].id;
    fetch(apiurl + 'bookmark/zoom/create', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          this.setState({ alertmessage: strings.bookmarkaddedsuccess, showalertmodel: true })
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading3: false })
      })
      .catch(e => console.warn(e))
  }

  modifyBooking = () => {
    this.setState({
      loading: true,
      timeerr: false,
      subjecterr: false,
      contectemailerr: false,
      cp1err: false,
      cph1err: false,
      chairmanerr: false
    });
    var rdata = {};
    var isvalid = true;
    if (this.state.startTime == 0 || this.state.endTime == 0) {
      this.setState({ timeerr: true });
      isvalid = false;
    } else {
      if (this.state.startTime <= new Date().getTime()) {
        this.setState({ alertmessage: strings.timeslorerr1, showalertmodel: true, loading: false })
        return;
      }
      if (sessionStorage.getItem("userrole") == 'user') {
        var newdate = new Date();
        newdate.setHours(23);
        newdate.setMinutes(59);
        newdate.setSeconds(59);
        newdate.setMilliseconds(999);
        if (this.state.startTime > newdate.getTime() + (1000 * 60 * 60 * 24 * 365 * 2) + (1000 * 60 * 60 * 24)) {
          this.setState({ alertmessage: strings.timeslorerr2, showalertmodel: true, loading: false })
          return;
        }
      }

      rdata.startTime = this.state.startTime;
      rdata.endTime = this.state.endTime;
    }

    if (this.state.contactPhone1 == "") {
      this.setState({ cph1err: true });
      isvalid = false;
    } else {

      if (this.state.contactPhone1.length <= 45) {
        rdata.contactPhone = this.state.contactPhone1;
      } else {
        message.warning(strings.contactphonemaxlength)
        isvalid = false;
      }
    }

    if (this.state.contactPerson1 == "") {
      this.setState({ cp1err: true });
      isvalid = false;
    } else {

      if (this.state.contactPerson1.length <= 100) {
        rdata.contactPerson = this.state.contactPerson1;
      } else {
        message.warning(strings.contactpersonmaxlength)
        isvalid = false;
      }
    }


    if (this.state.contactPhone2.length <= 45) {
      rdata.contactPhone1 = this.state.contactPhone2;
    } else {
      message.warning(strings.contactphonemaxlength)
      isvalid = false;
    }

    if (this.state.contactPerson2.length <= 100) {
      rdata.contactPerson1 = this.state.contactPerson2;
    } else {
      message.warning(strings.contactpersonmaxlength)
      isvalid = false;
    }

    if (this.state.remark.length <= 500) {
      rdata.remark = this.state.remark;
    } else {
      message.warning(strings.remarkmaxlength)
      isvalid = false;
    }

    if (this.state.subject == "") {
      this.setState({ subjecterr: true });
      isvalid = false;
    } else {
      if (this.state.subject.length <= 200) {
        rdata.subject = this.state.subject;
      } else {
        message.warning(strings.subjectmaxlength)
        isvalid = false;
      }
    }

    if (this.state.chairman == "") {
      this.setState({ chairmanerr: true });
      isvalid = false;
    } else {
      rdata.chairman = this.state.chairman;
    }

    if (this.state.chairmanId != 0)
      rdata.chairmanId = this.state.chairmanId;

    rdata.id = this.state.bid;
    rdata.isReminder = this.state.isReminder?'Y':'N';
    rdata.salutation = this.state.salutation1;
    rdata.salutation1 = this.state.salutation2;

    if (this.state.resourceType === RESOURCE_TYPES.ZOOM_MEETING)
      rdata.setting = JSON.stringify(this.state.settings);
    else {
      if (this.state.wsettings.contact_email == '' || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.wsettings.contact_email)) {
        rdata.setting = JSON.stringify(this.state.wsettings);
      } else {
        this.setState({ contectemailerr: true });
        isvalid = false;
      }
    }

    if (isvalid == false) {
      this.setState({ loading: false })
      return;
    }
    fetch(apiurl + 'zoom/booking/edit', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        this.setState({ loading: false })
        if (data.code == "1") {
          this.setState({ alertmessage: strings.updatesuccess, showalertmodel: true, triggerBack:true })
        } else {
          this.setState({ alertmessage: strings.error + (data.error != null ? data.error : data.error_code), showalertmodel: true })
        }
      })
      .catch(e => console.warn(e))
  }

  getBookingApprovals = (bid) => {
    this.setState({ loading4: true })
    var rdata = {};
    rdata.bookingId = bid;
    fetch(apiurl + 'zoom/getApprovalUsers', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        this.setState({ loading4: false })
        if (data.code == "1") {
          this.setState({ registrantsUsersData: data.data, registrantsUsers: data.data.pendingUsers.registrants, count: data.data.pendingUsers.registrants.length })
        } else {
          this.setState({ alertmessage: strings.error + (data.error != null ? data.error : data.error_code), showalertmodel: true })
        }
      })
      .catch(e => console.warn(e))
  }

  approveUser = (r, action) => {
    this.setState({ loading4: true })
    var rdata = {};
    rdata.bookingId = this.state.bid;
    rdata.action = (action == 1 ? 'approve' : action == 2 ? 'deny' : 'cancel');
    rdata.registrants = [r];
    fetch(apiurl + 'zoom/approveUser', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        this.setState({ loading4: false })
        if (data.code == "1") {
          var users = [];
          if (this.state.radiooption == 'a') {
            users = data.data.pendingUsers.registrants;
          } else if (this.state.radiooption == 'b') {
            users = data.data.approvedUsers.registrants;
          } else {
            users = data.data.declinedUsers.registrants;
          }
          this.setState({ registrantsUsersData: data.data, registrantsUsers: users, count: users.length })
        } else {
          this.setState({ alertmessage: strings.error + (data.error != null ? data.error : data.error_code), showalertmodel: true })
        }
      })
      .catch(e => console.warn(e))
  }


  clearScreen = () => {
    var b = this.props.sb;
    this.setState({
      contactPhone1: b.firstcontact.split('(')[1] != null ? b.firstcontact.split('(')[1].slice(0, -1) : '',
      contactPerson1: b.firstcontact.split('(')[0] != null ? b.firstcontact.split('(')[0] : '',
      salutation1: b.salutation1 != null ? b.salutation1:'',
      salutation2: b.salutation2 != null ? b.salutation2:'',
      contactPhone2: b.secondcontact.split('(')[1] != null ? b.secondcontact.split('(')[1].slice(0, -1) : '',
      contactPerson2: b.secondcontact.split('(')[0] != null ? b.secondcontact.split('(')[0] : '',
      remark: b.remark != null ? b.remark : '',
      chairman: b.chairman != null ? b.chairman : '',
      subject: b.subject != null ? b.subject : '',
      settings: JSON.parse(b.setting),
      wsettings: JSON.parse(b.setting),
      isReminder:b.isReminder==='Y'?true:false,
      startTime:b.startTime,
      endTime:b.endTime,
      timeerr:false,
      selectedTime: b.date.split(" ")[1],
      cdatevalue: moment(b.date.split(" ")[0].split("-")[0] + "-" + b.date.split(" ")[0].split("-")[1] + "-" + b.date.split(" ")[0].split("-")[2]),
      dateValue: b.date.split(" ")[0].split("-")[0] + "-" + b.date.split(" ")[0].split("-")[1] + "-" + b.date.split(" ")[0].split("-")[2]
    })

    var isstart = false;
    var times = b.date.split(" ")[1].split('-');
    var t1 = times[0];
    if (t1.startsWith('0')) {
      t1 = t1.slice(1);
    }
    t1 = t1.replace(':00', '');
    var t2 = times[1];
    if (t2.startsWith('0')) {
      t2 = t2.slice(1);
    }
    t2 = t2.replace(':00', '');
    var cells = this.state.cells;
    for (var i = 0; i < cells[0].length; i++) {
      cells[0][i] = false;
    }
    for (var i = 0; i < timelines.length; i++) {
      if (timelines[i] === t1 || isstart == true) {
        if (timelines[i] === t2) {
          break;
        }
        cells[0][i] = true;        
        isstart = true;
      }
    }
    this.setState({ cells: cells, cellstemp: cells[0] });
  }


  findErrorMessage = (msg) => {

    return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
  }

  searchapprover = () => {
    this.setState({ showmodel: true })
  }

  handleOk = () => {
    this.setState({ showmodel: false })
  }


  handleselectChange = (val) => {
    if (val != '0')
      this.setState({ chairmanId: parseInt(val.split('-')[0]), chairman: val.split('-')[1] })
  }

  copymeetingurl = () => {
    var input = document.createElement('textarea');
    var str = this.state.JoinUrl;
    input.innerHTML = str;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    message.success(strings.cipiedsuccess);
  }

  copyaccountemail = () => {
    var resources = this.state.resources;
    var input = document.createElement('textarea');
    var str = resources[0].accountEmail;
    input.innerHTML = str;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    message.success(strings.cipiedsuccess);
  }

  copyaccountpassword = () => {
    var input = document.createElement('textarea');
    var str = this.state.zoomAccountPassword;
    input.innerHTML = str;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    message.success(strings.cipiedsuccess);
  }

  copyinvitetext = () => {
    var input = document.createElement('textarea');

    var str = strings.topic + " " + this.state.subject + "\r\n";
    str += strings.time + ": " + this.state.meetingtime + "\r\n\r\n";
    str += strings.joinzoommeeting + "\r\n" + this.state.JoinUrl + "\r\n\r\n";
    str += (this.state.resourceType === RESOURCE_TYPES.ZOOM_WEBINAR ? strings.webinarid : strings.meetingid) + ": " + this.state.meetingId + "\r\n";
    str += strings.password + ": " + this.state.meetingPassword + "\r\n\r\n";

    if (this.state.dial_up_numbers != null && this.state.dial_up_numbers != '') {
      str += strings.joinbyvoiceonly + "\r\n";
      str += (strings.dialstring.replace('#dial_number#', this.state.dial_up_numbers)) + "\r\n";
      str += strings.afterdialedinstring + this.state.meetingId + '#,#,' + this.state.meetingPassword + '#';
    }


    input.innerHTML = str;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    message.success(strings.cipiedsuccess)
  }

  copyHostinfo = () => {
    var resources = this.state.resources;
    var input = document.createElement('textarea');
    var str = strings.hostemailaddress + ": " + resources[0].accountEmail + "\r\n" + strings.hostpassword + ": " + this.state.zoomAccountPassword;
    input.innerHTML = str;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    message.success(strings.cipiedsuccess);
  }

  handleSearch = (e) => {
    if (!this.state.issearching && this.state.searchstr.length > 1) {
      var val = this.state.searchstr;
      this.setState({ issearching: true });
      var rdata = {};
      rdata.name = val;
      fetch(apiurl + 'user/search1', {
        headers: ApiHeader,
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(rdata),
      }).then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
        .then(data => {
          if (data.code == "1") {
            var users = data.data.user;
            this.setState({ approvers: users, issearching: false });
          } else {
            this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true, issearching: false })
          }
          this.setState({ issearching: false })
        })
        .catch(e => { console.warn(e); this.setState({ issearching: false }) })
    } else {
      this.setState({ alertmessage: strings.enter3char, showalertmodel: true })
    }
  }

  rendertd = () => {
    var res = [];

    for (var i = 0; i < 50; i++) {
      if (i < 2) {
        res.push(<td key={i} className='dragselecttimeline1' ></td>);
      } else {
        res.push(<td key={i} className='dragselecttimeline' ></td>);
      }

    }
    return res;
  }

  onRadioChange = (e) => {
    if (e.target.value == 'a') {
      var users = this.state.registrantsUsersData == null ? [] : this.state.registrantsUsersData.pendingUsers == null ? [] : this.state.registrantsUsersData.pendingUsers.registrants;
      this.setState({ radiooption: 'a', registrantsUsers: users, count: users.length })
    } else if (e.target.value == 'b') {
      var users = this.state.registrantsUsersData == null ? [] : this.state.registrantsUsersData.approvedUsers == null ? [] : this.state.registrantsUsersData.approvedUsers.registrants;
      this.setState({ radiooption: 'b', registrantsUsers: users, count: users.length })
    } else {
      var users = this.state.registrantsUsersData == null ? [] : this.state.registrantsUsersData.declinedUsers == null ? [] : this.state.registrantsUsersData.declinedUsers.registrants;
      this.setState({ radiooption: 'c', registrantsUsers: users, count: users.length })
    }
  }

  onSelect = (value) => {
    this.setState({ cdatevalue: moment(value.format('YYYY-MM-DD')), dateValue:value.format('YYYY-MM-DD'), selectedTime: '' })
    this.handleTimelineChange(this.state.cells)
    if (this.state.resourceType === RESOURCE_TYPES.ZOOM_MEETING){
      this.getBookingForDay(value.format('YYYYMMDD'),'all',this.state.rooms,this.state.resourceId);
    }else{
      this.getBookingForDay1(value.format('YYYYMMDD'),'all',this.state.rooms,this.state.resourceId);
    }
  }

  handleTimelineChange = (cells) => {
    var celltemp = this.state.cellstemp;
    for (var i = 0; i < celltemp.length; i++) {
      if (celltemp[i] == true && cells[0][i] == true) {
        cells[0][i] = false;
      }
    }
    var times = [];
    var times1 = [];
    var index = 0;
    for (var i = 0; i < cells[0].length; i++) {
      if (cells[0][i] == true) {
        if (times.length == 0) {
          index = i;
          times.push(timelines[i]);
          times1.push((i * 900000) + 30600000);
        } else {
          times1.push(((i + 1) * 900000) + 30600000);
          times.push(timelines[i + 1]);
        }
      }
    }
    if (times.length == 1) {
      times.push(timelines[index + 1]);
      times1.push(((index + 1) * 900000) + 30600000);
    }
    this.setState({ cells: cells, cellstemp: cells[0], });
    var datestr = this.state.dateValue + ' 00:00:00';
    var ts = moment(datestr, "YYYY-MM-DD HH:mm:SS").valueOf();
    if (times[0] != null && times[times.length - 1] != null)
      this.setState({ selectedTime: times[0] + " - " + times[times.length - 1], startTime: parseInt(ts + times1[0]), endTime: parseInt(ts + times1[times1.length - 1]) });
    else
      this.setState({ selectedTime: '', startTime: '', endTime: '' });
  }

  onIsReminderChange=(e)=>{
    this.setState({isReminder:e.target.checked});
  }

  rendertd1=(tds)=>{
    var rds = [];
    for(var i=0; i<tds.length; i++){    
      var d = tds[i];
      if (i < 2) {
        rds.push(
          d.isbooking?
            d.disabled?
              <td key={d.id} className='dragselecttimeline1' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderBottom:'0px',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',backgroundColor: '#dedede'}}></td>
              :<td key={d.id} className='dragselecttimeline1'style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px'}}></td>:
            d.disabled?
              <td key={d.id} className='dragselecttimeline1' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderBottom:'0px',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',backgroundColor: '#CECAAE'}}></td>
              :<td key={d.id} className='dragselecttimeline1' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px'}} ></td>  
        );
      }else{
        rds.push(
          d.isbooking?
            d.disabled?
              <td key={d.id} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px',backgroundColor: '#dedede'}} ></td>
              :<td key={d.id} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px'}}></td>:
            d.disabled?
              <td key={d.id} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px',backgroundColor: '#CECAAE'}}></td>
              :<td key={d.id} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px'}}></td>  
        );
      }
    };
    return rds;
  }

  render() {

    var resources = this.state.resources;
    return (

      <div>
        <Row>
          <Col span={4} className='bookingcalanderdiv'>
            <div style={{ backgroundColor: '#fff', borderRadius: 4 }}>
              <Calendar fullscreen={false} value={this.state.cdatevalue} onSelect={this.onSelect}/>
            </div>
          </Col>
          <Col span={20} className='bookinginfodiv1' hidden={!this.state.isRegistrantShowing}>
            <Button size='default' style={{ marginRight: 15, float: 'right', fontSize: 14 }} onClick={() => this.setState({ isRegistrantShowing: false })}>
              {strings.back}
            </Button>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Radio.Group value={this.state.radiooption} buttonStyle="solid" onChange={this.onRadioChange}>
                <Radio.Button value="a">{strings.pending}</Radio.Button>
                <Radio.Button value="b">{strings.approved}</Radio.Button>
                <Radio.Button value="c">{strings.denied}</Radio.Button>
              </Radio.Group>
            </div>

            <Table style={{ padding: '0 15px', marginTop: 20 }} columns={this.columns} loading={this.state.loading4} pagination={{ pageSize: 15 }} dataSource={this.state.registrantsUsers} />
            <div className="CountOfData">
              <span className="CountText">{strings.totalcount} {this.state.count}</span>
            </div>
          </Col>
          <Col span={20} className='bookinginfodiv1' hidden={this.state.isRegistrantShowing}>
            <div className="RoomInformationDiv">
              <Row xs={24}>
                <Col xs={8} style={{ marginTop: 5 }}>
                  <img className="minusLogo" src={require(resources.length > 0 ? resources[0].status == 'waiting' ? './assets/green.png' : './assets/red.png' : './assets/grey.svg')} />
                  <span className="RoomName">{resources.length > 0 ? (locale == 'EN' ? resources[0].nameEN : resources[0].nameZH) : '--'}</span>
                  <Button hidden={!this.props.isEdit} className="subscribe" type="danger" loading={this.state.loading3} onClick={this.addBookmark}>
                    {strings.bookmark}
                  </Button>
                </Col>
                <Col xs={8} style={{ marginTop: 5 }}>
                  <span className="floorText2">{strings.zoomstatus}</span>
                  <span className="FloorNumber">{this.state.zoomMeetingStatus == 'waiting' ? strings.waiting : this.state.zoomMeetingStatus == 'started' ? strings.started : this.state.zoomMeetingStatus == 'cancelled' ? strings.cancelled : strings.finished}</span>
                </Col>
                <Col xs={8} style={{ marginTop: 5 }}>
                  <span className="floorText2">{strings.capacity}</span>
                  <span className="FloorNumber">{resources.length > 0 ? resources[0].capacity : '--'}</span>
                </Col>
              </Row>
            </div>

            <div className="RoomInformationDiv" style={{ marginTop: 10 }} hidden={(!this.state.isAdminSchedule && sessionStorage.getItem("userrole") == 'user' && sessionStorage.getItem("userId") !== this.state.bookinguserid + '')}>
              <Row xs={24} style={{ marginTop: 15 }}>
                <Col xs={12}>
                  <Row>
                    <Col xl={6} lg={8} md={12} sm={12} xs={12}>
                      <span className="meetinginfo" style={{ marginRight: 0, marginLeft: 20 }}>{this.state.resourceType === RESOURCE_TYPES.ZOOM_WEBINAR ? strings.webinarid : strings.meetingid}</span>
                    </Col>
                    <Col lg={12}>
                      <span className="meetinginfo" style={{ marginLeft: 20 }}>{this.state.meetingId}</span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <Col xl={6} lg={8} md={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                      <span className="meetinginfo" style={{ marginRight: 0, marginLeft: 20 }}>{strings.password}</span>
                    </Col>
                    <Col lg={12}>
                      <span className="meetinginfo" style={{ marginLeft: 20 }}>{this.state.showmeetingpassword ? this.state.meetingPassword : '******'}</span>
                      <Button className='subscribe1' type="danger" style={{ marginTop: 7, marginLeft: 25 }} onClick={() => this.setState({ showmeetingpassword: !this.state.showmeetingpassword })}>{this.state.showmeetingpassword ? strings.hide : strings.show}</Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} >
                  <Row>
                    <Col xl={6} lg={8} md={12} sm={12} xs={12}>
                      <span className="meetinginfo" style={{ marginLeft: 0, marginRight: 0 }}>{strings.hostemailaddress}</span>
                    </Col>
                    <Col xl={11} lg={11} md={10} sm={10} xs={10}>
                      <span className="meetinginfo" style={{ marginLeft: 20, marginRight: 0 }}>{resources.length > 0 ? resources[0].accountEmail : '--'}</span>
                    </Col>
                    <Col xl={5} lg={5} md={2} sm={2} xs={2}>
                      <Button className="subscribe1" hidden={this.state.zoomMeetingStatus == 'finished' || this.state.zoomMeetingStatus == 'cancelled'} onClick={this.copyaccountemail} style={{ marginLeft: 0 }}>
                        {strings.copy}
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <Col xl={6} lg={8} md={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                      <span className="meetinginfo" style={{ marginLeft: 0, marginRight: 0 }}>{strings.hostpassword}</span>
                    </Col>
                    <Col xl={11} lg={11} md={10} sm={10} xs={10}>
                      <span className="meetinginfo" style={{ marginLeft: 20, marginRight: 0 }}>{this.state.showhostpassword ? this.state.zoomAccountPassword : '********'}</span>
                      <Button className='subscribe1' type="danger" style={{ marginTop: 7, marginLeft: 25 }} onClick={() => this.setState({ showhostpassword: !this.state.showhostpassword })}>{this.state.showhostpassword ? strings.hide : strings.show}</Button>
                    </Col>
                    <Col xl={5} lg={5} md={2} sm={2} xs={2} style={{ marginTop: 12 }}>
                      <Button className="subscribe1" hidden={this.state.zoomMeetingStatus == 'finished' || this.state.zoomMeetingStatus == 'cancelled'} onClick={this.copyaccountpassword} style={{ marginLeft: 0 }}>
                        {strings.copy}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row xs={24} style={{ marginTop: 15 }}>
                <Col xs={12}>
                  <Row>
                    <Col xl={6} lg={7} md={12} sm={12} xs={12}>
                      <span className="meetinginfo" style={{ marginRight: 0, marginLeft: 20 }}>{strings.joinurl}</span>
                    </Col>
                    <Col xl={16} lg={15} md={11} sm={11} xs={11} style={{ marginLeft: 20 }}>
                      <span className="meetinginfo" style={{ marginLeft: 0 }}>{this.state.JoinUrl}</span>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} >
                  <span className="LongText1">{strings.passwordonlyvalidformeetingdate} {this.state.dateValue} {strings.only}.</span>
                </Col>
              </Row>
              <Row xs={24} style={{ marginTop: 15 }}>
                <Col xs={12}>
                  <Button className="subscribe1" hidden={this.state.zoomMeetingStatus == 'finished' || this.state.zoomMeetingStatus == 'cancelled'} onClick={this.copyinvitetext} style={{ marginLeft: 20 }}>
                    {strings.copyinvitetext}
                  </Button>
                  <Button className="subscribe1" hidden={this.state.zoomMeetingStatus == 'finished' || this.state.zoomMeetingStatus == 'cancelled'} onClick={this.copymeetingurl} style={{ marginLeft: 20 }}>
                    {strings.copyjoinurl}
                  </Button>
                </Col>
                <Col xs={12} >
                  <Button className="subscribe1" hidden={this.state.zoomMeetingStatus == 'finished' || this.state.zoomMeetingStatus == 'cancelled'} onClick={this.copyHostinfo} style={{ marginLeft: 0 }}>
                    {strings.copyhostaccountinfo}
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="SecondBox" style={{ marginTop: 10 }} hidden={this.state.wsettings.approval_type === 2 || this.state.settings.approval_type === 2}>
              <span className="TitleOfTheBox">{strings.registrants}</span>
              <Row xs={24} style={{ marginTop: 10 }} >
                <Col md={6}>
                  <span className={"DateText"} style={{ marginLeft: 20 }}>{strings.pending} : {this.state.loading4 ? strings.loading : this.state.registrantsUsersData == null ? strings.na : this.state.registrantsUsersData.pendingUsers == null ? strings.na : this.state.registrantsUsersData.pendingUsers.registrants.length}</span>
                </Col>
                <Col md={6}>
                  <span className={"DateText"} style={{ marginLeft: 12 }}>{strings.approved} : {this.state.loading4 ? strings.loading : this.state.registrantsUsersData == null ? strings.na : this.state.registrantsUsersData.approvedUsers == null ? strings.na : this.state.registrantsUsersData.approvedUsers.registrants.length}</span>
                </Col>

                <Col md={6}>
                  <span className={"DateText"} style={{ marginLeft: 12 }}>{strings.denied} : {this.state.loading4 ? strings.loading : this.state.registrantsUsersData == null ? strings.na : this.state.registrantsUsersData.declinedUsers == null ? strings.na : this.state.registrantsUsersData.declinedUsers.registrants.length}</span>
                </Col>
              </Row>
            </div>
            <div className="SecondBox">
              <span className="TitleOfTheBox">{this.state.resourceType === RESOURCE_TYPES.ZOOM_WEBINAR ? strings.bookazoomwebinar : strings.bookazoommeeting}</span>
              <div>
                <Row xs={24} style={{ marginTop: 7 }}>
                  <Col md={4} style={{ marginTop: 10, }}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className="DateText">{strings.date}</span>
                  </Col>
                  <Col md={5} style={{ marginTop: 10, }}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.dateValue} disabled />
                    </div>
                  </Col>
                  <Col md={3} style={{ marginTop: 11 }}>

                  </Col>

                  <Col md={12} style={{}}>
                  </Col>
                </Row>
                <div style={{ marginTop: 30, height: 100 }}>
                  <table><tbody>
                    <tr className='headertimeline'>
                      <td >8:30<span>am</span>-9<span>am</span></td>
                      <td >9<span>am</span>-10<span>am</span></td>
                      <td >10<span>am</span>-11<span>am</span></td>
                      <td >11<span>am</span>-12<span>pm</span></td>
                      <td >12<span>pm</span>-1<span>pm</span></td>
                      <td >1<span>pm</span>-2<span>pm</span></td>
                      <td >2<span>pm</span>-3<span>pm</span></td>
                      <td >3<span>pm</span>-4<span>pm</span></td>
                      <td >4<span>pm</span>-5<span>pm</span></td>
                      <td >5<span>pm</span>-6<span>pm</span></td>
                      <td >6<span>pm</span>-7<span>pm</span></td>
                      <td >7<span>pm</span>-8<span>pm</span></td>
                      <td >8<span>pm</span>-9<span>pm</span></td>
                    </tr></tbody>
                  </table>
                  <tr>
                    {this.rendertd1(this.state.trs.length>0?this.state.trs[0].tds:[])}
                  </tr>
                  <TableDragSelect value={this.state.cells} onChange={this.handleTimelineChange}>
                    <tr>
                      {this.rendertd()}
                    </tr>
                  </TableDragSelect>
                </div>

                <Row xs={24} >
                  <Col md={4} style={{ marginTop: 10, }}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.timeerr ? "DateTextErr" : "DateText"}>{strings.time}</span>
                  </Col>
                  <Col md={20} style={{ marginTop: 10, }}>
                    <div>
                      <Input size="large" className='bookingDatebox' value={this.state.selectedTime.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm')} disabled />

                    </div>
                  </Col>
                </Row>


                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4} >
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.chairmanerr ? "DateTextErr" : "DateText"}>{strings.chairman}</span>
                  </Col>
                  <Col md={5}>
                    <div>
                      <Input disabled size="large" className='bookingDatebox' value={this.state.chairman} placeholder={strings.pleasefill + ' ' + strings.chairman} />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="Btnsubscribe" style={{ marginTop: 4 }}>
                      <Button className="subscribe" type="danger" onClick={this.searchapprover} hidden={!this.props.isEdit}>
                        {strings.search}
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.subjecterr ? "DateTextErr" : "DateText"}>{strings.subject}</span>
                  </Col>
                  <Col md={20}>
                    <div>
                      <Input disabled={!this.props.isEdit} size="large" style={{ width: 480 }} value={this.state.subject} placeholder={strings.pleasefill + ' ' + strings.subject} onChange={e => this.setState({ subject: e.target.value })} />
                    </div>
                  </Col>
                </Row>

                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4} >
                    <span style={{ marginLeft: 12 }} className="DateText">{strings.remark}</span>
                  </Col>
                  <Col md={20}>
                    <div>
                      <Input disabled={!this.props.isEdit} size="large" style={{ width: 480 }} value={this.state.remark} placeholder={strings.pleasefill + ' ' + strings.remark} onChange={e => this.setState({ remark: e.target.value })} />
                    </div>
                  </Col>
                </Row>

                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.cp1err ? "DateTextErr" : "DateText"}>{strings.contactperson1}</span>
                  </Col>
                  <Col md={8}>
                    <div>
                      <Select size="large" disabled={!this.props.isEdit} style={{ width: 80, marginRight:5 }} className="bookingselect" value={this.state.salutation1} onChange={e => this.setState({ salutation1: e })} >
                          <Option value="MR">{strings.MR}</Option>
                          <Option value="MS">{strings.MS}</Option>
                          <Option value="MISS">{strings.MISS}</Option>
                      </Select>
                      <Input disabled={!this.props.isEdit} size="large" className='bookingDatebox' value={this.state.contactPerson1} placeholder={strings.pleasefill+' '+strings.contactperson1} onChange={e => this.setState({ contactPerson1: e.target.value })} />
                    </div>
                  </Col>
                  <Col md={5}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                    <span className={this.state.cph1err ? "DateTextErr" : "DateText"}>{strings.contactphone1}</span>
                  </Col>
                  <Col md={7}>
                    <div>
                      <Input disabled={!this.props.isEdit} size="large" className='bookingDatebox' value={this.state.contactPhone1} placeholder={strings.pleasefill+' '+strings.contactphone1} onChange={e => this.setState({ contactPhone1: e.target.value })} />
                    </div>
                  </Col>
                </Row>

                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span style={{ marginLeft: 12 }} className="DateText">{strings.contactperson2}</span>
                  </Col>
                  <Col md={8}>
                    <div>
                      <Select size="large" disabled={!this.props.isEdit} style={{ width: 80, marginRight:5 }} className="bookingselect" value={this.state.salutation2} onChange={e => this.setState({ salutation2: e })} >
                          <Option value="MR">{strings.MR}</Option>
                          <Option value="MS">{strings.MS}</Option>
                          <Option value="MISS">{strings.MISS}</Option>
                      </Select>
                      <Input disabled={!this.props.isEdit} size="large" className='bookingDatebox' value={this.state.contactPerson2} placeholder={strings.pleasefill+' '+strings.contactperson2} onChange={e => this.setState({ contactPerson2: e.target.value })} />
                    </div>
                  </Col>
                  <Col md={5}>
                    <span style={{ marginLeft: 12 }} className="DateText">{strings.contactphone2}</span>
                  </Col>
                  <Col md={7}>
                    <div>
                      <Input disabled={!this.props.isEdit} size="large" className='bookingDatebox' value={this.state.contactPhone2} placeholder={strings.pleasefill+' '+strings.contactphone2} onChange={e => this.setState({ contactPhone2: e.target.value })} />
                    </div>
                  </Col>
                </Row>
                <Row xs={24} style={{ marginTop: 20, }} >
                  <Col md={4}>
                    <span className="DateText" style={{ marginLeft: 12 }}>{strings.isreminder}</span>
                  </Col>
                  <Col md={1}>
                    <div>
                    <Checkbox disabled={!this.props.isEdit} onChange={(e) => this.onIsReminderChange(e)} style={{ marginLeft: 5 }} checked={this.state.isReminder}>{''}</Checkbox>
                    </div>
                  </Col>
                  <Col md={14}>
                    <span className="LongText2" style={{marginLeft:0}}>{strings.zoomremindermsg}</span>
                  </Col>
                </Row>
                {this.state.isCloud && <Row xs={24} style={{ marginTop: 20, }} >
                  <Col span={24}>
                    <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*</span>
                    <Checkbox disabled style={{ marginLeft: 2, color: '#405069' }} checked={true}>{strings.zoomcloudterms}</Checkbox>
                  </Col>
                </Row>}
                {!this.state.isAdvanceConfig ? '' :
                  <div style={{ marginTop: 20 }}>
                    <span className="TitleOfTheBox" style={{ color: '#ff7f27', marginLeft: 12 }}>{this.state.resourceType === RESOURCE_TYPES.ZOOM_MEETING ? strings.meetingsetting : strings.webinarsetting}</span>
                    {this.state.resourceType === RESOURCE_TYPES.ZOOM_MEETING && <Row xs={24} style={{ marginTop: 20, }} >
                      <Col md={4}>
                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}></span>
                        <span className={"DateText"} style={{ marginLeft: 12 }}>{strings.audiotype} <Tooltip title={strings.audiotooltip}><Icon type="question-circle" style={{ fontSize: 14 }} /></Tooltip></span>
                      </Col>
                      <Col md={8}>
                        <div>
                          <Select disabled={!this.props.isEdit} value={this.state.settings.audio} onChange={(e) => this.setState({ settings: { ...this.state.settings, audio: e } })} className='setupinputbox' >
                            <Option value="both">{strings.bothtelephobyandvoip}</Option>
                            <Option value="telephony">{strings.teleponyonly}</Option>
                            <Option value="voip">{strings.voiponly}</Option>
                          </Select>
                        </div>
                      </Col>
                      <Col md={5}>
                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}></span>
                        <span className={"DateText"} style={{ marginLeft: 12 }}>{strings.waitingroom} <Tooltip title={strings.waitingroomtooltip}><Icon type="question-circle" style={{ fontSize: 14 }} /></Tooltip></span>
                      </Col>
                      <Col md={7}>
                        <div style={{ marginTop: 5 }}>
                          <Switch disabled={!this.props.isEdit} checked={this.state.settings.waiting_room} onChange={(e) => this.setState({ settings: { ...this.state.settings, waiting_room: e } })} />
                        </div>
                      </Col>
                    </Row>}

                    {this.state.resourceType === RESOURCE_TYPES.ZOOM_MEETING && <Row xs={24} style={{ marginTop: 20, }} >
                      <Col md={4}>
                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}></span>
                        <span className={"DateText"} style={{ marginLeft: 12 }}>{strings.approvaltype} <Tooltip title={strings.approvaltype}><Icon type="question-circle" style={{ fontSize: 14 }} /></Tooltip></span>
                      </Col>
                      <Col md={8}>
                        <div>
                          <Select disabled={!this.props.isEdit} size="large" value={this.state.settings.approval_type} onChange={(e) => this.setState({ settings: { ...this.state.settings, approval_type: e } })} className='setupinputbox zoomsetting' >
                            <Option value={0}>{strings.automaticallyapprove}</Option>
                            <Option value={1}>{strings.manuallyapprove}</Option>
                            <Option value={2}>{strings.noregistrationrequired}</Option>
                          </Select>
                        </div>
                      </Col>
                      <Col md={5}>
                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>  </span>
                        <span className={"DateText"} style={{ marginLeft: 12 }}>{strings.encryptiontype} <Tooltip title={strings.encryptiontyperemark}><Icon type="question-circle" style={{ fontSize: 14 }} /></Tooltip></span>
                      </Col>
                      <Col md={7}>
                        <div style={{ marginTop: 5 }}>
                          <Select disabled={!this.props.isEdit} size="large" value={this.state.settings.encryption_type} onChange={(e) => this.setState({ settings: { ...this.state.settings, encryption_type: e } })} className='setupinputbox zoomsetting' >
                            <Option value={'enhanced_encryption'}>{strings.enhancedencryption}</Option>
                            <Option value={'e2ee'}>{strings.e2ee}</Option>
                          </Select>
                        </div>
                      </Col>
                    </Row>}

                    {this.state.resourceType === RESOURCE_TYPES.ZOOM_WEBINAR && <Row xs={24} style={{ marginTop: 20, }} >
                      <Col md={4}>
                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}> </span>
                        <span className={"DateText"} style={{ marginLeft: 12 }}>{strings.approvaltype} <Tooltip title={strings.approvaltype}><Icon type="question-circle" style={{ fontSize: 14 }} /></Tooltip></span>
                      </Col>
                      <Col md={8}>
                        <div>
                          <Select disabled={!this.props.isEdit} size="large" value={this.state.wsettings.approval_type} onChange={(e) => this.setState({ wsettings: { ...this.state.wsettings, approval_type: e } })} className='setupinputbox zoomsetting' >
                            <Option value={0}>{strings.automaticallyapprove}</Option>
                            <Option value={1}>{strings.manuallyapprove}</Option>
                            <Option value={2}>{strings.noregistrationrequired}</Option>
                          </Select>
                        </div>
                      </Col>
                      <Col md={5}>
                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}></span>
                        <span className={"DateText"} style={{ marginLeft: 12 }}>{strings.audiotype} <Tooltip title={strings.audiotooltip}><Icon type="question-circle" style={{ fontSize: 14 }} /></Tooltip></span>
                      </Col>
                      <Col md={7}>
                        <div>
                          <Select disabled={!this.props.isEdit} size="large" value={this.state.wsettings.audio} onChange={(e) => this.setState({ wsettings: { ...this.state.wsettings, audio: e } })} className='setupinputbox zoomsetting' >
                            <Option value="both">{strings.bothtelephobyandvoip}</Option>
                            <Option value="telephony">{strings.teleponyonly}</Option>
                            <Option value="voip">{strings.voiponly}</Option>
                          </Select>
                        </div>
                      </Col>
                    </Row>}
                    {this.state.resourceType === RESOURCE_TYPES.ZOOM_WEBINAR && <Row xs={24} style={{ marginTop: 20, }} >
                      <Col md={4}>
                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}></span>
                        <span className={"DateText"} style={{ marginLeft: 12 }}>{strings.allowmultipledevices} <Tooltip title={strings.allowmultipledevicestooltip}><Icon type="question-circle" style={{ fontSize: 14 }} /></Tooltip></span>
                      </Col>
                      <Col md={8}>
                        <div style={{ marginTop: 5 }}>
                          <Switch disabled={!this.props.isEdit} checked={this.state.wsettings.allow_multiple_devices} onChange={(e) => this.setState({ wsettings: { ...this.state.wsettings, allow_multiple_devices: e } })} />
                        </div>
                      </Col>
                      <Col md={5}>
                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}></span>
                        <span className={"DateText"} style={{ marginLeft: 12 }}>{strings.registrantsrestrictnumber} <Tooltip title={strings.registrantsrestrictnumbertooltip}><Icon type="question-circle" style={{ fontSize: 14 }} /></Tooltip></span>
                      </Col>
                      <Col md={7}>
                        <div>
                          <InputNumber disabled={!this.props.isEdit} size="large" min={1} max={500} className='bookingDatebox' value={this.state.wsettings.registrants_restrict_number} placeholder={strings.pleasefill+' '+strings.maxregistrant} onChange={e => this.setState({ wsettings: { ...this.state.wsettings, registrants_restrict_number: e } })} />
                        </div>
                      </Col>
                    </Row>}

                    {this.state.resourceType === RESOURCE_TYPES.ZOOM_WEBINAR && <Row xs={24} style={{ marginTop: 20, }} >
                      <Col md={4}>
                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}></span>
                        <span className={"DateText"} style={{ marginLeft: 12 }}>{strings.contactname} <Tooltip title={strings.contactnametooltip}><Icon type="question-circle" style={{ fontSize: 14 }} /></Tooltip></span>
                      </Col>
                      <Col md={8}>
                        <div style={{ marginTop: 5 }}>
                          <Input size="large" disabled={!this.props.isEdit} className='bookingDatebox' value={this.state.wsettings.contact_name} placeholder={strings.pleasefill+' '+strings.contactname} onChange={e => this.setState({ wsettings: { ...this.state.wsettings, contact_name: e.target.value } })} />
                        </div>
                      </Col>
                      <Col md={5}>
                        <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}></span>
                        <span className={this.state.contectemailerr ? "DateTextErr" : "DateText"} style={{ marginLeft: 12 }}>{strings.contactemail} <Tooltip title={strings.contactemailtooltip}><Icon type="question-circle" style={{ fontSize: 14 }} /></Tooltip></span>
                      </Col>
                      <Col md={7}>
                        <div>
                          <Input size="large" disabled={!this.props.isEdit} className='bookingDatebox' value={this.state.wsettings.contact_email} placeholder={strings.pleasefill+' '+strings.contactemail} onChange={e => this.setState({ wsettings: { ...this.state.wsettings, contact_email: e.target.value } })} />
                        </div>
                      </Col>
                    </Row>}
                  </div>}

                <div style={{ marginTop: 25, marginBottom: 30 }}>
                  <span>
                    <Button hidden={!this.props.isEdit} type="primary" size='large' style={{ marginRight: '8%' }} loading={this.state.loading} onClick={this.modifyBooking}>
                      {strings.edit}
                    </Button>
                    <Button size='large' style={{ marginRight: 20 }} onClick={this.props.backbtn}>
                      {strings.back}
                    </Button>
                    <Button hidden={!this.props.isEdit} size='large' style={{ marginRight: 20 }} onClick={this.clearScreen}>
                      {strings.clear}
                    </Button>
                    <Button size='large' style={{ marginRight: 20 }} onClick={this.showAdvanceConfig}>
                      {strings.advanceconfig} <Icon type={this.state.isAdvanceConfig ? "up" : "down"} />
                    </Button>
                    <Button size='large' onClick={() => this.setState({ isRegistrantShowing: true })} hidden={this.state.wsettings.approval_type === 2 || this.state.settings.approval_type === 2}>
                      {strings.registrants}
                    </Button>

                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          title={strings.searchuser}
          visible={this.state.showmodel}
          onOk={this.handleOk}
          onCancel={() => this.setState({ showmodel: false })}
          footer={[

            <Button key="submit" className='subscribe1' type="danger" onClick={this.handleOk}>
              {strings.select}
            </Button>,
          ]}
        >
          <div style={{ paddingTop: 5 }}>
            <Row>
              <span style={{ color: '#ff7f27', fontSize: 14 }}>{strings.plsincludeslashinsearch}</span>
            </Row>
            <Row type="flex" style={{ marginTop: 15 }}>
              <Col span={6}>{strings.postname1}</Col>
              <Col span={18}>
                <Form>
                  <Row type="flex">
                    <Col span={17}>
                      <Input value={this.state.searchstr} placeholder={strings.enter3char} onChange={e => this.setState({ searchstr: e.target.value })} />
                    </Col>
                    <Col span={6}>
                      <Button type="danger" htmlType="submit" className='subscribe1' style={{ marginLeft: 15, marginTop: 0 }} disabled={this.state.issearching} loading={this.state.issearching} onClick={(e) => this.handleSearch(e)}>
                        {strings.search}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                
              </Col>
            </Row>
            <Row type="flex" style={{ marginTop: 15 }}>
              <Col span={6}>{strings.user}</Col>
              <Col span={18}>
                <Select defaultValue="0" style={{ width: '100%' }} onChange={this.handleselectChange}>
                  <Option value="0">{strings.pleaseselect}</Option>
                  {this.state.approvers.map(d => <Option key={d.id + '-' + d.name + " (" + d.post + ")"}>{d.name} ({d.post})</Option>)}

                </Select>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          title={strings.alert}
          visible={this.state.showalertmodel}
          onCancel={() => this.setState({ showalertmodel: false })}
          footer={[
            <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerBack) this.props.backbtn(); }}>
              {strings.ok}
            </Button>,
          ]}
        >
          <span className="TitleOfTheBox">{strings.errormessage}</span><br />
          <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
        </Modal>
      </div>
    );

  }
}

export default EditZoomBooking;