import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col, Select, Button, Modal, InputNumber, Spin, Table, message, Tooltip, Icon, Radio, Checkbox, Tag } from "antd";
import './App.css';
import './sharing.css';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES, FLOOR_WITH_ZONE, DIVISIONCOLORS, DIVISIONS, legendText1, legendText2, TYPES_SUMMARY, TYPES, SIZES, ZONES, largeSlotWidth, smallSlotWidth, smallSlotHeight, floorPlanWidth, floorPlanHeight } from './data';
import f1 from './assets/floor1.png';
import f2 from './assets/floor2.png';
import f3 from './assets/floor3.png';
import f4 from './assets/floor4.png';
import f5 from './assets/floor5.png';
import f6 from './assets/floor6.png';
import f7 from './assets/floor7.png';
import fM from './assets/floorM.png';
import fG from './assets/floorG.png';
import fz1 from './assets/floor1_zone_v3.png';
import fz2 from './assets/floor2_zone_v3.png';
import fz3 from './assets/floor3_zone_v3.png';
import fz4 from './assets/floor4_zone_v3.png';
import fz5 from './assets/floor5_zone_v3.png';
import fz6 from './assets/floor5_zone_v3.png';
import fz7 from './assets/floor5_zone_v3.png';
import fzM from './assets/floor5_zone_v3.png';
import fzG from './assets/floorG_zone_v3.png';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from "jspdf";
import moment from 'moment';
const Option = Select.Option;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const {CheckableTag} = Tag;

const locale = 'EN';
const radioStyle = {
    paddingTop: '4px',
    marginRight: '10px',
    height: '35px',
    lineHeight: '25px',
    textAlign: 'center',
    borderRadius: '4px',
};

const radioStyle1 = {
    paddingTop: '2px',
    height: '24px',
    lineHeight: '20px',
    textAlign: 'center',
    fontSize:'12px',
};
var filterFontSize = 14;
class Rect extends React.Component {
    componentDidMount() {
    }
    render() {
        const { cs = {} } = this.props;
        return <g transform={"translate(" + cs.x + ", " + cs.y + ")"}>
        <defs>
          <linearGradient id={cs.spaceNo} gradientTransform={"rotate("+cs.rotate2+")"}>
            <stop offset="50%" stopColor={cs.bgcolor.color1} />
            <stop offset="50%" stopColor={cs.bgcolor.color2} />
          </linearGradient>
          
        </defs>
            <rect width={cs.width} height={cs.height} transform={"rotate(" + cs.rotate + ")"} fill={"url(#"+cs.spaceNo+")"} strokeWidth={0.5} stroke={cs.color ? cs.color : 'black'}/>
            <text x={cs.x1} y={cs.y1} transform={"rotate(" + cs.rotate1 + ")"} style={{fontSize:cs.fontsize+'px',fontFamily:"Verdana"}} fill={cs.color ? cs.color : 'black'}>{cs.spaceNo}</text>
        </g>
    }
}
class Sharing extends Component {
    constructor(props) {
        super(props);
        this.column = [
            {
                title: strings.num,
                dataIndex: 'nameEN',
                key: 'nameEN',
                width:'8%',
                render: (a, b) => <span onClick={() => this.onRowClick(b)} style={{ color: '#3895D3', cursor:'pointer' }}>{a}</span>
            }, {
                title: strings.zone,
                dataIndex: 'zone',
                key: 'zone',
                width: '11%',
                render: (a, b) => <div>{ZONES.map((d, i) => <CheckableTag  key={i} checked={a===d} onChange={checked => this.onChangeData(b, 'zone', checked, d)} >{d}</CheckableTag>)}</div>
                
            }, {
                title: strings.division,
                dataIndex: 'divisions',
                key: 'divisions',
                width: '10%',
                render: (a, b) => a.length === 0 ? <p style={{ width: 50, margin: 0, cursor: 'pointer', color: '#3895D3' }} onClick={(e) => this.changeDivision(b, e)}>{strings.na}</p> :
                    a.map((d)=><div style={{ width: 80, margin: 0, cursor: 'pointer', color: '#3895D3' }} onClick={(e) => this.changeDivision(b, e)}>{d.name+' '+(d.value===1?'':d.value)}</div>)
            }, {
                title: <span>{strings.type} <Tooltip style={{ width: 200 }} title={<span>{'SDO = ' + strings.sdo}<br />{'DO = ' + strings.do}<br />{'DMA = ' + strings.dma}<br />{'CC = ' + strings.cc}<br />{'AM = ' + strings.am}</span>}><Icon type="question-circle" style={{ fontSize: 12 }} /></Tooltip></span>,
                dataIndex: 'type',
                key: 'type',
                width: '15%',
                render: (a, b) => <Select value={a} onChange={(e) => this.onChangeData(b, 'type', e)} style={{width:'90%'}} size={'small'}>
                    <Option value="">{strings.na}</Option>
                    {TYPES.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                </Select>
            }, {
                title: <span>{strings.lev} <Tooltip title={<span>{'EV = Electric Vehicle'}<br />{'L = Long Vehicle'}</span>}><Icon type="question-circle" style={{ fontSize: 12 }} /></Tooltip></span>,
                dataIndex: 'size',
                key: 'size',
                width: '12%',
                render: (a, b) => <div>{SIZES.map((d, i) => <CheckableTag  key={i} checked={a.split('&').indexOf(d) > -1} onChange={checked => this.onChangeData(b, 'size', checked, d)} >{d}</CheckableTag>)}</div>
            }, {
                title: strings.color,
                dataIndex: 'color',
                key: 'color',
                width:'8%',
                render: (a, b) => <Row style={{width:'80%'}}>
                    <Col span={12} style={{ background: DIVISIONCOLORS[b.division.split('&')[0]] ? DIVISIONCOLORS[b.division.split('&')[0]].color1 : '#cccccc', height: 20 }}></Col>
                    <Col span={12} style={{ background: DIVISIONCOLORS[b.division.split('&')[0]] ? DIVISIONCOLORS[b.division.split('&')[0]].color2 : '#cccccc', height: 20 }}></Col>
                </Row>
            }, {
                title: strings.sharing,
                dataIndex: 'shared',
                key: 'shared',
                width: '10%',
                render: (a, b) => <Checkbox style={{marginLeft:10}} onChange={(e) => this.onChangeData(b, 'shared', e.target.checked?'Y':'N')} checked={a==='Y'?true:false}></Checkbox>                
            }, {
                title: strings.remark,
                dataIndex: 'remark',
                key: 'remark',
                render: (a, b) => <Input value={a} style={{ width: '90%', height:24 }} onChange={(e) => this.onChangeData(b, 'remark', e.target.value)} />
            }
        ];

        this.state = {
            systemData: null,
            resource: null,
            loading: false,
            floors: [],
            fname: '',
            floorId: 0,
            triggerReload: false,
            showalertmodel: false,
            alertmessage: "",
            showSummaryModel: false,
            showBuildingSummaryModel:false,
            showAllFloorSummaryModel:false,
            selectedSpace: null,
            selectedRow: null,
            showDivisionModel: false,
            divisions: [],
            tableData: [],
            originalData: [],
            isfloorloaded: false,
            isfloorloaded1: false,
            underMaintenance:false,
            fontSize:8,
            charwidth:5,
            filteredIds:[],
            allFloorData:[],
            isColor:'N',
            loading1:false,
            isFilter:false,
            filterFontSize:14,
            filters: { division: 'all', type: 'all', size: 'all', zone: 'all', join: 'all', resourcetype: 'fixed_carpark', shared: 'all', iscolor:'N' }
        }
    }

    componentDidMount() {
        
        sessionStorage.setItem('tabNumber', 17);
        ApiHeader.userId = sessionStorage.getItem("userId");
        window.addEventListener('resize', this.resize)
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({ resource: sd.resource, systemData: sd });
        var buildingFloor = sd.buildingFloor;
        var floors = [];
        var floor3f = {};
        for (var i = 0; i < buildingFloor.length; i++) {
            if (buildingFloor[i].floorEN === '3/F' || buildingFloor[i].floorEN === '4/F' || buildingFloor[i].floorEN === '5/F' || 
            buildingFloor[i].floorEN === 'G/F' || buildingFloor[i].floorEN === '1/F' || buildingFloor[i].floorEN === '2/F') {
                if (locale === 'EN') {
                    floors.push({ id: buildingFloor[i].id, name: buildingFloor[i].floorEN, name1: buildingFloor[i].floorEN });
                } else {
                    floors.push({ id: buildingFloor[i].id, name: buildingFloor[i].floorZH, name1: buildingFloor[i].floorEN });
                }

                if(buildingFloor[i].floorEN === '3/F'){
                    floor3f = buildingFloor[i];
                }
            }
        }
        if(window.devicePixelRatio>=1.25){
            this.setState({fontSize:6,charwidth:3.86,filterFontSize:12})
        }else if(window.devicePixelRatio>=1.10){
            this.setState({fontSize:7,charwidth:4.48,filterFontSize:12})
        }else{
            this.setState({fontSize:8,charwidth:5.09,filterFontSize:14})
        }
        this.setState({ floors: floors, floorId: floor3f.id, fname: floor3f.floorEN });
        this.getFloorData(floor3f.id);
    }

    resize = (e) => {
        if(e.currentTarget.devicePixelRatio>=1.25){
            this.setState({fontSize:6, charwidth:3.86,filterFontSize:12})
        }else if(e.currentTarget.devicePixelRatio>=1.10){
            this.setState({fontSize:7, charwidth:4.48,filterFontSize:12})
        }else{
            this.setState({fontSize:8, charwidth:5.09,filterFontSize:14})
        }        
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }


    getFloorData = (floorId) => {
        var rdata = {};
        this.setState({ loading: true })
        rdata.buildingFloorId = floorId;
        fetch(apiurl + 'resourcetemp/sharingdata', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    var data1 = data.data;
                    if(data.underMaintenance === '1'){
                        this.setState({underMaintenance:true})
                    }else{
                        this.setState({underMaintenance:false})
                    }

                    for (var d of data1) {
                        d.divisions = JSON.parse(d.divisions);
                    }
                    this.setState({ tableData: data1, originalData: data1 })
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    onChangeData = (record, type, value,  tag) => {
        var data = [...this.state.tableData];
        for (var d of data) {
            if (d.id === record.id) {
                if(type === 'size'){
                    const selectedTags = d.size.split('&');
                    const nextSelectedTags = value ? selectedTags.includes(tag) ? selectedTags : [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
                    d.size = nextSelectedTags.filter((s)=>s !== '').join('&');
                }else if(type === 'zone'){
                    d.zone =  value ? tag: '';
                }else{
                    d[type] = value;
                }
                break;
            }
        }

        var data1 = [...this.state.originalData];
        for (var d of data1) {
            if (d.id === record.id) {
                if(type === 'size'){
                    const selectedTags = d.size.split('&');
                    const nextSelectedTags = value ? selectedTags.includes(tag) ? selectedTags : [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
                    d.size = nextSelectedTags.filter((s)=>s !== '').join('&');
                }else if(type === 'zone'){
                    d.zone =  value ? tag : '';
                }else{
                    d[type] = value;
                }
                break;
            }
        }
        this.setState({ tableData: data, originalData : data1 });
    }

    updateDivision = () => {
        var divisionStr = '';
        var division = '';
        var value = 0
        this.state.divisions.map((d) => {
            if (d.name === '' || d.value === '') {
                message.error(strings.plsselectdivision);
                return;
            } else {
                if (division === '') {
                    division = d.name;
                } else {
                    division = division + '&' + d.name;
                }
                if (divisionStr === '') {
                    divisionStr = d.name + ' ' + (d.value===1?'':d.value);
                } else {
                    divisionStr = divisionStr + '&' + d.name + ' ' + (d.value===1?'':d.value);
                }
                value = value + d.value;
            }
        })

        if (value !== 1 ) {
            message.error(strings.sumofdivisionshouldbeone);
            return;
        }

        var record = this.state.selectedRow;
        var data = [...this.state.tableData];
        for (var d of data) {
            if (d.id === record.id) {
                d.divisions = this.state.divisions;
                d.division = division;
                d.divisionStr = divisionStr;
                break;
            }
        }
        this.setState({ tableData: data, selectedRow: null, divisions: [], showDivisionModel: false });
    }

    showSummary = () => {
        this.setState({ showSummaryModel: true });
    }

    showBuildingSummary=()=>{
        this.setState({ showBuildingSummaryModel: true,loading: true});
        fetch(apiurl + 'resourcetemp/getAdminCarparks', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                var data1 = data.data;
                for (var d of data1) {
                    d.divisions = JSON.parse(d.divisions);
                }
                this.setState({ allFloorData: data1})
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
            }
            this.setState({ loading: false })
        })
        .catch(e => { console.warn(e); this.setState({ loading: false }) })
        
    }

    exportData = () => {
        this.setState({loading: true});
        fetch(apiurl + 'resourcetemp/exportsharing', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                var filename = data.filename;
                var link = document.createElement('a');
                link.href = "/oc1logsv2/"+filename;
                link.download = filename;
                link.click();
                document.removeChild(link);
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
            }
            this.setState({ loading: false })
        })
        .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    filterData = () => {
        const fs = { ...this.state.filters };
        var ds1 = [...this.state.originalData];
        var fds1 = ds1.filter((d) => (d.division.split('&').includes(fs.division) || fs.division === 'all') &&
            (d.type === fs.type || fs.type === 'all') &&
            (d.zone === fs.zone || fs.zone === 'all') &&
            (d.size.split('&').includes(fs.size) || fs.size === 'all') &&
            ((d.division.includes('&') && fs.join === 'Y') || (!d.division.includes('&') && fs.join === 'N') || fs.join === 'all') &&
            (d.shared === fs.shared || fs.shared === 'all'));
        var filteredIds = [];
        for(var fd of fds1){
            filteredIds.push(fd.id);
        }
        this.setState({ tableData: fds1, filteredIds:filteredIds, isColor: fs.iscolor, isFilter:true });
    }

    resetFilters = () => {
        this.setState({ tableData: this.state.originalData, filteredIds:[], isFilter:false, isColor:'N', filters: { iscolor:'N', division: 'all', type: 'all', size: 'all', zone: 'all', join: 'all', resourcetype: 'fixed_carpark', shared: 'all' } });
    }

    changeDivision = (b, e) => {
        this.setState({ selectedRow: b, divisions: b.divisions, showDivisionModel: true });
        e.stopPropagation();
    }

    cancelDivisionChange = () => {
        this.setState({ selectedRow: null, divisions: [], showDivisionModel: false });
    }


    onRowClick = (r) => {
        if (this.state.selectedSpace && this.state.selectedSpace.id === r.id) {
            this.setState({ selectedSpace: null });
        } else {
            this.setState({ selectedSpace: r });
        }
    }

    changeDivisionValues = (i, e, type) => {
        var ds = [...this.state.divisions];
        if(type === 'name'){
            ds[i][type] = e;
        }else{
            ds[i][type] = parseFloat(e);
        }        
        this.setState({ divisions: [...ds] });
    }

    getAllByDivisionSharedType = (division, shared, type) => {
        if(type !== null){
            var ds1 = [...this.state.originalData];
            if(division === ''){
                var td1 = ds1.filter((d) => d.divisionStr === '' && d.shared === shared && d.type === type);            
                return td1.length;
            }else{
                var td1 = ds1.filter((d) => d.division.split('&').includes(division) && d.shared === shared && d.type === type)
                var count = 0;
                td1.map((td) => td.divisions.map((d) => {
                    if(d.name === division){
                        count = count + parseFloat(d.value);
                    }                
                }));
                return count % 1 === 0 ? count : count.toFixed(2);
            }
        }else{
            var ds1 = [...this.state.originalData];
            if(division === ''){
                var td1 = ds1.filter((d) => d.divisionStr === '' && d.shared === shared);            
                return td1.length;
            }else{
                var td1 = ds1.filter((d) => d.division.split('&').includes(division) && d.shared === shared);
                var count = 0;
                td1.map((td) => td.divisions.map((d) => {
                    if(d.name === division){
                        count = count + d.value;
                    }                
                }));
                return count % 1 === 0 ? count : count.toFixed(2);
            } 
        }
       
    }

    getAllByDivision = (division) => {
        var ds1 = [...this.state.originalData];
        if(division === ''){
            var td1 = ds1.filter((d) => d.divisionStr === '');            
            return td1.length;
        }else{
            var td1 = ds1.filter((d) => d.division.split('&').includes(division));
            var count = 0;
            td1.map((td) => td.divisions.map((d) => {
                if(d.name === division){
                    count = count + parseFloat(d.value);
                }                
            }));
            return count % 1 === 0 ? count : count.toFixed(2);
        }
    }    

    getTotalBySharedAndType = (shared, type) => {
        if(type !== null){
            var ds1 = [...this.state.originalData];
            var td1 = ds1.filter((d) => d.shared === shared && d.type === type)
            var count = 0;
            td1.map((td) => {
                if(td.divisionStr === ''){
                    count = count + 1;
                }else{
                    td.divisions.map((d) => 
                        count = count + parseFloat(d.value)
                    )
                }            
            });
            return count % 1 === 0 ? count : count.toFixed(2);
        }else{
            var ds1 = [...this.state.originalData];
            var td1 = ds1.filter((d) => d.shared === shared)
            var count = 0;
            td1.map((td) => {
                if(td.divisionStr === ''){
                    count = count + 1
                }else{
                    td.divisions.map((d) => 
                        count = count + parseFloat(d.value)
                    );
                }            
            });
            return count % 1 === 0 ? count : count.toFixed(2);
        }
    }


    getAllByDivisionSharedType1 = (ds1, division, shared, type) => {
        if(type !== null){
            if(division === ''){
                var td1 = ds1.filter((d) => d.divisionStr === '' && d.shared === shared && d.type === type);            
                return td1.length;
            }else{
                var td1 = ds1.filter((d) => d.division.split('&').includes(division) && d.shared === shared && d.type === type)
                var count = 0;
                td1.map((td) => td.divisions.map((d) => {
                    if(d.name === division){
                        count = count + parseFloat(d.value);
                    }                
                }));
                return count % 1 === 0 ? count : count.toFixed(2);
            }
        }else{
            if(division === ''){
                var td1 = ds1.filter((d) => d.divisionStr === '' && d.shared === shared);            
                return td1.length;
            }else{
                var td1 = ds1.filter((d) => d.division.split('&').includes(division) && d.shared === shared);
                var count = 0;
                td1.map((td) => td.divisions.map((d) => {
                    if(d.name === division){
                        count = count + d.value;
                    }                
                }));
                return count % 1 === 0 ? count : count.toFixed(2);
            } 
        }
       
    }

    getAllByDivision1 = (ds1, division) => {
        if(division === ''){
            var td1 = ds1.filter((d) => d.divisionStr === '');            
            return td1.length;
        }else{
            var td1 = ds1.filter((d) => d.division.split('&').includes(division));
            var count = 0;
            td1.map((td) => td.divisions.map((d) => {
                if(d.name === division){
                    count = count + parseFloat(d.value);
                }                
            }));
            return count % 1 === 0 ? count : count.toFixed(2);
        }
    }

    getTotalSpace11 = (ds1) => {
        return this.getTotalBySharedAndType1(ds1,'N',null) + this.getTotalBySharedAndType1(ds1,'Y',null);
    }

    getTotalBySharedAndType1 = (ds1, shared, type) => {
        if(type !== null){
            var td1 = ds1.filter((d) => d.shared === shared && d.type === type)
            var count = 0;
            td1.map((td) => {
                if(td.divisionStr === ''){
                    count = count + 1;
                }else{
                    td.divisions.map((d) => 
                        count = count + parseFloat(d.value)
                    )
                }            
            });
            return count % 1 === 0 ? count : count.toFixed(2);
        }else{
            var td1 = ds1.filter((d) => d.shared === shared)
            var count = 0;
            td1.map((td) => {
                if(td.divisionStr === ''){
                    count = count + 1
                }else{
                    td.divisions.map((d) => 
                        count = count + parseFloat(d.value)
                    );
                }            
            });
            return count % 1 === 0 ? count : count.toFixed(2);
        }
    }

    getTotalSpace1 = () => {
        return this.getTotalBySharedAndType('N',null) + this.getTotalBySharedAndType('Y',null);
    }    

    getAllByDivisionSharedTypeB = (division, shared, type) => {
        if(type !== null){
            var ds1 = [...this.state.allFloorData];
            if(division === ''){
                var td1 = ds1.filter((d) => d.divisionStr === '' && d.shared === shared && d.type === type);            
                return td1.length;
            }else{
                var td1 = ds1.filter((d) => d.division.split('&').includes(division) && d.shared === shared && d.type === type)
                var count = 0;
                td1.map((td) => td.divisions.map((d) => {
                    if(d.name === division){
                        count = count + parseFloat(d.value);
                    }                
                }));
                return count % 1 === 0 ? count : count.toFixed(2);
            }
        }else{
            var ds1 = [...this.state.allFloorData];
            if(division === ''){
                var td1 = ds1.filter((d) => d.divisionStr === '' && d.shared === shared);            
                return td1.length;
            }else{
                var td1 = ds1.filter((d) => d.division.split('&').includes(division) && d.shared === shared);
                var count = 0;
                td1.map((td) => td.divisions.map((d) => {
                    if(d.name === division){
                        count = count + d.value;
                    }                
                }));
                return count % 1 === 0 ? count : count.toFixed(2);
            }   
        }
    }

    getAllByDivisionB = (division) => {
        var ds1 = [...this.state.allFloorData];
        if(division === ''){
            var td1 = ds1.filter((d) => d.divisionStr === '');            
            return td1.length;
        }else{
            var td1 = ds1.filter((d) => d.division.split('&').includes(division));
            var count = 0;
            td1.map((td) => td.divisions.map((d) => {
                if(d.name === division){
                    count = count + parseFloat(d.value);
                }                
            }));
            return count % 1 === 0 ? count : count.toFixed(2);
        }
    }

    getTotalSpaceB = () => {
        return this.state.allFloorData.length;
    }

    
    getTotalBySharedAndTypeB = (shared, type) => {
        if(type !== null){
            var ds1 = [...this.state.allFloorData];
            var td1 = ds1.filter((d) => d.shared === shared && d.type === type)
            var count = 0;
            td1.map((td) => {
                if(td.divisionStr === ''){
                    count = count + 1;
                }else{
                    td.divisions.map((d) => 
                        count = count + parseFloat(d.value)
                    )
                }            
            });
            return count % 1 === 0 ? count : count.toFixed(2);
        }else{
            var ds1 = [...this.state.allFloorData];
            var td1 = ds1.filter((d) => d.shared === shared)
            var count = 0;
            td1.map((td) => {
                if(td.divisionStr === ''){
                    count = count + 1
                }else{
                    td.divisions.map((d) => 
                        count = count + parseFloat(d.value)
                    );
                }            
            });
            return count % 1 === 0 ? count : count.toFixed(2);
        }
        
    }

    getTotalSpace1B = () => {
        return this.getTotalBySharedAndTypeB('N',null) + this.getTotalBySharedAndTypeB('Y',null);
    }

    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg : msg;
    }

    onFloorChange = (e) => {
        var fid = e.target.value;
        var floor = this.state.floors;
        var fname = '';
        for (var i = 0; i < floor.length; i++) {
            if (floor[i].id == fid) {
                fname = floor[i].name1;
                break;
            }
        }
        this.setState({ isfloorloaded: false, floorId: fid, fname: fname, selectedSpace: null, selectedRow: null, divisions: [] });
        this.getFloorData(fid);
    }

    divideArray = (list) => {
        if (list.length > 6) {
            const threePartIndex = Math.ceil(list.length / 2);
            const secondPart = list.splice(-threePartIndex);
            const firstPart = list;
            return { tableData1: firstPart, tableData2: secondPart }
        } else {
            return { tableData1: list, tableData2: [] }
        }

    }

    saveData = (tableData) => {
        this.setState({ loading: true });
        var data2 = [];
        for (var d of tableData) {
            var newd = { ...d };
            newd.divisions = JSON.stringify(d.divisions);
            data2.push(newd);
        }
        fetch(apiurl + 'resourcetemp/savesharingdata', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(data2),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    this.setState({ alertmessage: strings.savedsuccess, showalertmodel: true })
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    getFloorImg = () => {
        var { fname = '' } = this.state;
        if (fname === '1/F') {
            return f1;
        } else if (fname === '2/F') {
            return f2;
        } else if (fname === '3/F') {
            return f3;
        } else if (fname === '4/F') {
            return f4;
        } else if (fname === '5/F') {
            return f5;
        } else if (fname === '6/F') {
            return f6;
        } else if (fname === '7/F') {
            return f7;
        } else if (fname === 'M/F') {
            return fM;
        } else if (fname === 'G/F') {
            return fG;
        }
    }

    getFloorImg1 = (fname) => {
        if (fname === '1/F') {
            return f1;
        } else if (fname === '2/F') {
            return f2;
        } else if (fname === '3/F') {
            return f3;
        } else if (fname === '4/F') {
            return f4;
        } else if (fname === '5/F') {
            return f5;
        } else if (fname === '6/F') {
            return f6;
        } else if (fname === '7/F') {
            return f7;
        } else if (fname === 'M/F') {
            return fM;
        } else if (fname === 'G/F') {
            return fG;
        }
    }

    getFloorZoneImg = () => {
        var { fname = '' } = this.state;
        if (fname === '1/F') {
            return fz1;
        } else if (fname === '2/F') {
            return fz2;
        } else if (fname === '3/F') {
            return fz3;
        } else if (fname === '4/F') {
            return fz4;
        } else if (fname === '5/F') {
            return fz5;
        } else if (fname === '6/F') {
            return fz6;
        } else if (fname === '7/F') {
            return fz7;
        } else if (fname === 'M/F') {
            return fzM;
        } else if (fname === 'G/F') {
            return fzG;
        }
    }

    getFloorZoneImg1 = (fname) => {
        if (fname === '1/F') {
            return fz1;
        } else if (fname === '2/F') {
            return fz2;
        } else if (fname === '3/F') {
            return fz3;
        } else if (fname === '4/F') {
            return fz4;
        } else if (fname === '5/F') {
            return fz5;
        } else if (fname === '6/F') {
            return fz6;
        } else if (fname === '7/F') {
            return fz7;
        } else if (fname === 'M/F') {
            return fzM;
        } else if (fname === 'G/F') {
            return fzG;
        }
    }

    publishData = (tableData) => {
        this.setState({ loading: true });
        var data2 = [];
        for (var d of tableData) {
            var newd = { ...d };
            newd.divisions = JSON.stringify(d.divisions);
            data2.push(newd);
        }
        fetch(apiurl + 'resourcetemp/publishdata', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(data2),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    this.fetchSystemData();
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true, loading: false })
                }

            })
            .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }   

    fetchSystemData = () => {
        fetch(apiurl + 'system/list', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                this.setState({ loading: false })
                if (data.code == "1") {
                    sessionStorage.setItem('systemdata', JSON.stringify(data.data));
                    this.setState({ alertmessage: strings.publishsuccess, showalertmodel: true })
                } else {
                    this.setState({ alertmessage: strings.errorcode + '' + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => { console.warn(e); this.setState({ loading: false }) })
    }

    calculateSlotInfo = (slotInfo, text, id, division, floorimgid) => {
        var w = 0, h = 0, width = 0, height = 0, rotate = 0, rotate1 = 0, rotate2 = 0, x1 = 0, y1 = 0;
        var currentFloorplanWidth = document.getElementById(floorimgid).clientWidth;
        var currentFloorplanHeight = document.getElementById(floorimgid).clientHeight;
        if (slotInfo.size === 'S') {
            w = (currentFloorplanWidth * smallSlotWidth) / floorPlanWidth;
            h = (currentFloorplanHeight * smallSlotHeight) / floorPlanHeight;
        } else {
            w = (currentFloorplanWidth * largeSlotWidth) / floorPlanWidth;
            h = (currentFloorplanHeight * smallSlotHeight) / floorPlanHeight;
        }
        var textWidth = this.state.charwidth * text.length
        var fontsize = this.state.fontSize;
        if (slotInfo.direction === 'H') {
            width = w;
            height = h;
            var textpos = (width - textWidth) / 2;
            if (slotInfo.textPos === 'U') {                
                x1 = textpos<0?0:textpos;
                y1 = -3;
            }else if (slotInfo.textPos === 'L')  {
                x1 = (width - textWidth) / 2;
                y1 = h + fontsize;
            }else if (slotInfo.textPos === 'LF') {                
                x1 = -(textWidth+2);
                y1 = h-(fontsize/2);
            }else if (slotInfo.textPos === 'RI') {
                x1 = width+2;
                y1 = h-(fontsize/2);
            }
        }else if (slotInfo.direction === 'V') {
            width = w;
            height = h;
            rotate = 90;
            rotate1 = 90;
            if (slotInfo.textPos === 'RI') {
                x1 = (width - textWidth) / 2;
                y1 = -3;
            }else if (slotInfo.textPos === 'LF')  {
                x1 = (width - textWidth) / 2;
                y1 = h + fontsize;
            }else if (slotInfo.textPos === 'U') {                
                x1 = -(textWidth+2);
                y1 = h-(fontsize/2);
            }else if (slotInfo.textPos === 'L') {
                x1 = width+2;
                y1 = h-(fontsize/2);
            }
        } else if (slotInfo.direction === 'R') {
            width = w;
            height = h;
            rotate = 45;
            rotate1 = 45;
            if (slotInfo.textPos === 'U') {
                x1 = (width - textWidth) / 2;
                y1 = -3;
            }else if (slotInfo.textPos === 'L')  {
                x1 = (width - textWidth) / 2;
                y1 = h + fontsize;
            }else if (slotInfo.textPos === 'LF') {                
                x1 = -(textWidth+2);
                y1 = h-(fontsize/2);
            }else if (slotInfo.textPos === 'RI') {
                x1 = width+2;
                y1 = h-(fontsize/2);
            }
        } else if (slotInfo.direction === 'L') {
            width = w;
            height = h;
            rotate = 315;
            rotate1 = 315;
            if (slotInfo.textPos === 'U') {
                x1 = (width - textWidth) / 2;
                y1 = -3;
            }else if (slotInfo.textPos === 'L')  {
                x1 = (width - textWidth) / 2;
                y1 = h + fontsize;
            }else if (slotInfo.textPos === 'LF') {                
                x1 = -(textWidth+2);
                y1 = h-(fontsize/2);
            }else if (slotInfo.textPos === 'RI') {
                x1 = width+2;
                y1 = h-(fontsize/2);
            }
        }

        var fontcolor = 'black';
        var bgcolor = {color1:'#ffffff88',color2:'#ffffff88'};
        if(this.state.selectedSpace){
            if(this.state.selectedSpace.id === id){
                fontcolor = 'red'; 
                if(this.state.isColor === 'Y'){
                    if(division !== ''){
                        bgcolor = DIVISIONCOLORS[division];
                    }else{
                        bgcolor = {color1:'#ff000088',color2:'#ff000088'}
                    }
                }else{
                    bgcolor = {color1:'#ff000088',color2:'#ff000088'}  
                }
                             
            }else{
                if(this.state.isFilter && !this.state.filteredIds.includes(id)){
                    fontcolor = '#d4d3d3';                    
                }else{
                    fontcolor = 'black';
                    if(this.state.isColor === 'Y'){
                        if(division !== ''){
                            bgcolor = DIVISIONCOLORS[division];
                        }
                        
                    }
                }
            }
        }else{
            if(this.state.isFilter && !this.state.filteredIds.includes(id)){
                fontcolor = '#d4d3d3';                
            }else{
                fontcolor = 'black';
                if(this.state.isColor === 'Y'){
                    if(division !== ''){
                        bgcolor = DIVISIONCOLORS[division];
                    }
                    
                }
            }
        }
        
        return { width: width, height: height, fontsize:fontsize, x: (slotInfo.x * currentFloorplanWidth) / floorPlanWidth, y: (slotInfo.y * currentFloorplanHeight) / floorPlanHeight, x1: x1, y1: y1, rotate: rotate, rotate1: rotate1, rotate2: rotate2, spaceNo: text, color: fontcolor, bgcolor:bgcolor };
    }

    showPDFModel=()=>{
        this.setState({ showAllFloorSummaryModel: true, loading1: true});
        fetch(apiurl + 'resourcetemp/getAdminCarparks', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then(data => {
            if (data.code == "1") {
                var data1 = data.data;
                for (var d of data1) {
                    d.divisions = JSON.parse(d.divisions);
                }
                this.setState({ allFloorData: data1, })
                setTimeout(()=>this.printFloorPlan(),5000)
            } else {
                this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
            }
        })
        .catch(e => { console.warn(e); this.setState({ loading1: false }) })
    }

    printFloorPlan = () => {        
        var thisobj = this;
        htmlToImage.toPng(document.getElementById('floordiv3/F'))
        .then(function (floor3) {
            var floor3img = floor3;
            htmlToImage.toPng(document.getElementById('floordiv4/F'))
            .then(function (floor4) {
                var floor4img = floor4;
                htmlToImage.toPng(document.getElementById('floordiv5/F'))
                .then(function (floor5) {
                    var floor5img = floor5;
                    htmlToImage.toPng(document.getElementById('floordivG/F'))
                    .then(function (floorG) {
                        var floorGimg = floorG;
                        htmlToImage.toPng(document.getElementById('floordiv1/F'))
                        .then(function (floor1) {
                            var floor1img = floor1;
                            htmlToImage.toPng(document.getElementById('floordiv2/F'))
                            .then(function (floor2) {
                                var floor2img = floor2;                    
                                var doc = new jsPDF('l', 'pt', [842, 595]);
                                doc.addImage(floorGimg, 'png', 5, 10, 830, 571, "floorG", 'SLOW');
                                doc.addPage('pt','l')
                                doc.addImage(floor1img, 'png', 5, 10, 830, 571, "floor1", 'SLOW');
                                doc.addPage('pt','l')
                                doc.addImage(floor2img, 'png', 5, 10, 830, 571, "floor2", 'SLOW');
                                doc.addPage('pt','l')
                                doc.addImage(floor3img, 'png', 5, 10, 830, 571, "floor3", 'SLOW');
                                doc.addPage('pt','l')
                                doc.addImage(floor4img, 'png', 5, 10, 830, 571, "floor4", 'SLOW');
                                doc.addPage('pt','l')
                                doc.addImage(floor5img, 'png', 5, 10, 830, 571, "floor5", 'SLOW');
                                var ts = moment().format('YYYYMMDDhhmm');
                                doc.save("Carpark_"+ts+".pdf");
                                thisobj.setState({ loading1:false, showAllFloorSummaryModel:false });
                            });
                        });
                    });
                });
            });
        });
    }

    render() {
        const { selectedSpace = null, filters = {}, loading1=false, showDivisionModel = false, tableData = [], isfloorloaded1=false, showSummaryModel = false, showAllFloorSummaryModel=false, allFloorData=[], showBuildingSummaryModel=false, filteredIds=[], divisions = [], loading = false, underMaintenance=false } = this.state;
        const { tableData1 = [], tableData2 = [] } = this.divideArray([...tableData]);
        return (
            <div className='Tab2Div Tab2Div1 sharing' style={{ textAlign: 'center' }}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: 5 }}>
                        <Col span={8} style={{ textAlign: 'left' }}>
                            <RadioGroup onChange={(e) => this.onFloorChange(e)} value={this.state.floorId} className={'sharing1'}>
                                {this.state.floors.map(f => <RadioButton style={radioStyle} key={f.id} value={f.id}>{f.name}</RadioButton>)}
                            </RadioGroup>
                        </Col>
                        <Col span={16} style={{ textAlign: 'right' }}>
                            <Button type={'primary'} onClick={this.showBuildingSummary} style={{ marginLeft: 10 }}>{strings.hqsummary}</Button>
                            <Button type={'primary'} onClick={this.showSummary} style={{ marginLeft: 10 }}>{strings.floorsummary}</Button>
                            <Button type={'primary'} onClick={this.exportData} style={{ marginLeft: 10 }}>{'XLS'}</Button>                             
                            <Button type={'primary'} onClick={this.showPDFModel} style={{ marginLeft: 10 }}>{'PDF'}</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 5 }}>
                        <Col span={3}></Col>
                        <Col span={18}>
                            <div id="floordiv" style={{background:'white'}}>
                                <img src={this.getFloorZoneImg()} style={{ width: '90%', verticalAlign: 'middle', position: 'absolute',background: 'transparent' }} alt='Floorzone'></img>
                                {this.state.isfloorloaded && <svg x={0} y={0} width={'100%'} height={'100%'} style={{ position: 'absolute' }}>
                                    {this.state.originalData.map((cs, i) => <Rect key={i} cs={this.calculateSlotInfo(JSON.parse(cs.slotInfo), cs.nameEN, cs.id, cs.divisions.length>0?cs.divisions[0].name:'', "floorimg")} />)}
                                </svg>}
                                <div style={{position: 'absolute',bottom:'2%',right:'0%',width:160}} >
                                    <Row style={{textAlign:'center',fontWeight:'bold'}}><Col span={24}>{'EMSD Headquarters'}</Col></Row>
                                    <Row style={{textAlign:'center',fontWeight:'bold'}}><Col span={24}>{this.state.fname}</Col></Row>
                                    <Row style={{textAlign:'left',marginTop:5}} hidden={!FLOOR_WITH_ZONE.includes(this.state.fname)}><Col span={7}></Col><Col span={1} style={{width:20,height:20,background:'#FAF465'}}></Col><Col span={12} style={{marginLeft:5}}>{'Zone A'}</Col></Row>
                                    <Row style={{textAlign:'left',marginTop:5}} hidden={!FLOOR_WITH_ZONE.includes(this.state.fname)}><Col span={7}></Col><Col span={1} style={{width:20,height:20,background:'#c9e9f6'}}></Col><Col span={12} style={{marginLeft:5}}>{'Zone B'}</Col></Row>                                    
                                </div>
                                <img src={this.getFloorImg()} id="floorimg" style={{ width: '90%', verticalAlign: 'middle' }} onLoad={() => this.setState({ isfloorloaded: true })} alt="floor" />
                            </div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row style={{marginTop:5, fontSize:this.state.filterFontSize}}>
                        <Col span={24} className="sharing1">
                            <Row>
                                <Col span={1} style={{ marginTop: 5, textAlign:'right', width:'3%' }}><span style={{ color: 'red' }}>*</span>{strings.zone}</Col>
                                <Col span={1} style={{width:'5%'}}>
                                    <Select style={{ width: '90%' }} value={filters.zone} onChange={(e) => this.setState({ filters: { ...filters, zone: e } })}>
                                        <Option value='all'>{strings.all}</Option>
                                        {ZONES.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                                    </Select>
                                </Col>
                                <Col span={1} style={{ marginTop: 5, textAlign:'right', width:'3.3%' }}><span style={{ color: 'red' }}>*</span>{strings.division}</Col>
                                <Col span={2} style={{width:'5%'}}>
                                    <Select style={{ width: '90%' }} value={filters.division} onChange={(e) => this.setState({ filters: { ...filters, division: e } })}>
                                        <Option value='all'>{strings.all}</Option>
                                        <Option value="">{strings.na}</Option>
                                        {DIVISIONS.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                                    </Select>
                                </Col>
                                <Col span={1} style={{ marginTop: 5, textAlign:'right', width:'2.5%' }}><span style={{ color: 'red' }}>*</span>{strings.type}</Col>
                                <Col span={2} style={{width:'5%'}}>
                                    <Select style={{ width: '90%' }} value={filters.type} onChange={(e) => this.setState({ filters: { ...filters, type: e } })}>
                                        <Option value='all'>{strings.all}</Option>
                                        <Option value="">{strings.na}</Option>
                                        {TYPES.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                                    </Select>
                                </Col>
                                <Col span={1} style={{ marginTop: 5, textAlign:'right', width:'2.5%' }}><span style={{ color: 'red' }}>*</span>{strings.lev}</Col>
                                <Col span={2} style={{width:'5%'}}>
                                    <Select style={{ width: '90%' }} value={filters.size} onChange={(e) => this.setState({ filters: { ...filters, size: e } })}>
                                        <Option value='all'>{strings.all}</Option>
                                        {SIZES.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                                    </Select>
                                </Col>
                                <Col span={1} style={{ marginTop: 5, textAlign:'right',width:'3%' }}><span style={{ color: 'red' }}>*</span>{strings.shared}</Col>
                                <Col span={1} style={{width:'5%'}}>
                                    <Select style={{ width: '90%' }} value={filters.shared} onChange={(e) => this.setState({ filters: { ...filters, shared: e } })}>
                                        <Option value='all'>{strings.all}</Option>
                                        <Option value={"Y"}>{strings.yes}</Option>
                                        <Option value={"N"}>{strings.no}</Option>
                                    </Select>
                                </Col>
                                <Col span={1} style={{ marginTop: 5, textAlign:'right', minWidth:'4.5%' }}><span style={{ color: 'red' }}>*</span>{strings.coowner}</Col>
                                <Col span={1} style={{width:'5%'}}>
                                    <Select style={{ width: '90%' }} value={filters.join} onChange={(e) => this.setState({ filters: { ...filters, join: e } })}>
                                        <Option value='all'>{strings.all}</Option>
                                        <Option value={"Y"}>{strings.yes}</Option>
                                        <Option value={"N"}>{strings.no}</Option>
                                    </Select>
                                </Col>
                                <Col span={1} style={{ marginTop: 5, textAlign:'right', width:'2.5%' }}><span style={{ color: 'red' }}>*</span>{strings.color}</Col>
                                <Col span={1} style={{width:'5%'}}>
                                    <Select style={{ width: '90%' }} value={filters.iscolor} onChange={(e) => this.setState({ filters: { ...filters, iscolor: e } })}>                                        
                                        <Option value={"Y"}>{strings.yes}</Option>
                                        <Option value={"N"}>{strings.no}</Option>
                                    </Select>
                                </Col>
                                <Col span={1} style={{ textAlign: 'left', marginLeft:10 }}>
                                    <Button type="primary" style={{width:'100%'}} onClick={this.filterData}>{strings.apply}</Button>                                    
                                </Col>
                                <Col span={1} style={{ textAlign: 'left', marginLeft:10 }}>
                                    <Button style={{width:'100%'}} onClick={() => this.resetFilters()}>{strings.reset}</Button>
                                </Col>
                                <Col span={4} style={{ textAlign: 'left',marginRight:10,float:'right'}}>
                                    <Button type={'primary'} onClick={()=>this.publishData([...tableData])} style={{float:'right', marginLeft: 10,background: '#fff', color: 'red', borderColor: 'red' }}>{strings.publish}</Button>
                                    <Button style={{ marginLeft: 10, background: '#49dc49', color: 'white', borderColor: '#49dc49',float:'right'}} type="default" onClick={() => this.saveData([...tableData])}>{strings.save}</Button>                                                                        
                                </Col>                                
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{fontSize:12}}>
                        <Col span={12} style={{ padding: 5 }}>
                            <Table rowKey={'id'} rowClassName={(r) => selectedSpace && r.id === selectedSpace.id ? 'tablerowselcted' : 'tablerowsharing'} columns={this.column} dataSource={tableData1} size={'small'} pagination={false} scroll={{ y: 235 }} />
                        </Col>

                        <Col span={12} style={{ padding: 5 }}>
                            <Table rowKey={'id'} columns={this.column} rowClassName={(r) => selectedSpace && r.id === selectedSpace.id ? 'tablerowselcted' : 'tablerowsharing'} dataSource={tableData2} size={'small'} pagination={false} scroll={{ y: 235 }} />
                        </Col>
                    </Row>
                </Spin>
                <Modal
                    title={strings.hqsummary}
                    visible={showBuildingSummaryModel}
                    width={1300}
                    onCancel={() => this.setState({ showBuildingSummaryModel: false })}
                    footer={null}
                >
                    {showBuildingSummaryModel && allFloorData.length>0 && <div style={{ width:'1250px' }} id="floorsummarydiv">
                        <Row style={{ fontWeight: 'bold' }}>
                            <Col span={3} className='summarycol'></Col>
                            {DIVISIONS.map((d, i) => <Col key={i} span={1} className='summarycol'>{d}</Col>)}
                            <Col span={1} className='summarycol'  style={{color:'red'}}>{strings.pool}</Col>
                            <Col span={1} className='summarycol' style={{ borderRight: '1px solid #aaaaaa' }} title={strings.physicalparkingspace}>{strings.total}</Col>
                        </Row>
                        {TYPES_SUMMARY.map((ts,tsindex)=>
                            <Row style={{ fontWeight: ts.isBold?'bold':'normal' }} key={tsindex} hidden={ts.title==='N/A' && this.getTotalBySharedAndTypeB(ts.shared, ts.value) === 0}>
                                <Col span={3} className='summarycol1'>{strings[ts.title]?strings[ts.title]:ts.title}</Col>
                                {DIVISIONS.map((d, i) => <Col key={i} span={1} className='summarycol1'>{this.getAllByDivisionSharedTypeB(d, ts.shared, ts.value)}</Col>)}
                                <Col span={1} className='summarycol1' style={{color:'red'}}>{this.getAllByDivisionSharedTypeB('', ts.shared, ts.value)}</Col>
                                <Col span={1} className='summarycol1' style={{ borderRight: '1px solid #aaaaaa' }}>{this.getTotalBySharedAndTypeB(ts.shared, ts.value)}</Col>
                            </Row>
                        )}
                        <Row style={{ fontWeight: 'bold' }}>
                            <Col span={3} className='summarycol1' >{strings.total}</Col>
                            {DIVISIONS.map((d, i) => <Col key={i} span={1} className='summarycol1'>{this.getAllByDivisionB(d)}</Col>)}
                            <Col span={1} className='summarycol1'  style={{color:'red'}}>{this.getAllByDivisionB('')}</Col>
                            <Col span={1} className='summarycol1' style={{ borderRight: '1px solid #aaaaaa' }} title={strings.totalallocation}>{this.getTotalSpace1B()}</Col>
                        </Row>
                        <Row style={{ marginTop: 20, fontSize:10 }}>
                            <Col span={5}>
                                <Row>
                                    <Col span={4}>{'SDO'}</Col>
                                    <Col span={20}>{'= ' + strings.sdo}</Col>
                                </Row>
                                <Row >
                                    <Col span={4}>{'DO'}</Col>
                                    <Col span={20}>{'= ' + strings.do}</Col>
                                </Row>
                                <Row >
                                    <Col span={4}>{'DMA'}</Col>
                                    <Col span={20}>{'= ' + strings.dma}</Col>
                                </Row>
                                <Row >
                                    <Col span={4}>{'CC'}</Col>
                                    <Col span={20}>{'= ' + strings.cc}</Col>
                                </Row>
                                <Row >
                                    <Col span={4}>{'AM'}</Col>
                                    <Col span={20}>{'= ' + strings.am}</Col>
                                </Row>
                            </Col>
                            <Col span={9} style={{border:'1px solid grey',borderBottom:'0px solid grey',textAlign:'center',width:'35%'}}>
                                {legendText1.map((lt, i) => <Row key={i} >
                                    <Col span={6} style={{height:17, borderBottom:lt.border?'1px solid grey':'0px solid grey'}}>
                                        <span>{lt.col1}</span>
                                    </Col>
                                    <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col2}</span>
                                    </Col>
                                    <Col span={1} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col2?'=':''}</span>
                                    </Col>
                                    <Col span={11} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col3}</span>
                                    </Col>
                                    <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <div>
                                            <Row>
                                                <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color1}}></Col>
                                                <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color2}}></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>)}
                            </Col>
                            <Col span={10} style={{border:'1px solid grey',borderBottom:'0px solid grey',textAlign:'center',width:'43.3%',marginLeft:10}}>
                                {legendText2.map((lt, i) => <Row key={i}>
                                    <Col span={6} style={{height:17, borderBottom:lt.border?'1px solid grey':'0px solid grey'}}>
                                        <span>{lt.col1}</span>
                                    </Col>
                                    <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col2}</span>
                                    </Col>
                                    <Col span={1} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col2?'=':''}</span>
                                    </Col>
                                    <Col span={11} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col3}</span>
                                    </Col>
                                    <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <div>
                                            <Row>
                                                {lt.col2 !== '' && <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color1}}></Col>}
                                                {lt.col2 !== '' && <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color2}}></Col>}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>)}
                            </Col>
                        </Row>                        
                    </div>}
                </Modal>
                <Modal
                    title={strings.division}
                    visible={showDivisionModel}
                    width={500}
                    onCancel={() => this.cancelDivisionChange()}
                    footer={[
                        <Button type="primary" onClick={() => this.updateDivision()}>{strings.submit}</Button>,
                        <Button onClick={() => this.cancelDivisionChange()}>{strings.cancel}</Button>
                    ]}
                >
                    <div style={{ marginLeft: 0 }}>
                        <Button type="primary" onClick={() => this.setState({ divisions: [...divisions, { name: 'BTSD', value: 1 }] })}>{strings.add}</Button>
                        {divisions.map((d, i) => <Row key={i} style={{ marginTop: 10 }}>
                            <Col span={10}>
                                <Select style={{ width: '90%' }} value={d.name} onChange={(e) => this.changeDivisionValues(i, e, 'name')}>
                                    {DIVISIONS.map((d, i) => <Option key={i} value={d}>{d}</Option>)}
                                </Select>
                            </Col>
                            <Col span={10}>
                                <InputNumber style={{ width: '90%' }} max={1} min={0.1} value={d.value} onChange={(e) => this.changeDivisionValues(i, e, 'value')} />
                            </Col>
                            <Col span={4}>
                                <Button type="danger" onClick={() => this.setState({ divisions: divisions.filter((d, i1) => i1 !== i) })}>{strings.remove}</Button>
                            </Col>
                        </Row>)}
                    </div>
                </Modal>
                <Modal
                    title={strings.floorsummary}
                    visible={showSummaryModel}
                    width={1300}
                    onCancel={() => this.setState({ showSummaryModel: false })}
                    footer={null}
                >
                    {showSummaryModel && <div style={{ width:'1250px' }} id="floorsummarydiv">
                        <Row style={{ fontWeight: 'bold' }}>
                            <Col span={3} className='summarycol'></Col>
                            {DIVISIONS.map((d, i) => <Col key={i} span={1} className='summarycol'>{d}</Col>)}
                            <Col span={1} className='summarycol' style={{color:'red'}}>{strings.pool}</Col>
                            <Col span={1} className='summarycol' style={{ borderRight: '1px solid #aaaaaa' }} title={strings.physicalparkingspace}>{strings.total}</Col>
                        </Row>
                        {TYPES_SUMMARY.map((ts,tsindex)=>
                            <Row style={{ fontWeight: ts.isBold?'bold':'normal' }} key={tsindex} hidden={ts.title==='N/A' && this.getTotalBySharedAndType(ts.shared, ts.value) === 0}>
                                <Col span={3} className='summarycol1'>{strings[ts.title]?strings[ts.title]:ts.title}</Col>
                                {DIVISIONS.map((d, i) => <Col key={i} span={1} className='summarycol1'>{this.getAllByDivisionSharedType(d, ts.shared, ts.value)}</Col>)}
                                <Col span={1} className='summarycol1' style={{color:'red'}}>{this.getAllByDivisionSharedType('', ts.shared, ts.value)}</Col>
                                <Col span={1} className='summarycol1' style={{ borderRight: '1px solid #aaaaaa' }}>{this.getTotalBySharedAndType(ts.shared, ts.value)}</Col>
                            </Row>
                        )}                        
                        <Row style={{ fontWeight: 'bold' }}>
                            <Col span={3} className='summarycol1' >{strings.total}</Col>
                            {DIVISIONS.map((d, i) => <Col key={i} span={1} className='summarycol1'>{this.getAllByDivision(d)}</Col>)}
                            <Col span={1} className='summarycol1' style={{color:'red'}}>{this.getAllByDivision('')}</Col>
                            <Col span={1} className='summarycol1' style={{ borderRight: '1px solid #aaaaaa' }} title={strings.totalallocation}>{this.getTotalSpace1()}</Col>
                        </Row>
                        <Row style={{ marginTop: 20, fontSize:10 }}>
                            <Col span={5}>
                                <Row>
                                    <Col span={4}>{'SDO'}</Col>
                                    <Col span={20}>{'= ' + strings.sdo}</Col>
                                </Row>
                                <Row >
                                    <Col span={4}>{'DO'}</Col>
                                    <Col span={20}>{'= ' + strings.do}</Col>
                                </Row>
                                <Row >
                                    <Col span={4}>{'DMA'}</Col>
                                    <Col span={20}>{'= ' + strings.dma}</Col>
                                </Row>
                                <Row >
                                    <Col span={4}>{'CC'}</Col>
                                    <Col span={20}>{'= ' + strings.cc}</Col>
                                </Row>
                                <Row >
                                    <Col span={4}>{'AM'}</Col>
                                    <Col span={20}>{'= ' + strings.am}</Col>
                                </Row>
                            </Col>
                            <Col span={9} style={{border:'1px solid grey',borderBottom:'0px solid grey',textAlign:'center',width:'35%'}}>
                                {legendText1.map((lt, i) => <Row key={i} >
                                    <Col span={6} style={{height:17, borderBottom:lt.border?'1px solid grey':'0px solid grey'}}>
                                        <span>{lt.col1}</span>
                                    </Col>
                                    <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col2}</span>
                                    </Col>
                                    <Col span={1} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col2?'=':''}</span>
                                    </Col>
                                    <Col span={11} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col3}</span>
                                    </Col>
                                    <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <div>
                                            <Row>
                                                <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color1}}></Col>
                                                <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color2}}></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>)}
                            </Col>
                            <Col span={10} style={{border:'1px solid grey',borderBottom:'0px solid grey',textAlign:'center',width:'43.3%',marginLeft:10}}>
                                {legendText2.map((lt, i) => <Row key={i}>
                                    <Col span={6} style={{height:17, borderBottom:lt.border?'1px solid grey':'0px solid grey'}}>
                                        <span>{lt.col1}</span>
                                    </Col>
                                    <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col2}</span>
                                    </Col>
                                    <Col span={1} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col2?'=':''}</span>
                                    </Col>
                                    <Col span={11} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <span>{lt.col3}</span>
                                    </Col>
                                    <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                        <div>
                                            <Row>
                                                {lt.col2 !== '' && <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color1}}></Col>}
                                                {lt.col2 !== '' && <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color2}}></Col>}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>)}
                            </Col>
                        </Row>                        
                    </div>}
                </Modal>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={() => this.setState({ showalertmodel: false })}
                    footer={[
                        <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerBack) this.props.gotomybooking(); }}>
                            {strings.ok}
                        </Button>,
                    ]}
                >
                    <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                    <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                </Modal>
                <Modal
                    title={strings.floorsummary}
                    visible={showAllFloorSummaryModel}
                    width={1300}
                    onCancel={() => this.setState({ showAllFloorSummaryModel: false })}
                    footer={[<Button onClick={this.printFloorPlan}>{'Print'}</Button>]}
                >
                    <div>
                        <Spin spinning={loading1}>
                            {this.state.allFloorData.length>0 && this.state.floors.map((f,findex) => <div key={findex} id={"floordiv"+f.name1}>
                                <div style={{background:'white',position:'relative'}}>
                                
                                    <img src={this.getFloorZoneImg1(f.name1)} style={{ width: '100%', verticalAlign: 'middle', position: 'absolute',background: 'transparent' }} alt='Floorzone'></img>
                                    {isfloorloaded1 && <svg x={0} y={0} width={'100%'} height={'100%'} style={{ position: 'absolute' }}>
                                        {this.state.allFloorData.filter((res)=>res.buildingFloorId === f.id).map((cs, i) => <Rect key={i} cs={this.calculateSlotInfo(JSON.parse(cs.slotInfo), cs.nameEN, cs.id, cs.divisions.length>0?cs.divisions[0].name:'', "floorimg"+f.name1)} />)}
                                    </svg>}
                                    <div style={{position: 'absolute',bottom:'17%',right:'0%',width:160}} >
                                        <Row style={{textAlign:'center',fontWeight:'bold'}}><Col span={24}>{'EMSD Headquarters'}</Col></Row>
                                        <Row style={{textAlign:'center',fontWeight:'bold'}}><Col span={24}>{f.name1}</Col></Row>
                                    </div>
                                    <div style={{position: 'absolute',bottom:'4%',right:'0%',width:160}} >
                                        <Row style={{textAlign:'left',marginTop:5}} hidden={!FLOOR_WITH_ZONE.includes(f.name1)}><Col span={7}></Col><Col span={1} style={{width:20,height:20,background:'#FAF465'}}></Col><Col span={12} style={{marginLeft:5}}>{'Zone A'}</Col></Row>
                                        <Row style={{textAlign:'left',marginTop:5}} hidden={!FLOOR_WITH_ZONE.includes(f.name1)}><Col span={7}></Col><Col span={1} style={{width:20,height:20,background:'#c9e9f6'}}></Col><Col span={12} style={{marginLeft:5}}>{'Zone B'}</Col></Row>                                    
                                    </div>
                                    <img src={this.getFloorImg1(f.name1)} id={"floorimg"+f.name1} style={{ width: '100%', verticalAlign: 'middle' }} onLoad={() => this.setState({ isfloorloaded1: true })} alt="floor" />
                                </div>
                                {showAllFloorSummaryModel && <div style={{ width:'1250px' }} id={"floorsummarydiv"+f.name1}>
                                    <Row style={{ fontWeight: 'bold' }}>
                                        <Col span={3} className='summarycol'></Col>
                                        {DIVISIONS.map((d, i) => <Col key={i} span={1} className='summarycol'>{d}</Col>)}
                                        <Col span={1} className='summarycol' style={{color:'red'}}>{strings.pool}</Col>
                                        <Col span={1} className='summarycol' style={{ borderRight: '1px solid #aaaaaa' }} title={strings.physicalparkingspace}>{strings.total}</Col>
                                    </Row>
                                    {TYPES_SUMMARY.map((ts,tsindex)=>
                                        <Row style={{ fontWeight: ts.isBold?'bold':'normal' }} key={tsindex} hidden={ts.title==='N/A' && this.getTotalBySharedAndType1(this.state.allFloorData.filter((res)=>res.buildingFloorId === f.id), ts.shared, ts.value) === 0}>
                                            <Col span={3} className='summarycol1'>{strings[ts.title]?strings[ts.title]:ts.title}</Col>
                                            {DIVISIONS.map((d, i) => <Col key={i} span={1} className='summarycol1'>{this.getAllByDivisionSharedType1(this.state.allFloorData.filter((res)=>res.buildingFloorId === f.id), d, ts.shared, ts.value)}</Col>)}
                                            <Col span={1} className='summarycol1' style={{color:'red'}}>{this.getAllByDivisionSharedType1(this.state.allFloorData.filter((res)=>res.buildingFloorId === f.id), '', ts.shared, ts.value)}</Col>
                                            <Col span={1} className='summarycol1' style={{ borderRight: '1px solid #aaaaaa' }}>{this.getTotalBySharedAndType1(this.state.allFloorData.filter((res)=>res.buildingFloorId === f.id), ts.shared, ts.value)}</Col>
                                        </Row>
                                    )}                        
                                    <Row style={{ fontWeight: 'bold' }}>
                                        <Col span={3} className='summarycol1' >{strings.total}</Col>
                                        {DIVISIONS.map((d, i) => <Col key={i} span={1} className='summarycol1'>{this.getAllByDivision1(this.state.allFloorData.filter((res)=>res.buildingFloorId === f.id), d)}</Col>)}
                                        <Col span={1} className='summarycol1' style={{color:'red'}}>{this.getAllByDivision1(this.state.allFloorData.filter((res)=>res.buildingFloorId === f.id), '')}</Col>
                                        <Col span={1} className='summarycol1' style={{ borderRight: '1px solid #aaaaaa' }} title={strings.totalallocation}>{this.getTotalSpace11(this.state.allFloorData.filter((res)=>res.buildingFloorId === f.id))}</Col>
                                    </Row>   
                                    <Row style={{ marginTop: 20, fontSize:10 }}>
                                        <Col span={5}>
                                            <Row>
                                                <Col span={4}>{'SDO'}</Col>
                                                <Col span={20}>{'= ' + strings.sdo}</Col>
                                            </Row>
                                            <Row >
                                                <Col span={4}>{'DO'}</Col>
                                                <Col span={20}>{'= ' + strings.do}</Col>
                                            </Row>
                                            <Row >
                                                <Col span={4}>{'DMA'}</Col>
                                                <Col span={20}>{'= ' + strings.dma}</Col>
                                            </Row>
                                            <Row >
                                                <Col span={4}>{'CC'}</Col>
                                                <Col span={20}>{'= ' + strings.cc}</Col>
                                            </Row>
                                            <Row>
                                                <Col span={4}>{'AM'}</Col>
                                                <Col span={20}>{'= ' + strings.am}</Col>
                                            </Row>
                                        </Col>
                                        <Col span={9} style={{border:'1px solid grey',borderBottom:'0px solid grey',textAlign:'center',width:'35%'}}>
                                            {legendText1.map((lt, i) => <Row key={i} >
                                                <Col span={6} style={{height:17, borderBottom:lt.border?'1px solid grey':'0px solid grey'}}>
                                                    <span>{lt.col1}</span>
                                                </Col>
                                                <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                                    <span>{lt.col2}</span>
                                                </Col>
                                                <Col span={1} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                                    <span>{lt.col2?'=':''}</span>
                                                </Col>
                                                <Col span={11} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                                    <span>{lt.col3}</span>
                                                </Col>
                                                <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                                    <div>
                                                        <Row>
                                                            <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color1}}></Col>
                                                            <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color2}}></Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>)}
                                        </Col>
                                        <Col span={10} style={{border:'1px solid grey',borderBottom:'0px solid grey',textAlign:'center',width:'43.3%',marginLeft:10}}>
                                            {legendText2.map((lt, i) => <Row key={i}>
                                                <Col span={6} style={{height:17, borderBottom:lt.border?'1px solid grey':'0px solid grey'}}>
                                                    <span>{lt.col1}</span>
                                                </Col>
                                                <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                                    <span>{lt.col2}</span>
                                                </Col>
                                                <Col span={1} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                                    <span>{lt.col2?'=':''}</span>
                                                </Col>
                                                <Col span={11} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                                    <span>{lt.col3}</span>
                                                </Col>
                                                <Col span={3} style={{height:17,borderBottom:'1px solid grey', borderLeft:'1px solid grey'}}>
                                                    <div>
                                                        <Row>
                                                            {lt.col2 !== '' && <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color1}}></Col>}
                                                            {lt.col2 !== '' && <Col span={12} style={{height:16,background:DIVISIONCOLORS[lt.col2].color2}}></Col>}
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>)}
                                        </Col>
                                    </Row>    
                                    <div style={{width:'100%',height:20}}></div>                                             
                                </div>}
                            </div>)}
                        </Spin>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default Sharing;