import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Row, Col, Radio, Input, Modal, Button, Icon, Spin } from "antd";
import './App.css';
import f1 from './assets/floor1.png';
import f2 from './assets/floor2.png';
import f3 from './assets/floor3.png';
import f4 from './assets/floor4.png';
import f5 from './assets/floor5.png';
import f6 from './assets/floor6.png';
import f7 from './assets/floor7.png';
import fM from './assets/floorM.png';
import fG from './assets/floorG.png';
import fz1 from './assets/floor1_zone_v3.png';
import fz2 from './assets/floor2_zone_v3.png';
import fz3 from './assets/floor3_zone_v3.png';
import fz4 from './assets/floor4_zone_v3.png';
import fz5 from './assets/floor5_zone_v3.png';
import fz6 from './assets/floor5_zone_v3.png';
import fz7 from './assets/floor5_zone_v3.png';
import fzM from './assets/floor5_zone_v3.png';
import fzG from './assets/floorG_zone_v3.png';
import FloorMap from './FloorMap'
import { ApiHeader, strings, apiurl, RESOURCE_TYPES, floorPlanHeight, floorPlanWidth, FLOOR_WITH_ZONE, largeSlotWidth, smallSlotWidth, smallSlotHeight, floorPlan_URL } from './data';
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
class Rect extends React.Component {
  componentDidMount() {
  }
  render() {
    const { cs = {} } = this.props;
    return <g transform={"translate(" + cs.x + ", " + cs.y + ")"}>
      {/* <defs>
        <linearGradient id={cs.spaceNo} gradientTransform={"rotate("+cs.rotate2+")"}>
          <stop offset="50%" stop-color={cs.colors.color1} />
          <stop offset="50%" stop-color={cs.colors.color2} />
        </linearGradient>
        fill={"url(#"+cs.spaceNo+")"}
      </defs> */}
      <rect width={cs.width} height={cs.height} transform={"rotate(" + cs.rotate + ")"} fill={cs.fillcolor} strokeWidth={0.5} stroke={cs.color ? cs.color : 'black'} />
      <text x={cs.x1} y={cs.y1} transform={"rotate(" + cs.rotate1 + ")"} fontFamily="Verdana" fontSize={cs.fontsize} fill={cs.color ? cs.color : 'black'} >{cs.spaceNo}</text>
    </g>
  }
}
const locale = 'EN';
class SensorData extends Component {
  constructor(props) {
    super(props);
    this.intervalobj = null;
    this.washroomObj = null;
    this.state = {
      floors: [],
      resource: [],
      type: 'c',
      floorId: 0,
      fname: '3/F',
      systemData: null,
      sensorData: [],
      carparkSensorData: [],
      carparkSensorData1:[],
      content: '',
      contenterr: false,
      showmodel: false,
      rid: 0,
      triggerReload: false,
      showalertmodel: false,
      alertmessage: "",
      isfloorplan: false,
      isrd: false,
      isrd1: true,
      srhandle: null,
      zoomdata: [],
      occupiedroom: 0,
      availableroom: 0,
      nodataroom: 0,
      occupiedzoom: 0,
      availablezoom: 0,
      carSlots: [],
      isFloorLoded: false,
      showCarparkSensor:false,
      fontsize:8,
      charwidth:5,
      isLayerA:true,
      avaibleCounts:[],
      washroomSensorData:[],
      washroomCounts:[],
      currentDate:new Date(),
    }
  }

  componentDidMount() {
    sessionStorage.setItem('tabNumber', 6);
    ApiHeader.userId = sessionStorage.getItem("userId");
    var d = sessionStorage.getItem('systemdata');
    var sd = JSON.parse(d);
    this.setState({ resource: sd.resource, systemData: sd });
    var buildingFloor = sd.buildingFloor;
    var floors = [];
    var floorId = 0;
    for (var i = 0; i < buildingFloor.length; i++) {
      if(buildingFloor[i].floorEN === '3/F'){
        floorId = buildingFloor[i].id;
      }
      if (locale === 'EN') {
        floors.push({ id: buildingFloor[i].id, name: buildingFloor[i].floorEN, name1: buildingFloor[i].floorEN });
      } else {
        floors.push({ id: buildingFloor[i].id, name: buildingFloor[i].floorZH, name1: buildingFloor[i].floorEN });
      }
    }
    
    if(window.devicePixelRatio>=1.25){
      this.setState({fontsize:6,charwidth:3.86})
    }else if(window.devicePixelRatio>=1.10){
      this.setState({fontsize:7,charwidth:4.48})
    }else{
      this.setState({fontsize:8,charwidth:5.09})
    }

    this.setState({ floors: floors, floorId: floorId });
    this.searchcarparkResource(floorId);
    this.intervalobj = setInterval(this.searchcarparkResource,5000)
    window.addEventListener('resize', this.resize)
  }

  resize = (e) => {    
    if(e.currentTarget.devicePixelRatio>=1.25){
      this.setState({fontsize:6, charwidth:3.86})
    }else if(e.currentTarget.devicePixelRatio>=1.10){
        this.setState({fontsize:7, charwidth:4.48})
    }else{
        this.setState({fontsize:8, charwidth:5.09})
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
    clearInterval(this.intervalobj);
  }

  searchcarparkResource = (id) => {
    if(this.state.type !== 'c'){
      return;
    }
    this.setState({ isrd: false, isrd1: true, })
    var rdata = {};
    if (id == null)
      rdata.buildingFloorId = [this.state.floorId];
    else
      rdata.buildingFloorId = [id];

    fetch(apiurl + 'resource/carparksensordata', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          if(data.showCarparkSensor === 'Y'){
            this.setState({showCarparkSensor:true})
          }else{
            this.setState({showCarparkSensor:false})
          }
          
          var data1 = data.data;
          var sensorData = [];
          for (var i = 0; i < data1.length; i++) {
            var sd = {};
            
            if (data1[i].IsOccupied != null)
              sd.occu = data1[i].IsOccupied;
            else
              sd.occu = null;

            sd.rid = data1[i].resourceId;

            sd.slotInfo = JSON.parse(data1[i].slotInfo);
            sd.name = data1[i].name;
            sd.shared = data1[i].shared;
            sd.zone = data1[i].zone;
            sd.topic = data1[i].topic;
            sd.isUnderMaintenance = data1[i].isUnderMaintenance;
            if (data1[i].inprogress != null)
              sd.inprogress = data1[i].inprogress;
            else
              sd.inprogress = false;

            if (data1[i].undermaintenance != null)
              sd.undermaintenance = data1[i].undermaintenance;
            else
              sd.undermaintenance = false;

            var resource = this.state.resource;
            var iscompared = false;
            for (var r = 0; r < resource.length; r++) {
              if (resource[r].id == data1[i].resourceId) {
                iscompared = true;
                if (locale == 'EN')
                  sd.name = resource[r].nameEN;
                else
                  sd.name = resource[r].nameZH;
                sd.type = resource[r].resourceType;                
                break;
              }
            }
            sensorData.push(sd);
          }

          sensorData.sort((a, b) => a.name.localeCompare(b.name));
          
          if(!this.state.isLayerA){
            this.setState({carparkSensorData: sensorData, avaibleCounts:data.avaibleCounts},
              this.setState({isLayerA:true}))
          }else{
            this.setState({carparkSensorData1: sensorData, avaibleCounts:data.avaibleCounts },
              this.setState({isLayerA:false}))
          }
          

        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
      })
      .catch(e => { console.warn(e); this.setState({ loading: false }) })
  }

  searchWashroomResource=(id)=>{
    var rdata = {};
    if (id == null)
      rdata.buildingFloorId = [this.state.floorId];
    else
      rdata.buildingFloorId = [id];

    fetch(apiurl + 'resource/washroomsensordata', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {          
          var data1 = data.data;
          var sensorData = [];
          console.log(rdata.buildingFloorId)
          for (var i = 0; i < data1.length; i++) {
            if(data1[i].id === rdata.buildingFloorId[0]){
              sensorData = data1[i].resources;
              break;
            }            
          }        
          
          this.setState({washroomSensorData: sensorData, washroomCounts:data1});            
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
        if(this.washroomObj == null){
          this.washroomObj = setInterval(()=>this.searchWashroomResource(null),5000);
        }          
      })
      .catch(e => { console.warn(e); this.setState({ loading: false }) })
  }

  searchResource = (id) => {
    this.setState({ zoomdata: [], isrd: false, isrd1: true, })
    var rdata = {};
    if (id == null)
      rdata.buildingFloorId = [this.state.floorId];
    else
      rdata.buildingFloorId = [id];

    fetch(apiurl + 'resource/sensordata', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
                    
          var zdata = data.zoomdata;
          var data = data.data;
          var sensorData = [], occupiedroom = 0, availableroom = 0, nodataroom = 0, occupiedzoom = 0, availablezoom = 0;
          for (var i = 0; i < data.length; i++) {
            var sd = {};
            if (data[i].Temperature != null)
              sd.temp = data[i].Temperature.toFixed(1);
            else
              sd.temp = null;

            if (data[i].Humidity != null)
              sd.humi = data[i].Humidity.toFixed(1);
            else
              sd.humi = null;

            if (data[i].IsOccupied != null)
              sd.occu = data[i].IsOccupied;
            else
              sd.occu = null;

            sd.rid = data[i].resourceId;
            sd.name = data[i].name;
            sd.topic = data[i].topic;

            if (data[i].inprogress != null)
              sd.inprogress = data[i].inprogress;
            else
              sd.inprogress = false;

            if (data[i].undermaintenance != null)
              sd.undermaintenance = data[i].undermaintenance;
            else
              sd.undermaintenance = false;

            var resource = this.state.resource;
            var iscompared = false;
            for (var r = 0; r < resource.length; r++) {
              if (resource[r].id == data[i].resourceId) {
                iscompared = true;
                if (locale == 'EN')
                  sd.name = resource[r].nameEN;
                else
                  sd.name = resource[r].nameZH;
                sd.type = resource[r].resourceType;
                if (resource[r].resourceType === RESOURCE_TYPES.MEETING_ROOM) {
                  this.setState({ isrd: true })
                  if (sd.occu == null) {
                    nodataroom++
                  } else {
                    if (sd.occu) {
                      occupiedroom++;
                    } else {
                      availableroom++;
                    }
                  }
                }
                break;
              }
            }
            sensorData.push(sd);
          }

          sensorData.sort((a, b) => a.name.localeCompare(b.name));
          var zoomdata = [];
          for (var i = 0; i < zdata.length; i++) {
            var sd = {};
            if (locale == 'EN')
              sd.name = zdata[i].nameEN;
            else
              sd.name = zdata[i].nameZH;

            sd.inprogress = zdata[i].inprogress;
            sd.status = zdata[i].status;
            if (sd.status == 'waiting') {
              availablezoom++;
            } else {
              occupiedzoom++;
            }
            zoomdata.push(sd);
          }

          this.setState({
            sensorData: sensorData, zoomdata: zoomdata, occupiedroom, availableroom, nodataroom, occupiedzoom,
            availablezoom
          })

        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
      })
      .catch(e => { console.warn(e); this.setState({ loading: false }) })
  }

  reportMalfunction = (id) => {
    this.setState({ showmodel: true, rid: id });
  }

  reportMalfunction1 = () => {

    var rdata = {};
    rdata.resourceId = this.state.rid;
    if (this.state.content == '') {
      this.setState({ contenterr: true });
      return;
    }
    rdata.malfunctionString = this.state.content;
    fetch(apiurl + 'malfunction/report', {
      headers: ApiHeader,
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(rdata),
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
      .then(data => {
        if (data.code == "1") {
          this.setState({ rid: 0, content: '', showmodel: false })
          this.setState({ alertmessage: strings.reportedsuccess, showalertmodel: true })
        } else {
          this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
      })
      .catch(e => console.warn(e))
  }


  onFloorChange = (e) => {
    var fid = e.target.value;
    var floor = this.state.floors;
    var fname = '';
    for (var i = 0; i < floor.length; i++) {
      if (floor[i].id == fid) {
        fname = floor[i].name1;
        break;
      }
    }
    this.setState({ floorId: fid, fname: fname, isFloorLoded: false });
    this.searchResource(fid);
  }

  onFloorChange1 = (name) => {
    if(name !== this.state.fname){
      var fid = 0;
      var floor = this.state.floors;
      var fname = '';
      for (var i = 0; i < floor.length; i++) {
        if (floor[i].name == name) {
          fname = floor[i].name1;
          fid = floor[i].id;
          break;
        }
      }
      this.setState({ floorId: fid, fname: fname, isFloorLoded: false });
      this.searchcarparkResource(fid);
    }    
  }

  onFloorChange2 = (name) => {
    var fid = 0;
    var floor = this.state.floors;
    var fname = '';
    for (var i = 0; i < floor.length; i++) {
      if (floor[i].name == name) {
        fname = floor[i].name1;
        fid = floor[i].id;
        break;
      }
    }
    this.setState({ floorId: fid, fname: fname, isFloorLoded: false });
    this.searchWashroomResource(fid);   
  }

  onTypeChange = (e) => {
    var t = e.target.value;
    this.setState({type:t,sensorData:[],carparkSensorData:[],carparkSensorData1:[],zoomdata:[],washroomSensorData:[]});   
    if (t === 'b') {
      this.searchResource(null);
    } else if (t === 'c') {
      this.onFloorChange1('3/F')
    } else if (t === 'd') {
      this.searchResource(null);
    } else if (t === 'e') {
      this.onFloorChange2('7/F');
    }

    if(t !== 'e'){
      if(this.washroomObj !== null){
        clearInterval(this.washroomObj);
        this.washroomObj = null;
      } 
    }
  }

  gotofloorplan = () => {
    this.setState({ isfloorplan: true })
  }
  backbtn1 = () => {
    this.setState({ isfloorplan: false })
  }

  findErrorMessage = (msg) => {
    return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
  }

  getFloorImg = () => {
    var { fname = '' } = this.state;
    if (fname === '1/F') {
      return f1;
    } else if (fname === '2/F') {
      return f2;
    } else if (fname === '3/F') {
      return f3;
    } else if (fname === '4/F') {
      return f4;
    } else if (fname === '5/F') {
      return f5;
    } else if (fname === '6/F') {
      return f6;
    } else if (fname === '7/F') {
      return f7;
    } else if (fname === 'M/F') {
      return fM;
    } else if (fname === 'G/F') {
      return fG;
    }
  }
  
  getWCFloorImg = () => {
    var { fname = '' } = this.state;
    if (fname === '1/F') {
      return 'floor1.png';
    } else if (fname === '2/F') {
      return 'floor2.png';
    } else if (fname === '3/F') {
      return 'floor3.png';
    } else if (fname === '4/F') {
      return 'floor4.png';
    } else if (fname === '5/F') {
      return 'floor5.png';
    } else if (fname === '6/F') {
      return 'floor6.png';
    } else if (fname === '7/F') {
      return 'floor7.png';
    } else if (fname === 'M/F') {
      return 'floorM.png';
    } else if (fname === 'G/F') {
      return 'floorG.png';
    }
  }

  getFloorZoneImg = () => {
    var { fname = '' } = this.state;
    if (fname === '1/F') {
      return fz1;
    } else if (fname === '2/F') {
      return fz2;
    } else if (fname === '3/F') {
      return fz3;
    } else if (fname === '4/F') {
      return fz4;
    } else if (fname === '5/F') {
      return fz5;
    } else if (fname === '6/F') {
      return fz6;
    } else if (fname === '7/F') {
      return fz7;
    } else if (fname === 'M/F') {
      return fzM;
    } else if (fname === 'G/F') {
      return fzG;
    }
  }

  calculateSlotInfo = (slotInfo, text, color, shared, occupied, isUnderMaintenance) => {
    var w = 0, h = 0, width = 0, height = 0, rotate = 0, rotate1 = 0, rotate2 = 0, x1 = 0, y1 = 0;
    var currentFloorplanWidth = document.getElementById('floorplanimg').clientWidth;
    var currentFloorplanHeight = document.getElementById('floorplanimg').clientHeight;

    if (slotInfo.size === 'S') {
      w = (currentFloorplanWidth * smallSlotWidth) / floorPlanWidth;
      h = (currentFloorplanHeight * smallSlotHeight) / floorPlanHeight;
    } else {
      w = (currentFloorplanWidth * largeSlotWidth) / floorPlanWidth;
      h = (currentFloorplanHeight * smallSlotHeight) / floorPlanHeight;
    }
    var fontsize = this.state.fontsize;
    var charwidth = this.state.charwidth;
    var textWidth = charwidth * text.length;
    if (slotInfo.direction === 'H') {
      width = w;
      height = h;
      if (slotInfo.textPos === 'U') {
        x1 = (width - textWidth) / 2;
        y1 = -3;
      }else if (slotInfo.textPos === 'L')  {
        x1 = (width - textWidth) / 2;
        y1 = h + fontsize;
      }else if (slotInfo.textPos === 'LF') {                
          x1 = -(textWidth+2);
          y1 = h-(fontsize/2);
      }else if (slotInfo.textPos === 'RI') {
          x1 = width+2;
          y1 = h-(fontsize/2);
      }
    }else if (slotInfo.direction === 'V') {
      width = w;
      height = h;
      rotate = 90;
      rotate1 = 90;
      if (slotInfo.textPos === 'RI') {
          x1 = (width - textWidth) / 2;
          y1 = -3;
      }else if (slotInfo.textPos === 'LF')  {
          x1 = (width - textWidth) / 2;
          y1 = h + fontsize;
      }else if (slotInfo.textPos === 'U') {                
          x1 = -(textWidth+2);
          y1 = h-(fontsize/2);
      }else if (slotInfo.textPos === 'L') {
          x1 = width+2;
          y1 = h-(fontsize/2);
      }
    } else if (slotInfo.direction === 'R') {
      width = w;
      height = h;
      rotate = 45;
      rotate1 = 45;
      if (slotInfo.textPos === 'U') {
        x1 = (width - textWidth) / 2;
        y1 = -3;
      }else if (slotInfo.textPos === 'L')  {
        x1 = (width - textWidth) / 2;
        y1 = h + fontsize;
      }else if (slotInfo.textPos === 'LF') {                
          x1 = -(textWidth+2);
          y1 = h-(fontsize/2);
      }else if (slotInfo.textPos === 'RI') {
          x1 = width+2;
          y1 = h-(fontsize/2);
      }
    } else if (slotInfo.direction === 'L') {
      width = w;
      height = h;
      rotate = 315;
      rotate1 = 315;
      if (slotInfo.textPos === 'U') {
        x1 = (width - textWidth) / 2;
        y1 = -3;
      }else if (slotInfo.textPos === 'L')  {
        x1 = (width - textWidth) / 2;
        y1 = h + fontsize;
      }else if (slotInfo.textPos === 'LF') {                
          x1 = -(textWidth+2);
          y1 = h-(fontsize/2);
      }else if (slotInfo.textPos === 'RI') {
          x1 = width+2;
          y1 = h-(fontsize/2);
      }
    }
    var fillcolor = '#BCBCBC';    
    //red is not shared n available. green is shared n available. grey is not shared  
    if(this.state.showCarparkSensor){
      if(isUnderMaintenance === 0){
        if (shared === 'Y' && occupied === true) {
          fillcolor = '#FE807E';
        } else if (shared === 'Y' && occupied === false) {
          fillcolor = '#9CD87F';
        }
      }      
    }else{
      fillcolor = '#ffffff88';
    }
    
    return { width: width, height: height, fontsize:fontsize, x: (slotInfo.x * currentFloorplanWidth) / floorPlanWidth, y: (slotInfo.y * currentFloorplanHeight) / floorPlanHeight, x1: x1, y1: y1, rotate: rotate, rotate1: rotate1, rotate2: rotate2, spaceNo: text, color: color, fillcolor: fillcolor };
  }

  getTopPosition=(w,halfHeight)=>{
    var currentFloorplanHeight = document.getElementById('wfloorplanimg').clientHeight;
    return ((parseFloat(w.longitude) * currentFloorplanHeight) / floorPlanHeight) - halfHeight;
  }

  getLeftPosition=(w, halfwidth)=>{
    var currentFloorplanWidth = document.getElementById('wfloorplanimg').clientWidth;
    return ((parseFloat(w.latitude) * currentFloorplanWidth) / floorPlanWidth) - halfwidth;
  }

  render() {
    const radioStyle = {
      paddingTop: '5px',
      display: 'block',
      height: '40px',
      lineHeight: '30px',
      marginTop: '20px',
      textAlign: 'center',
      borderRadius: '4px',
    };
    const underMaintenance = true;
    const { occupiedroom,
      availableroom,
      nodataroom,
      occupiedzoom,
      availablezoom,
      showCarparkSensor,
      isLayerA,
      avaibleCounts,
      washroomCounts,
      washroomSensorData
    } = this.state;
    return (
      this.state.isfloorplan == true ? <FloorMap backbtn={this.backbtn1} floor={this.state.fname} floorid={this.state.floorId} /> : <div className='Tab1Div'>
        <Button style={{ marginTop: 10, float: 'right', marginRight: 20, zIndex: 30 }} hidden={this.state.type !== 'b'} onClick={this.gotofloorplan}>{strings.floorplan}</Button>
        <Button style={{ marginTop: 10, marginLeft: 28, zIndex: 30, float: 'left' }} onClick={() => this.state.type==='c'?this.searchcarparkResource(null):this.state.type==='e'?this.searchWashroomResource(null):this.searchResource(null)}><Icon type="sync" /></Button>
        <RadioGroup onChange={this.onTypeChange} style={{ marginTop: 10 }} value={this.state.type}>
          <RadioButton value="b">{strings.meetingroom}</RadioButton>          
          <RadioButton value="c">{strings.carpark}</RadioButton>
          <RadioButton value="d">{strings.zoom}</RadioButton>
          <RadioButton value="e">{strings.toilet}</RadioButton>
        </RadioGroup>
        {this.state.type !== 'c' && this.state.type !== 'e' && <Row style={{ marginTop: 10 }}>
          <Col span={8}></Col>
          <Col span={4} style={{ border: '1px solid #333', padding: 3, marginLeft: 10 }}>
            <span>{strings.meetingroom}</span>
            <Row style={{ marginTop: 5, }}>
              <Col span={8}>
                <span className={'lagendinfo'}>{nodataroom}</span><br />
                <img src={require('./assets/grey.svg')} className="minusLogo3" /><br />
                <span className={'lagendinfo'}>{strings.nodata}</span>
              </Col>
              <Col span={8}>
                <span className={'lagendinfo'}>{availableroom}</span><br />
                <img className="minusLogo3" src={require('./assets/green.png')} /><br />
                <span className={'lagendinfo'}>{strings.available}</span>
              </Col>
              <Col span={8}>
                <span className={'lagendinfo'}>{occupiedroom}</span><br />
                <img className="minusLogo3" src={require('./assets/red.png')} /><br />
                <span className={'lagendinfo'}>{strings.occupied}</span>
              </Col>
            </Row>
          </Col>          
          <Col span={4} style={{ border: '1px solid #333', padding: 3, marginLeft: 10 }}>
            <span>{strings.zoom}</span>
            <Row style={{ marginTop: 5, }}>
              <Col span={12}>
                <span className={'lagendinfo'}>{availablezoom}</span><br />
                <img className="minusLogo3" src={require('./assets/green.png')} /><br />
                <span className={'lagendinfo'}>{strings.available}</span>
              </Col>
              <Col span={12}>
                <span className={'lagendinfo'}>{occupiedzoom}</span><br />
                <img className="minusLogo3" src={require('./assets/red.png')} /><br />
                <span className={'lagendinfo'}>{strings.occupied}</span>
              </Col>
            </Row>
          </Col>
          <Col span={8}></Col>
        </Row>}
        {this.state.type === 'e' && <div style={{ paddingLeft: 25, paddingBottom: 30, textAlign: 'left' }}>
            <Row lg={24} style={{marginTop:10}}>
              {/* <RadioGroup onChange={(e) => this.onFloorChange2(e.target.value)} value={this.state.fname}>
                {
                  this.state.floors.map((f,fi) => <RadioButton style={{marginLeft:fi===0?0:10}} key={f.name1} value={f.name1}>{f.name}</RadioButton>)
                }
              </RadioGroup> */}            
            </Row>
            <Row lg={24} style={{ maxHeight: 600, overflowY: 'auto' }}>
              <Col lg={3}></Col>
              <Col lg={17} style={{ marginTop: 20}}>
                {washroomSensorData.map((w,wi)=><div key={wi} style={{background:'#AFABAB', position: 'absolute', borderRadius:5, top:this.getTopPosition(w,50),left:this.getLeftPosition(w,35),width:70,height:100}}>
                  <div style={{textAlign:'center'}}>
                    <div style={{width:'100%',textAlign:'center'}}>
                      <span style={{fontSize:22,fontWeight:'bold'}}>{w.nameEN}</span>
                    </div>
                    <div style={{width:'100%',height:2,background:'black'}}></div>
                    <Row style={{marginTop:5}}>
                      <Col span={11}><img src={require('./assets/m.png')}  style={{width:15,height:30}}/></Col>
                      <Col span={2}> <div style={{background:'black',width:1,height:30,margin:'auto'}}></div></Col>
                      <Col span={11}><img src={require('./assets/f.png')}  style={{width:15,height:30}}/></Col>
                    </Row>
                    <Row style={{fontSize:20}}>
                      {!w.maleundermaintenance && <Col span={11} style={{color:w.maleCount===0?'red':'#000000a6'}}>{w.maleCount}</Col>}
                      {w.maleundermaintenance && <Col span={11}>
                        <img src={require('./assets/maintenance.png')}  style={{width:15,height:17}}/><br/>
                      </Col>}
                      <Col span={2}></Col>
                      {!w.femaleundermaintenance && <Col span={11} style={{color:w.femaleCount===0?'red':'#000000a6'}}>{w.femaleCount}</Col>}
                      {w.femaleundermaintenance && <Col span={11}>
                        <img src={require('./assets/maintenance.png')}  style={{width:15,height:17}}/><br/>
                      </Col>}
                    </Row>
                    
                  </div>
                </div>)}
                <img src={floorPlan_URL+this.getWCFloorImg()+'?v='+this.state.currentDate} style={{ width: '100%', background: 'white' }} onLoad={() => this.setState({ isFloorLoded: true })} alt='Floor' id="wfloorplanimg"></img>
              </Col>
              <Col lg={2}></Col>
            </Row>
        </div>}
        <div style={{position:'relative', textAlign:'center',width:'100%'}}>
          {this.state.type === 'e' && washroomCounts.sort((a,b)=>a.floor.localeCompare(b.floor)).map((ac,i)=>
            <div key={i} style={{cursor:'pointer',padding:10,fontWeight:'bold',width:350,display:'inline-block',height:250}} onClick={()=>this.onFloorChange2(ac.floor)}>
              <Row style={{width:'98%',padding:10,background:this.state.fname===ac.floor?'white':'#E2E2E2'}}>
                <Col span={24}>
                  <Row>
                    <Col span={8}>{strings.availablecubical}</Col>
                    <Col span={7} style={{fontSize:18,color:'#127BFF',padding:3}}><img src={require('./assets/m.png')}  style={{width:12,height:30,marginRight:5}}/>{strings.mlbl}</Col>
                    <Col span={1}></Col>
                    <Col span={7} style={{fontSize:18,color:'#FA374F',padding:3}}><img src={require('./assets/f.png')}  style={{width:15,height:30,marginRight:5}}/>{strings.flbl}</Col>
                  </Row>
                  <div style={{border:'1px solid #CBCBCB', borderRadius:5, marginTop: 5, fontSize:16}}>
                    {ac.resources.map((r,ri)=><Row key={ri} style={{ marginTop: 5}}>
                      <Col span={8} style={{height:50,lineHeight:'50px'}}>{r.nameEN}</Col>
                      {!r.maleundermaintenance && <Col span={7} style={{background:'#E8F3FF',height:50,lineHeight:'50px',color:'#127BFF'}}>{r.maleCount}</Col>}
                      {r.maleundermaintenance && <Col span={7} style={{background:'#E8F3FF',height:50,fontSize:10,lineHeight:'20px'}}>
                        <img src={require('./assets/maintenance.png')}  style={{width:15,height:20,marginTop:7}}/><br/>
                        {strings.maintenancelbl}
                      </Col>}
                      <Col span={1}></Col>
                      {!r.femaleundermaintenance && <Col span={7} style={{background:'#FFF3F4',height:50,lineHeight:'50px',color:'#FA374F'}}>{r.femaleCount}</Col>}
                      {r.femaleundermaintenance && <Col span={7} style={{background:'#FFF3F4',height:50,fontSize:10,lineHeight:'20px'}}>
                        <img src={require('./assets/maintenance.png')}  style={{width:15,height:20,marginTop:7}}/><br/>
                        {strings.maintenancelbl}
                      </Col>}
                    </Row>)}
                    <div style={{ marginTop: 5}}></div>
                  </div>
                </Col>
              </Row>       
            </div>)}
          </div>
        <div style={{ paddingLeft: 25, paddingBottom: 30, textAlign: 'left' }}>
          {this.state.type === 'b' && <Row lg={24} style={{ maxHeight: 700, overflowY: 'auto' }}>
            <Col lg={1} style={{ marginTop: 20 }}>
              {this.state.type === 'b' && <RadioGroup onChange={(e) => this.onFloorChange(e)} value={this.state.floorId}>
                {
                  this.state.floors.map(f => <RadioButton style={radioStyle} key={f.id} value={f.id}>{f.name}</RadioButton>)
                }
              </RadioGroup>}
            </Col>
            <Col lg={1} style={{ marginTop: 20 }}></Col>
            <Col lg={22} style={{ marginTop: 20 }}>
              <Spin spinning={this.state.loading}>
                <div>
                  <span style={{ fontSize: 18, fontWeight: 500 }} hidden={!this.state.isrd1}>{strings.meetingroom}</span><br />
                  <span style={{ fontSize: 18, fontWeight: 500, marginLeft: 90, marginTop: 20 }} hidden={this.state.isrd || !this.state.isrd1}>{strings.nodata}</span>
                  <Row lg={24}>
                    {
                      this.state.sensorData.map(sd => (sd.type === RESOURCE_TYPES.MEETING_ROOM ? <Col lg={5} style={{ marginTop: 20 }}>
                        <div className="Boxes">
                          {sd.occu == null ? <img src={require('./assets/grey.svg')} className="minusLogo2"></img> : (sd.occu == false ? <img className="minusLogo2" src={require('./assets/green.png')} />
                            : <img className="minusLogo2" src={require('./assets/red.png')} />)}
                          <span className="NumberTextinBox">{sd.name}</span>
                          <span className="TextinBoxYellow" hidden={!sd.inprogress}>{strings.roomreserved}</span>
                          <div className='temptextdiv'>
                            <img className="logoTemp" src={require('./assets/temp.png')} hidden={sd.temp == null} />
                            <span className="TempText" hidden={sd.temp == null}>{sd.temp}℃</span>

                            <span className='humitextdiv' hidden={sd.humi == null}>
                              <img className="logoDrop" src={require('./assets/drop.png')} />
                              <span className="TempTextPer" >{sd.humi}%</span>
                            </span>
                            {sd.undermaintenance == false ? "" :
                              <div className="ReportBtn" style={{ backgroundColor: '#fff' }}>
                                <span className="ReportBtnText" style={{ color: '#f00', fontWeight: 500 }} >{strings.undermaintenance}</span>
                              </div>}
                          </div>
                        </div>
                      </Col> : ""))
                    }

                  </Row>
                </div>
              </Spin>
            </Col>
          </Row>}
          {this.state.type === 'c' && <Row lg={24} style={{ maxHeight: 700, overflowY: 'auto' }}>
            <Col lg={23} style={{ marginTop: 20,marginLeft:'2%' }}>
              <Spin spinning={this.state.loading}>
                <div style={{ width: '98%', }}>
                  <img src={this.getFloorZoneImg()} style={{ width: '100%', position: 'absolute',background: 'transparent' }} alt='Floorzone'></img>
                  {this.state.isFloorLoded && <svg x={0} y={0} width={'100%'} height={'100%'} style={{ position: 'absolute', display:isLayerA?'block':'none' }}>
                    {this.state.carparkSensorData.map((sd, i) => sd.type === RESOURCE_TYPES.ADMIN_CARPARK ? <Rect key={i} cs={this.calculateSlotInfo(sd.slotInfo, sd.name, 'black', sd.shared, sd.occu, sd.isUnderMaintenance)} /> : '')}
                  </svg>}
                  {this.state.isFloorLoded && <svg x={0} y={0} width={'100%'} height={'100%'} style={{ position: 'absolute', display:isLayerA?'none':'block' }}>
                    {this.state.carparkSensorData1.map((sd, i) => sd.type === RESOURCE_TYPES.ADMIN_CARPARK ? <Rect key={i} cs={this.calculateSlotInfo(sd.slotInfo, sd.name, 'black', sd.shared, sd.occu, sd.isUnderMaintenance)} /> : '')}
                  </svg>}
                  <div style={{position: 'absolute',bottom:'2%',right:'0%',width:150}}>
                    {FLOOR_WITH_ZONE.includes(this.state.fname) && <Row style={{marginTop:5}}><Col span={2} style={{width:20,height:20,background:'#FAF465'}}></Col><Col span={18} style={{marginLeft:10}}>{'Zone A'}</Col></Row>}
                    {FLOOR_WITH_ZONE.includes(this.state.fname) && <Row style={{marginTop:5}}><Col span={2} style={{width:20,height:20,background:'#c9e9f6'}}></Col><Col span={18} style={{marginLeft:10}}>{'Zone B'}</Col></Row>}
                    {showCarparkSensor && <Row style={{marginTop:10}}><Col span={2} style={{width:10,height:20,background:'#FE807E',marginLeft:5}}></Col><Col span={18} style={{marginLeft:15}}>{strings.occupied}</Col></Row>}
                    {showCarparkSensor && <Row style={{marginTop:5}}><Col span={2} style={{width:10,height:20,background:'#9CD87F',marginLeft:5}}></Col><Col span={18} style={{marginLeft:15}}>{strings.available}</Col></Row>}
                    {showCarparkSensor && <Row style={{marginTop:5}}><Col span={2} style={{width:10,height:20,background:'#BCBCBC',marginLeft:5}}></Col><Col span={18} style={{marginLeft:15}}>{strings.others}</Col></Row>}
                  </div>
                  <img src={this.getFloorImg()} style={{ width: '100%', background: 'white' }} onLoad={() => this.setState({ isFloorLoded: true })} alt='Floor' id="floorplanimg"></img>
                </div>
              </Spin>
            </Col>            
          </Row>}
          
          <div style={{ marginTop: 10, position:'relative', textAlign:'center',width:'100%'}}>
          {this.state.type === 'c' && avaibleCounts.sort((a,b)=>a.floor.localeCompare(b.floor)).map((ac,i)=>
            <div key={i} style={{cursor:'pointer',padding:5,fontWeight:'bold',width:300,display:'inline-block',height:134}} onClick={()=>this.onFloorChange1(ac.floor)}>
              {ac.hasZones === 1 && ac.isUnderMaintenance === '0' && showCarparkSensor && 
              <Row style={{width:'98%',background:this.state.fname===ac.floor?'white':'#E2E2E2',height:73}}>
                <Col span={12}>
                  <Row>
                    <Col span={5}></Col>
                    <Col span={2} style={{width:10,height:25,background:'#9CD87F',marginTop:20,marginLeft:5}}></Col>
                    <Col span={14} style={{fontSize:35,marginLeft:5}}>{ac.countA}</Col>
                  </Row>
                  <Row>
                    <Col span={5}></Col>
                    <Col span={2} style={{width:20,height:20,background:'#FAF465'}}></Col>
                    <Col span={14} style={{marginLeft:5}}>{'Zone A'}</Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={5}></Col>
                    <Col span={2} style={{width:10,height:24,background:'#9CD87F',marginTop:20,marginLeft:5}}></Col>
                    <Col span={14} style={{fontSize:35,marginLeft:5}}>{ac.countB}</Col>
                  </Row>
                  <Row>
                    <Col span={5}></Col>
                    <Col span={2} style={{width:20,height:20,background:'#c9e9f6'}}></Col>
                    <Col span={14} style={{marginLeft:5}}>{'Zone B'}</Col>
                  </Row>
                </Col>
              </Row>}
              {ac.hasZones === 0 && ac.isUnderMaintenance === '0' && showCarparkSensor && 
              <Row style={{width:'98%',background:this.state.fname===ac.floor?'white':'#E2E2E2',height:73}}>
                <Col span={24}>
                  <Row>
                    <Col span={10}></Col>
                    <Col span={1} style={{width:10,height:25,background:'#9CD87F',marginTop:20}}></Col>
                    <Col span={4} style={{fontSize:35,marginLeft:5}}>{ac.count}</Col>
                    <Col span={9}></Col>
                  </Row>
                </Col>
              </Row>}
              {ac.isUnderMaintenance === '1' && <Row style={{padding:10,background:this.state.fname===ac.floor?'white':'#E2E2E2',width:'98%'}}>
                  <Col span={24} style={{background:'#f7d418',color:'black',height:63,borderRadius:5,padding:5,fontSize:16}}>
                  {'系統維修進行中'}<br/>{'MAINTENANCE IN PROGRESS'}
                  </Col>
                </Row>}
              <Row style={{width:'98%',background:this.state.fname===ac.floor?'white':'#E2E2E2',height:50}}>
                <Col span={24} style={{marginTop:5,fontSize:this.state.fname===ac.floor?30:20,color:'blue',textAlign:'center' }}>{ac.floor}</Col>
              </Row>            
          </div>)}
          </div>
          {this.state.type === 'd' && <Row lg={24} style={{ maxHeight: 700, overflowY: 'auto' }}>
            <Col lg={2} style={{ marginTop: 20 }}>
            </Col>
            <Col lg={22} style={{ marginTop: 20 }}>
              <Spin spinning={this.state.loading}>
                <div>
                  <span style={{ fontSize: 18, fontWeight: 500, marginTop: 40 }} >{strings.zoom}</span><br />
                  <span style={{ fontSize: 18, fontWeight: 500, marginLeft: 90, marginTop: 20 }} hidden={this.state.zoomdata.length > 0}>{strings.nodata}</span>
                  <Row lg={24} >
                    {
                      this.state.zoomdata.map(sd => <Col lg={5} style={{ marginTop: 20 }}>
                        <div className="Boxes">
                          {sd.status == 'waiting' ? <img className="minusLogo2" src={require('./assets/green.png')} /> : <img className="minusLogo2" src={require('./assets/red.png')} />}
                          <span className="NumberTextinBox">{sd.name}</span>
                          <span className="TextinBoxYellow" hidden={!sd.inprogress}>{strings.roomreserved}</span>
                          <div className='temptextdiv'>
                            <span className="TempText" hidden={sd.temp == null}></span>
                            <span style={{ float: 'right' }} hidden={sd.humi == null}>
                              <span className="TempTextPer" ></span>
                            </span>
                          </div>
                        </div>
                      </Col>)
                    }
                  </Row>
                </div>
              </Spin>
            </Col>
          </Row>}
        </div>
        <Modal
          title={strings.reportmalfunction}
          visible={this.state.showmodel}
          onOk={this.reportMalfunction1}
          onCancel={() => this.setState({ showmodel: false })}
          footer={[

            <Button key="submit" className='subscribe1' type="danger" onClick={this.reportMalfunction1}>
              {strings.submit}
            </Button>,
          ]}
        >
          <div style={{ paddingTop: 5 }}>
            <Row type="flex" >
              <Col span={8}>
                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                <span className={this.state.contenterr ? "DateTextErr" : "DateText"}>{strings.malfunctionmessage}</span>
              </Col>
              <Col span={16}><Input placeholder={strings.pleasefill + ' ' + strings.malfunctionmessage} value={this.state.content} onChange={(e) => this.setState({ content: e.target.value })} /></Col>
            </Row>
          </div>
        </Modal>
        <Modal
          title={strings.alert}
          visible={this.state.showalertmodel}
          onCancel={() => this.setState({ showalertmodel: false })}
          footer={[
            <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerReload) window.location.reload(); }}>
              {strings.ok}
            </Button>,
          ]}
        >
          <span className="TitleOfTheBox">{strings.errormessage}</span><br />
          <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
        </Modal>
      </div>
    );
  }
}
export default SensorData;