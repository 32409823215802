import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col,Table,Select,Tag,Radio,Button,DatePicker, Modal} from "antd";
import './App.css';
import {ApiHeader,strings,apiurl,RESOURCE_TYPES} from './data';
const Option = Select.Option;

var locale = 'EN';

class AddMalfunction extends Component {
    constructor(props) {
        super(props);
       
        this.state = {            
            systemData:null,
            resource:null,
            loading:false,
            type:RESOURCE_TYPES.MEETING_ROOM,
            floors:[],
            locations:[],
            floor:'',
            location:'',
            description:'',
            remark:'',
            triggerBack:false,
            showalertmodel:false,
            alertmessage:"",
            date:'',
        }
        
    }

    

    componentDidMount() {
        ApiHeader.userId = sessionStorage.getItem("userId");
        
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({resource:sd.resource,systemData:sd});
        var resource = sd.resource;
        
        if(this.props.isadd == true){
            var floors=[],locations=[];
            
            for(var i=0; i<resource.length; i++){
                if(RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType){
                    if(locale === 'EN'){
                        if(floors.indexOf(resource[i].locationEN.split(',')[1]) == -1){
                            floors.push(resource[i].locationEN.split(',')[1]);
                        }
                    }else{
                        if(floors.indexOf(resource[i].locationZH.split(',')[1]) == -1){
                            floors.push(resource[i].locationZH.split(',')[1]);
                        }
                    }
                }
            }
            for(var i=0; i<resource.length; i++){
                if(RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType){
                    if(resource[i].locationEN.split(',')[1] ===  floors[0]){
                        locations.push(resource[i].locationEN.split(',')[0]);
                    }
                }                
            }            
            this.setState({floors:floors,locations:locations,floor:floors[0],location:locations[0]});
        }else{
            var md = this.props.md;
            var res = md.resource;   
            this.setState({date:md.date,type:res.resourceType,floor:res.locationEN.split(',')[1],location:res.locationEN.split(',')[0],description:md.description,remark:md.remark});
        }
        
    }
  
    componentWillUnmount() {
  
    }

    addmalfunction=()=>{
        this.setState({loading:true})
        var rdata = {};
        rdata.userId = ApiHeader.userId;        
        var resource = this.state.resource;        
        for(var i=0; i<resource.length; i++){
            if(this.state.type === resource[i].resourceType){
                if((this.state.floor === resource[i].locationZH.split(',')[1] || this.state.floor === resource[i].locationEN.split(',')[1]) && (this.state.location === resource[i].locationZH.split(',')[0] || this.state.location === resource[i].locationEN.split(',')[0])){
                    rdata.resourceId = resource[i].id;
                    break;
                }
            }
        }
        
        var isvalid = true;
        if(this.state.description == ""){
            this.setState({descerr:true});
            isvalid = false;
        }else{
            rdata.malfunctionString=this.state.description;
        }
        
        if(isvalid == false)
        {
            this.setState({loading:false})
            return;
        }    
        fetch(apiurl+'malfunction/report', {
            headers: ApiHeader,
            method: 'POST',   
            credentials: 'same-origin',
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    
                    this.clearScrren();
                    this.setState({alertmessage:strings.addedsuccess,showalertmodel:true,triggerBack:true})
                
                }else{
                    this.setState({alertmessage:strings.errorcode+""+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    handleTypeChange=(value)=> {
        var resource = this.state.systemData.resource;
        var floors=[],locations=[];
        for(var i=0; i<resource.length; i++){
            if(value === resource[i].resourceType){
                if(locale === 'EN'){
                    if(floors.indexOf(resource[i].locationEN.split(',')[1]) == -1){
                        floors.push(resource[i].locationEN.split(',')[1]);
                    }
                }else{
                    if(floors.indexOf(resource[i].locationZH.split(',')[1]) == -1){
                        floors.push(resource[i].locationZH.split(',')[1]);
                    }
                }
            }
        }
        for(var i=0; i<resource.length; i++){
            if(value === resource[i].resourceType){
                if(resource[i].locationEN.split(',')[1] ===  floors[0]){
                    locations.push(resource[i].locationEN.split(',')[0]);
                }
            }            
        }
        this.setState({type:value,floors:floors,locations:locations,floor:floors[0],location:locations[0]});
    }

    handleFloorChange=(value)=>{
        var resource = this.state.systemData.resource;
        var locations = [];
        for(var i=0; i<resource.length; i++){
            if(this.state.type === resource[i].resourceType){
                if(resource[i].locationEN.split(',')[1] ===  value){
                    locations.push(resource[i].locationEN.split(',')[0]);
                }
            }
            
        }
        this.setState({locations:locations,floor:value,location:locations[0]});
    }

    clearScrren=()=>{
        this.setState({
            type:RESOURCE_TYPES.MEETING_ROOM,
            floor:'',
            floors:[],
            location:'',
            locations:[],
            description:'',
            remark:'',
        })
        var resource = this.state.resource;
        var floors=[],locations=[];
        for(var i=0; i<resource.length; i++){
            if(RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType){
                if(locale === 'EN'){
                    if(floors.indexOf(resource[i].locationEN.split(',')[1]) == -1){
                        floors.push(resource[i].locationEN.split(',')[1]);
                    }
                    
                }else{
                    if(floors.indexOf(resource[i].locationZH.split(',')[1]) == -1){
                        floors.push(resource[i].locationZH.split(',')[1]);
                    }
                    
                }
            }
        }
        for(var i=0; i<resource.length; i++){
            if(RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType){
                if(resource[i].locationEN.split(',')[1] ===  floors[0]){
                    locations.push(resource[i].locationEN.split(',')[0]);
                }
            }
            
        }
        this.setState({floors:floors,locations:locations,floor:floors[0],location:locations[0]});
    }

    updatemalfunction=()=>{
        this.setState({loading:true})
        var rdata = {};
        rdata.id = this.props.md.id;
        rdata.acknowledgement = this.state.remark;
        fetch(apiurl+'malfunction/update', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',   
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    
                    this.setState({alertmessage:strings.updatesuccess,showalertmodel:true})
                
                }else{
                    this.setState({alertmessage:strings.errorcode+''+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
    }
    

    render() {
    
    
        return (
            
            <div style={{marginTop:20}}>
                <div className="FirstDiv" style={{height:750}}>
                    {this.props.isadd?<span className="TitleOfTheBox">{strings.addmalfunction}</span>
                       :
                       (
                       this.props.isedit?<span className="TitleOfTheBox">{strings.editmalfunction}</span>
                       :<span className="TitleOfTheBox">{strings.malfunctiondetails}</span>
                       )
                    }

                    <Row xs={24} style={{marginTop: 10,}} hidden={this.props.isadd}>
                        <Col md={2} ></Col>
                        <Col md={4} >
                        <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                        <span style={{marginLeft:12}} className="DateText">{strings.reportdate}</span>
                        </Col>
                        <Col md={18}>
                        <div>
                        <Input size="large" style={{width: 480}} value={this.state.date} placeholder={strings.pleasefill+' '+strings.reportdate} disabled={!this.props.isadd} />
                        </div>
                        </Col>
                    </Row>
                    <Row xs={24} style={{marginTop: 20,}} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                        <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                        <span style={{marginLeft:12}} className="DateText">{strings.type}</span>
                        </Col>
                        <Col md={18}>
                        <div>
                            <Select className="InputSizeOfThird" value={this.state.type} onChange={this.handleTypeChange} disabled={!this.props.isadd}>
                                <Option value={RESOURCE_TYPES.MEETING_ROOM}>{strings.meetingroom}</Option>
                                <Option value={RESOURCE_TYPES.VISITOR_CARPARK}>{strings.visitorcarpark}</Option>
                                <Option value={RESOURCE_TYPES.OPERATIONAL_CARPARK}>{strings.operationalcarpark}</Option>
                            </Select>
                        </div>
                        </Col>
                    </Row>
                    <Row xs={24} style={{marginTop: 20,}} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                        <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                        <span style={{marginLeft:12}} className="DateText">{strings.floor}</span>
                        </Col>
                        <Col md={18}>
                        <div>
                        <Select className="InputSizeOfThird" value={this.state.floor} onChange={this.handleFloorChange}  disabled={!this.props.isadd}>
                            {
                                this.state.floors.map(ed =><Option value={ed}>{ed}</Option>)
                            }                            
                        </Select>
                        </div>
                        </Col>
                    </Row>
                    <Row xs={24} style={{marginTop: 20,}} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                        <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                        <span style={{marginLeft:12}} className="DateText">{strings.name}</span>
                        </Col>
                        <Col md={18}>
                        <div>
                        <Select className="InputSizeOfThird" value={this.state.location} onChange={val=>this.setState({location:val})}  disabled={!this.props.isadd}>
                            {
                                this.state.locations.map(ed =><Option value={ed}>{ed}</Option>)
                            }
                        </Select>
                        </div>
                        </Col>
                    </Row>
                    <Row xs={24} style={{marginTop: 20,}} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                        <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                        <span style={{marginLeft:12}} className={this.state.descerr?"DateTextErr":"DateText"}>{strings.description}</span>
                        </Col>
                        <Col md={18}>
                        <div>
                        <Input size="large" style={{width: 480}} value={this.state.description} placeholder={strings.pleasefill+' '+strings.description} onChange={e=>this.setState({description:e.target.value})}  disabled={!this.props.isadd}/>
                        </div>
                        </Col>
                    </Row>
                    <Row xs={24} style={{marginTop: 20,}} hidden={this.props.isadd}>
                        <Col md={2} ></Col>
                        <Col md={4} >
                        <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                        <span style={{marginLeft:12}} className="DateText">{strings.remark}</span>
                        </Col>
                        <Col md={18}>
                        <div>
                        <Input size="large" style={{width: 480}} value={this.state.remark} placeholder={strings.pleasefill+' '+strings.remark} onChange={e=>this.setState({remark:e.target.value})} disabled={!this.props.isedit}/>
                        </div>
                        </Col>
                    </Row>
                    <Row xs={24} style={{marginTop: 40,}} >
                        <Col md={2} ></Col>
                        <Col md={10} >
                        <Button type="primary" size='large' style={{marginRight: 17}}  loading={this.state.loading} onClick={this.addmalfunction} hidden={!this.props.isadd}>
                        {strings.create}
                        </Button>
                        <Button type="primary" size='large' style={{marginRight: 17}}  loading={this.state.loading} onClick={this.updatemalfunction} hidden={!this.props.isedit}>
                        {strings.update}
                        </Button>
                        <Button size='large' style={{marginRight: 20}}  onClick={this.clearScrren} hidden={!this.props.isadd}>
                        {strings.clear}
                        </Button>
                        <Button size='large' style={{marginRight: 20}}  onClick={this.props.backbtn} hidden={this.props.isadd}>
                        {strings.back}
                        </Button>
                        </Col>
                    </Row>                            
                </div>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={()=>this.setState({showalertmodel:false})}
                    footer={[                  
                        <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerBack)this.props.backbtn();}}>
                        {strings.ok}
                        </Button>,
                    ]}
                    >
                        <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                        <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                </Modal>
            </div>
        );
    }
}    
export default AddMalfunction;

