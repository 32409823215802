import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col,Table,Select,Tag,Radio,Button,DatePicker, Modal} from "antd";
import './App.css';
import {ApiHeader,strings,apiurl} from './data';
const Option = Select.Option;

var locale = 'EN';

class AddSystem extends Component {
    constructor(props) {
        super(props);
       
        this.state = {            
            systemData:null,
            resource:null,
            loading:false,
            type:'holiday',
            name:'',
            nameerr:false,
            dateerr:false,
            triggerBack:false,
            showalertmodel:false,
            alertmessage:"",
            date:'',
            date1:null,
        }
        
    }

    

    componentDidMount() {
        ApiHeader.userId = sessionStorage.getItem("userId");
        
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({resource:sd.resource,systemData:sd});
        
        if(this.props.isadd == false){
            var md = this.props.md;
            this.setState({date:md.date,date1:md.date2,type:md.type,name:md.name});
        }
        
    }
  
    componentWillUnmount() {
  
    }

    addSystem=()=>{
        this.setState({loading:true})
        var rdata = {};        
        
        var isvalid = true;
        if(this.state.name == ""){
            this.setState({nameerr:true});
            isvalid = false;
        }else{
            rdata.name=this.state.name;
        }
        if(this.state.date == ""){
            this.setState({dateerr:true});
            isvalid = false;
        }else{
            rdata.date=this.state.date;
        }
        
        if(isvalid == false)
        {
            this.setState({loading:false})
            return;
        }    
        fetch(apiurl+'holiday/create', {
            headers: ApiHeader,
            method: 'POST',   
            credentials: 'same-origin',
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    
                    this.clearScrren();
                    this.setState({alertmessage:strings.addedsuccess,showalertmodel:true,triggerBack:true})
                
                }else{
                    this.setState({alertmessage:strings.errorcode+""+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    handleTypeChange=(value)=> {
        
        this.setState({type:value});
    }

    ondateValueChange=(val)=>{
        this.setState({date1:val,date:val.format('YYYYMMDD')})
    }

    clearScrren=()=>{
        this.setState({
            type:'holiday',
            name:'',
            date:'',
            date1:null,
        })
    }

    updatesystem=()=>{
        this.setState({loading:true})
        var rdata = {};
        rdata.id = this.props.md.id;
        var isvalid = true;
        if(this.state.name == ""){
            this.setState({nameerr:true});
            isvalid = false;
        }else{
            rdata.name=this.state.name;
        }
        if(this.state.date == ""){
            this.setState({dateerr:true});
            isvalid = false;
        }else{
            rdata.date=this.state.date;
        }
        
        if(isvalid == false)
        {
            this.setState({loading:false})
            return;
        }   
        fetch(apiurl+'holiday/update', {
            headers: ApiHeader,
            method: 'POST',   
            credentials: 'same-origin',
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    
                    this.setState({alertmessage:strings.updatesuccess,showalertmodel:true})
                
                }else{
                    this.setState({alertmessage:strings.errorcode+''+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }
    
    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
    }

    render() {
    
    
        return (
            
            <div style={{marginTop:20}}>
                <div className="FirstDiv" style={{height:750}}>
                    {this.props.isadd?<span className="TitleOfTheBox">{strings.add}</span>
                       :
                       (
                       this.props.isedit?<span className="TitleOfTheBox">{strings.edit}</span>
                       :<span className="TitleOfTheBox">{strings.details}</span>
                       )
                    }

                   
                    <Row xs={24} style={{marginTop: 20,}} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                        <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                        <span style={{marginLeft:12}} className="DateText">{strings.type}</span>
                        </Col>
                        <Col md={18}>
                        <div>
                            <Select className="InputSizeOfThird" value={this.state.type} onChange={this.handleTypeChange} disabled={!this.props.isadd}>
                                <Option value="holiday">{strings.holiday}</Option>
                            </Select>
                        </div>
                        </Col>
                    </Row>
                    <Row xs={24} style={{marginTop: 20,}} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                        <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                        <span style={{marginLeft:12}} className={this.state.dateerr?"DateTextErr":"DateText"}>{strings.date}</span>
                        </Col>
                        <Col md={18}>
                        <div>
                        <DatePicker className="InputSizeOfThird" value={this.state.date1} onChange={(val)=>this.ondateValueChange(val)} disabled={!this.props.isedit} />
                        </div>
                        </Col>
                    </Row>                    
                    
                    <Row xs={24} style={{marginTop: 20,}} >
                        <Col md={2} ></Col>
                        <Col md={4} >
                        <span style={{color:'#ff1b03',fontWeight:500,fontSize:20}}>*  </span>
                        <span style={{marginLeft:12}} className={this.state.nameerr?"DateTextErr":"DateText"}>{strings.name}</span>
                        </Col>
                        <Col md={18}>
                        <div>
                        <Input size="large" style={{width: 480}} value={this.state.name} placeholder="Please Fill" onChange={e=>this.setState({name:e.target.value})} disabled={!this.props.isedit}/>
                        </div>
                        </Col>
                    </Row>
                    <Row xs={24} style={{marginTop: 40,}} >
                        <Col md={2} ></Col>
                        <Col md={10} >
                        <Button type="primary" size='large' style={{marginRight: 17}}  loading={this.state.loading} onClick={this.addSystem} hidden={!this.props.isadd}>
                        {strings.create}
                        </Button>
                        <Button type="primary" size='large' style={{marginRight: 17}}  loading={this.state.loading} onClick={this.updatesystem} hidden={this.props.isadd || !this.props.isedit}>
                        {strings.update}
                        </Button>
                        <Button size='large' style={{marginRight: 20}}  onClick={this.clearScrren} hidden={!this.props.isadd}>
                        {strings.clear}
                        </Button>
                        <Button size='large' style={{marginRight: 20}}  onClick={this.props.backbtn} hidden={this.props.isadd}>
                        {strings.back}
                        </Button>
                        </Col>
                    </Row>                            
                </div>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={()=>this.setState({showalertmodel:false})}
                    footer={[                  
                        <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerBack)this.props.backbtn();}}>
                        {strings.ok}
                        </Button>,
                    ]}
                    >
                        <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                        <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                </Modal>
            </div>
        );
    }
}    
export default AddSystem;

