import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Form, Icon, Input, Button,Modal } from 'antd';
import {ApiHeader,strings,apiurl} from './data';
const FormItem = Form.Item;

class Login extends Component {
  state = { showalertmodel:false,showalertmodel1:false,loginlang:'en', contacts:null, isMobile:false };

  componentDidMount() {    
      this.setState({loginlang:this.props.loginlang, isMobile:this.props.isMobile})  
      strings.setLanguage(this.props.loginlang);
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps = (nextProps) => {
        
    if(nextProps.loginlang != this.state.loginlang){
        this.setState({loginlang:nextProps.loginlang})
        strings.setLanguage(nextProps.loginlang);
    }        
  }

  getcontacts=()=>{        
    fetch(apiurl+'api/system/systemcontact', {
        headers: ApiHeader,
        method: 'GET', 
        credentials: 'same-origin',         
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong ...');
        }
    })
    .then(data =>{
        if(data.code == "1"){
            this.setState({contacts:data.data});
        }else{
            this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
        }
        this.setState({loading:false})
    })
    .catch(e => console.warn(e))
  }



  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
        if (!err) {  
                 
            this.props.logincallback(values)
        }
        
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;   
    const {contacts=null,isMobile=false} = this.state;
    return (
        <div className="loginwhitebox">
        <img src={require('./assets/EMSD_Logo.png')} width='200'></img>
        
        <div style={{height:10}}></div>
        {!isMobile && <div>        
            <Form onSubmit={(e)=>this.handleSubmit(e)} className="login-form loginform" style={{display:'inline-block',alignItems:'center'}}>
                <FormItem className="loginform">
                    {getFieldDecorator('userName', {
                        rules: [{ required: true, message: strings.plsinputusername }],
                    })(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={strings.employeeno} style={{width: '100%'}}/>
                    )}
                </FormItem>
                <FormItem className="loginform">
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: strings.plsinputpassword }],
                    })(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" autoComplete="off" placeholder={strings.password} style={{width: '100%'}}/>
                    )}
                </FormItem>
                <FormItem className="loginform">
                        <Button type="primary" htmlType="submit" className="login-form-button"  style={{background: '#ff234c',borderColor:'#ff234c',}}>
                        {strings.login}
                        </Button>
                </FormItem>
        </Form>
      </div>}
      {!isMobile && <span className="loginpagelinktxt" style={{fontWeight:500,float:'left',cursor:'pointer',marginLeft:5,textDecoration:'underline'}} onClick={()=>this.setState({showalertmodel1:true})}>{strings.minsysreq}</span>}
      {!isMobile && <span className="loginpagelinktxt" style={{fontWeight:500,float:'right',cursor:'pointer',marginRight:5,textDecoration:'underline'}} onClick={()=>{this.setState({showalertmodel:true});this.getcontacts();}}>{strings.supportcontact}</span>}
      <Modal
        title={strings.supportcontact}
        visible={this.state.showalertmodel}
        onCancel={()=>this.setState({showalertmodel:false})}
        footer={[                  
            <Button key="submit" className='subscribe1' type="danger" onClick={()=>this.setState({showalertmodel:false})}>
            {strings.ok}
            </Button>,
        ]}
        >
        <div ><span style={{fontSize:18,fontWeight:500,marginLeft:10}}>{strings.adminsupport}</span></div>
        {contacts && <div><span style={{fontSize:14,fontWeight:500,marginLeft:30}}>{contacts.admin1}</span></div>}
        {contacts && contacts.admin2 && contacts.admin2 !== '' && <div ><span style={{fontSize:14,fontWeight:500,marginLeft:30}}>{contacts.admin2}</span></div>}
        <div style={{marginTop:10}}><span style={{fontSize:18,fontWeight:500,marginLeft:10}}>{strings.techsupport}</span></div>
        {contacts && <div><span style={{fontSize:14,fontWeight:500,marginLeft:30}}>{contacts.tech1}</span></div>}
        {contacts && contacts.tech2 && <div ><span style={{fontSize:14,fontWeight:500,marginLeft:30}}>{contacts.tech2}</span></div>}
      </Modal>
      <Modal
        title={strings.minsysreq}
        visible={this.state.showalertmodel1}
        onCancel={()=>this.setState({showalertmodel1:false})}
        footer={[                  
            <Button key="submit" className='subscribe1' type="danger" onClick={()=>this.setState({showalertmodel1:false})}>
            {strings.ok}
            </Button>,
        ]}
        >
            <div><span style={{fontSize:14,fontWeight:500}}>{strings.webbrowsertxt}</span></div>
            <div><span style={{fontSize:14,fontWeight:500}}>{strings.resolutiontxt}</span></div>
      </Modal>
                    
      </div>
    );

  }
}

export default Login;