import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col,Table,Select,Tag,Button,DatePicker, Modal,Popconfirm} from "antd";
import './App.css';
import moment from 'moment';
import EditBooking from './EditBooking';
import EditCarpark from './EditCarpark';
import EditZoomBooking from './EditZoomBooking';
import {ApiHeader,strings,apiurl,RESOURCE_TYPES,getContactPerson} from './data';
const Option = Select.Option;
const locale = 'EN';

class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            contactperson:'',
            type:RESOURCE_TYPES.MEETING_ROOM,
            fromdate1:null,
            todate1:null,
            fromdate:'',
            todate:'',
            floors:[],
            locations:[],
            floor:'',
            location:'',
            status:'booked',
            systemData:null,
            tabledata:[],
            count:0,
            selectedBooking:null,
            isediting:false,
            iseditop:true,
            triggerReload:false,
            showalertmodel:false,
            alertmessage:"",
            zoomaccounts:[],
            zoomAccountId:1,
        }

        this.columns = [
            {
                title: strings.date,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))

            },{
                title: strings.subrsn,
                dataIndex: 'subject',
                key: 'subject',
                width:'10%',       
                render:(val, record) => (<p className='tablelongtext' style={{width:200}}>{val}</p>),         
                sorter: (a, b) => a.subject.localeCompare(b.subject, 'en', { numeric: true }),
            }, {
                title: strings.status,
                dataIndex: 'state1',
                key: 'state1',
                width:'10%',
                sorter: (a, b) => a.state1.localeCompare(b.state1, 'en', { numeric: true }),
            }, {
                title: strings.contact1st,
                dataIndex: 'firstcontact',
                key: 'firstcontact',
                width:'15%',
                sorter: (a, b) => a.firstcontact.localeCompare(b.firstcontact, 'en', { numeric: true }),
                render:(val, record) => (<p className='tablelongtext'>{getContactPerson(strings, record.salutation1, val.replace("()",""))}</p>),
            }, {
                title: strings.contact2nd,
                dataIndex: 'secondcontact',
                key: 'secondcontact',
                width:'15%',
                sorter: (a, b) => a.secondcontact.localeCompare(b.secondcontact, 'en', { numeric: true }),
                render:(val, record) => (<p className='tablelongtext'>{getContactPerson(strings, record.salutation2, val.replace("()",""))}</p>),
            }, {
                title: strings.floor,
                dataIndex: 'floor',
                key: 'floor',
                width:'10%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width:'10%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
            }, {
                title: strings.carnumber,
                dataIndex: 'carNumber1',
                key: 'carNumber1',
                width:'10%',
                sorter: (a, b) => a.carNumber1.localeCompare(b.carNumber1, 'en', { numeric: true }),
                render:(val, record) => (<p className='tablelongtext' style={{width:200}}>{val}</p>),
            },
            {
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {

                        return (tag != strings.cancel1?<Tag color={'#455773'} key={tag}  style={{marginTop:5}} onClick={()=>this.ontagclick({tag},record)}>{tag.toUpperCase()}</Tag>
                        :<Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={()=>this.ontagclick({tag},record)}>
                            <Tag color={'#455773'} key={tag} style={{marginTop:5}}>{tag.toUpperCase()}</Tag>
                        </Popconfirm>);
                    })}
                    </span>
                ),
            }
        ];
        
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber',3);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({resource:sd.resource,zoomaccounts:sd.zoomAccounts,systemData:sd});
        var resource = sd.resource;
        var floors=[],locations=[];
        floors.push(strings.any);
        locations.push(strings.any);
        for(var i=0; i<resource.length; i++){
            if(RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType){
                if(locale === 'EN'){
                    if(floors.indexOf(resource[i].locationEN.split(',')[1]) == -1){
                        floors.push(resource[i].locationEN.split(',')[1]);
                    }
                }else{
                    if(floors.indexOf(resource[i].locationZH.split(',')[1]) == -1){
                        floors.push(resource[i].locationZH.split(',')[1]);
                    }
                }
            }
        }
        
        this.setState({floors:floors,locations:locations,floor:floors[0],location:locations[0]});
    }
  
    componentWillUnmount() {
  
    }

    ontagclick=(tag,b)=>{
        if(tag.tag == strings.cancel1){            
            this.removeBooking(b.id,b.key);
        }else if(tag.tag == strings.edit){
           this.setState({isediting:true,selectedBooking:b,iseditop:true}); 
        }else if(tag.tag == strings.view){
            this.setState({isediting:true,selectedBooking:b,iseditop:false}); 
        }
        
    }

    removeBooking=(id,key)=>{
        var rdata = {};
        rdata.bookingId=id;
        var url = 'booking/cancel';
        if(this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR){
            url = 'zoom/booking/cancel';
        }
        fetch(apiurl+url, {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin',  
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var data = this.state.tabledata;
                    data.splice(key,1);    
                    for(var i=0;i<data.length;i++){                        
                        data[i].key = i;
                    }                
                    this.setState({tabledata:data,count:data.length});
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    editback=()=>{
        this.setState({isediting:false,iseditop:true})
        this.searchBooking();
    }

    searchBooking=()=>{
        this.setState({loading:true})
        var rdata = {};
        rdata.resourceType = this.state.type;   
        if(this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR){
            if(this.state.zoomAccountId != 0)
                rdata.zoomAccountId = this.state.zoomAccountId;
        }else{
            var resource = this.state.resource;
            if(this.state.location == strings.any){
                for(var i=0; i<resource.length; i++){
                    if(this.state.floor === resource[i].locationZH.split(',')[1] || this.state.floor === resource[i].locationEN.split(',')[1]){
                        rdata.buildingFloorId = resource[i].buildingFloorId;
                        break;
                    }
                }
            }else{
                for(var i=0; i<resource.length; i++){
                    if((this.state.floor === resource[i].locationZH.split(',')[1] || this.state.floor === resource[i].locationEN.split(',')[1]) && (this.state.location === resource[i].locationZH.split(',')[0] || this.state.location === resource[i].locationEN.split(',')[0])){
                        rdata.resourceId = resource[i].id;
                        break;
                    }
                }
            }
        }

        if(this.state.status == 'booked'){
            rdata.state=['booked', 'confirmed'];
            rdata.sort = 'asc';
        }
        if(this.state.status == "completed"){
            rdata.state=['completed', 'system_checkout'];
            rdata.sort = 'desc';
        }
        if(this.state.status == "all"){
            //rdata.state=['completed', 'system_checkout','booked', 'pending_accept', 'confirmed'];
            rdata.sort = 'desc';
        }
        
        if((this.state.fromdate != '' && this.state.todate == '') || (this.state.fromdate == '' && this.state.todate != '') ){
            this.setState({loading:false});
            this.setState({alertmessage:strings.dateerr,showalertmodel:true})    
            
            return;            
        }else if(this.state.fromdate != '' && this.state.todate != ''){
            rdata.fromDate=this.state.fromdate;
            rdata.toDate=this.state.todate;
        }
            
        if(this.state.contactperson != ''){
            if(this.state.contactperson.length>=3){
                rdata.contactPerson=this.state.contactperson;
            }else{
                this.setState({alertmessage:strings.namelengtherrr,showalertmodel:true})                
                this.setState({loading:false});
                return;
            }
            
        }
        
        

        fetch(apiurl+'booking/list', {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin',  
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bss = data.data.bookings;
                    var bs = [];
                    for(var i=0; i<bss.length;i++){
                        bs=bs.concat(bss[i].booking)
                    }
                    if(this.state.status == 'booked')
                        bs = bs.sort((a, b) => a.startTime - b.startTime);
                    else
                        bs = bs.sort((a, b) => b.startTime - a.startTime);
                    var bs1 = [];
                    var resource = this.state.resource;
                    var zoomaccounts = this.state.zoomaccounts;
                    for(var i=0;i<bs.length;i++){
                        var b1 = {};
                        b1.key = i;
                        b1.id = bs[i].id;
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        var ds = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
                        
                        b1.date = ds;
                        b1.userId = bs[i].userId;

                        
                        if(b1.userId == ApiHeader.userId && bs[i].startTime>moment().valueOf() && bs[i].state == 'booked')
                            b1.tags = [strings.view, strings.edit,strings.cancel1];
                        else
                            b1.tags = [strings.view];
                        b1.firstcontact = bs[i].contactPerson1 +'('+bs[i].contactPhone1+')';
                        b1.secondcontact = bs[i].contactPerson2 +'('+bs[i].contactPhone2+')';
                        b1.salutation1 = bs[i].salutation1;  
                        b1.salutation2 = bs[i].salutation2;
                        if(bs[i].subject == null || bs[i].subject == "")
                            b1.subject = bs[i].reason == null ? '':bs[i].reason;
                        else
                            b1.subject = bs[i].subject;
                        
                        b1.remark = bs[i].remark;
                        b1.staffType = bs[i].staffType;
                        b1.reason = bs[i].reason;
                        b1.resourceType = this.state.type;
                        if(this.state.type === RESOURCE_TYPES.VISITOR_CARPARK){
                            b1.type = strings.visitor;
                        }else if(this.state.type == RESOURCE_TYPES.OPERATIONAL_CARPARK){
                            b1.type = strings.operational;
                        }
                        
                        b1.carNumber1 = bs[i].carNumber===null || bs[i].carNumber == 'tobeconfirmed'?'':bs[i].carNumber;
                        b1.carNumber = bs[i].carNumber===null?'':bs[i].carNumber;
                        b1.approverName = bs[i].approverName;
                        b1.approverId = bs[i].approverId;
                        b1.state = bs[i].state;
                        b1.isReminder = bs[i].isReminder;
                        b1.isElectric=bs[i].isElectric===null?'':bs[i].isElectric;
                        b1.visitorCompany=bs[i].visitorCompany===null?'':bs[i].visitorCompany;
                        b1.visitorName=bs[i].visitorName===null?'':bs[i].visitorName;
                        b1.purpose=bs[i].purpose===null?'':bs[i].purpose;
                        b1.division = bs[i].division;
                        b1.isMechanized = bs[i].isMechanized;
                        if(bs[i].state == 'system_checkout' || bs[i].state == 'completed'){
                            b1.state1 = strings.completed;
                        }

                        if(bs[i].state == 'manually_cancelled' || bs[i].state == 'admin_cancelled'){
                            b1.state1 = strings.cancelled;
                        }

                        if(bs[i].state == 'rejected' ){
                            b1.state1 = strings.rejected;
                        }

                        if(bs[i].state == 'booked' || bs[i].state == 'confirmed'){
                            b1.state1 = strings.booked;
                        }

                        if(bs[i].state == 'pending_approval' || bs[i].state == 'pending_admin' || bs[i].state == 'pending_accept'){
                            b1.state1 = strings.pending;
                        }

                        b1.chairman = bs[i].chairman;
                        b1.groupName = bs[i].groupName;

                        if(this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR){
                            b1.zoomMeetingPassword = bs[i].zoomMeetingPassword;
                            b1.zoomMeetingId = bs[i].zoomMeetingId;
                            b1.zoomJoinUrl = bs[i].zoomJoinUrl;
                            b1.setting = bs[i].setting;
                            b1.zoomAccountPassword = bs[i].zoomAccountPassword;
                            b1.zoomMeetingStatus = bs[i].zoomMeetingStatus;
                            b1.zoomDialinNo = bs[i].zoomDialinNo;
                            b1.type = strings.zoommeeting;
                            for(var j=0; j<zoomaccounts.length; j++){
                                if(bs[i].zoomAccountId === zoomaccounts[j].id){
                                    b1.zoomEmail = zoomaccounts[j].accountEmail;
                                    b1.zoomPassword = zoomaccounts[j].password;
                                    b1.resource = zoomaccounts[j];
                                   
                                    break;
                                }
                            }
                        }else{
                            for(var j=0;j<resource.length;j++){
                                if(resource[j].id === bs[i].resourceId){
                                    if(locale ==='EN'){
                                        if(this.state.type === RESOURCE_TYPES.MEETING_ROOM){
                                            b1.floor = resource[j].locationEN.split(',')[1];
                                            b1.location = resource[j].locationEN.split(',')[0];
                                        }else{
                                            b1.floor = '';
                                            b1.location = '';
                                        }
                                        
                                    }else{
                                        
                                        if(this.state.type === RESOURCE_TYPES.MEETING_ROOM){
                                            b1.floor = resource[j].locationZH.split(',')[1];
                                            b1.location = resource[j].locationZH.split(',')[0];
                                        }else{
                                            b1.floor = '';
                                            b1.location = '';
                                        }
                                    }
                                    b1.resource = resource[j];
                                    break;
                                }
                            }
                        }
                        


                        bs1.push(b1);

                    }
                    this.setState({tabledata:bs1,count:bs1.length});
                    
                
                }else{
                    this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    onfromChange=(val)=>{
        this.setState({fromdate1:val,fromdate:val.format('YYYYMMDD')})
    }

    ontoChange=(val)=>{
        this.setState({todate1:val,todate:val.format('YYYYMMDD')})
    }

    handleTypeChange=(value)=> {
        var resource = this.state.systemData.resource;
        var floors=[],locations=[];
        floors.push(strings.any);
        locations.push(strings.any);
        for(var i=0; i<resource.length; i++){
            if(value === resource[i].resourceType){
                if(locale === 'EN'){
                    if(floors.indexOf(resource[i].locationEN.split(',')[1]) == -1){
                        floors.push(resource[i].locationEN.split(',')[1]);
                    }
                }else{
                    if(floors.indexOf(resource[i].locationZH.split(',')[1]) == -1){
                        floors.push(resource[i].locationZH.split(',')[1]);
                    }
                }
            }
        }
        var zas = [];
        zas.push({id:0,nameEN:strings.any,nameZH:strings.any});
        if(value === RESOURCE_TYPES.ZOOM_MEETING || value === RESOURCE_TYPES.ZOOM_WEBINAR){
            var zas1 = this.state.systemData.zoomAccounts.filter((el)=>el.resourceType === value);
            zas = zas.concat(zas1);
        }
        this.setState({type:value,zoomaccounts:zas,zoomAccountId:zas.length>0?zas[0].id:'',floors:floors,locations:locations,floor:floors[0],location:locations[0]});
    }

    handleFloorChange=(value)=>{
        var resource = this.state.systemData.resource;
        var locations = [];
        locations.push(strings.any);
        for(var i=0; i<resource.length; i++){
            if(this.state.type === resource[i].resourceType){
                if(resource[i].locationEN.split(',')[1] ===  value){
                    locations.push(resource[i].locationEN.split(',')[0]);
                }
            }
            
        }
        this.setState({locations:locations,floor:value,location:locations[0]});
    }

    handleStatusChange=(value)=>{
        this.setState({status:value})
    }

    clearScrren=()=>{
        this.setState({
            floor:'',
            floors:[],
            location:'',
            locations:[],
            type:RESOURCE_TYPES.MEETING_ROOM,
            status:'booked',
            contactperson:'',
            fromdate:'',
            todate:'',
            fromdate1:null,
            todate1:null,
        })
        var resource = this.state.resource;
        var floors=[],locations=[];
        floors.push(strings.any);
        locations.push(strings.any);
        for(var i=0; i<resource.length; i++){
            if(RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType){
                if(locale === 'EN'){
                    if(floors.indexOf(resource[i].locationEN.split(',')[1]) == -1){
                        floors.push(resource[i].locationEN.split(',')[1]);
                    }
                    
                }else{
                    if(floors.indexOf(resource[i].locationZH.split(',')[1]) == -1){
                        floors.push(resource[i].locationZH.split(',')[1]);
                    }
                    
                }
            }
        }
        this.setState({floors:floors,locations:locations,floor:floors[0],location:locations[0]});
    }

    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
      }

    render() {
        return (
            <div className='Tab2Div'>
                {
                    this.state.isediting?(this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR?<EditZoomBooking sb={this.state.selectedBooking} isEdit={this.state.iseditop} backbtn={this.editback}/>
                    :this.state.type===RESOURCE_TYPES.MEETING_ROOM?<EditBooking sb={this.state.selectedBooking} isEdit={this.state.iseditop} backbtn={this.editback}/>
                    :<EditCarpark sb={this.state.selectedBooking} isEdit={this.state.iseditop} backbtn={this.editback}/>)                    
                    :                
                    <div>
                        <div className="FirstDiv">
                            <span className="TitleOfTheBox">{strings.searchbooking}</span>
                            <Row xs={24} >
                                <Col md={5}>
                                    <div>
                                        <span className="placeholder">{strings.type}</span>
                                    </div>
                                    <Select className="InputSizeOfThird" value={this.state.type} style={{width:260}} onChange={this.handleTypeChange}>
                                        <Option value={RESOURCE_TYPES.MEETING_ROOM}>{strings.meetingroom}</Option>
                                        <Option value={RESOURCE_TYPES.VISITOR_CARPARK}>{strings.visitorcarpark}</Option>
                                        <Option value={RESOURCE_TYPES.OPERATIONAL_CARPARK}>{strings.operationalcarpark}</Option>
                                        <Option value={RESOURCE_TYPES.ZOOM_MEETING}>{strings.zoommeeting}</Option>
                                        <Option value={RESOURCE_TYPES.ZOOM_WEBINAR}>{strings.zoomwebinar}</Option>
                                    </Select>
                                </Col>
                                <Col md={5}>
                                    <div>
                                        <span className="placeholder">{strings.from}</span>
                                        <div style={{ marginBottom: 16 }}>
                                        <DatePicker className="InputSizeOfThird" value={this.state.fromdate1} onChange={this.onfromChange} />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div>
                                        <span className="placeholder">{strings.to}</span>
                                    </div>
                                    <DatePicker className="InputSizeOfThird" value={this.state.todate1} onChange={this.ontoChange} />
                                </Col>
                                    <Col md={5}>
                                    <div>
                                        <span className="placeholder">{strings.status}</span>
                                    </div>
                                    <Select className="InputSizeOfThird" value={this.state.status} onChange={this.handleStatusChange}>
                                        <Option value="booked">{strings.booked}</Option>
                                        <Option value="completed">{strings.completed}</Option>
                                        <Option value="all">{strings.all}</Option>
                                    </Select>
                                </Col>
                            </Row>

                            {this.state.type !== RESOURCE_TYPES.ZOOM_MEETING && this.state.type !== RESOURCE_TYPES.ZOOM_WEBINAR && <Row md={24} style={{height:73}}>
                                <Col md={5} style={{marginTop:-10}}>
                                <div>
                                    <span className="placeholder">{strings.floor}</span>
                                </div>
                                <Select className="InputSizeOfThird" value={this.state.floor} style={{width:260}} onChange={this.handleFloorChange}>
                                    {
                                        this.state.floors.map((ed,index) =><Option key={index} value={ed}>{ed}</Option>)
                                    }                            
                                </Select>
                                </Col>
                                <Col md={5}  style={{marginTop:-10}}>
                                <div>
                                    <span className="placeholder">{strings.location}</span>
                                    <div style={{ marginBottom: 16 }}>
                                    <Select className="InputSizeOfThird" value={this.state.location} onChange={val=>this.setState({location:val})}>
                                    {
                                        this.state.locations.map((ed,index) =><Option key={index} value={ed}>{ed}</Option>)
                                    }
                                    </Select>
                                        </div>
                                </div>
                                </Col>
                                <Col md={5}  style={{marginTop:-10}}>
                                <div>
                                    <span className="placeholder">{strings.contactperson}</span>
                                </div>
                                <Input className="InputSizeOfThird" value={this.state.contactperson} onChange={e=>this.setState({contactperson:e.target.value})}/>
                                </Col>
                            </Row>}
                            {(this.state.type === RESOURCE_TYPES.ZOOM_MEETING || this.state.type === RESOURCE_TYPES.ZOOM_WEBINAR) && <Row md={24} style={{height:73}}>
                                <Col md={5} style={{marginTop:-10}}>
                                <div>
                                    <span className="placeholder">{strings.zoomacc}</span>
                                </div>
                                <Select className="InputSizeOfThird" value={this.state.zoomAccountId} style={{width:260}} onChange={e=>this.setState({zoomAccountId:e})}>
                                    {
                                        this.state.zoomaccounts.map((ed,index) =><Option key={index} value={ed.id}>{locale === 'EN'?ed.nameEN:ed.nameZH}</Option>)
                                    }                            
                                </Select>
                                </Col>
                                <Col md={5}  style={{marginTop:-10}}></Col>
                                <Col md={5}  style={{marginTop:-10}}></Col>
                            </Row>}
                            <Button type="primary" size='large' style={{marginRight: 17}}  loading={this.state.loading} onClick={this.searchBooking}>
                                {strings.search}
                            </Button>
                            <Button size='large' style={{marginRight: 20}}  onClick={this.clearScrren}>
                                {strings.clear}
                            </Button>
                        </div>

                        <div>
                            <div className="table-operations">
                            </div>
                            <Table style={{marginTop:12,backgroundColor:'#fff'}} columns={this.columns} dataSource={this.state.tabledata} />
                            <div className="CountOfData">
                                <span className="CountText">{strings.totalcount} {this.state.count}</span>
                            </div>        
                        </div>
                        <Modal
                            title={strings.alert}
                            visible={this.state.showalertmodel}
                            onCancel={()=>this.setState({showalertmodel:false})}
                            footer={[                  
                                <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerReload)window.location.reload();}}>
                                {strings.ok}
                                </Button>,
                            ]}
                            >
                                <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                                <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                        </Modal>
                    </div>
                }
            </div>
        );
    }
}    
export default Schedule;

