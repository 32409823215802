import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col, Calendar, Button, Spin, Modal } from "antd";
import "antd/dist/antd.css";
import moment from 'moment';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES } from './data';
const timelines = ['8AM', '8:15AM', '8:30AM', '8:45AM',
  '9AM', '9:15AM', '9:30AM', '9:45AM', '10AM', '10:15AM', '10:30AM', '10:45AM', '11AM', '11:15AM', '11:30AM', '11:45AM',
  '12PM', '12:15PM', '12:30PM', '12:45PM', '1PM', '1:15PM', '1:30PM', '1:45PM', '2PM', '2:15PM', '2:30PM', '2:45PM',
  '3PM', '3:15PM', '3:30PM', '3:45PM', '4PM', '4:15PM', '4:30PM', '4:45PM', '5PM', '5:15PM', '5:30PM', '5:45PM',
  '6PM', '6:15PM', '6:30PM', '6:45PM', '7PM', '7:15PM', '7:30PM', '7:45PM', '8PM', '8:15PM', '8:30PM', '8:45PM',
  '9PM','9:15PM','9:30PM','9:45PM','10PM'];
const timelines1 = ['08:00','08:15','08:30', '08:45',
  '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
  '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45',
  '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45',
  '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45',
  '21:00','21:15', '21:30', '21:45','22:00'];
const locale = 'ZH';
const thds = ['8am-9am', '9am-10am', '10am-11am', '11am-12pm', '12pm-1pm', '1pm-2pm', '2pm-3pm', '3pm-4pm', '4pm-5pm', '5pm-6pm', '6pm-7pm', '7pm-8pm', '8pm-9pm','9pm-10pm'];

export default class TimelineCarparking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthOne: new Date().getMonth() + 1,
      monthTwo: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getMonth() + 1,
      monthThree: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getMonth() + 1,
      monthFour: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getMonth() + 1,
      monthFive: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getMonth() + 1,
      monthSix: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getMonth() + 1,
      monthSeven: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getMonth() + 1,
      dayOne: new Date().getDate(),
      dayTwo: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getDate(),
      dayThree: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getDate(),
      dayFour: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getDate(),
      dayFive: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getDate(),
      daySix: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getDate(),
      daySeven: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getDate(),


      d1: new Date().getDay(),
      d2: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getDay(),
      d3: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getDay(),
      d4: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getDay(),
      d5: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getDay(),
      d6: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getDay(),
      d7: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getDay(),
      firstday: this.props.details ? new Date(this.props.details._d) : new Date(),
      dateDetails: this.props.details ? new Date(this.props.details._d) : new Date(),
      dateDetails1: this.props.details ? new Date(this.props.details._d) : new Date(),
      resources: [],
      equipment: [],
      booking: [],
      maintenance: [],
      rooms: [],
      trs: [],
      loading: false,
      triggerReload: false,
      showalertmodel: false,
      alertmessage: "",
      vrids: [],
      trids: [],
      isfp: false,
    };
  }



  componentDidMount() {
    var d = sessionStorage.getItem('systemdata');
    ApiHeader.userId = sessionStorage.getItem("userId");
    var sd = JSON.parse(d);
    var resource = sd.resource;
    this.setState({ resources: sd.resource, equipment: sd.equipment, systemData: sd });
    var rooms = [], vrids = [], trids = [];
    for (var i = 0; i < resource.length; i++) {
      if (RESOURCE_TYPES.VISITOR_CARPARK === resource[i].resourceType)
        vrids.push(resource[i].id);
      else if (RESOURCE_TYPES.OPERATIONAL_CARPARK === resource[i].resourceType)
        trids.push(resource[i].id);
    }
    rooms.push({ name: strings.visitor, id: 1 });
    rooms.push({ name: strings.operational, id: 2 });
    this.setState({ rooms: rooms, vrids: vrids, trids: trids });
  }


  componentWillUnmount() {

  }


  getBookingForDay = (date) => {
    var rdata = {};
    var d;
    if (date == null) {
      d = this.state.dateDetails;
    } else {
      d = date;
    }
    var rdata = {};
    rdata.date = moment(d).unix()*1000;
    fetch(apiurl + 'resource/currentvisitorparking', {
        headers: ApiHeader,
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(rdata),
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong ...');
        }
    })
    .then(data => {
        if (data.code == "1") {
            var vbs = data.data.vbs;
            var tbs = data.data.tbs;
            var specificcurrent = data.data.active1;
            var defaultcurrent = data.data.active2;
            var vtcount = 0, otcount = 0; 
            if(specificcurrent){
              vtcount = specificcurrent.visitorQuota;
              otcount = specificcurrent.operationalQuota;
            }else{
              vtcount = defaultcurrent.visitorQuota;
              otcount = defaultcurrent.operationalQuota;
            }
            var trs = [];
            var tds = [], tds1 = [];
            var datestr = moment(d).format('YYYY-MM-DD') + ' 00:00:00';
            var ts = moment(datestr, "YYYY-MM-DD HH:mm:SS").valueOf();
            ts = (ts / 1000) + 28800;
            for (var i = 0; i < timelines.length; i++) {
                if (i + 1 === timelines.length) {
                    break;
                }

                var vcount = 0, ocount = 0;
                var st = ts + (i * 900);
                var et = ts + ((i + 1) * 900);

                for (var b = 0; b < vbs.length; b++) {
                    var sd = vbs[b].startTime / 1000;
                    var ed = vbs[b].groupEndTime ? (vbs[b].groupEndTime/1000) : (vbs[b].endTime/1000);
                    if (sd < et && ed > st) {
                        vcount = vcount + 1;
                    }
                }
                var datestr = moment(d).format('YYYY-MM-DD')+' '+timelines1[i]
                var cdate = moment(datestr,'YYYY-MM-DD HH:mm');
                if(cdate.valueOf()<=moment().valueOf() || ['9:15PM','9:30PM','9:45PM','9PM'].includes(timelines[i])){
                  tds.push({ id: i+"-1", isBooked: true });
                }else{
                  tds.push({ id: i+"-1", isBooked: vcount>=vtcount?true:false });
                }
                

                for (var b = 0; b < tbs.length; b++) {
                    var sd = tbs[b].startTime / 1000;
                    var ed = tbs[b].groupEndTime ? (tbs[b].groupEndTime/1000) : (tbs[b].endTime/1000);
                    if (sd < et && ed > st) {
                        ocount = ocount + 1;
                    }
                }
                if(cdate.valueOf()<=moment().valueOf() || ['9:15PM','9:30PM','9:45PM','9PM'].includes(timelines[i])){
                  tds1.push({ id: i+"-2", isBooked: true });
                }else{
                  tds1.push({ id: i+"-2", isBooked: ocount>=otcount?true:false });
                }
                
            }
            trs.push({ id: 1, tds: tds });
            trs.push({ id: 2, tds: tds1 });
            this.setState({ trs: trs})
        } else {
            this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
        }
        this.setState({ loading: false })
    })
    .catch(e => {
        console.warn(e);
        this.setState({ loading: false });
    })
  }  

  forward() {
    let dateDetails = this.state.firstday;
    this.setState({
      dateDetails: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)),
      dateDetails1: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)),
      firstday: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)),
      dayOne: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)).getDate(),
      dayTwo: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 8)).getDate(),
      dayThree: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 9)).getDate(),
      dayFour: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 10)).getDate(),
      dayFive: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 11)).getDate(),
      daySix: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 12)).getDate(),
      daySeven: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 13)).getDate(),

      monthOne: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)).getMonth() + 1,
      monthTwo: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 8)).getMonth() + 1,
      monthThree: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 9)).getMonth() + 1,
      monthFour: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 10)).getMonth() + 1,
      monthFive: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 11)).getMonth() + 1,
      monthSix: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 12)).getMonth() + 1,
      monthSeven: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 13)).getMonth() + 1,

      d1: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 7)).getDay(),
      d2: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 8)).getDay(),
      d3: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 9)).getDay(),
      d4: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 10)).getDay(),
      d5: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 11)).getDay(),
      d6: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 12)).getDay(),
      d7: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 13)).getDay(),

    })
    this.refs.d7.className = this.refs.d6.className = this.refs.d5.className = this.refs.d4.className = this.refs.d3.className = this.refs.d2.className = this.refs.d1.className = 'TimeRangeBox';
    this.refs.d1.className = "TimeRangeBox1";
    var self = this;
    this.setState({ loading: true });
    setTimeout(function () {
      self.getBookingForDay(null);
    }, 500);

  }

  backward() {
    let dateDetails = this.state.firstday;
    this.setState({
      dateDetails: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)),
      dateDetails1: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)),
      firstday: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)),
      dayOne: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)).getDate(),
      dayTwo: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 6)).getDate(),
      dayThree: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 5)).getDate(),
      dayFour: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 4)).getDate(),
      dayFive: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 3)).getDate(),
      daySix: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 2)).getDate(),
      daySeven: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 1)).getDate(),

      monthOne: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)).getMonth() + 1,
      monthTwo: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 6)).getMonth() + 1,
      monthThree: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 5)).getMonth() + 1,
      monthFour: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 4)).getMonth() + 1,
      monthFive: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 3)).getMonth() + 1,
      monthSix: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 2)).getMonth() + 1,
      monthSeven: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 1)).getMonth() + 1,

      d1: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 7)).getDay(),
      d2: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 6)).getDay(),
      d3: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 5)).getDay(),
      d4: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 4)).getDay(),
      d5: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 3)).getDay(),
      d6: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 2)).getDay(),
      d7: new Date(dateDetails.getTime() - (1000 * 60 * 60 * 24 * 1)).getDay(),

    })
    this.refs.d7.className = this.refs.d6.className = this.refs.d5.className = this.refs.d4.className = this.refs.d3.className = this.refs.d2.className = this.refs.d1.className = 'TimeRangeBox';
    this.refs.d1.className = "TimeRangeBox1";
    var self = this;
    this.setState({ loading: true });
    setTimeout(function () {
      self.getBookingForDay(null);
    }, 500);
  }

  today() {
    this.setState({
      monthOne: new Date().getMonth() + 1,
      monthTwo: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getMonth() + 1,
      monthThree: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getMonth() + 1,
      monthFour: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getMonth() + 1,
      monthFive: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getMonth() + 1,
      monthSix: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getMonth() + 1,
      monthSeven: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getMonth() + 1,
      dayOne: new Date().getDate(),
      dayTwo: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getDate(),
      dayThree: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getDate(),
      dayFour: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getDate(),
      dayFive: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getDate(),
      daySix: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getDate(),
      daySeven: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getDate(),


      d1: new Date().getDay(),
      d2: new Date(Date.now() + (1000 * 60 * 60 * 24 * 1)).getDay(),
      d3: new Date(Date.now() + (1000 * 60 * 60 * 24 * 2)).getDay(),
      d4: new Date(Date.now() + (1000 * 60 * 60 * 24 * 3)).getDay(),
      d5: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).getDay(),
      d6: new Date(Date.now() + (1000 * 60 * 60 * 24 * 5)).getDay(),
      d7: new Date(Date.now() + (1000 * 60 * 60 * 24 * 6)).getDay(),

      dateDetails: new Date(),
      dateDetails1: new Date()
    })
    this.refs.d7.className = this.refs.d6.className = this.refs.d5.className = this.refs.d4.className = this.refs.d3.className = this.refs.d2.className = this.refs.d1.className = 'TimeRangeBox';
    this.refs.d1.className = "TimeRangeBox1";
    var self = this;
    this.setState({ loading: true });
    setTimeout(function () {
      self.getBookingForDay(null);
    }, 500);
  }


  componentWillReceiveProps = (nextProps) => {
    if (nextProps.changeDay == false) {
      return;
    }
    let dateDetails = nextProps.details ? nextProps.details._d : new Date();
    if (this.state.isfp != nextProps.changeDay) {
      this.setState({ isfp: nextProps.changeDay })
    }
    this.setState({
      dateDetails: dateDetails,
      dateDetails1: dateDetails,
      dayOne: new Date(dateDetails.getTime()).getDate(),
      dayTwo: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 1)).getDate(),
      dayThree: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 2)).getDate(),
      dayFour: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 3)).getDate(),
      dayFive: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 4)).getDate(),
      daySix: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 5)).getDate(),
      daySeven: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 6)).getDate(),

      monthOne: dateDetails.getMonth() + 1,
      monthTwo: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 1)).getMonth() + 1,
      monthThree: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 2)).getMonth() + 1,
      monthFour: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 3)).getMonth() + 1,
      monthFive: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 4)).getMonth() + 1,
      monthSix: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 5)).getMonth() + 1,
      monthSeven: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 6)).getMonth() + 1,


      d1: dateDetails.getDay(),
      d2: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 1)).getDay(),
      d3: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 2)).getDay(),
      d4: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 3)).getDay(),
      d5: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 4)).getDay(),
      d6: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 5)).getDay(),
      d7: new Date(dateDetails.getTime() + (1000 * 60 * 60 * 24 * 6)).getDay(),
      firstday: dateDetails,

    })
    this.refs.d7.className = this.refs.d6.className = this.refs.d5.className = this.refs.d4.className = this.refs.d3.className = this.refs.d2.className = this.refs.d1.className = 'TimeRangeBox';
    this.refs.d1.className = "TimeRangeBox1";


    var self = this;
    this.setState({ loading: true });
    setTimeout(function () {
      self.getBookingForDay(null);
    }, 500);


  }

  changeday = (day) => {
    this.refs.d7.className = this.refs.d6.className = this.refs.d5.className = this.refs.d4.className = this.refs.d3.className = this.refs.d2.className = this.refs.d1.className = 'TimeRangeBox';
    if (day === 0) {
      this.refs.d1.className = "TimeRangeBox1";
    } else if (day === 1) {
      this.refs.d2.className = "TimeRangeBox1";
    } else if (day === 2) {
      this.refs.d3.className = "TimeRangeBox1";
    } else if (day === 3) {
      this.refs.d4.className = "TimeRangeBox1";
    } else if (day === 4) {
      this.refs.d5.className = "TimeRangeBox1";
    } else if (day === 5) {
      this.refs.d6.className = "TimeRangeBox1";
    } else if (day === 6) {
      this.refs.d7.className = "TimeRangeBox1";
    }
    var d = this.state.dateDetails1;
    var d1 = new Date(d.getTime() + day * 24 * 60 * 60 * 1000);
    this.setState({ loading: true, dateDetails: d1 });
    this.getBookingForDay(d1);

  }

  renderTr = () => {
    var rds = [];
    this.state.trs.map((d) => {
      rds.push(<tr key={d.id} className='tablerowb'>{this.renderTd(d.tds)}</tr>);
    });
    return rds;
  }

  renderTd = (tds) => {
    var rds = [];
    tds.map((d) => {
      rds.push(<td key={d.id} style={{ textAlign: 'center' }} className={d.isBooked?'disabledtd':''} onClick={() => this.bktdclick(this.state.dateDetails, d.id.split('-')[1])}>{d.count}</td>);
    });
    return rds;
  }

  renderHeaderTr = () => {
    var rds = [];
    rds.push(<tr key='999' >{this.renderheaderTd()}</tr>);
    return rds;
  }

  renderheaderTd = () => {
    var rhds = [];
    thds.map((d) => {
      rhds.push(<td key={d.id} className='tablerow2tdb'>{d}</td>);
    });
    return rhds;
  }

  renderRespurce = () => {
    var res = [];
    res.push(<tr key='999' className='tablerow1b'><td className='roomnamecolb' style={{ textAlign: 'center' }}>{strings.type}</td></tr>);
    this.state.rooms.map((d) => {
      res.push(<tr key={d.id} className='tablerow1b' ><td>{d.name}<br/>{d.id === 1 ? strings.visitorcapacity : strings.operationalcapacity}</td></tr>);
    })
    return res;
  }


  bktdclick = (datedetails, type) => {
    this.props.tdclick(datedetails, type, this.state.trs);
  }



  findErrorMessage = (msg) => {
    return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
  }

  render() {
    let daysArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let monthsArray = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]; const { dateDetails, dayOne, dayTwo, dayThree, dayFour, dayFive, daySeven, daySix, monthFive, monthFour, monthOne, monthSeven, monthSix, monthThree,
      monthTwo, d1, d3, d2, d4, d5, d6, d7 } = this.state
    let monthStr = "";
    (monthsArray[monthOne] !== monthsArray[monthSeven]) ?
      monthStr = monthsArray[monthOne] + " - " + monthsArray[monthSeven] + " " + dateDetails.getFullYear()
      : monthStr = monthsArray[monthOne] + " " + dateDetails.getFullYear()



    return (
      <div className="SecondBox" style={{ marginTop: 0, backgroundColor: 'rgba(0,0,0,0)' }} >

        <Row lg={24} style={{ marginBottom: 15 }}>
          <Col lg={12}>
            <span style={{ fontSize: "1.4rem", fontWeight: "bold", float: 'right', marginTop: 6 }}>{monthStr}</span>
          </Col>
          <Col lg={6}>
            <Button onClick={this.today.bind(this)} size='large' style={{ marginLeft: 15, marginTop: 8 }} >{strings.today}</Button>
          </Col>
        </Row>
        <Row lg={24} >
          <Col lg={3} className='firstbox'>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',alignItems:'center',textAlign:'center',height:'100%',cursor:'pointer'}}>
              <span className='prevnextbtn' onClick={this.backward.bind(this)}>
                {'< '+strings.previous}
              </span>
              <span className='prevnextbtn' style={{borderLeftWidth:0}} onClick={this.forward.bind(this)}>
                {strings.next+' >'}
              </span>
            </div>                        
          </Col>  
          <Col lg={3} className='dayboxs'>
            <div className="TimeRangeBox1" ref="d1" style={{ borderLeft: '0.5px solid white' }} onClick={() => this.changeday(0)}>
              <span style={{ color: '#405069', fontWeight: '500' }}>{monthOne < 10 ? "0" + monthOne : monthOne}-{dayOne < 10 ? "0" + dayOne : dayOne}<span style={{ color: '#ff7f27' }}>({daysArray[d1]})</span></span>
            </div>
          </Col>
          <Col lg={3} className='dayboxs'>
            <div className="TimeRangeBox" ref="d2" onClick={() => this.changeday(1)}>
              <span style={{ color: '#405069', fontWeight: '500', }}>{monthTwo < 10 ? "0" + monthTwo : monthTwo}-{dayTwo < 10 ? "0" + dayTwo : dayTwo}<span style={{ color: '#ff7f27' }}>({daysArray[d2]})</span></span>

            </div>
          </Col>
          <Col lg={3} className='dayboxs'>
            <div className="TimeRangeBox" ref="d3" onClick={() => this.changeday(2)}>
              <span style={{ color: '#405069', fontWeight: '500', }}>{monthThree < 10 ? "0" + monthThree : monthThree}-{dayThree < 10 ? "0" + dayThree : dayThree}<span style={{ color: '#ff7f27' }}>({daysArray[d3]})</span></span>
            </div>
          </Col>
          <Col lg={3} className='dayboxs'>
            <div className="TimeRangeBox" ref="d4" onClick={() => this.changeday(3)}>
              <span style={{ color: '#405069', fontWeight: '500', }}>{monthFour < 10 ? "0" + monthFour : monthFour}-{dayFour < 10 ? "0" + dayFour : dayFour}<span style={{ color: '#ff7f27' }}>({daysArray[d4]})</span></span>

            </div>
          </Col>
          <Col lg={3} className='dayboxs'>
            <div className="TimeRangeBox" ref="d5" onClick={() => this.changeday(4)}>
              <span style={{ color: '#405069', fontWeight: '500', }}>{monthFive < 10 ? "0" + monthFive : monthFive}-{dayFive < 10 ? "0" + dayFive : dayFive}<span style={{ color: '#ff7f27' }}>({daysArray[d5]})</span></span>

            </div>
          </Col>
          <Col lg={3} className='dayboxs'>
            <div className="TimeRangeBox" ref="d6" onClick={() => this.changeday(5)}>
              <span style={{ color: '#405069', fontWeight: '500', }}>{monthSix < 10 ? "0" + monthSix : monthSix}-{daySix < 10 ? "0" + daySix : daySix}<span style={{ color: '#ff7f27' }}>({daysArray[d6]})</span></span>
            </div>
          </Col>
          <Col lg={3} className='dayboxs'>
            <div className="TimeRangeBox" ref="d7" onClick={() => this.changeday(6)}>
              <span style={{ color: '#405069', fontWeight: '500' }}>{monthSeven < 10 ? "0" + monthSeven : monthSeven}-{daySeven < 10 ? "0" + daySeven : daySeven}<span style={{ color: '#ff7f27' }}>({daysArray[d7]})</span></span>
            </div>
          </Col>
        </Row>
        <Spin spinning={this.state.loading} >
          <Row lg={24} style={{ height: 600}}>
            <Col lg={3} className='dayboxs1'>
              <div style={{ marginTop: 0, backgroundColor: '#fff', }}>
                <table>
                  <tbody>
                    {this.renderRespurce()}
                  </tbody>
                </table>
              </div>
            </Col>
            <Col lg={18} className='timeslot-maindivb'>
              <table>
                <tbody>
                  {this.renderHeaderTr()}
                </tbody>
              </table>
              <table style={{ marginTop: -1 }}>
                <tbody>
                  {this.renderTr()}
                </tbody>
              </table>

            </Col>
          </Row>
        </Spin>
        <Modal
          title={strings.alert}
          visible={this.state.showalertmodel}
          onCancel={() => this.setState({ showalertmodel: false })}
          footer={[
            <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerReload) window.location.reload(); }}>
              {strings.ok}
            </Button>,
          ]}
        >
          <span className="TitleOfTheBox">{strings.errormessage}</span><br />
          <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
        </Modal>
      </div>

    );
  }
}
